<template>
  <CrowdfundingItem
    v-for="item in features"
    :key="item.title"
    :title="item.title"
    :description="item.description"
    :wallet="item.walletAddress"
    :amount="item.amountCollected"
    :target="item.amountRequired"
    @reload="fetchFeatures"
  />
</template>

<script>
import CrowdfundingItem from '@/modules/features/Crowdfunding/CrowdfundingItem';
import { useStore } from 'vuex';
import { computed, onMounted } from 'vue';

export default {
  components: {
    CrowdfundingItem,
  },
  data() {
    return {};
  },
  setup() {
    const store = useStore();

    const features = computed(() => {
      return store.getters['features/getFeatures'];
    });

    const fetchFeatures = () => {
      store.dispatch('features/fetchFeatures');
    };

    onMounted(() => {
      fetchFeatures();
    });

    return { features, fetchFeatures };
  },
};
</script>
