import { connApp } from '@/helpers/signalR';

export const getBountyBalance = async () => {
  return await connApp.invoke('GetBountyBalance');
};

export const getBountyTasks = async () => {
  return await connApp.invoke('GetBountyStatus');
};

export const getPositionStatus = async () => {
  return await connApp.invoke('IsOpenedBountyPosition');
};

export const proposeBountyPosition = (msg, amount) => {
  return connApp.invoke('ProposeBountyPosition', {
    TransactionMessage: msg,
    TransactionAmount: amount,
  });
};
