<template>
  <Modal
    @close="$emit(EVENTS.close)"
    :stop-mobile-max-height="true"
    style="z-index: 10050"
  >
    <Card
      :title="$t('tradingSession.titles.risk')"
      class="card"
      :body-class="'card-full'"
    >
      <div class="modal-card-content">
        <p>
          {{ $t('tradingSession.titles.info8') }}
        </p>
        <p>
          {{
            $t('tradingSession.titles.info9', { settlement: nextSettlement })
          }}
        </p>
        <p>
          {{ $t('tradingSession.titles.info10') }}
        </p>
        <h4>
          {{ $t('tradingSession.titles.tradingSession') }}
        </h4>
        <div class="session-wrap">
          <SessionInfo style="overflow: visible" hide-toggle />
        </div>
        <p>
          {{ $t('tradingSession.titles.DXS') }}
          <span class="underline cursor-pointer" @click="openLiquidity">
            {{ $t('tradingSession.titles.providers') }}
          </span>
          {{ $t('tradingSession.titles.earn') }}
        </p>
      </div>
      <div class="btns">
        <Button
          :text="$t('popups.buttons.cancel')"
          type="secondary"
          @click="handleCancel"
        />
        <Button
          :text="$t('popups.buttons.continue')"
          type="secondary"
          @click="handleClose"
        />
      </div>
    </Card>
  </Modal>
</template>

<script>
import Modal from '@/modules/modals/Modal';
import Card from '@/components/ui/Card';
import Button from '@/components/ui/Button';
import SessionInfo from '@/modules/session/SessionInfo.vue';

import DateTime from 'luxon/src/datetime.js';
import { formatMinutes } from '@/helpers/utils';
import { defineComponent, computed, watch, ref } from 'vue';
import { useStore } from 'vuex';
import { useModals } from '@/modules/modals/api';
import useUserActions from '@/compositions/useUserActions';

const ONE_MINUTE = 60 * 1000;

const EVENTS = {
  close: 'close',
};

export default defineComponent({
  name: 'SessionInfoModal',
  components: {
    Modal,
    Card,
    Button,
    SessionInfo,
  },
  props: {
    hideToggle: {
      type: Boolean,
      default: false,
    },
  },
  emits: { ...Object.values(EVENTS) },
  setup(_, { emit }) {
    const { showModal, modalsByName } = useModals();

    const store = useStore();

    const sessionEnd = computed(() => store.getters['session/info'].sessionEnd);

    const nextSettlement = ref('');

    const calcNextSettlement = () => {
      nextSettlement.value = formatMinutes(
        DateTime.fromISO(sessionEnd.value).diffNow('minutes').minutes
      );
    };

    const nextSettlementInterval = ref(
      setInterval(calcNextSettlement, ONE_MINUTE)
    );

    watch(
      () => sessionEnd.value,
      () => {
        clearInterval(nextSettlementInterval.value);
        nextSettlementInterval.value = setInterval(
          calcNextSettlement,
          ONE_MINUTE
        );
      }
    );

    const handleClose = () => {
      showModal(modalsByName.positionClose);
      emit(EVENTS.close);
    };

    const handleCancel = () => {
      emit(EVENTS.close);
    };

    return {
      EVENTS,
      nextSettlement,
      handleClose,
      handleCancel,
      ...useUserActions(),
    };
  },
});
</script>

<style lang="scss" scoped>
.modal-card-content {
  height: auto;
  max-height: calc(100% - 162px);
  overflow-y: auto;
  font-family: Gotham_Pro_Regular;
  line-height: 18px;
  margin-bottom: 60px;
  cursor: default;

  @media screen and (max-width: 1024px) {
    max-height: calc(100dvh - 182px) !important;
  }

  @media screen and (min-width: 1024px) {
    .session-wrap {
      padding: 0 20px;
    }
  }

  > p,
  h4 {
    padding: 0 20px 15px;
    margin: 0;

    @media screen and (min-width: 1024px) {
      padding: 0 40px 15px;
    }
  }

  .underline {
    text-decoration-line: underline;
  }

  .cursor-pointer {
    cursor: pointer;
  }
}

.btns {
  width: 100%;
  margin-top: 30px;
  position: absolute;
  bottom: 60px;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  a {
    text-decoration: none;
  }

  @media screen and (min-width: 1024px) {
    bottom: 0;
  }
}
</style>
