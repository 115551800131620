/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'sell': {
    width: 23.383,
    height: 23.382,
    viewBox: '0 0 23.383 23.382',
    data: '<path pid="0" d="M19.365.058H4.019a3.972 3.972 0 00-3.96 3.96v15.345a3.972 3.972 0 003.96 3.96h15.346a3.972 3.972 0 003.96-3.96V4.019a3.972 3.972 0 00-3.96-3.961zm-2.559 10.163l-3.989 5.78c-.619.896-1.631.896-2.249 0l-3.989-5.78c-.62-.896-.234-1.629.855-1.629h8.517c1.088 0 1.473.733.855 1.629z" _fill="#ff3030"/>'
  }
})
