/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'starfill': {
    width: 16,
    height: 15.219,
    viewBox: '0 0 16 15.219',
    data: '<path pid="0" d="M8 0l2.472 5.009L16 5.812 12.001 9.71l.943 5.509L8 12.619l-4.947 2.6.944-5.509L0 5.812l5.526-.803z"/>'
  }
})
