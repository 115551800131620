<template>
  <div
    :class="{
      demo: !activeConnect.provider,
      isWeb,
      standalone:
        wd.navigator.standalone || isRunningStandaloneAndroid() || isTelegram,
      isFiorinNullableBalance,
    }"
    class="market-tabs-filter"
  >
    <div class="row row-absolute">
      <transition name="fade">
        <TabButton
          v-if="showAllMarkets"
          max
          active
          :title="$t('markets.showAll')"
          :class="{ 'mr-10': !isWeb }"
          @click="toggle(false)"
        />
        <div class="row row-absolute" v-else>
          <TabButton
            max
            is-opacity
            :title="$t('markets.watchList')"
            :class="{ active: activeIndex === 0 }"
            class="mr-10"
            @click="selectTab(0)"
          />
          <TabButton
            max
            is-opacity
            :title="$t('markets.trending')"
            :class="{ active: activeIndex === 1 }"
            @click="selectTab(1)"
          />
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import TabButton from '@/components/ui/TabButton.vue';
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { isRunningStandaloneAndroid } from '@/helpers/detects';
import { useI18n } from 'vue-i18n';
import { useFiorinBalanceNull } from '@/compositions/useFiorinBalanceNull';

export default {
  components: {
    TabButton,
  },
  props: {
    activeIndex: {
      type: Number,
      default: 0,
    },
    isWeb: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const store = useStore();
    const showAllMarkets = ref(false);
    const cacheMarket = ref(2);
    const { isFiorinNullableBalance } = useFiorinBalanceNull();

    const tabsItems = ref([
      { icon: 'starfill', width: 12, filter: '_star' },
      { icon: 'fire', width: 12, filter: '_fire' },
      { title: t('markets.crypto'), width: 36, filter: 'crypto' },
      { title: t('markets.commodities'), width: 40, filter: 'commodities' },
      { title: t('markets.stocks'), width: 34, filter: 'stocks' },
      { title: t('markets.indices'), width: 34, filter: 'indices' },
      { title: t('markets.forex'), width: 32, filter: 'forex' },
    ]);

    const activeConnect = computed(
      () => store.getters['connectors/activeConnect']
    );

    const toggle = (flag) => {
      showAllMarkets.value = flag;
      emit('toggleFilter', flag);

      if (flag === false) {
        emit('setTabFromCache', cacheMarket.value);
      }

      if (window.document.body.clientWidth <= 480) {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }
    };

    const selectTab = (ndx, autoTrigger = false) => {
      if (ndx === 0 || ndx === 1) {
        cacheMarket.value = props.activeIndex;
        toggle(true);
      }

      emit('change', {
        type: tabsItems.value[ndx].filter,
        index: ndx,
        autoTrigger,
      });
    };

    onMounted(() => {
      if (props.activeIndex === 0 || props.activeIndex === 1) {
        selectTab(props.activeIndex);
      }
    });

    const isTelegram = computed(() =>
      Boolean(store.getters['telegram/instance'])
    );

    return {
      wd: window,
      activeConnect,
      showAllMarkets,
      selectTab,
      toggle,
      isRunningStandaloneAndroid,
      isTelegram,
      isFiorinNullableBalance,
    };
  },
};
</script>
<style lang="scss" scoped>
.market-tabs-filter {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 100%;
  height: 84px;
  min-height: 84px;
  max-height: 84px;

  left: 0;
  padding: 20px;
  bottom: 50px;
  transition: all 0.2s ease-in-out;

  @media (max-width: 480px) {
    // background: linear-gradient(to bottom, #04040400, #a6a6a642);
  }

  &.isWeb {
    height: 60px;
    min-height: 60px;
    max-height: 60px;
    background: none;
    padding: 11px 20px;
  }

  @media (min-width: 1024px) {
    position: initial;
    background: none;
  }

  &.standalone {
    bottom: 80px;
  }

  &.demo {
    bottom: 120px;

    &.standalone {
      bottom: 145px;
    }
  }

  &.isFiorinNullableBalance {
    bottom: 110px;

    &.standalone {
      bottom: 142px;
    }
  }

  .row {
    position: relative;
    display: flex;
    align-items: center;
    height: 44px;
    padding: 0 23px 0 19px;

    @media (max-width: 480px) {
      padding: 0 20px;
    }

    &-absolute {
      position: absolute;
      left: 0;
      width: 100%;
      height: 44px;
    }
  }

  .mr-10 {
    margin-right: 10px;
  }
}

body.dark {
  .market-tabs-filter {
    @media (max-width: 480px) {
      // background: linear-gradient(to bottom, #04040430, #121722);
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.4s ease-out;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
