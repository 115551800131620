<template>
  <div
    v-for="(n, k) in 15"
    :key="k"
    :class="{ isGroup: [0, 4, 7, 10].includes(k) }"
    class="skeleton-markets"
  />
</template>
<script>
export default {
  name: 'SkeletonMarkets',
};
</script>
<style lang="scss" scoped>
.skeleton-markets {
  width: 100%;
  height: 50px;
  background: linear-gradient(130deg, #adababb8, #ebebebb8, #fff7f7b0);
  background-size: 200% 200%;
  -webkit-animation: Animation 2s ease infinite;
  -moz-animation: Animation 2s ease infinite;
  animation: Animation 2s ease infinite;
  border-bottom: 1px solid #eaeaea;

  &.isGroup {
    height: 23px;
    -webkit-animation: Animation 0s ease infinite;
    -moz-animation: Animation 0s ease infinite;
    animation: Animation 0s ease infinite;
    background: #dce1e6;
  }

  &:first-child {
    border-top: 1px solid #eaeaea;
  }
}

body.dark {
  .skeleton-markets {
    border-bottom: 1px solid #252525;
    background: linear-gradient(
      130deg,
      #121722,
      #121722,
      rgba(86, 92, 123, 0.34)
    );
    background-size: 200% 200%;

    &.isGroup {
      height: 23px;
      -webkit-animation: Animation 0s ease infinite;
      -moz-animation: Animation 0s ease infinite;
      animation: Animation 0s ease infinite;
      background: rgba(86, 92, 123, 0.34);
    }

    &:first-child {
      border-top: 1px solid #252525;
    }
  }
}

body.twilight {
  .skeleton-markets {
    border-bottom: 1px solid #5a67a755;
    background: linear-gradient(130deg, #272d52, #485490, #485490);
    background-size: 200% 200%;

    &.isGroup {
      height: 23px;
      background: #485490;
    }

    &:first-child {
      border-top: 1px solid #5a67a755;
    }
  }
}

@-webkit-keyframes Animation {
  0% {
    background-position: 10% 0%;
  }
  50% {
    background-position: 91% 100%;
  }
  100% {
    background-position: 10% 0%;
  }
}
@-moz-keyframes Animation {
  0% {
    background-position: 10% 0%;
  }
  50% {
    background-position: 91% 100%;
  }
  100% {
    background-position: 10% 0%;
  }
}
@keyframes Animation {
  0% {
    background-position: 10% 0%;
  }
  50% {
    background-position: 91% 100%;
  }
  100% {
    background-position: 10% 0%;
  }
}
</style>
