<template>
  <div class="notifications">
    <transition-group name="fade-right">
      <Notification
        v-for="notification of notifications"
        :key="notification.id"
        :type="notification.type"
        :title="notification.title"
        :text="notification.text"
        @click="close(notification.id)"
        @close.stop="close(notification.id)"
      />
    </transition-group>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import Notification from './Notification';

export default {
  name: 'Notifications',
  components: { Notification },
  setup() {
    const store = useStore();
    const notifications = computed(
      () => store.getters['notifications/notifications']
    );

    const close = (id) => {
      store.dispatch('notifications/removeNotification', id);
    };

    return {
      notifications,
      close,
    };
  },
};
</script>

<style lang="scss" scoped>
.notifications {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 99999;
  padding: 10px;

  @media screen and (min-width: 1024px) {
    top: auto;
    bottom: 10px;
  }

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  width: fit-content;
}
</style>
