<template>
  <div class="card-donate-item mb-8">
    <h4>{{ title }}</h4>
    <p>{{ description }}</p>
    <ItemLabelValue
      :label="$t('popups.crowdfunding.progress')"
      :value="`${amount}/${target} ${fiorinMode ? 'USD' : 'BSV'}`"
    />
    <div class="card-donate-item-range">
      <div :style="{ width: fillWidth }" class="card-donate-item-amount" />
    </div>
    <div class="card-donate">
      <SimpleInput
        :value="amountBtc"
        type="text"
        directive="number"
        :tip="usdAmount"
        :min-value="minValue"
        :title="
          $t('popups.crowdfunding.donateAmount', {
            symbol: fiorinMode ? 'USD' : 'BSV',
          })
        "
        :disabled="inputDisabled"
        step="0.1"
        @onChange="changeAmount"
      />
      <Button
        :text="$t('popups.crowdfunding.button')"
        :disabled="!amountBtc"
        size="medium"
        type="secondary"
        class="button"
        @click="handleDonate"
      />
      <Modal
        v-if="isValidDonate"
        @close="resetPayButton"
        :stop-mobile-max-height="true"
      >
        <div class="features-confirm">
          <Card :title="$t('popups.crowdfunding.modalTitle')" class="card">
            <div class="card-content">
              <CrowdfundingPayButton
                :amount="amountBtc"
                :title="title"
                :to="wallet"
                @close="cancelDonate"
                @payment="handleSuccessPayment"
                @error="handleError"
              />
            </div>
          </Card>
        </div>
      </Modal>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { toCurrencyFormat } from '@/helpers/utils';
import { computed, reactive } from 'vue';
import { toRefs } from '@vueuse/core';
import notify from '@/plugins/notify';
import { useI18n } from 'vue-i18n';
import { DEFAULT_MARKET_PAIR } from '@/modules/markets/helpers';

import SimpleInput from '@/components/ui/SimpleInput';
import ItemLabelValue from '@/components/ui/ItemLabelValue.vue';
import Button from '@/components/ui/Button';
import Modal from '@/modules/modals/Modal';
import CrowdfundingPayButton from '@/modules/features/Crowdfunding/CrowdfundingPayButton';
import Card from '@/components/ui/Card';
import { PositionSides } from '@/config/constants';
import useBalance from '@/compositions/useBalance';

export default {
  components: {
    SimpleInput,
    Button,
    Modal,
    CrowdfundingPayButton,
    ItemLabelValue,
    Card,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    amount: {
      type: [String, Number],
      default: 0,
    },
    target: {
      type: [String, Number],
      default: 0,
    },
    wallet: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const { t } = useI18n();

    const { fetchWalletBalance } = useBalance();

    const localState = reactive({
      type: PositionSides.BUY,
      isValidDonate: false,
      inputDisabled: false,
      amountBtc: '',
      minValue: 0,
      marginValue: 0,
    });

    const fiorinMode = computed(() => store.getters['burger/fiorinModeFlag']);

    const config = computed(() => {
      return store.getters['user/wsConfig'];
    });

    const marketsByName = computed(
      () => store.getters['markets/marketsByName']
    );

    const bsvPrice = computed(() => {
      const market = marketsByName.value[DEFAULT_MARKET_PAIR];

      return (market && market.usdPrice) || 1;
    });

    const fillWidth = computed(() => {
      return `${100 * (+props.amount / +props.target)}%`;
    });

    const usdAmount = computed(() => {
      if (!+localState.amountBtc) {
        return '';
      }
      if (fiorinMode.value) {
        return '';
      }
      return `$${toCurrencyFormat(
        bsvPrice.value * localState.amountBtc,
        null,
        2
      )}`;
    });

    const handleDonate = () => {
      if (+localState.amountBtc >= localState.minValue) {
        localState.isValidDonate = true;
      } else {
        localState.amountBtc = '';
        notify({
          text: t('popups.crowdfunding.minDonation', {
            symbol: fiorinMode.value ? 'USD' : 'BSV',
          }).replace('__min_donate__', `${localState.minValue}`),
          type: 'error',
        });
      }
    };

    const cancelDonate = () => {
      notify({
        text: t('order.new.toasts.error'),
        type: 'error',
      });
      resetPayButton();
    };

    const handleSuccessPayment = () => {
      resetPayButton();
      emit('reload');

      notify({
        text: t('order.new.toasts.thankYou'),
        type: 'info',
      });

      setTimeout(async () => {
        fetchWalletBalance();
      }, 7000);
    };

    const handleError = () => {
      notify({
        text: t('order.new.toasts.failedToLoad'),
        type: 'info',
      });
      resetPayButton();
    };

    const resetPayButton = () => {
      localState.isValidDonate = false;
      localState.amountBtc = '';
    };

    const changeAmount = (val) => {
      localState.amountBtc = val;
    };

    return {
      ...toRefs(localState),
      config,
      bsvPrice,
      fillWidth,
      usdAmount,
      fiorinMode,
      handleDonate,
      handleError,
      handleSuccessPayment,
      cancelDonate,
      resetPayButton,
      changeAmount,
    };
  },
};
</script>

<style lang="scss">
.card-donate-item {
  input {
    width: 100%;
    padding-right: 1rem;
    line-height: 28px;
  }

  .input {
    margin-top: 0.75rem;
    margin-bottom: 1rem;

    &__title {
      &.input_has-content {
        font-size: 0.65rem;
        --tw-text-opacity: 1;
        color: rgba(122, 118, 117, var(--tw-text-opacity));
        opacity: 0.8;
      }
    }

    &__tip {
      //margin-top: -0.75rem;
    }
  }

  .card-donate {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .btn {
      height: 35px;
      min-height: 0;
      margin-top: -5px;
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  .card-donate-item {
    position: relative;

    &-range {
      width: 100%;
      height: 10px;
      margin-bottom: 7px;
      border-radius: 5px;
      background-color: #cacaca47;
    }

    &-amount {
      height: 100%;
      border-radius: 5px;
      max-width: 100%;
      background-color: #c4c4c4;
    }
  }

  .card-confirm {
    top: 0;
    position: absolute;
    overflow: hidden;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(256, 256, 256, 0.8);
  }

  .card-donate .input {
    margin-right: 10px;
  }

  .delete__icon {
    top: 50%;
    margin-top: -7px;
  }

  .card-confirm {
    padding-top: 10px;
  }

  h4 {
    font-family: Gotham_Pro_Bold;
  }

  p {
    font-family: Gotham_Pro_Regular;
  }
}
</style>
