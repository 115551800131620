import router from '@/router';
const state = {
  modals: {},
};

const getters = {
  modalStatus: (state) => (key) => {
    return state.modals[key];
  },
  modals: (state) => state.modals,
};

const types = {
  TOGGLE_MODAL: 'TOGGLE_MODAL',
};

const mutations = {
  [types.TOGGLE_MODAL](state, { k, v }) {
    state.modals = { ...state.modals, [k]: v || !!v };
  },
};

const actions = {
  toggleModal({ commit, state }, { k, v }) {
    // handle nested 'liquidity' modals
    if (k && k.startsWith('liquidity') && !state.modals['liqFundraiser']) {
      commit(types.TOGGLE_MODAL, { k: 'liqFundraiser', v: true });
      setTimeout(commit(types.TOGGLE_MODAL, { k, v }), 300);
      // normal behaviour
    } else {
      commit(types.TOGGLE_MODAL, { k, v });
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

// reflects open modals into url query param 'modal'
export const storePlugin = (store) => {
  store.subscribe(({ type, payload }) => {
    const isSilentModal = !payload?.k?.startsWith('_');
    if (type === 'modals/TOGGLE_MODAL' && isSilentModal) {
      const route = router.currentRoute.value;
      const statuses = store.getters['modals/modals'];
      let arr = [];
      const query = Object.assign({}, route.query);
      Object.keys(statuses).forEach((status) => {
        if (statuses[status] && !status.startsWith('_')) {
          arr.push(status);
        }
      });
      if (arr.length) {
        query.modal = arr.join(',');
      } else {
        delete query.modal;
        delete query.stage;
      }

      router.push({ path: route.fullPath, query });
    }
  });
};
