/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'cancel': {
    width: 12.91,
    height: 12.911,
    viewBox: '0 0 12.91 12.911',
    data: '<path pid="0" d="M12.84.778l-.707-.707-5.678 5.677L.777.071.07.778l5.678 5.677L.07 12.133l.707.707 5.678-5.678 5.678 5.678.707-.707-5.678-5.678z"/>'
  }
})
