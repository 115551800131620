import Connector from './Connector';
import axios from 'axios';
import config from '@/config';
import router from '@/router';
import store from '@/store';
import { parseJwt, parseParams } from '@/helpers/utils';
// const { MoneyButtonClient } = require('@moneybutton/api-client');
// const client = new MoneyButtonClient(config.mbOauthId);
const oauthId = config.mbOauthId;
const oauthRedirectUrl = config.mbRedirectUri;
const tokenUri = 'https://www.moneybutton.com/oauth/v1/token';

const transformTokenData = (resData) => {
  const dt = new Date();
  dt.setSeconds(dt.getSeconds() + resData.expires_in);

  return {
    accessToken: resData.access_token,
    refreshToken: resData.refresh_token,
    expirationDate: dt.toUTCString(),
  };
};

export default class MoneybuttonConnector extends Connector {
  constructor(opts) {
    super(opts);
    this.provider = 'MoneyButton';
    this.code = opts.code || '';
    this.accessToken = opts.accessToken || '';
    this.refreshToken = opts.refreshToken || '';

    this.account = '';
    this.satoshiBalance = 0; // 100000000
  }

  async authorize() {
    try {
      let res = await axios.post(
        tokenUri,
        parseParams({
          grant_type: 'authorization_code',
          client_id: oauthId,
          code: this.code,
          redirect_uri: oauthRedirectUrl,
        }),
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      );
      if (res.data) {
        const data = transformTokenData(res.data);

        this.refreshToken = data.refreshToken;
        this.accessToken = data.accessToken;
        this.expirationDate = data.expirationDate;

        axios.defaults.headers.common['Authorization'] =
          'Bearer ' + res.data.access_token;

        await this.fetchBalance();
      }
    } catch (e) {
      console.error('error in authorize', e);
      await this.refresh();
    }
  }

  async refresh() {
    try {
      let res = await axios.post(
        tokenUri,
        parseParams({
          grant_type: 'refresh_token',
          client_id: oauthId,
          refresh_token: this.refreshToken,
        }),
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      );
      if (res.data) {
        const data = transformTokenData(res.data);

        this.refreshToken = data.refreshToken;
        this.accessToken = data.accessToken;
        this.expirationDate = data.expirationDate;

        axios.defaults.headers.common['Authorization'] =
          'Bearer ' + res.data.access_token;

        await this.fetchBalance();
      }
    } catch (e) {
      store.dispatch('connectors/setActiveConnect', {});
      router.push('/');
    }
  }

  async fetchBalance() {
    if (!this.accessToken) {
      return;
    }
    const userData = parseJwt(this.accessToken);

    if (!userData) {
      return;
    }

    await axios
      .get(`https://www.moneybutton.com/api/v1/users/${userData.sub}/balance`, {
        headers: {
          Authorization: 'Bearer ' + this.accessToken,
        },
      })
      .then(
        (res) => {
          if (res.data) {
            this.satoshiBalance = res.data.data.attributes.satoshis / 100000000;
          }
        },
        async (e) => {
          console.error('error in MB getUserBalance', e);
        }
      );
  }

  async fetchProfile() {
    this.profile = {};
  }

  connect() {
    const moneyButton = {
      authUri:
        'https://www.moneybutton.com/oauth/v1/authorize?response_type=code&',
      tokenUri: 'https://www.moneybutton.com/oauth/v1/token',
    };

    const param = 'index';

    window.location.href = `${moneyButton.authUri}client_id=${
      config.mbClientId
    }&redirect_uri=${
      config.uiUrl
    }&scope=users.balance:read auth.user_identity:read users.profiles:read&state=${
      param || 'index'
    }`;
    /* client.requestAuthorization(
      'users.balance:read auth.user_identity:read users.profiles:read',
      `${oauthRedirectUrl}`
    ); */
  }
}
