<template>
  <Modal :stop-mobile-max-height="true" @close="$emit('close')">
    <Card :title="$t('newVersion.title').toUpperCase()" class="card">
      <div class="newversion-modal">
        {{ $t('newVersion.description') }}
      </div>
    </Card>
    <Button
      :text="$t('newVersion.updateBtn')"
      type="secondary"
      class="update-btn"
      @click="updateApp"
    />
  </Modal>
</template>
<script>
import Modal from '@/modules/modals/Modal';
import Card from '@/components/ui/Card';
import Button from '@/components/ui/Button';

export default {
  name: 'NewVersion',
  components: {
    Modal,
    Card,
    Button,
  },
  setup() {
    const updateApp = () => {
      window.location.reload(true);
      // window.location.href = window.location.href;
    };

    return {
      updateApp,
    };
  },
};
</script>
<style lang="scss" scoped>
.newversion-modal {
  position: relative;
  font-family: Gotham_Pro_Regular;
  height: 100px;
}

.update-btn {
  margin-top: 30px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
</style>
