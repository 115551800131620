<template>
  <div
    :class="{ toggleProTrading, toggleLeverageEntry, isWeb: !isMobile }"
    class="form-position-expanded"
  >
    <div
      v-if="isMobile"
      class="form-position-expanded__title"
      @click="showModal(modalsByName.marketData)"
    >
      MARKET DATA
    </div>
    <Selector
      description="SELECT LEVERAGE"
      description-input="EXPAND"
      description-secodary="SELECT % OF MAX AMOUNT"
      hide-all-description
      :update-value="updateValue"
      :optional-btn="optionalLeverageBtnTitle"
      :optional-btn-secondary="optionalAmountBtnTitle"
      :items="leverageItems"
      :hide-items="!!toggleLeverageEntry"
      :secondary-items="percentAmountItems"
      :initial-slider-value="leverage"
      :initial-input-value="amountBtc"
      :initial-percent-amount="percentSelectorAmount"
      :input-title-label="
        !enableToOpenPosition
          ? isFiorinNullableBalance
            ? 'Wallet depleted'
            : $t('popups.marketInactive.limitReached')
          : 'AMOUNT, USD'
      "
      :max-value="+maxBalance"
      :disabled="!activeMarket || !activeMarket.name || !enableToOpenPosition"
      lefted
      fixed-arrow-width
      show-input-first
      not-reset-input-after-select
      @select="onSelectLeverage"
      @selectSecondary="onSelectPercentMaxAmount"
      @changeInput="onChangeSelectorInput"
    />
    <div v-if="toggleLeverageEntry" class="new-field">
      <SimpleInput
        :value="leverage"
        type="text"
        directive="number"
        :min-value="1"
        new-version
        :max-value="+activeMarket.max_leverage"
        :title="$t('order.new.titles.leverage')"
        @onChange="onChangeLeverage"
        @blur="onLeverageInputBlur"
      />
    </div>
    <div class="new-field">
      <!-- :tip="entryPriceError ? '' : entryPriceTip" -->
      <SimpleInput
        :value="entryPrice"
        :note-clickable="true"
        type="text"
        :error="entryPriceError || ''"
        directive="number"
        :title="$t('order.new.titles.entryPrice')"
        with-arrows
        new-version
        :tip="entryPriceTip"
        @note-clicked="onEntryPriceNoteClickHandler()"
        @onChange="onChangeEntryPrice"
        @onArrowLeft="onArrowLeft"
        @onArrowRight="onArrowRight"
      />
    </div>
    <div class="new-field row">
      <div class="field">
        <SimpleInput
          :value="takeProfit"
          type="text"
          :tip="takeProfitTip"
          :error="tpslErrorMessage[0]"
          directive="number"
          :title="
            $t(
              riskInPercents
                ? 'order.new.titles.takeProfitPercent'
                : 'order.new.titles.takeProfitPrice'
            )
          "
          new-version
          @onChange="onChangeTakeProfit"
        />
      </div>
      <div class="field">
        <SimpleInput
          :value="stopLoss"
          type="inputType"
          :tip="stopLossTip"
          :error="tpslErrorMessage[1]"
          :title="
            $t(
              riskInPercents
                ? 'order.new.titles.stopLossPercent'
                : 'order.new.titles.stopLossPrice'
            )
          "
          directive="number"
          new-version
          @onChange="onChangeStopLoss"
        />
      </div>
    </div>
    <div v-if="!toggleProTrading" class="new-field">
      <div
        :class="{ toggleLeverageEntry }"
        class="form-position-expanded__collapse"
        @click="onExpand"
      >
        COLLAPSE
      </div>
    </div>
  </div>
</template>
<script>
import { useStore } from 'vuex';
import { ref, computed, onMounted, watch, nextTick } from 'vue';
import {
  toCurrencyFormat,
  toUSDMarginFormat,
  getPrecision,
  toFixedAmount,
} from '@/helpers/utils';
import useMaxBalance from '@/compositions/useMaxBalance';
import { floatList } from '@/helpers/floatList';
import SimpleInput from '@/components/ui/SimpleInput';
import { useI18n } from 'vue-i18n';
import { RISK_MANAGEMENT } from '@/modules/settings/store';
import notify from '@/plugins/notify';
import { DEFAULT_MARKET_BSV_USD, PositionSides } from '@/config/constants';
import { POSITION_OPEN_MIN_VAL } from '@/helpers/enums';
import { userProviders } from '@/modules/user/api';
const LEVERAGE_LOCAL_KEY = 'leverage_';
import { updateAndSetUserSettings } from '@/modules/settings/api';
import { fiorinStables } from '@/config/constants';
import { useMarketPriceForMargin } from '@/compositions/useMarketPriceForMargin';
import Selector from '@/components/ui/Selector';
import { scope } from '@/breakpoints';
import { useModals } from '@/modules/modals/api';
import { useFiorinBalanceNull } from '@/compositions/useFiorinBalanceNull';

export default {
  name: 'FormPositionExpandedForm',
  components: { SimpleInput, Selector },
  props: {
    activeMarket: { required: true },
    orderType: { required: true },
    initialAmount: { required: true },
    initialLeverage: { required: true },
    isBounty: { required: true },
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const { isFiorinNullableBalance } = useFiorinBalanceNull();
    const { marketPriceFromFiorinCurrency } = useMarketPriceForMargin();

    const store = useStore();
    const updateValue = ref('');

    const amountBtc = ref(props.initialAmount || '');

    const toggleLeverageEntry = computed(() =>
      store.getters['settings/uiSetting']('toggleLeverageEntry')
    );

    const onFocus = () => {
      // if (window.document.body.clientWidth <= 480) {
      //   // window.scrollTo(0, document.body.scrollHeight);
      //   window.scrollTo({
      //     top: document.body.scrollHeight,
      //     behavior: 'smooth',
      //   });
      // }
    };

    const { showModal, modalsByName } = useModals();

    const toggleProTrading = computed(() =>
      store.getters['settings/uiSetting']('toggleProTrading')
    );

    const getLeverageLocalKey = () =>
      `${LEVERAGE_LOCAL_KEY}${props.activeMarket.name}`;

    const leverage = ref(
      props.initialLeverage ||
        (props.activeMarket && props.activeMarket.max_leverage) ||
        1
    );

    const uiSettings = computed(() => {
      return store.getters['settings/uiSettings'];
    });

    const setInitialLeverage = () => {
      const cachedLeverageLocal = localStorage.getItem(getLeverageLocalKey());
      const cachedLeverageServer = uiSettings.value[getLeverageLocalKey()];
      leverage.value =
        props.initialLeverage ||
        localStorage.getItem('cacheLeverage') ||
        cachedLeverageLocal ||
        cachedLeverageServer ||
        props.activeMarket.max_leverage;

      if (leverage.value > props.activeMarket.max_leverage) {
        leverage.value = props.activeMarket.max_leverage;
      }
    };

    onMounted(() => {
      setInitialLeverage();
      sendFormData();
    });

    watch(
      () => props.activeMarket.name,
      () => {
        if (props.activeMarket.name) {
          const initialAmount = localStorage.getItem(
            `amount${props.activeMarket.name}`
          );
          percentSelectorAmount.value = '';

          const cachedLeverage = localStorage.getItem(getLeverageLocalKey());
          leverage.value = cachedLeverage || props.activeMarket.max_leverage;

          if (leverage.value > props.activeMarket.max_leverage) {
            leverage.value = props.activeMarket.max_leverage;
          }

          if (initialAmount) {
            amountBtc.value = initialAmount;
            updateValue.value = initialAmount;
            onChangeSelectorInput(initialAmount);
          } else {
            amountBtc.value = '';
            updateValue.value = '';
          }
          onChangeEntryPrice('');
          onChangeTakeProfit('');
          onChangeStopLoss('');

          sendFormData();
        }
      }
    );

    const marginValue = ref('');
    const takeProfit = ref('');
    const entryPrice = ref('');
    const stopLoss = ref('');

    const riskMgmt = ref(
      store.getters['settings/uiSetting']('riskManagement') ===
        RISK_MANAGEMENT.PERCENT
        ? '%'
        : '='
    );

    const getAccountBalance = computed(
      () => store.getters['user/getAccountBalance']
    );

    const marketsById = computed(() => store.getters['markets/marketsByName']);

    const bsvPrice = computed(() => {
      const market = marketsById.value[DEFAULT_MARKET_BSV_USD];

      return (market && market.usdPrice) || 1;
    });

    const getRiskManagement = computed(() => {
      return riskMgmt.value === '%'
        ? RISK_MANAGEMENT.PERCENT
        : RISK_MANAGEMENT.PRICE;
    });

    const riskInPercents = computed(
      () => getRiskManagement.value === RISK_MANAGEMENT.PERCENT
    );

    const takeProfitPercent = computed(() => {
      if (riskInPercents.value) {
        return takeProfit.value;
      }
      if (!takeProfit.value) {
        return '';
      }

      return toCurrencyFormat(
        (props.orderType === PositionSides.BUY
          ? leverage.value *
            (takeProfit.value / (entryPrice.value || +props.activeMarket.buy) -
              1) *
            100
          : leverage.value *
            (1 -
              takeProfit.value /
                (entryPrice.value || +props.activeMarket.sell)) *
            100
        ).toFixed(2)
      );
    });

    const stopLossPercent = computed(() => {
      if (riskInPercents.value) {
        return +stopLoss.value >= 0 ? stopLoss.value : '';
      }
      if (!stopLoss.value || +stopLoss.value < 0) {
        return '';
      }

      return toCurrencyFormat(
        (props.orderType === PositionSides.BUY
          ? leverage.value *
            (1 -
              stopLoss.value / (entryPrice.value || +props.activeMarket.buy)) *
            100
          : leverage.value *
            (stopLoss.value / (entryPrice.value || +props.activeMarket.sell) -
              1) *
            100
        ).toFixed(2)
      );
    });

    const amountMarginTip = computed(() => {
      const bountyPos =
        props.isBounty && getAccountBalance.value.bounty >= marginValue.value;
      // fiorin tokens
      if (
        !bountyPos &&
        activeConnect.value.provider === userProviders.fiorin &&
        fiorinStables.includes(
          activeConnect?.value?.tokenIdCurrency?.toLowerCase()
        )
      ) {
        return marginValue.value
          ? `${toFixedAmount(
              toCurrencyFormat(
                marginValue.value / marketPriceFromFiorinCurrency.value,
                null,
                null
              )
            )} ${activeConnect?.value?.tokenIdCurrency?.toUpperCase()}`
          : '';
      }
      // fiorin stables
      if (activeConnect.value.provider === userProviders.fiorin) {
        return marginValue.value
          ? `$${
              toUSDMarginFormat(
                (activeConnect.value.provider === userProviders.fiorin
                  ? 1
                  : bsvPrice.value) * marginValue.value,
                2
              ) || 0
            }`
          : '';
      }

      return marginValue.value
        ? `$${
            toCurrencyFormat(
              (activeConnect.value.provider === userProviders.fiorin
                ? 1
                : bsvPrice.value) * marginValue.value,
              null,
              2
            ) || 0
          }`
        : '';
    });

    const marginValueTip = computed(() => {
      const maxAmountValue = props.activeMarket.max_amount_usd;

      return `${t('order.new.titles.max')} ${toCurrencyFormat(
        Math.min(
          props.activeMarket && maxAmountValue / leverage.value,
          Math.max(
            getAccountBalance.value.available,
            getAccountBalance.value.bounty
          )
        )
      )}`;
    });

    const leverageTip = computed(() => {
      return `${t('order.new.titles.max')} ${
        (props.activeMarket && props.activeMarket.max_leverage) || 1
      }x`;
    });

    const tabSell = computed(() => {
      if (!props.activeMarket) {
        return '0';
      }
      const activeMarket = props.activeMarket;
      return toCurrencyFormat(
        activeMarket && activeMarket.sell,
        null,
        floatList[activeMarket.name]
      );
    });

    const tabBuy = computed(() => {
      if (!props.activeMarket) {
        return '0';
      }
      const activeMarket = props.activeMarket;
      return toCurrencyFormat(
        activeMarket && activeMarket.buy,
        null,
        floatList[activeMarket.name]
      );
    });

    const entryPriceTip = computed(() => {
      const current =
        props.orderType === PositionSides.SELL
          ? props.activeMarket.sell || 0
          : props.activeMarket.buy || 0;
      if (!entryPrice.value) {
        return '';
      }

      if (entryPrice.value < current) {
        const percent = (100 - (+entryPrice.value / +current) * 100).toFixed(1);
        return `ENTRY PRICE <span style="font-family: 'Cantarell_Regular';">${percent}</span>% BELOW MARKET`;
      }
      if (entryPrice.value > current) {
        const percent = (100 - (+current / +entryPrice.value) * 100).toFixed(1);
        return `ENTRY PRICE <span style="font-family: 'Cantarell_Regular';">${percent}</span>% ABOVE MARKET`;
      }
      return '';
    });

    // const entryPriceTip = computed(() => {
    //   return `curr. ${
    //     props.orderType === PositionSides.SELL ? tabSell.value : tabBuy.value
    //   }`;
    // });

    const entryPriceError = computed(() => {
      if (!entryPrice.value) {
        emit('tpsl-error', false);
        return false;
      }

      if (entryPrice.value === '0') {
        emit('tpsl-error', true);

        return t('order.new.mustBe', {
          value: `> 0`,
        });
      }

      emit('tpsl-error', false);
      return false;
    });

    const takeProfitTip = computed(() => {
      if (!amountBtc.value || !takeProfit.value || !leverage.value) {
        return '';
      }
      if (riskInPercents.value) {
        let tpProfit =
          (amountBtc.value * takeProfit.value) / 100 / leverage.value;

        let tpPrice =
          props.orderType === PositionSides.BUY
            ? toCurrencyFormat(
                (tpProfit * (entryPrice.value || +props.activeMarket.buy)) /
                  amountBtc.value +
                  (+entryPrice.value || +props.activeMarket.buy)
              )
            : toCurrencyFormat(
                (
                  (entryPrice.value || +props.activeMarket.sell) -
                  (tpProfit * (+entryPrice.value || +props.activeMarket.sell)) /
                    amountBtc.value
                ).toFixed(props.activeMarket.precision + 1)
              );

        return `<span style="font-family: 'Cantarell_Regular';">${tpPrice}</span>, PROFIT <span style="font-family: 'Cantarell_Regular';">${toCurrencyFormat(
          tpProfit,
          null,
          2
        )}</span>`;
      } else {
        const tpPercent = takeProfitPercent.value;
        const tpProfit =
          (amountBtc.value * takeProfitPercent.value.replace(/\s/, '')) /
          100 /
          leverage.value;

        if (tpPercent === 'Infinity') {
          return '';
        }

        return `<span style="font-family: 'Cantarell_Regular';">${tpPercent}%</span>, PROFIT <span style="font-family: 'Cantarell_Regular';">${toCurrencyFormat(
          tpProfit,
          null,
          2
        )}</span>`;
      }
    });

    const stopLossTip = computed(() => {
      if (!amountBtc.value || !stopLoss.value || !leverage.value) {
        return '';
      }
      if (riskInPercents.value) {
        let sl = stopLoss.value / 100;

        let slPrice =
          props.orderType == PositionSides.BUY
            ? toCurrencyFormat(
                (
                  (entryPrice.value || +props.activeMarket.buy) *
                  (1 - sl / leverage.value)
                ).toFixed(props.activeMarket.precision + 1)
              )
            : toCurrencyFormat(
                (
                  (entryPrice.value || +props.activeMarket.sell) *
                  (1 + sl / leverage.value)
                ).toFixed(props.activeMarket.precision + 1)
              );

        let slLoss = (amountBtc.value * stopLoss.value) / 100 / leverage.value;
        return `<span style="font-family: 'Cantarell_Regular';">${slPrice}</span>, LOSS <span style="font-family: 'Cantarell_Regular';">${toCurrencyFormat(
          slLoss,
          null,
          2
        )}</span>`;
      } else {
        const slPercent = stopLossPercent.value;
        const slLoss =
          (amountBtc.value * stopLossPercent.value.replace(/\s/, '')) /
          100 /
          leverage.value;
        return `<span style="font-family: 'Cantarell_Regular';">${slPercent}%</span>, LOSS <span style="font-family: 'Cantarell_Regular';">${toCurrencyFormat(
          slLoss,
          null,
          2
        )}</span>`;
      }
    });

    const setMargin = () => {
      marginValue.value = toCurrencyFormat(
        amountBtc.value /
          (leverage.value *
            (activeConnect.value.provider === userProviders.fiorin
              ? 1
              : bsvPrice.value))
      ).replaceAll(' ', '');
    };

    const onEntryPriceNoteClickHandler = () => {
      entryPrice.value =
        props.orderType === PositionSides.SELL ? tabSell.value : tabBuy.value;
      entryPrice.value = entryPrice.value.replace(' ', '');
      setMargin();
      sendFormData();
    };

    const sendFormData = () => {
      emit('changeNewOrderForm', {
        amountBtc: amountBtc.value,
        marginValue:
          amountBtc.value /
          (leverage.value *
            (activeConnect.value.provider === userProviders.fiorin
              ? 1
              : bsvPrice.value)),
        takeProfit: takeProfitPercent.value,
        entryPrice: entryPrice.value,
        leverage: leverage.value || '1',
        stopLoss: stopLossPercent.value,
      });
    };

    const isNoteClickable = computed(
      () => true // !amountBtc.value || !amountBtc.value.length
    );

    const activeConnect = computed(
      () => store.getters['connectors/activeConnect']
    );

    const enableToOpenPosition = computed(() => {
      return useMaxBalance(leverage.value, amountBtc.value, fiorinMode.value, t)
        .enableToOpenPosition.value;
    });

    const maxBalance = computed(() => {
      return useMaxBalance(leverage.value, amountBtc.value, fiorinMode.value, t)
        .maxBalance.value;
    });

    const maxWalletValue = computed(() => {
      return useMaxBalance(leverage.value, amountBtc.value, fiorinMode.value, t)
        .maxWalletValue.value;
    });

    const fiorinMode = computed(() => store.getters['burger/fiorinModeFlag']);

    const onChange = (value, opts = {}) => {
      if (
        leverage.value &&
        +leverage.value > +props.activeMarket.max_leverage
      ) {
        leverage.value = props.activeMarket.max_leverage;
      }

      // after remove min-value 1 for leverage
      if (!leverage.value) {
        leverage.value = 1;
      }

      const toastText =
        maxWalletValue.value < POSITION_OPEN_MIN_VAL
          ? t('order.new.toasts.depleted', {
              provider: activeConnect.value.provider,
            })
          : t('order.new.toasts.maxLimit');

      if (+value > +maxBalance.value) {
        if (userProviders.fiorin === activeConnect.value.provider) {
          // showModal(modalsByName.smallAmount);
          const input = document.querySelector(
            '.new-version.selectorLiquidityInput input'
          );
          if (input) {
            input.blur();
          }
        }
        if (opts.initialMode) {
          amountBtc.value = '';
          return;
        }
        onChange(maxBalance.value);

        if (hideToast.value) {
          notify({
            text: toastText,
            type: 'info',
          });
        }
      } else {
        amountBtc.value = value;
      }

      setMargin();
      sendFormData();
    };

    if (props.initialAmount) {
      onChange(props.initialAmount, { initialMode: true });
    }

    const onNoteClickLeverage = () => {
      localStorage.removeItem('cacheLeverage');
      leverage.value = props.activeMarket.max_leverage;
      setMargin();
      sendFormData();
      // update local
      localStorage.setItem(getLeverageLocalKey(), leverage.value);
      // update on server
      updateAndSetUserSettings({
        uiSettings: {
          [getLeverageLocalKey()]: leverage.value,
        },
      });
    };

    const onChangeMargin = (val) => (marginValue.value = val);

    const onChangeStopLoss = (val) => {
      stopLoss.value = val;
      sendFormData();
    };
    const onChangeTakeProfit = (val) => {
      takeProfit.value = val;
      sendFormData();
    };
    const onChangeEntryPrice = (val) => {
      entryPrice.value = val;
      sendFormData();
    };

    const onChangeLeverage = (val) => {
      leverage.value = val;
      onSelectLeverage(val);
      // leverage.value = val;
      // // update local
      // localStorage.setItem(getLeverageLocalKey(), val);
      // // update on server
      // updateAndSetUserSettings({
      //   uiSettings: {
      //     [getLeverageLocalKey()]: val,
      //   },
      // });

      // if (leverage.value > 1) {
      //   localStorage.removeItem('cacheLeverage'); // reset after empty
      // }

      // if (!amountBtc.value || !leverage.value) {
      //   sendFormData();
      //   return '';
      // }
      // if (+leverage.value < 1) {
      //   // leverage.value = 1;
      // }
      // if (+leverage.value > +props.activeMarket.max_leverage) {
      //   leverage.value = props.activeMarket.max_leverage;
      // }

      // if (+amountBtc.value > +maxBalance.value) {
      //   onChange(maxBalance.value);
      // }

      // setMargin();
      // sendFormData();
    };

    const onLeverageInputBlur = () => {
      if (!leverage.value) {
        leverage.value = props.activeMarket.max_leverage;
      }
    };

    let stopLossTimeout = null;

    const tpslErrorMessage = computed(() => {
      let tpslMessage = ['', ''];
      const tpError =
        takeProfitPercent.value &&
        (takeProfitPercent.value.includes('-') ||
          takeProfitPercent.value.includes('−') ||
          takeProfit.value.includes('-') ||
          takeProfit.value.includes('−'));
      const slError =
        stopLossPercent.value.includes('-') ||
        stopLossPercent.value.includes('−');

      const type = props.orderType;
      if (tpError || slError) {
        clearTimeout(stopLossTimeout);
        stopLossTimeout = null;
        emit('tpsl-error', true);

        const marketPrice =
          +entryPrice.value ||
          (type === PositionSides.BUY
            ? props.activeMarket.buy
            : props.activeMarket.sell);
        const greaterOrLess = type === PositionSides.BUY ? '> ' : '< ';

        const fractionDigits =
          floatList[props.activeMarket.name] || getPrecision(marketPrice) || 2;

        const recommendedPrice = +marketPrice.toFixed(fractionDigits);
        if (tpError) {
          if (riskInPercents.value) {
            tpslMessage[0] = t('order.new.mustBe', { value: '> 0' });

            return tpslMessage;
          }

          const greaterOrLessValue = greaterOrLess + recommendedPrice;
          tpslMessage[0] = t('order.new.mustBe', { value: greaterOrLessValue });
        }
        if (slError) {
          const greaterOrLess =
            props.orderType === PositionSides.BUY ? '<' : '>';
          const greaterOrLessValue = riskInPercents.value
            ? '> 0%'
            : greaterOrLess + ' ' + (entryPrice.value || recommendedPrice);
          tpslMessage[1] = t('order.new.mustBe', { value: greaterOrLessValue });
        }
      } else if (+stopLossPercent.value.replace(' ', '') > 80) {
        tpslMessage[1] = t('order.new.mustBe', { value: '< 80%' });
        if (!stopLossTimeout) {
          emit('tpsl-error', true);
          // eslint-disable-next-line vue/no-async-in-computed-properties
          stopLossTimeout = setTimeout(() => {
            if (riskInPercents.value) {
              stopLoss.value = '79';
            } else if (type === PositionSides.BUY) {
              const fractionDigits =
                floatList[props.activeMarket.name] ||
                getPrecision(props.activeMarket.buy) ||
                2;

              stopLoss.value = +(
                (entryPrice.value || +props.activeMarket.buy) *
                (1 - 0.79 / +leverage.value)
              ).toFixed(fractionDigits);
            } else if (type === PositionSides.SELL) {
              const fractionDigits =
                floatList[props.activeMarket.name] ||
                getPrecision(props.activeMarket.buy) ||
                2;

              stopLoss.value = +(
                (entryPrice.value || +props.activeMarket.sell) *
                (1 + 0.79 / +leverage.value)
              ).toFixed(fractionDigits);
            }
            clearTimeout(stopLossTimeout);
            stopLossTimeout = null;
          }, 2000);
        }
      } else {
        clearTimeout(stopLossTimeout);
        stopLossTimeout = null;
        emit('tpsl-error', false);
      }
      return tpslMessage;
    });

    // NEW HANDLERS

    const leverageItems = computed(() => {
      const max = +props.activeMarket.max_leverage;

      const items = [{ title: 1 }];

      if (max <= 10) {
        for (let i = 2; i <= max; i++) {
          items.push({
            title: i,
          });
        }
        return items;
      }

      for (let i = 2; i < 11; i++) {
        items.push({
          title:
            i === 10
              ? max
              : Math.round(+items[items.length - 1].title + max / 10),
        });
      }
      return items;
    });

    const percentAmountItems = computed(() => {
      const arr = [10, 25, 50, 70, 80, 100];
      const result = [];
      for (let i = 0; i <= arr.length - 1; i++) {
        result.push({
          title: arr[i],
        });
      }
      return result;
    });

    const percentSelectorAmount = ref('');

    const optionalLeverageBtnTitle = computed(() => {
      return `${leverage.value}X`;
    });

    const optionalAmountBtnTitle = computed(() => {
      const val1 = amountBtc.value;
      const val2 = toFixedAmount(
        (maxBalance.value / 100) * percentSelectorAmount.value
      );
      if (!percentSelectorAmount.value || (amountBtc.value && val1 != val2)) {
        return `X%`;
      }
      return `${
        percentSelectorAmount.value == 100
          ? 'MAX'
          : percentSelectorAmount.value + '%'
      }`;
    });

    const hideToast = ref(false);

    const onSelectLeverage = async (val) => {
      leverage.value = val?.title || leverage.value;
      onChange(amountBtc.value);
      console.log('maxBalance', maxBalance.value);
      // sendFormData();
      // localStorage.setItem(getLeverageLocalKey(), leverage.value);
      localStorage.setItem(getLeverageLocalKey(), leverage.value);
      // update on server
      await updateAndSetUserSettings({
        uiSettings: {
          [getLeverageLocalKey()]: leverage.value,
        },
      });
      hideToast.value = true;
      nextTick(() => {
        updateValue.value = amountBtc.value;
        onChange(amountBtc.value);
        setTimeout(() => {
          hideToast.value = false;
        }, 1500);
      });
    };

    const onSelectPercentMaxAmount = (val) => {
      if (localStorage.getItem('stopAutosetAmount')) {
        // not set after open positions
        return;
      }

      percentSelectorAmount.value =
        val?.title || val?.value?.title || percentSelectorAmount.value;

      if (maxBalance.value) {
        updateValue.value = ' ';
        setTimeout(() => {
          updateValue.value =
            toFixedAmount(
              (maxBalance.value / 100) * percentSelectorAmount.value
            ) + '';
          amountBtc.value = updateValue.value;
          onChange(amountBtc.value);
        }, 0);
      }
    };

    const onChangeSelectorInput = (val) => {
      amountBtc.value = val;
      onChange(amountBtc.value);
      updateValue.value = val;
      sendFormData();
      // percentSelectorAmount.value = '';
      // percentSelectorAmount.value = '';
    };

    const onArrowLeft = () => {
      const curr =
        props.orderType === PositionSides.SELL
          ? +props.activeMarket.buy
          : +props.activeMarket.sell;
      const entry = +entryPrice.value;
      const value = entry || curr;

      entryPrice.value = (value - value * 0.001).toFixed(
        floatList[props.activeMarket.name] || 4
      );
      sendFormData();
    };

    const onArrowRight = () => {
      const curr =
        props.orderType === PositionSides.SELL
          ? +props.activeMarket.buy
          : +props.activeMarket.sell;
      const entry = +entryPrice.value;
      const value = entry || curr;

      entryPrice.value = (value + value * 0.001).toFixed(
        floatList[props.activeMarket.name] || 3
      );
      sendFormData();
    };

    const isMobile = computed(
      () => scope.isSmall || scope.isMedium || scope.noMatch
    );

    const onExpand = () => {
      emit('expand', {
        value: leverage.value,
        percentSelectorAmount:
          optionalAmountBtnTitle.value === 'X%'
            ? ''
            : optionalAmountBtnTitle.value,
      });
    };

    return {
      onExpand,
      isMobile,
      onArrowLeft,
      onArrowRight,
      onSelectLeverage,
      onSelectPercentMaxAmount,
      onChangeSelectorInput,
      optionalLeverageBtnTitle,
      optionalAmountBtnTitle,
      leverageItems,
      percentAmountItems,

      maxBalance,
      amountBtc,
      entryPrice,
      leverage,
      stopLoss,
      takeProfit,
      takeProfitTip,
      stopLossTip,
      riskInPercents,
      tpslErrorMessage,
      updateValue,
      isNoteClickable,
      marginValueTip,
      onChangeMargin,
      marginValue,
      leverageTip,
      entryPriceTip,
      entryPriceError,
      amountMarginTip,
      enableToOpenPosition,
      // eslint-disable-next-line vue/no-dupe-keys
      activeMarket: props.activeMarket,
      onChange,
      onEntryPriceNoteClickHandler,
      onChangeLeverage,
      onChangeTakeProfit,
      onChangeStopLoss,
      onChangeEntryPrice,
      onNoteClickLeverage,
      onLeverageInputBlur,
      activeConnect,
      userProviders,
      toUSDMarginFormat,
      onFocus,
      percentSelectorAmount,
      toggleProTrading,
      showModal,
      modalsByName,
      toggleLeverageEntry,
      isFiorinNullableBalance,
    };
  },
};
</script>
<style lang="scss" scoped>
.form-position-expanded {
  width: 100%;
  min-height: 310px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto 0;
  padding: 18px 20px 0 26px;
  box-sizing: border-box;
  margin-top: 11px;

  &.toggleProTrading {
    min-height: 290px;

    &.toggleLeverageEntry {
      min-height: 350px;
    }
  }

  &.isWeb {
    min-height: 277px;

    &.toggleProTrading {
      min-height: 250px;

      &.toggleLeverageEntry {
        min-height: 320px;
      }
    }
  }

  &__title {
    font-size: 14px;
    margin: 14px 0 21px;
    color: #fff; // #888b90;
  }

  &__collapse {
    font-size: 14px;
    margin: 10px 0;
    color: #888b90;
    text-align: center;
    cursor: pointer;

    &.toggleLeverageEntry {
      margin-bottom: 19px;
    }
  }

  .new-field {
    width: 100%;
    margin-top: 12px;
    width: calc(100% + 15px);
    margin-left: -6px;
    max-height: 60px;
    overflow: hidden;

    .field {
      width: 154px;
    }

    &.row {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  @media screen and (min-width: 640px) and (max-width: 1023px) {
    justify-content: center;
  }

  .column {
    padding: 5px;
    width: auto;

    @media screen and (min-width: 1024px) {
      width: 143px;
      padding: 0;
    }
  }

  .input-item {
    width: auto;
    height: 50px;
    margin: 20px 10px;

    @media screen and (min-width: 1024px) {
      width: 143px;
      margin: 20px 0;
    }
  }
}

@media (min-width: 481px) {
  .form-position-expanded {
    // padding: 0 21px 0 27px;

    .new-field {
      .field {
        width: 48.5%;
      }
    }
  }
}

@media (max-width: 480px) {
  .form-position-expanded {
    padding: 0 21px 0 27px;

    .new-field {
      .field {
        width: 100%;

        &:first-child {
          margin-right: 12px;
        }
      }
    }
  }
}

body.dark {
  .form-position-expanded {
    &__collapse {
      color: #fff;
    }
  }
}
</style>
