/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'ico_burger': {
    width: 30.424,
    height: 23.424,
    viewBox: '0 0 30.424 23.424',
    data: '<path pid="0" _fill="#FFF" d="M0 0v2.309h30.424V0H0zm0 12.866h30.424v-2.309H0v2.309zm0 10.558h30.424v-2.309H0v2.309z"/>'
  }
})
