/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'positions_on': {
    width: 17,
    height: 17,
    viewBox: '0 0 17 17',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M0 6C0 3.287 1.8.995 4.272.253v11.445l-1.43-1.43-1.272 1.274 2.966 2.966.636.636.636-.636 2.966-2.966-1.273-1.273-1.429 1.43V0H11a6 6 0 016 6v5c0 2.713-1.8 5.005-4.272 5.747V5.493l1.43 1.43L15.43 5.65l-2.966-2.966-.636-.636-.636.636L8.226 5.65l1.273 1.273 1.429-1.43V17H6a6 6 0 01-6-6V6z" _fill="#fff"/>'
  }
})
