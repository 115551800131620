import values from 'lodash/values';
import pick from 'lodash/pick';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';

const createInitialList = (composition, data) => ({
  list: [],
  key: composition.key,
  data: { ...omit(composition, 'key'), ...data },
});

export const createCompositionLists = (list, { compositePath, data }) => {
  const map = list.reduce((table, item) => {
    const composition = createComposition(item, compositePath);
    const { key } = composition;

    if (isEmpty(table[key])) {
      table[key] = createInitialList(composition, data);
    }

    table[key].list.push(item);

    return table;
  }, {});

  return values(map);
};

export const createComposition = (entity, compositePath) => {
  const composition = pick(entity, compositePath);
  const objectKey = values(composition);
  // for merge types
  if (objectKey[2] === 'pending') {
    objectKey[2] = 'open';
  }

  return { ...composition, key: objectKey.join('-') };
};

export const findListByComposition = (lists, compositionToFind) =>
  find(lists, (list) => list.key === compositionToFind.key);

export const createCompositionList = (compositionToInsert, data) =>
  createInitialList(compositionToInsert, data);
