/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'arrowHistory': {
    width: 317,
    height: 117,
    viewBox: '0 0 317 117',
    data: '<path pid="0" d="M114.709 42.01L158.5 4.908l43.791 37.102" _stroke="#fff" stroke-width="6"/><path pid="1" d="M14.11 108.41c-4.44 0-8.08-1.48-11.44-4.48l1.96-2.32c2.92 2.64 5.72 3.96 9.6 3.96 3.76 0 6.24-2 6.24-4.84 0-2.6-1.4-4.08-7.28-5.32-6.44-1.4-9.4-3.48-9.4-8.16 0-4.4 3.88-7.64 9.2-7.64 4.08 0 7 1.16 9.84 3.44l-1.84 2.44c-2.6-2.12-5.2-3.04-8.08-3.04-3.64 0-5.96 2-5.96 4.6 0 2.64 1.44 4.12 7.6 5.44 6.24 1.36 9.12 3.64 9.12 8 0 4.8-4 7.92-9.56 7.92zm15.987-.4v-28h3.16v16.72l16.08-16.72h4.08l-12 12.24 12.52 15.76h-3.96l-10.76-13.56-5.96 6.04v7.52h-3.16zm29.03 0v-28h3.16v28h-3.16zm11.36 0v-28h10.48c6.32 0 10.48 3.36 10.48 8.96 0 6.04-5.04 9.16-11 9.16h-6.8v9.88h-3.16zm3.16-12.76h6.92c4.64 0 7.68-2.48 7.68-6.24 0-4-3-6.08-7.52-6.08h-7.08v12.32zm42.951 12.76V82.93h-9.4v-2.92h22v2.92h-9.4v25.08h-3.2zm29.738.44c-6.92 0-11.64-4.24-11.64-12.16V80.01h3.16v16.08c0 6.04 3.2 9.44 8.56 9.44 5.16 0 8.44-3.12 8.44-9.24V80.01h3.16v16.04c0 8.16-4.68 12.4-11.68 12.4zm26.551-.44V82.93h-9.4v-2.92h22v2.92h-9.4v25.08h-3.2zm29.624.48c-8.52 0-14.24-6.68-14.24-14.48 0-7.72 5.8-14.48 14.32-14.48s14.24 6.68 14.24 14.48c0 7.72-5.8 14.48-14.32 14.48zm.08-2.92c6.4 0 10.96-5.12 10.96-11.56 0-6.36-4.64-11.56-11.04-11.56-6.4 0-10.96 5.12-10.96 11.56 0 6.36 4.64 11.56 11.04 11.56zm20.904 2.44v-28h12.04c3.44 0 6.2 1.04 7.96 2.8 1.36 1.36 2.16 3.32 2.16 5.6 0 4.64-3.2 7.36-7.6 8.16l8.6 11.44h-3.88l-8.12-10.88h-8v10.88h-3.16zm3.16-13.72h8.6c4.2 0 7.2-2.16 7.2-5.84 0-3.44-2.64-5.52-7.16-5.52h-8.64v11.36zm26.026 13.72v-28h3.16v28h-3.16zm8.961 0l12.76-28.2h2.96l12.76 28.2h-3.4l-3.28-7.4h-15.24l-3.32 7.4h-3.24zm7.8-10.24h12.76l-6.36-14.28-6.4 14.28zm26.202 10.24v-28h3.16v25.08h15.76v2.92h-18.92z" _fill="#fff"/>'
  }
})
