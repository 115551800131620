<template>
  <Modal :stop-mobile-max-height="true" @close="$emit(EVENTS.close)">
    <Card
      :title="$t('popups.cookies.title').toUpperCase()"
      class="card"
      :body-class="'card-full'"
    >
      <div class="cookies-policy">
        <p>{{ $t('popups.cookies.info') }}</p>
        <h4>{{ $t('popups.cookies.title1') }}</h4>
        <p>{{ $t('popups.cookies.info1') }}</p>
        <h4>{{ $t('popups.cookies.title2') }}</h4>
        <p>{{ $t('popups.cookies.info2') }}</p>
        <h4>{{ $t('popups.cookies.title3') }}</h4>
        <div v-html="$t('popups.cookies.info3')" />
        <h4>{{ $t('popups.cookies.title4') }}</h4>
        <p v-html="$t('popups.cookies.info4')" />
        <h4>{{ $t('popups.cookies.title5') }}</h4>
        <p v-html="$t('popups.cookies.info5')" />
        <h4>{{ $t('popups.cookies.title6') }}</h4>
        <p>{{ $t('popups.cookies.info6') }}</p>
        <h4>{{ $t('popups.cookies.title7') }}</h4>
        <p>{{ $t('popups.cookies.info7') }}</p>
        <h4>{{ $t('popups.cookies.title8') }}</h4>
        <p>{{ $t('popups.cookies.info8') }}</p>
        <h4>{{ $t('popups.cookies.title9') }}</h4>
        <p>{{ $t('popups.cookies.info9') }}</p>
      </div>
    </Card>
    <Button
      :text="$t('popups.cookies.agree')"
      type="secondary"
      class="cookies-btn"
      @click="$emit('close')"
    />
  </Modal>
</template>
<script>
import Modal from '@/modules/modals/Modal';
import Card from '@/components/ui/Card';
import Button from '@/components/ui/Button';

const EVENTS = {
  close: 'close',
};

export default {
  components: { Modal, Card, Button },
  emits: [EVENTS.close],
  setup() {
    return {
      EVENTS,
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/colors';
@import '@/assets/styles/base';

.cookies-policy {
  height: 400px;
  overflow-y: auto;
  margin-bottom: 45px;
  padding-right: $px20;
  padding-left: $px20;
  box-sizing: border-box;
  font-family: Gotham_Pro_Regular;
  line-height: 20px;

  p {
    &.underline {
      text-decoration-line: underline;
    }
  }

  div {
    margin-top: 25px;

    h4 {
      font-family: Gotham_Pro_Bold !important;
    }
  }

  span {
    text-decoration-line: underline;
    cursor: pointer;
  }
}

.cookies-btn {
  margin-top: 30px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
</style>
