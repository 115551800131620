import UAParser from 'ua-parser-js';

export const useUAParser = () => {
  const parser = new UAParser();

  const getOS = () => {
    return parser.getOS();
  };

  const getBrowser = () => {
    return parser.getBrowser();
  };

  const isSafari = () => {
    const { name: OSName = '' } = getOS();
    const { name: BrowserName = '' } = getBrowser();

    return (
      OSName.toLowerCase().includes('ios') &&
      BrowserName.toLowerCase().includes('safari')
    );
  };

  return { getOS, getBrowser, isSafari };
};
