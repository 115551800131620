import { toCurrencyFormat } from '@/helpers/utils';
import { floatList } from '@/helpers/floatList';

export default function useMarketCoin() {
  const getClassesOfDynamic = ({ price }) => {
    return {
      _increase: price[0] === '+',
      _drop: price[0] === '-',
      _zero: price[0] !== '+' && price[0] !== '-',
    };
  };

  const getChangeValue24 = (item) => {
    const pricePercent = (
      (100 * (item.usdPrice - item.price24h)) /
      item.price24h
    ).toFixed(2);

    if (pricePercent == Infinity) {
      return '0.00%';
    }
    const calcChangeValue = ({ price }) => {
      if (!+price || price === Infinity) {
        return '0.00%';
      }
      return +price >= 0 ? `+${price}%` : `${price}%`;
    };
    return calcChangeValue({
      price: ((100 * (item.usdPrice - item.price24h)) / item.price24h).toFixed(
        2
      ),
    });
  };

  const getTickerName = (market) => {
    return market.name;
  };

  const marketHold = (market) => {
    return market.timeline >= market.maxAcceptablePriceLatency / 60;
  };

  const isMarketActive = (market) => {
    return market && !!market.is_work_time;
  };

  const calcMarketUSDPrice = (market) => {
    return toCurrencyFormat(
      market.usdPrice,
      null,
      floatList[getTickerName(market)]
    ).toString();
  };

  const isWorkingMarket = (market) =>
    isMarketActive(market) && !marketHold(market);

  return {
    getClassesOfDynamic,
    getChangeValue24,
    getTickerName,
    marketHold,
    isMarketActive,
    calcMarketUSDPrice,
    isWorkingMarket,
  };
}
