import axios from 'axios';
import store from '@/store';
import config from '@/config';
import { userProviders, refreshTokenUpfrontMs } from '.';
import { parseJwt, parseParams } from '@/helpers/utils';
const oauthId = config.mbOauthId;
const oauthRedirectUrl = config.mbRedirectUri;
const tokenUri = 'https://www.moneybutton.com/oauth/v1/token';

export async function startAuth() {
  const { MoneyButtonClient } = await import('@moneybutton/api-client');
  const client = new MoneyButtonClient(oauthId);

  client.requestAuthorization(
    'users.balance:read auth.user_identity:read users.profiles:read',
    `${oauthRedirectUrl}`
  );
}

function transformTokenData(resData) {
  const dt = new Date();
  dt.setSeconds(dt.getSeconds() + resData.expires_in);
  return {
    accessToken: resData.access_token,
    refreshToken: resData.refresh_token,
    expirationDate: dt.toUTCString(),
  };
}

export function transformTokenDataClientStorage() {
  const prefix = 'mb_js_client:';
  return {
    accessToken: localStorage.getItem(prefix + 'oauth_access_token'),
    refreshToken: localStorage.getItem(prefix + 'oauth_refresh_token'),
    expirationDate: localStorage.getItem(prefix + 'oauth_expiation_time'),
  };
}

export async function authorize() {
  try {
    let res = await axios.post(
      tokenUri,
      parseParams({
        grant_type: 'authorization_code',
        client_id: oauthId,
        authCode: this.accessToken,
        redirect_uri: oauthRedirectUrl,
      }),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );
    if (res.data) {
      const data = transformTokenData(res.data);
      store.commit('user/UPDATE_AUTH_DATA', {
        data,
        provider: userProviders.moneyButton,
      });
      axios.defaults.headers.common['Authorization'] =
        'Bearer ' + res.data.access_token;
    }
  } catch (e) {
    console.error('error in authorize', e);
    refreshToken();
  }
}

export async function refreshToken() {
  // commit(types.SET_AUTH_ERROR, false);
  const { refreshToken } = store.getters['user/authInfoByProvider'](
    userProviders.moneyButton
  );

  if (!refreshToken) {
    return;
  }

  try {
    let res = await axios.post(
      tokenUri,
      parseParams({
        grant_type: 'refresh_token',
        client_id: oauthId,
        refresh_token: refreshToken,
      }),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );
    if (res.data) {
      const data = transformTokenData(res.data);
      store.commit('user/UPDATE_AUTH_DATA', {
        data,
        provider: userProviders.moneyButton,
      });
      axios.defaults.headers.common['Authorization'] =
        'Bearer ' + res.data.access_token;
    }
  } catch (e) {
    console.error('error in refreshToken', e.toString());
    localStorage.removeItem('errorEmail');
  }
}

export async function getUserBalance() {
  let { expirationDate, accessToken } = store.getters[
    'user/authInfoByProvider'
  ](userProviders.moneyButton);
  if (
    new Date().valueOf() + refreshTokenUpfrontMs >
    new Date(expirationDate).valueOf()
  ) {
    await refreshToken();
    ({ accessToken } = store.getters['user/authInfoByProvider'](
      userProviders.moneyButton
    ));
  }
  const userData = parseJwt(accessToken);
  if (!userData) {
    return;
  }
  await axios
    .get(`https://www.moneybutton.com/api/v1/users/${userData.sub}/balance`, {
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
    })
    .then(
      (res) => {
        if (res.data) {
          store.commit(
            'user/SET_SATOSHI_BALANCE',
            res.data.data.attributes.satoshis
          );
        }
      },
      async (e) => {
        console.error('error in MB getUserBalance', e);
      }
    );
}
