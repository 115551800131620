<template>
  <TableRow
    @select-row="(data) => $emit('select-market', data)"
    :dataset="dataset"
  >
    <template v-slot:0>
      <div
        class="markets-table__field-fav"
        @click.stop="
          $parent.store.dispatch('settings/toggleFavouriteMarket', market.name)
        "
      >
        <icon
          v-if="isFavourite"
          name="starfill"
          width="14"
          height="14"
          :strokeColor="'none'"
          :fill-color="'#ffffff99'"
        />
        <icon
          v-else
          name="star"
          width="14"
          height="14"
          :strokeColor="'none'"
          :fillColor="'rgba(167, 169, 172, 1)'"
        />
      </div>
    </template>
    <template v-slot:1>
      <div class="markets-table__field-name">
        <div
          class="markets-table__field-name-ticker"
          v-html="marketTicker(market.name)"
        />
        <div
          v-if="isHold || !isActive"
          :class="{
            'markets-table__field-status-hold': isHold && isActive,
            'markets-table__field-status-inactive': !isActive,
          }"
          class="markets-table__field-status"
        />
      </div>
    </template>
    <template v-slot:2>
      <div
        :class="[
          {
            ...$parent.getClassesOfDynamic({
              price: $parent.getChangeValue24(market),
            }),
            inSleep: sleepLoading,
          },
          priceClass,
        ]"
        class="markets-table__field-change"
      >
        {{ $parent.getChangeValue24(market) }}
      </div>
    </template>
    <template v-slot:3>
      <div
        class="markets-table__field-price"
        :class="[priceClass, { inSleep: sleepLoading }]"
      >
        {{ $parent.calcMarketUSDPrice(market) }}
      </div>
    </template>
  </TableRow>
</template>

<script>
import TableRow from '@/components/ui/TableRow';
import { marketTicker } from '@/config/wrapMarkets';
import { ANIMATION_DELAY_MS } from '@/config/constants';

export default {
  name: 'MarketListItem',
  components: {
    TableRow,
  },
  props: {
    dataset: {
      type: Array,
      required: true,
    },
    isHold: {
      type: Boolean,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
    sleepLoading: {
      type: Boolean,
      default: false,
    },
    isFavourite: {
      type: Boolean,
      required: true,
    },
    market: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      priceClass: '',
      marketTicker,
    };
  },
  computed: {
    marketPrice() {
      return this.market.usdPrice;
    },
  },
  watch: {
    marketPrice(newPrice, oldPrice) {
      if (newPrice > oldPrice) {
        this.priceClass = 'increase';
      } else {
        this.priceClass = 'decrease';
      }
    },
    priceClass(newClass) {
      if (newClass) {
        setTimeout(() => {
          this.priceClass = '';
        }, ANIMATION_DELAY_MS);
      }
    },
  },
};
</script>
