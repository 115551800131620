<template>
  <Modal stop-mobile-max-height to-top @close="onClose">
    <Card
      :title="$t('tradingSession.titles.accountDeletion').toUpperCase()"
      class="card"
    >
      <div class="recovery-modal">
        <div class="nomargin">
          {{ $t('popups.accountDeletion.text') }}
        </div>
      </div>
    </Card>
    <div
      class="btns"
      :class="{
        standalone: wd.navigator.standalone || isTelegram,
      }"
    >
      <Button
        :text="$t('popups.accountDeletion.button')"
        :loading="isDeleting"
        :disabled="isDeleting"
        @click="deleteAccount"
        type="secondary"
      />
    </div>
  </Modal>
</template>

<script>
import { useStore } from 'vuex';
import Modal from '@/modules/modals/Modal';
import Card from '@/components/ui/Card';
import Button from '@/components/ui/Button';
import { ref, computed } from 'vue';
import axios from 'axios';
import config from '@/config';
import { useModals } from '@/modules/modals/api';

const EVENTS = {
  close: 'close',
};

export default {
  components: { Modal, Card, Button },
  emits: [EVENTS.close],
  setup(_, { emit }) {
    const store = useStore();
    const { hideModal, modalsByName } = useModals();

    const isTelegram = computed(() =>
      Boolean(store.getters['telegram/instance'])
    );

    const isDeleting = ref(false);
    const deleteAccount = async () => {
      if (isDeleting.value) {
        return;
      }

      isDeleting.value = true;
      const res = await axios.delete(`${config.apiUrl}User/delete`, {
        headers: {
          Authorization: `Bearer ${store.getters['connectors/activeConnect'].accessToken}`,
        },
      });

      if (!res.status === 200) {
        isDeleting.value = false;
        return;
      }

      hideModal(modalsByName.accountDeletion);
      store.dispatch('burger/logout');
    };

    const onClose = () => {
      emit(EVENTS.close);
    };

    return {
      onClose,
      deleteAccount,
      isDeleting,
      isTelegram,
      wd: window,
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/colors';
@import '@/assets/styles/base';

.btn--loading.btn--disabled:hover {
  background: #262a39;
}

.recovery-modal {
  height: auto;
  max-height: calc(100% - 102px);
  width: calc(100% + 40px);
  margin-left: -20px;
  overflow-y: auto;
  margin-bottom: 45px;
  padding: 0 20px;
  box-sizing: border-box;
  font-family: Gotham_Pro_Regular;
  line-height: 20px;

  @media screen and (min-width: 1024px) {
    max-height: calc(100vh - 200px) !important;
  }

  div {
    margin-top: 25px;

    &.nomargin {
      margin: 0;
      margin-bottom: 15px;
    }

    h4 {
      font-family: Gotham_Pro_Bold !important;
    }
  }

  &__issue {
    display: flex;
    align-items: center;
    margin-top: 10px;

    &.last {
      margin-bottom: 20px;
    }

    .check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      border-radius: 4px;
      margin-top: 0;
      border: 1px solid #ccc;
    }

    .label-description {
      margin-top: 1px;
      margin-left: 10px;
    }
  }

  p {
    &.underline {
      text-decoration-line: underline;
    }
  }
}

.btns {
  width: 100%;
  margin-top: 30px;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 1024px) {
    bottom: 50px;

    &.standalone {
      bottom: 82px;
    }
  }

  a {
    text-decoration: none;
  }
}
</style>
