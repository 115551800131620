export default function createListenMutationPlugin() {
  return (store) => {
    store.listenMutation = (type, cb) => {
      return store.subscribe((mutation) => {
        if (mutation.type === type) {
          cb(mutation);
        }
      });
    };
  };
}
