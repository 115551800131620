import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { marketTicker } from '@/config/wrapMarkets';

export const useMarketsTable = () => {
  const { t } = useI18n();

  const marketsColumns = ref([
    { title: '', size: 8, prop: 'favourite', align: 'left' },
    {
      title: t('tables.headers.markets.market'),
      size: 39,
      prop: (market) => marketTicker(market.name).toLowerCase(),
      align: 'left',
    },
    {
      title: t('tables.headers.markets.time'),
      size: 26,
      prop: (market) => (market.usdPrice - market.price24h) / market.price24h,
      align: 'left',
    },
    {
      title: t('tables.headers.markets.price'),
      size: 26,
      prop: 'usdPrice',
      align: 'left',
    },
  ]);

  return {
    marketsColumns,
  };
};
