import {
  cryptoLabelByName,
  marketSymbolByName,
} from '@/modules/markets/helpers';

export const wrapMarket = (market) => {
  const markets = {
    Microstrategy: 'Micro<br/>strategy',
    NortonLifeLock: 'Norton<br/>LifeLock',
    'S&P 500 ETF': 'S&P 500<br/>ETF',
  };
  return markets[market] || market;
};

export const marketTicker = (market) => {
  return marketSymbolByName[market] || market;
};

export const marketLabel = (market) => {
  return cryptoLabelByName[market] || market;
};
