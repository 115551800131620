/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'refr': {
    width: 52,
    height: 51,
    viewBox: '0 0 52 51',
    data: '<path pid="0" d="M34.215 10.157a18.294 18.294 0 00-12.769-1.603l-.004.001a18.08 18.08 0 00-8.183 4.374 17.696 17.696 0 00-4.867 7.808 1.67 1.67 0 01-3.197-.967 21.036 21.036 0 015.785-9.282 21.419 21.419 0 019.692-5.183 21.634 21.634 0 0115.1 1.898 21.3 21.3 0 017.869 7.13V9.164a1.67 1.67 0 013.34 0v11.09a1.67 1.67 0 01-1.67 1.67H34.072a1.67 1.67 0 010-3.34h8.11a17.901 17.901 0 00-7.967-8.427zM44.967 29.744a1.67 1.67 0 011.113 2.083 21.035 21.035 0 01-5.786 9.266 21.419 21.419 0 01-9.683 5.175 21.634 21.634 0 01-15.098-1.898 21.302 21.302 0 01-7.864-7.124v5.185a1.672 1.672 0 01-3.343 0V31.34c0-.923.748-1.671 1.671-1.671h11.24a1.672 1.672 0 110 3.344H9.114a17.902 17.902 0 007.955 8.401 18.294 18.294 0 0012.768 1.604h.003a18.08 18.08 0 008.175-4.368 17.696 17.696 0 004.868-7.794 1.67 1.67 0 012.083-1.113z" _fill="#fff"/>'
  }
})
