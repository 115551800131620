<template>
  <div class="position-split">
    <div class="position-split__controls">
      <SimpleInput
        :value="splitOffInput"
        :title="$t('order.card.titles.splitOff')"
        type="text"
        :min-value="0"
        directive="number"
        @onChange="onChange"
        class="position-split__input"
      />
      <div class="separator" />
      <div
        :class="{ 'box-active': splitIndexMode === 0 }"
        class="position-split__control-btn border box-hover"
        @click="setMode(0)"
      >
        %
      </div>
      <div
        :class="{ 'box-active': splitIndexMode === 1 }"
        class="position-split__control-btn border box-hover"
        @click="setMode(1)"
      >
        {{ position.base_currency === 'USD' ? '$' : 'Ƀ' }}
      </div>
    </div>
    <div class="position-split__info">
      <div class="position-split__values">
        <div class="label text-secondary">
          {{ $t('order.card.titles.position1') }}
        </div>
        <div class="value text-main">
          {{ toCurrencyFormat(splitPosition1Value) }}
          {{ position.base_currency === 'USD' ? 'USD' : 'BSV' }}
        </div>
      </div>
      <div class="position-split__values">
        <div class="label text-secondary">
          {{ $t('order.card.titles.position2') }}
        </div>
        <div class="value test-main">
          {{ toCurrencyFormat(splitPosition2Value) }}
          {{ position.base_currency === 'USD' ? 'USD' : 'BSV' }}
        </div>
      </div>
    </div>
    <div class="position-split__description">
      {{ $t('order.card.hints.splitPosition') }}
    </div>
  </div>
</template>
<script>
import SimpleInput from '@/components/ui/SimpleInput';
import { ref, onMounted, computed, watch } from 'vue';
import { toCurrencyFormat } from '@/helpers/utils';

export default {
  name: 'PositionSplit',
  components: { SimpleInput },
  props: {
    position: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const splitOff = ref(0);
    const splitOffInput = ref(0);
    const splitIndexMode = ref(0);

    const emitData = () => {
      emit('changePosition', {
        position_id: props.position.id,
        amount: (props.position.amount * splitOff.value) / 100,
        market: props.position.market,
      });
    };

    emitData();

    const onChange = (value) => {
      splitOffInput.value = value;

      if (splitIndexMode.value === 0) {
        splitOff.value = +splitOffInput.value;
      } else {
        splitOff.value = (100 * splitOffInput.value) / props.position.amount;
      }
      emitData();
    };

    const splitPosition1Value = computed(() => {
      if (!splitOffInput.value) {
        return 0;
      } else {
        return splitIndexMode.value === 1
          ? splitOffInput.value
          : ((props.position.amount * splitOffInput.value) / 100).toFixed(6);
      }
      // TODO: fix it?
      // eslint-disable-next-line no-unreachable
      emitData();
    });

    const splitPosition2Value = computed(() => {
      return (props.position.amount - splitPosition1Value.value).toFixed(6);
    });

    watch([splitPosition1Value, splitPosition2Value], (val1, val2) => {
      emit('changeSplitValues', { val1, val2 });
    });

    const setMode = (index) => {
      splitIndexMode.value = index;

      if (index === 0) {
        splitOffInput.value = splitOff.value;
      } else {
        splitOffInput.value = (splitOff.value * props.position.amount) / 100;
      }
      emitData();
    };

    onMounted(() => {
      onChange(50);
    });

    return {
      splitOffInput,
      splitIndexMode,
      onChange,
      setMode,
      toCurrencyFormat,
      splitPosition1Value,
      splitPosition2Value,
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/colors';
@import '@/assets/styles/base';

.position-split {
  display: flex;
  flex-direction: column;
  padding-top: 20px;

  .separator {
    margin: 0 5px;
  }

  &__controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__input {
    width: 180px;
  }

  &__control-btn {
    font-family: Gotham_Pro_Regular;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 68px;
    margin-right: 5px;
    height: 40px;
    border-width: 1px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    &:last-child {
      margin-right: 0;
    }
  }

  &__values {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    font-family: Gotham_Pro_Regular;
  }

  &__info {
    display: flex;
    flex-direction: column;
    margin: 20px 0; // 20px 0 40px 0;

    .value {
      font-family: Cantarell_Regular;
    }
  }

  &__description {
    font-family: Gotham_Pro_Regular;
  }

  .split-btn {
    position: absolute;
    bottom: -10px;
    left: 0;
  }
}
</style>
