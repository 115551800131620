<template>
  <Modal :stop-mobile-max-height="true" @close="$emit(EVENTS.close)">
    <Card
      :title="$t('order.card.transactions.title').toUpperCase()"
      class="card"
    >
      <p>
        <a
          class="decoration--underlined"
          rel="noopener noreferrer"
          :href="txLink"
          target="_blank"
          >{{ $t('order.card.transactions.opening') }}</a
        >
      </p>
    </Card>
  </Modal>
</template>
<script>
import Modal from '@/modules/modals/Modal';
import Card from '@/components/ui/Card';

const EVENTS = {
  close: 'close',
};

export default {
  components: { Modal, Card },
  emits: [EVENTS.close],
  props: {
    txLink: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {
      EVENTS,
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/colors';
@import '@/assets/styles/base';
p {
  height: 20px;
  font-family: Gotham_Pro_Medium;

  a {
    text-decoration: none;

    &:hover {
      text-decoration-line: underline;
      text-decoration-thickness: 1px;
    }
  }
}

.close-btn {
  margin-top: 30px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
</style>
