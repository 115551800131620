<template>
  <Modal stop-mobile-max-height @close="onClose">
    <Card :title="$t('popups.usa.title').toUpperCase()" class="card">
      <div
        class="restricted-modal"
        :class="{ 'restricted-modal_with-buttons': showButtons }"
      >
        {{
          $t('popups.usa.info1', {
            country:
              longNamesMap[location?.countryCode] || location?.countryCode,
            citizen:
              shortNamesMap[location?.countryCode] || location?.countryCode,
          })
        }}
      </div>
    </Card>
    <div class="btns" v-if="showButtons">
      <Button
        type="secondary"
        :text="
          $t('popups.usa.usa', {
            citizen:
              shortNamesMap[location?.countryCode] || location?.countryCode,
          })
        "
        :is-html="true"
        class="btn"
        @click="agreeUsa"
      />
      <Button
        type="secondary"
        :text="
          $t('popups.usa.notUsa', {
            citizen:
              shortNamesMap[location?.countryCode] || location?.countryCode,
          })
        "
        :is-html="true"
        class="btn"
        @click="notAgreeUsa"
      />
    </div>
  </Modal>
</template>
<script>
import { useStore } from 'vuex';
import { computed } from 'vue';
import Modal from '@/modules/modals/Modal';
import Card from '@/components/ui/Card';
import Button from '@/components/ui/Button';
import { updateAndSetUserSettings } from '@/modules/settings/api';
import notify from '@/plugins/notify';
import { isIOSDxsApp } from '@/helpers/detects';

const EVENTS = {
  close: 'close',
};

export default {
  components: {
    Modal,
    Card,
    Button,
  },
  emits: ['close'],
  setup(_, { emit }) {
    const store = useStore();
    const showButtons = computed(() => !isIOSDxsApp());

    const longNamesMap = {
      RU: 'Russian Federation (RF)',
      US: 'USA',
    };

    const shortNamesMap = {
      RU: 'RF',
      US: 'US',
    };

    const location = computed(() => store.getters['usa/getLocation']);

    const agreeUsa = () => {
      emit(EVENTS.close);
    };

    const notAgreeUsa = async () => {
      localStorage.setItem('usa', 'notusa');

      await updateAndSetUserSettings({
        uiSettings: {
          blockedFromIp: location.value.countryCode,
        },
      });

      const phoneConfirmed =
        store.getters['settings/settings']?.phoneNumberConfirmed;

      if (!phoneConfirmed) {
        store.dispatch('burger/phoneVerification', { auto: false });
      }

      emit(EVENTS.close);
    };

    const onClose = () => {
      if (!isIOSDxsApp()) {
        emit(EVENTS.close);
      }

      notify({
        text: 'Please close DXS',
        type: 'info',
      });
    };

    return {
      location,
      agreeUsa,
      notAgreeUsa,
      onClose,
      longNamesMap,
      shortNamesMap,
      showButtons,
    };
  },
};
</script>
<style scoped lang="scss">
@import '@/assets/styles/colors';
@import '@/assets/styles/base';
.restricted-modal {
  height: auto;
  overflow-y: auto;
  margin-top: 10px;
  box-sizing: border-box;
  font-family: Gotham_Pro_Regular;
  line-height: 20px;

  div {
    margin-top: 25px;
  }

  &_with-buttons {
    margin-bottom: 50px;
  }
}

.btns {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
