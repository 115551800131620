/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'visa': {
    width: 32,
    height: 21,
    viewBox: '0 0 32 21',
    data: '<path pid="0" d="M1.28.312A1.28 1.28 0 000 1.592v17.536c0 .707.574 1.28 1.28 1.28h29.44a1.28 1.28 0 001.28-1.28V1.592a1.28 1.28 0 00-1.28-1.28H1.28zm17.832 5.99c.779 0 1.403.171 1.801.331l-.272 1.711-.18-.09a3.494 3.494 0 00-1.504-.304c-.786 0-1.15.351-1.15.68-.005.37.425.613 1.127.978 1.159.564 1.695 1.247 1.687 2.145-.016 1.64-1.386 2.698-3.498 2.698-.9-.01-1.769-.2-2.238-.42l.282-1.769.259.126c.66.295 1.087.414 1.891.414.577 0 1.197-.242 1.202-.77.004-.346-.259-.593-1.041-.98-.762-.377-1.772-1.01-1.761-2.145.012-1.534 1.41-2.606 3.395-2.606zm-16.282.25h3.243c.437.017.79.157.911.631l.699 3.608v.002l.21 1.081 1.969-5.322h2.13l-3.166 7.79-2.128.002-1.694-6.286c1.008.534 1.866 1.152 2.363 2.002a5.641 5.641 0 00-.513-.872c-.252-.348-.795-.798-1.022-.99-.791-.667-1.866-1.206-3.027-1.49l.025-.156zm10.009.01h2.083l-1.303 7.777h-2.083l1.303-7.778zm11.765 0h1.578l1.652 7.777H25.94s-.188-.893-.249-1.166l-2.614-.003c-.08.21-.43 1.17-.43 1.17h-2.143l3.031-7.133c.215-.507.58-.646 1.069-.646zm.155 2.091c-.102.29-.28.757-.269.736 0 0-.641 1.71-.809 2.154h1.685l-.47-2.226-.137-.664z" _fill="#767C83"/>'
  }
})
