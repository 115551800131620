/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'relayx': {
    width: 21,
    height: 21,
    viewBox: '0 0 21 21',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M8.917 3.247c-4.852 0-8.785 3.857-8.785 8.615s3.933 8.615 8.785 8.615 8.786-3.857 8.786-8.615-3.934-8.615-8.786-8.615zm0 2.068c3.682 0 6.677 2.937 6.677 6.547S12.6 18.41 8.917 18.41c-3.681 0-6.677-2.938-6.677-6.548s2.996-6.547 6.677-6.547z" _fill="#fff"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M11.346.903C6.494.903 2.561 4.76 2.561 9.52c0 4.758 3.933 8.615 8.785 8.615 4.853 0 8.786-3.857 8.786-8.615 0-4.759-3.933-8.616-8.786-8.616zm.536 2.068c3.682 0 6.677 2.937 6.677 6.548 0 3.61-2.995 6.547-6.677 6.547-3.681 0-6.677-2.937-6.677-6.547S8.201 2.97 11.882 2.97z" _fill="#fff"/>'
  }
})
