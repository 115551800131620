<template>
  <Modal :stop-mobile-max-height="true" @close="$emit('close')">
    <div class="brakeout-trade-wrap">
      <Card :title="$t('popups.breakoutTrade.title')" class="card">
        <div class="brakeout-trade">
          <div class="brakeout-trade__info">
            {{ $t('popups.breakoutTrade.info1') }}
          </div>
          <div class="brakeout-trade__info mt-20">
            {{ $t('popups.breakoutTrade.info2') }}
          </div>
        </div>
      </Card>
    </div>
  </Modal>
</template>
<script>
import Modal from '@/modules/modals/Modal';
import Card from '@/components/ui/Card';

export default {
  components: { Modal, Card },
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/colors';
@import '@/assets/styles/base';

.brakeout-trade-wrap {
  .card {
    min-height: 270px;

    @media screen and (max-width: 480px) {
      min-height: 250px;
    }
  }

  .brakeout-trade {
    &__info {
      font-family: Gotham_Pro_Regular;
      font-size: 0.9375rem;
      color: $color-gray;
      line-height: $default-lineheight;

      &.mt-20 {
        margin-top: 20px;
      }
    }
  }

  .btn {
    margin-top: 30px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
}
</style>
