<template>
  <Modal :stop-mobile-max-height="true" @close="onClose">
    <div class="dotwallet-users-wrap">
      <Card :title="$t('popups.lowAutopayBalance.title')" class="card">
        <div class="dotwallet-users">
          <div class="dotwallet-users__info mt-20">
            {{ $t('popups.lowAutopayBalance.text') }}
          </div>
        </div>
      </Card>
      <Button
        :text="$t('popups.buttons.learnMore')"
        type="secondary"
        class="btn"
        @click="redirectUser"
      />
    </div>
  </Modal>
</template>

<script>
import Modal from '@/modules/modals/Modal';
import Card from '@/components/ui/Card';
import Button from '@/components/ui/Button';
import { useStore } from 'vuex';
import { computed } from 'vue';

export default {
  components: {
    Button,
    Modal,
    Card,
  },
  setup(_, { emit }) {
    const store = useStore();

    const activeConnect = computed(() => {
      return store.getters['connectors/activeConnect'];
    });

    const onClose = () => {
      if (+activeConnect.value.satoshiBalance) {
        localStorage.setItem('showDWUsers', true);
      }
      emit('close');
    };

    const redirectUser = () => {
      localStorage.setItem('showDWUsers', true);
      const redirectUrl = window.location.href.replace(
        window.location.search,
        ''
      );
      window.location.href = `https://ddpurse.com/wallet/open/transfer?redirect_url=${redirectUrl}`;
    };

    return { redirectUser, onClose };
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/colors';
@import '@/assets/styles/base';

.dotwallet-users-wrap {
  .card {
    min-height: 180px;
  }

  .dotwallet-users {
    &__info {
      font-family: Gotham_Pro_Regular;
      font-size: 0.9375rem;
      color: $color-gray;
      line-height: $default-lineheight;

      &.mt-20 {
        margin-top: 20px;
      }
    }
  }

  .btn {
    margin-top: 30px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
}
</style>
