<template>
  <div id="v-step-6" class="positions-list box">
    <div class="positions-list__title" @click="toggleCard">
      {{
        activeConnect && activeConnect.provider
          ? parseTitle($t('blocks.titles.positions'))
          : parseTitle($t('blocks.titles.demoPositions'))
      }}
      <Icon
        :class="{ openCard }"
        name="arrow_down"
        width="12"
        height="12"
        class="arrow-icon"
      />
      <Icon
        v-if="false"
        name="refr"
        width="13"
        height="13"
        class="arrow-icon arrows"
        @click.stop="toggleGroups"
      />
      <div
        :class="{
          profit: +accountBalance.unrealizedPL >= 0,
        }"
        v-html="unrlTotalMainPL"
        class="pl-amount"
      />
    </div>
    <PositionsTabs @change="onChangePositionsFilter" />
    <PositionsList
      :filter-type="filterType"
      :hide-all-positions="!openCard"
      :positions="positionsLists || []"
      :loading="loading"
      :sleep-loading="sleepLoading"
      @closePosition="onClosePosition"
      class="mt-15"
    />
    <ModalMarketOpenClose
      v-if="showMarketOpenClose"
      :hide-again-btn="true"
      :value="workHour"
      @close="
        showMarketOpenClose = false;
        // eslint-disable-next-line prettier/prettier
        workHour = '';
      "
    />
  </div>
</template>
<script>
import PositionsTabs from './components/PositionsTabs.vue';
import PositionsList from './components/PositionsList.vue';
import Icon from '@/components/ui/Icon.vue';
import '@/assets/icons/arrow_down';
import useToggleCard from '@/compositions/useToggleCard';
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import ModalMarketOpenClose from '@/components/modals/ModalMarketOpenClose';
import { workHours, usdBsvFormatAmount } from '@/helpers/utils';
import useMarketCoin from '@/compositions/useMarketCoin';
import { PositionTypes } from './helpers';
import useClosePosition from '@/compositions/useClosePosition';
import { parseTitle } from '@/helpers/ui';
// import { connApp } from '@/helpers/signalR';
import '@/assets/icons/refr';

export default {
  name: 'Positions',
  components: {
    PositionsTabs,
    PositionsList,
    ModalMarketOpenClose,
    Icon,
  },
  setup() {
    const store = useStore();
    const filterType = ref(PositionTypes.open);
    const { openCard, toggleCard } = useToggleCard();
    const { closePositionJustNow } = useClosePosition();

    const toggleGroups = () => {
      store.dispatch('localUiSettings/togglePositionGroup', null);
    };

    const showMarketOpenClose = ref(false);
    const workHour = ref('');

    const activePosition = computed(
      () => store.getters['positions/getCurrentPosition']
    );

    const accountBalance = computed(
      () => store.getters['user/getAccountBalance']
    );

    const unrlTotalMainPL = computed(() => {
      if (accountBalance.value.unrealizedPL === '') {
        return '';
      } else if (!+accountBalance.value.unrealizedPL) {
        return '$0';
      }
      return usdBsvFormatAmount(
        accountBalance.value.unrealizedPL,
        '',
        accountBalance.value.unrealizedPL > 0
      );
    });

    const { isMarketActive } = useMarketCoin();

    const onChangePositionsFilter = (type) => {
      filterType.value = type;

      // if (type === PositionTypes.history) {
      //   connApp
      //     .invoke('FilterUserPositions', {
      //       state: 'History',
      //       take: 200,
      //     })
      //     .then((historyPositions) => {
      //       const openPositions =
      //         store.getters['positions/getPositionsByType'][PositionTypes.open];
      //       const pendingPositions =
      //         store.getters['positions/getPositionsByType'][
      //           PositionTypes.pending
      //         ];

      //       store.dispatch('positions/setAllPositions', [
      //         ...openPositions,
      //         ...pendingPositions,
      //         ...historyPositions,
      //       ]);
      //     });
      // }
    };

    const activeConnect = computed(
      () => store.getters['connectors/activeConnect']
    );

    const userId = computed(() => store.getters['user/userId']);

    const positionsLists = computed(
      () => store.getters['positions/getPositionsListsByType'][filterType.value]
    );

    const loading = computed(() => store.getters['positions/positionsLoading']);
    const sleepLoading = computed(
      () => store.getters['positions/sleepLoading']
    );

    const onClosePosition = async (position) => {
      store.dispatch('positions/setCurrentPosition', { position });

      const market = store.getters['markets/marketsById'][position.market_id];

      if (market && position.state === 'opened' && !isMarketActive(market)) {
        workHour.value = workHours(new Date(), market);
        showMarketOpenClose.value = true;
        return;
      }

      if (position.state === 'proposed') {
        await closePositionJustNow();
        return;
      }

      store.dispatch('positions/onPositionCloseRequest');
    };

    return {
      activeConnect,
      positionsLists,
      filterType,
      onChangePositionsFilter,
      openCard,
      activePosition,
      toggleCard,
      onClosePosition,
      userId,
      workHour,
      showMarketOpenClose,
      loading,
      sleepLoading,
      accountBalance,
      unrlTotalMainPL,
      toggleGroups,
      parseTitle,
    };
  },
};
</script>
<style lang="scss" scoped>
.positions-list {
  position: relative;
  box-sizing: border-box;

  .mt-15 {
    margin-top: 15px;
  }

  &__title {
    position: relative;
    display: flex;
    align-items: center;
    font-family: Gotham_Pro_Medium;
    font-size: 22px; // 1rem;
    padding-bottom: 15px;
    padding: 18px 0 0 20px;
    cursor: pointer;
    margin-bottom: 15px;
    text-transform: capitalize;

    .arrow-icon {
      margin-left: 10px;
      margin-top: 3px;
      transition: all 0.3s ease-in-out;
      transform: rotate(-90deg);

      &.openCard {
        transform: rotate(0);
      }

      &.arrows {
        margin-left: 13px;
        transform: none;
      }
    }

    .pl-amount {
      display: none;
      position: absolute;
      left: 225px;
      font-family: Cantarell_Bold;
      color: #f85485;

      &.profit {
        color: #11b5d6;
      }
    }
  }

  body.dark,
  body.light {
    .positions-list {
      .pl-amount {
        color: #de5e56;

        &.profit {
          color: #509f96;
        }
      }
    }
  }

  @media screen and (max-width: 480px) {
    .pl-amount {
      display: initial;
    }
  }
}
</style>
