<template>
  <Modal @close="$emit('close')" stop-mobile-max-height>
    <div class="android-pwa">
      <Card :title="'Install wep app'" class="card kys-status__inner">
        <div class="android-pwa__content">
          Install this webapp on your Phone?
        </div>
      </Card>
      <div class="android-pwa__btns">
        <Button type="secondary" width="full" text="CLOSE" @click="onClose" />
        <Button
          type="secondary"
          width="full"
          text="INSTALL"
          @click="onInstall"
        />
      </div>
    </div>
  </Modal>
</template>
<script>
import Modal from '@/modules/modals/Modal';
import Card from '@/components/ui/Card';
import Button from '@/components/ui/Button';

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ShareBounty',
  components: { Button, Modal, Card },
  props: {
    installEvent: {
      required: true,
    },
  },
  setup(props, { emit }) {
    const onClose = () => {
      localStorage.setItem('showPWAModalAndroid', true);
      emit('close');
    };

    const onInstall = () => {
      props.installEvent.prompt();
      props.installEvent.userChoice.then((choice) => {
        emit('close');
        if (choice.outcome === 'accepted') {
          // Do something additional if the user chose to install
        } else {
          // Do something additional if the user declined
        }
      });
    };

    return {
      onClose,
      onInstall,
    };
  },
});
</script>

<style lang="scss">
.android-pwa {
  &__content {
    margin: 10px 0;
    line-height: 1.2;
    position: relative;
    text-align: left;
    font-family: Gotham_Pro_Regular;
  }

  &__btns {
    position: absolute;
    display: flex;
    flex-direction: row;
    bottom: 0;
    width: 100%;
  }
}
</style>
