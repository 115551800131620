import { computed } from 'vue';
import { useStore } from 'vuex';

export const useMarketPriceForMargin = () => {
  const store = useStore();
  const marketsList = computed(() => store.getters['markets/markets']);

  const activeConnect = computed(
    () => store.getters['connectors/activeConnect']
  );

  const marketPriceFromFiorinCurrency = computed(() => {
    const findMarket = marketsList.value.find(
      (item) =>
        item.name.toLowerCase() ===
        `${activeConnect?.value?.tokenIdCurrency?.toLowerCase()}/usd`
    );
    console.log('REFILL LOG PRICE FOR MARGIN', findMarket?.usdPrice || 1);
    return findMarket?.usdPrice || 1;
  });

  return {
    marketPriceFromFiorinCurrency,
  };
};
