export const setMetaThemeColor = (value) => {
  let color = '#141721';

  if (value === 'dark') {
    color = '#141721';
  }
  if (value === 'light') {
    color = '#fff';
  }
  if (value === 'twilight') {
    color = 'rgb(59, 67, 116)';
  }

  document.querySelector("meta[name='theme-color']").content = color;
};

export const parseTitle = (title, replaceStr = '') => {
  if (!title) {
    return title;
  }
  return `${title[0].toUpperCase()}${title
    .toLowerCase()
    .slice(1)
    .replaceAll(replaceStr.toLowerCase(), replaceStr.toUpperCase())}`;
};
