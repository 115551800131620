import {
  createCompositionLists,
  createComposition,
  findListByComposition,
  createCompositionList,
} from '@/helpers/compositionGroup';
import filter from 'lodash/filter';
import sumBy from 'lodash/sumBy';
import { PositionSides } from '@/config/constants';
import BigNumber from 'bignumber.js';

export const calcIncomingMarginPosition = ({
  side,
  closed_price,
  entry_price,
  leverage,
  margin,
  marginTx,
  state,
  // decay,
  // amount,
}) => {
  if (!marginTx) {
    return 0;
  }

  let percent = 0;

  if (state !== PositionTypes.canceled) {
    percent = (
      (side === PositionSides.BUY
        ? (closed_price - entry_price) / entry_price
        : (entry_price - closed_price) / entry_price) *
      leverage *
      100
    ).toFixed(4);
  }
  // const pl =
  //   side === PositionSides.BUY
  //     ? (amount * (closed_price - entry_price)) / entry_price
  //     : (amount * (entry_price - closed_price)) / entry_price;

  // const net_pl = pl - (decay || 0);

  let mrg = margin;
  if (percent > -80 && percent < 0) {
    const perc = -percent;
    mrg = margin - (margin / 100) * perc;
  }
  if (percent < -80) {
    mrg = 0;
  }

  return mrg;
};

export const extendPositionWithStats = (position, market, exchangeRate) => {
  const item = {
    take_profit_price: null,
    stop_loss_price: null,
    ...position,
  };

  if (!market) {
    return item;
  }

  item.market = market.name;

  if (!exchangeRate) {
    exchangeRate = 1;
  }

  if (item.close_exchange_rate) {
    exchangeRate = item.close_exchange_rate;
  }

  if (!item.entry_price && item.entry_price !== 0) {
    item.entry_price =
      item.side === PositionSides.BUY ? market.buy : market.sell;
  }

  // const activePrice =
  //   item.side === PositionSides.BUY ? market.buy : market.sell;

  if (item.type !== PositionTypes.history) {
    if (item.state === 'proposed') {
      item.closed_price =
        item.side === PositionSides.BUY ? market.buy : market.sell;
    } else {
      item.closed_price =
        item.side === PositionSides.BUY ? market.sell : market.buy;
    }
  }

  if (!item.margin) {
    item.margin = BigNumber(
      (item.amount * exchangeRate) / item.leverage
    ).toFixed();
  }

  if (!item.decay) {
    item.decay = 0;
  }

  if (item.state != 'canceled') {
    item.pl =
      item.side === PositionSides.BUY
        ? // ? (item.amount * activePrice) / item.entry_price - item.amount
          // : item.amount - (activePrice * item.amount) / item.entry_price;
          (item.amount * (item.closed_price - item.entry_price)) /
          item.entry_price
        : (item.amount * (item.entry_price - item.closed_price)) /
          item.entry_price;

    item.pl = BigNumber(item.pl).toFixed();

    item.net_pl = item.pl - (item.decay || 0);
    item.pl_percent =
      (item.side === PositionSides.BUY
        ? (item.closed_price - item.entry_price) / item.entry_price
        : (item.entry_price - item.closed_price) / item.entry_price) *
      item.leverage *
      100;
  }

  let stop_loss = item.stop_loss || item.stop_loss === 0 ? item.stop_loss : 80;

  if (item.state == 'opened') {
    if (80 == stop_loss) {
      item.risk = item.margin;
    } else {
      if (stop_loss > 0) {
        item.risk = BigNumber((stop_loss * item.margin) / 100).toFixed();
      } else {
        item.risk = 0;
      }
    }
  }

  item.liquidation_price =
    item.side === PositionSides.BUY
      ? item.entry_price * (1 - 80 / item.leverage / 100)
      : item.entry_price * (1 + 80 / item.leverage / 100);

  item.liquidation_price = BigNumber(item.liquidation_price).toFixed();

  if (item.stop_loss || item.stop_loss === 0) {
    item.stop_loss_price =
      item.side === PositionSides.BUY
        ? item.entry_price * (1 - stop_loss / 100 / item.leverage)
        : item.entry_price * (1 + stop_loss / 100 / item.leverage);

    item.stop_loss_price = BigNumber(item.stop_loss_price).toFixed();
  }

  if (item.take_profit || item.take_profit === 0) {
    item.take_profit_price =
      item.side === PositionSides.BUY
        ? item.entry_price * (1 + item.take_profit / 100 / item.leverage)
        : item.entry_price * (1 - item.take_profit / 100 / item.leverage);

    item.take_profit_price = BigNumber(item.take_profit_price).toFixed();
  }

  return item;
};

export const PositionTypes = {
  history: 'history',
  open: 'open',
  pending: 'pending',
  proposed: 'proposed',
  canceled: 'canceled',
  opened: 'opened',
  closed: 'closed',
  updated: 'updated',
  duplicate: 'duplicate',
};

export const PositionSideTypes = {
  bid: 'BID',
  ask: 'ASK',
};

export const isHistoryPosition = (state) =>
  ['history', 'canceled', 'closed'].includes(state);

export const getPositionType = (state) => {
  if (state === 'history' || state === 'canceled' || state === 'closed') {
    return PositionTypes.history;
  }

  if (state === 'opened') {
    return PositionTypes.open;
  }

  if (state === 'proposed') {
    return PositionTypes.pending;
  }

  throw new Error(`Unable to determine position type. Actual state=${state}`);
};

export const createPositionLists = (positions = []) => {
  const lists = createCompositionLists(positions, {
    compositePath: ['market', 'side', 'type'],
    data: { opened: true },
  });

  return lists;
};

export const insertPositionToLists = (lists, position) => {
  const composition = createComposition(position, ['market', 'side', 'type']);
  let compositionList = findListByComposition(lists, composition);

  if (!compositionList) {
    compositionList = createCompositionList(composition, { opened: true });
    lists.push(compositionList);
  }

  compositionList.list.push(position);
};

export const sortHistoryPositions = (lists) => {
  const compareByClosed = (posA, posB) =>
    posB.closedTimestamp - posA.closedTimestamp;

  sortPositionsLists(lists, compareByClosed);
};

export const sortOpenedPositions = (lists) => {
  const compareByOpened = (posA, posB) =>
    posB.openedTimestamp - posA.openedTimestamp;
  const compareById = (posA, posB) => posB.id - posA.id;
  const compare = (posA, posB) =>
    compareByOpened(posA, posB) || compareById(posA, posB);

  sortPositionsLists(lists, compare);
};

export const sortPositionsLists = (lists, comparator) => {
  const sortListPositions = ({ list }) => list.sort(comparator);

  lists.forEach(sortListPositions);

  lists.sort(({ list: listA }, { list: listB }) =>
    comparator(listA[0], listB[0])
  );
};

export const createPositionEntity = (position) => ({
  type: getPositionType(position.state),
  closedTimestamp: Date.parse(position.closed),
  openedTimestamp: Date.parse(position.opened),
  pl: 0,
  risk: 0,
  decay: 0,
  margin: 0,
  net_pl: 0,
  pl_percent: 0,
  entry_price: 0,
  closed_price: 0,
  take_profit_price: 0,
  stop_loss_price: 0,
  liquidation_price: 0,
  base_currency: '',
  close_exchange_rate: 0,
  ...position,
});

const calcSummary = (list, field) => {
  const totalSum = list.reduce((all, item) => {
    const positionPrice = item[field] * item.amount || 0;
    return all + positionPrice;
  }, 0);

  const filtered = filter(list, field);
  const amount = sumBy(filtered, 'amount');

  return amount ? totalSum / amount : undefined;
};

export const calculateTotalsForPositionList = (list) => {
  return {
    marketId: list[0]?.market_id,
    amount: sumBy(list, 'amount'),
    pl: BigNumber(
      list.reduce((prev, item) => {
        // console.log(+(`${item.pl}`).slice(0, 8));
        return prev + +`${item.pl}`.slice(0, 8);
      }, 0)
    ).toFixed(),
    entrySummary: calcSummary(list, 'entry_price'),
    closedSummary: calcSummary(list, 'closed_price'),
  };
};
