<template>
  <div class="desktop">
    <!-- MARKETS LEFT-->
    <div class="sticky sticky-10">
      <div class="mb-10" id="new-order-wrap">
        <FormPosition />
      </div>
      <div
        class="markets left box v-tour-target"
        :style="{
          height: stickyMarketsHeight || '',
        }"
      >
        <Markets />
      </div>
    </div>
    <!-- RIGHT -->
    <div class="right">
      <!-- CHART -->
      <div class="chart box">
        <div class="card__title--menu" @click="(e) => e.stopPropagation()">
          <Menu />
        </div>
        <Card
          :title="$parent.chartTitle"
          :replace-str-upper="$parent.chartMarketName"
          no-first-letter-parse-title
          stop-web-paddings
          :auto-width="true"
          :is-toggle="true"
          :body-class="'card-full'"
        >
          <Chart />
        </Card>
      </div>
      <!-- BOTTOM -->
      <div class="row mt-10">
        <!-- POSITIONS -->
        <div class="positions">
          <Positions />
        </div>
        <!-- ACCOUNT, MARKET INFO, ETC -->
        <div class="details ml-10">
          <div class="block mb-10">
            <AccountInfo />
          </div>
          <div class="block mb-10 box">
            <MarketInfo />
          </div>
          <div
            v-if="$store.getters['connectors/activeConnect'].provider"
            id="v-step-8"
            class="block box"
          >
            <SessionInfo />
          </div>
        </div>
      </div>
    </div>
    <v-tour name="desktop-tour" :steps="steps" :options="tourOptions" />
    <swaps-widget
      v-if="modalStatus(modalsByName.swaps)"
      @close="hideModal(modalsByName.swaps)"
      modal-class="widget-modal"
    />
    <FeaturesModal
      v-if="modalStatus('features')"
      @close="hideModal('features')"
    />
    <HandCashNotify
      v-if="modalStatus(modalsByName.handCashNotify)"
      @close="hideModal(modalsByName.handCashNotify)"
    />
    <KYCVerification
      v-if="modalStatus(modalsByName.verification)"
      @close="hideModal(modalsByName.verification)"
    />
    <KYCStatus
      v-if="modalStatus(modalsByName.kycStatus)"
      @close="hideModal(modalsByName.kycStatus)"
    />
    <!-- <LostConnectionStatus
      v-if="modalStatus(modalsByName.lostConnectionStatus)"
      @close="hideModal(modalsByName.lostConnectionStatus)"
    />-->
  </div>
</template>
<script>
import FormPosition from '@/modules/formPosition/FormPosition.vue';
import Markets from '@/modules/markets/Markets.vue';
import AccountInfo from '@/modules/accountInfo/AccountInfo.vue';
import MarketInfo from '@/modules/marketInfo/MarketInfo.vue';
import SessionInfo from '@/modules/session/SessionInfo.vue';
import Positions from '@/modules/positions/Positions.vue';
import Chart from '@/components/ui/Chart.vue';
import Card from '@/components/ui/Card.vue';
import Menu from '@/components/app/Menu.vue';
import SwapsWidget from '@/components/modals/SwapsWidget';
import FeaturesModal from '@/modules/features/index.vue';
import HandCashNotify from '@/components/modals/HandCashNotify';
import KYCVerification from '@/components/modals/KYCVerification';
import KYCStatus from '@/components/modals/KYCStatus';
// import LostConnectionStatus from '@/components/modals/MainConnectionLost.vue';
import { defineComponent } from 'vue';
import { useModals } from '@/modules/modals/api';
import useUserActions from '@/compositions/useUserActions';

export default defineComponent({
  name: 'DesktopLayout',
  components: {
    FormPosition,
    Markets,
    AccountInfo,
    MarketInfo,
    SessionInfo,
    Positions,
    Chart,
    Card,
    Menu,
    SwapsWidget,
    FeaturesModal,
    HandCashNotify,
    KYCVerification,
    KYCStatus,
    // LostConnectionStatus,
  },
  data() {
    return {
      marketsHeight: 'calc(100vh - 77px)',
      steps: [
        {
          target: '#v-step-0',
          content: 'Here are the markets you can trade.',
          params: {
            placement: 'right-start',
            enableScrolling: false,
          },
        },
        {
          target: '#v-step-1',
          content: 'Please select a market category.',
          params: {
            placement: 'right-start',
            enableScrolling: false,
          },
        },
        {
          target: '#v-step-2',
          content: 'Please click the market you wish to trade.',
          params: {
            placement: 'right-start',
            enableScrolling: false,
          },
        },
        {
          target: '#v-step-3',
          content:
            'Here you can conduct technical analysis on the selected market.',
          params: {
            placement: 'bottom-start',
            enableScrolling: false,
          },
        },
        {
          target: '#v-step-4',
          content: `Before opening the trade please study the 'Market data' panel, each asset has different terms. Please view these numbers and their meaning.`,
          params: {
            placement: 'left-start',
          },
        },
        {
          target: '#v-step-5',
          content: `This is where you create positions. Go long (buy/green) or short (sell order/red).`,
          params: {
            placement: 'right-start',
          },
        },
        {
          target: '#v-step-5',
          content: `The amount is equal to margin (collateral) multiplied by leverage (max by default). Click 'More' on the right side of the 'Buy/Sell' button to define entry price, change leverage or set 'Target/Stop' in advance.`,
          params: {
            placement: 'right-start',
          },
          before() {
            setTimeout(() => {
              const buyButton = document.querySelector(
                '.positon-order-btns__btn.buy'
              );
              buyButton.click();

              setTimeout(() => {
                const moreButton = document.querySelector(
                  '.form-position__controls-btn.toggle'
                );
                moreButton.click();
              }, 1000);

              document.documentElement.scrollTop = 0;
            }, 10);
          },
        },
        {
          target: '#v-step-6',
          content: `Here you can see all of your open, pending or historic positions. You can click on any of your positions to see details, manage 'Target/Stop' prices or split the position into two or more new positions to be managed separately.`,
          params: {
            placement: 'top-start',
          },
          before() {
            setTimeout(() => {
              document.documentElement.scrollTop = 0;
            }, 0);
          },
        },
        {
          target: '#v-step-7',
          content: `Here you can see your wallet balance, pending and current positions as well as current risk. You can also see your Grand Total, your unrealized profit/loss and unsettled profits.`,
          params: {
            placement: 'left-start',
          },
        },
        {
          target: '#v-step-8',
          content: `This panel shows current session liquidity,
          i.e. how much communal profit available for all traders to win.
          It is calculated based on the current trading session
          profit/loss balance and coverage from the liquidity pool.
          Profits and losses are settled against each other every 8 hours.`,
          params: {
            placement: 'left-start',
          },
        },
      ],
      tourOptions: {
        highlight: true,
        useKeyboardNavigation: false,
        labels: {
          buttonPrevious: 'Back',
          buttonNext: 'Next',
          buttonStop: 'Finish',
        },
      },
    };
  },
  computed: {
    stickyMarketsHeight() {
      return this.marketsHeight;
    },
  },
  setup() {
    const { hideModal, modalStatus, modalsByName } = useModals();

    useUserActions();

    return { hideModal, modalStatus, modalsByName };
  },
  mounted() {
    const resizeObserver = new ResizeObserver(() => {
      const el = document.getElementById('new-order-wrap');
      if (el) {
        this.marketsHeight = `calc(100vh - ${+el.offsetHeight - -30}px)`;
      }
    });

    resizeObserver.observe(document.getElementById('new-order-wrap'));
  },
});
</script>
<style lang="scss">
@import '@/assets/styles/colors';
@import '@/assets/styles/base';

.desktop {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  max-width: 1680px;
  box-sizing: border-box;

  .sticky {
    max-width: 350px;
    position: sticky;

    &-10 {
      top: 0;
      margin-top: -10px;
      padding-top: 10px;
    }
  }

  .markets {
    overflow: hidden;

    &.left {
      width: 350px;
    }
  }

  .right {
    position: relative;
    width: calc(100% - 360px);
    overflow: hidden;
    margin-left: 10px;
  }

  .chart {
    width: 100%;
    // min-height: 340px;
  }

  .row {
    display: flex;
    width: 100%;
  }

  .positions {
    width: calc(100% - 34%);
  }

  .details {
    width: 34%;
  }

  .block {
    width: 100%;
    min-height: 57px;
  }

  .mt-10 {
    margin-top: $px10;
  }

  .mb-10 {
    margin-bottom: $px10;
  }

  .ml-10 {
    margin-left: $px10;
  }
}

@media (min-width: 1400px) {
  .desktop {
    .positions {
      width: calc(100% - 278px);
    }

    .details {
      width: 278px;
    }
  }
}

@media (min-width: 1700px) {
  .desktop {
    .positions {
      width: calc(100% - 278px);
    }

    .details {
      width: 278px;
    }
  }
}
</style>
