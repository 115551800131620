<template>
  <Modal :stop-mobile-max-height="true" @close="$emit(EVENTS.close)">
    <Card
      :title="$t('reviews.title').toUpperCase()"
      class="card"
      :body-class="'card-full'"
    >
      <div class="users">
        <ReviewItem author="joe_sun@relayx.io">
          <div v-html="$t('reviews.item1')" />
        </ReviewItem>
        <ReviewItem author="26959@moneybutton.com">
          <div v-html="$t('reviews.item2')" />
        </ReviewItem>
        <ReviewItem author="ehas@moneybutton.com">
          <div v-html="$t('reviews.item3')" />
        </ReviewItem>
        <ReviewItem author="michaeljackson@moneybutton.com">
          <div v-html="$t('reviews.item4')" />
        </ReviewItem>
        <ReviewItem author="lindsay@relayx.io">
          <div v-html="$t('reviews.item5')" />
        </ReviewItem>
        <ReviewItem author="13680@moneybutton.com">
          <div v-html="$t('reviews.item6')" />
        </ReviewItem>
        <ReviewItem author="cog@moneybutton.com">
          <div v-html="$t('reviews.item7')" />
        </ReviewItem>
        <ReviewItem author="24003@moneybutton.com">
          <div v-html="$t('reviews.item8')" />
        </ReviewItem>
        <ReviewItem author="29664@moneybutton.com">
          <div v-html="$t('reviews.item9')" />
        </ReviewItem>
        <ReviewItem author="29460@moneybutton.com">
          <div v-html="$t('reviews.item10')" />
        </ReviewItem>
        <ReviewItem author="13437@moneybutton.com">
          <div v-html="$t('reviews.item11')" />
        </ReviewItem>
        <ReviewItem author="fritsrules@relayx.io">
          <div v-html="$t('reviews.item12')" />
        </ReviewItem>
        <ReviewItem author="32810@moneybutton.com">
          <div v-html="$t('reviews.item13')" />
        </ReviewItem>
        <ReviewItem author="xl8junk@moneybutton.com">
          <div v-html="$t('reviews.item14')" />
        </ReviewItem>
        <ReviewItem author="33142@moneybutton.com">
          <div v-html="$t('reviews.item15')" />
        </ReviewItem>
        <ReviewItem author="xstream@moneybutton.com">
          <div v-html="$t('reviews.item16')" />
        </ReviewItem>
        <ReviewItem author="gian@moneybutton.com">
          <div v-html="$t('reviews.item17')" />
        </ReviewItem>
        <ReviewItem author="31924@moneybutton.com">
          <div v-html="$t('reviews.item18')" />
        </ReviewItem>
        <ReviewItem author="twitter: @BitcoinHoarder">
          <div v-html="$t('reviews.item19')" />
        </ReviewItem>
        <ReviewItem author="11x854@moneybutton.com">
          <div v-html="$t('reviews.item20')" />
        </ReviewItem>
        <ReviewItem author="twitter: @sv_frontier">
          <div v-html="$t('reviews.item21')" />
        </ReviewItem>
      </div>
    </Card>
    <Button
      :text="$t('close')"
      type="secondary"
      class="close-btn"
      @click="$emit('close')"
    />
  </Modal>
</template>
<script>
import Modal from '@/modules/modals/Modal';
import Card from '@/components/ui/Card';
import Button from '@/components/ui/Button';
import ReviewItem from '@/components/ui/ReviewItem';

const EVENTS = {
  close: 'close',
};

export default {
  components: { Modal, Card, Button, ReviewItem },
  emits: [EVENTS.close],
  setup() {
    return {
      EVENTS,
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/colors';
@import '@/assets/styles/base';

.users {
  height: 400px;
  overflow-y: auto;
  margin-bottom: 45px;
  margin-top: 15px;
  padding-right: $px20;
  padding-left: $px20;
  box-sizing: border-box;
  font-family: Gotham_Pro_Regular;
  line-height: 20px;

  p {
    &.underline {
      text-decoration-line: underline;
    }
  }

  div {
    h4 {
      font-family: Gotham_Pro_Bold !important;
    }
  }

  span {
    text-decoration-line: underline;
    cursor: pointer;
  }
}

.close-btn {
  margin-top: 30px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
</style>
