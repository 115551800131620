export default {
  data() {
    return {
      $tour_mobile: 'mobile-tour',
      $tour_laptop: 'laptop-tour',
      $tour_desktop: 'desktop-tour',
    };
  },
  methods: {
    $tour_goToNextStep(name, callback, step = -1) {
      if (this.$tour_isDefaultStep(step)) {
        if (this.$tour_isRunning(name)) {
          this.$tours[name].nextStep(true);
        }
        callback();
        return;
      }

      if (this.$tour_isDisabledOrSpecificStep(name, step)) {
        callback();
        if (this.$tour_isRunning(name)) {
          this.$nextTick(() => {
            this.$tours[name].nextStep(true);
          });
        }
      }
    },
    $tour_isDefaultStep(step) {
      return step === -1;
    },
    $tour_isRunning(name) {
      return this.$tours[name]?.isRunning;
    },
    $tour_isDisabledOrSpecificStep(name, step) {
      return (
        !this.$tour_isRunning(name) || this.$tours[name].currentStep === step
      );
    },
  },
};
