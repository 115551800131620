<template>
  <SwipeButton
    ref="swipe"
    button-color="#0000FF"
    overlay-color="#0000FF"
    slider-background-color="#FFFFFF"
    text-color="#000000"
    button-text=""
    :initial-instruction-text="preAmountUsd"
    @actionConfirmed="onActionConfirmed"
  />
</template>
<script>
import { v4 as uuidv4 } from 'uuid';
import { mapGetters } from 'vuex';
import axios from 'axios';
import { toCurrencyFormat } from '@/helpers/utils';
import SwipeButton from './SwipeButton.vue';
import bsvPrice from '@/compositions/bsvPrice';
import notify from '@/plugins/notify';

export default {
  name: 'DotWalletButton',
  components: {
    SwipeButton,
  },
  props: {
    opreturn: {
      type: String,
      default: '',
    },
    amount: {
      type: Number,
      default: 0,
    },
    address: {
      type: String,
      required: true,
    },
    productName: {
      type: String,
      required: true,
    },
    productId: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters({
      activeConnect: 'connectors/activeConnect',
    }),
    preAmountUsd() {
      let usdAmount = bsvPrice() * this.amount;
      return `$${toCurrencyFormat(usdAmount, null, 2)}`;
    },
  },
  methods: {
    async onActionConfirmed() {
      const script = this.opreturn;
      const productId = this.productId ? this.productId : uuidv4();
      try {
        const result = await axios.post(
          `${process.env.VUE_APP_API_URL}wallets/dotwallet/pay`,
          {
            address: this.address,
            amount: Math.round(1e8 * this.amount),
            productId,
            script,
            productName: this.productName,
          },
          {
            headers: {
              Authorization: `Bearer ${this.activeConnect.accessToken}`,
            },
          }
        );

        if (result.status === 200) {
          this.$emit('payment', result.data);
        }
      } catch (e) {
        console.error(e);
        const data = e.response.data;
        this.$emit('error');
        let errorMessage;
        if (data.error.errorCode === 10180029) {
          // this.toggleModal({ k: 'lowAutopayBalance', v: true })
          errorMessage = 'Please increase spending limit for DW Auto Pay.';
        } else if (data.error.errorCode === 10180007) {
          // this.toggleModal({ k: 'lowAutopayBalance', v: true })
          errorMessage = 'BSV insufficient balance.';
        }

        if (errorMessage || data.error.message) {
          notify({
            text: errorMessage || data.error.message,
            type: 'error',
          });
        }
      }
    },
    resetSwipe() {
      // debug
      this.$refs.swipe.reset();
    },
  },
};
</script>
<style lang="scss" scoped></style>
