/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'positions_off': {
    width: 17,
    height: 17,
    viewBox: '0 0 17 17',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M5.822.003C5.882 0 5.94 0 6 0h5a6 6 0 016 6v5a6.003 6.003 0 01-4.522 5.817V4.89l1.856 1.856.92-.92-2.966-2.965-.46-.46-.46.46-2.965 2.966.919.92 1.856-1.857v12.107L11 17H6a6 6 0 01-6-6V6A6.003 6.003 0 014.522.183v12.119l-1.856-1.856-.92.919 2.966 2.966.46.46.46-.46 2.965-2.966-.919-.92-1.856 1.857V.002z" _fill="#fff"/>'
  }
})
