/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'sell_simple': {
    width: 6,
    height: 5,
    viewBox: '0 0 6 5',
    data: '<path pid="0" d="M5.638 1.241L3.58 4.223c-.319.463-.84.463-1.16 0L.364 1.241C.043.78.243.401.804.401h4.393c.562 0 .76.379.44.84z" _fill="#DE5E56"/>'
  }
})
