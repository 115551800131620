<template>
  <div :class="{ opened: isDialogOpened }" class="container theme-block-bg">
    <div class="block gap theme-text-main"></div>
    <div class="block gplay theme-text-main">
      <div class="gplay__icon" @click="handleOpenDialog"></div>
    </div>
    <div class="block close theme-text-main">
      <div class="close__icon" @click="handleCloseDialog"></div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  name: 'UseNativeApp',
  setup() {
    const deferredPrompt = ref(null);
    const isDialogOpened = ref(false);

    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();

      deferredPrompt.value = e;
      isDialogOpened.value = true;
    });

    const handleCloseDialog = () => {
      isDialogOpened.value = false;
    };

    const handleOpenDialog = () => {
      isDialogOpened.value = false;

      if (!deferredPrompt.value) {
        return;
      }

      deferredPrompt.value.prompt();
      deferredPrompt.value.userChoice.then(() => {
        deferredPrompt.value = null;
      });
    };

    return {
      handleOpenDialog,
      handleCloseDialog,
      isDialogOpened,
    };
  },
};
</script>

<style lang="sass" scoped>
.container
  display: flex
  justify-content: center
  align-items: center
  width: 100%
  height: 0
  transition: height .3s
  overflow: hidden

  span
    font: 15px "Gotham_Pro_Regular"
    padding-top: 3px

.container.opened
  height: 50px

.block
  height: 100%
  display: flex
  justify-content: center
  align-items: center

.gap
  flex-basis: 45px

.gplay
  flex: 1

  &__icon
    width: 143px
    height: 33px

.close
  flex-basis: 45px

  &__icon
    width: 15px
    height: 15px

body.light
  .gplay__icon
    background: url('~@/assets/svg/gplay-light.svg')
  .close__icon
    background: url('~@/assets/svg/close-light.svg')
  .block
    background: #ECECED


body.twilight
  .gplay__icon
    background: url('~@/assets/svg/gplay-twilight.svg')
  .close__icon
    background: url('~@/assets/svg/close-twilight.svg')
  .block
    background: #5A67A7

body.dark
  .gplay__icon
    background: url('~@/assets/svg/gplay-dark.svg')
  .close__icon
    background: url('~@/assets/svg/close-dark.svg')
  .block
    background: #222939
</style>
