<template>
  <div class="positions-card-tabs">
    <div
      v-for="(tab, ndx) in tabsItems"
      :key="ndx"
      :class="{
        border: true,
        'box-active': ndx === activeIndex,
        borderLeft: ndx === 1,
        borderRight: ndx === 1,
      }"
      class="positions-card-tabs__item text-secondary box-hover"
      @click="selectTab(ndx)"
    >
      <span v-if="tab.title">{{ tab.title }}</span>
    </div>
  </div>
</template>
<script>
import { useI18n } from 'vue-i18n';

export default {
  name: 'PositionsCardTabs',
  props: {
    activeIndex: {
      required: true,
    },
    isProposed: {
      required: true,
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();

    const tabsItems = [
      { title: t('order.tabs.details') },
      { title: t('order.tabs.risk') },
      {
        title: props.isProposed ? t('order.tabs.price') : t('order.tabs.split'),
      },
    ];

    const selectTab = (ndx) => {
      emit('changeTab', ndx);
    };
    return { tabsItems, selectTab };
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/colors';
@import '@/assets/styles/base';

.positions-card-tabs {
  font-family: Gotham_Pro_Bold;
  display: flex;
  flex-wrap: wrap;
  border-top-width: 1px;
  border-bottom-width: 1px;
  z-index: 10;
  width: 100%;

  &__item {
    display: flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    font-size: 0.875rem;
    line-height: 0.8rem;
    text-transform: uppercase;
    height: 40px;
    width: 33.3%;
    color: $color-gray;
    transition: all 0.3s ease-in-out;

    span {
      color: #888;
    }

    &:hover {
      cursor: pointer;
    }

    &.borderLeft {
      border-left-width: 1px;
    }

    &.borderBottom {
      border-bottom-width: 1px;
    }

    &.borderRight {
      border-right-width: 1px;
    }
  }

  &__item {
    &.box-active {
      span {
        color: inherit;
      }
    }
  }
}
</style>
