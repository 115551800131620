<template>
  <Modal stop-mobile-max-height to-top @close="close">
    <Card
      :title="$t('header.menuItems.information').toUpperCase()"
      class="card"
    >
      <div class="recovery-modal">
        <div class="nomargin">
          {{ $t('popups.kycNotAllowed.text') }}
        </div>
      </div>
    </Card>
    <div class="btns">
      <Button
        :text="$t('popups.kycNotAllowed.button')"
        type="secondary"
        @click="openLink"
      />
    </div>
  </Modal>
</template>

<script>
import Modal from '@/modules/modals/Modal';
import Card from '@/components/ui/Card';
import Button from '@/components/ui/Button';
import { nativeSdk } from '@/helpers/nativeSdk';

const EVENTS = {
  close: 'close',
};

export default {
  components: { Modal, Card, Button },
  emits: [EVENTS.close],
  setup(_, { emit }) {
    const close = async () => emit(EVENTS.close);
    const openLink = async () => {
      const bridge = nativeSdk.getAndroidBridge();

      bridge.openInBrowser('https://dxs.app');
    };

    return { close, openLink };
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/colors';
@import '@/assets/styles/base';

.recovery-modal {
  height: auto;
  max-height: calc(100% - 102px);
  width: calc(100% + 40px);
  margin-left: -20px;
  overflow-y: auto;
  margin-bottom: 45px;
  padding: 0 20px;
  box-sizing: border-box;
  font-family: Gotham_Pro_Regular;
  line-height: 20px;

  @media screen and (min-width: 1024px) {
    max-height: calc(100vh - 200px) !important;
  }

  div {
    margin-top: 25px;

    &.nomargin {
      margin: 0;
      margin-bottom: 15px;
    }

    h4 {
      font-family: Gotham_Pro_Bold !important;
    }
  }
}

.btns {
  width: 100%;
  margin-top: 30px;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 1024px) {
    bottom: 50px;
  }

  a {
    text-decoration: none;
  }
}
</style>
