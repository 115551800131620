<template>
  <div class="review-item">
    <div class="item-author">
      {{ author }}
    </div>
    <div class="item-content">
      <slot />
    </div>
  </div>
</template>
<script>
export default {
  name: 'ReviewItem',
  props: {
    author: {
      type: String,
      default: '',
    },
  },
};
</script>
<style lang="scss" scoped>
.review-item {
  font-size: 15px;
  padding-bottom: 2rem;

  .item-author {
    padding-bottom: 0.5rem;
    color: gray;
  }
}
</style>
