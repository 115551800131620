import { ref } from 'vue';

export default function useChangeFilter() {
  // TODO: rename this to more universal names
  const activeIndex = ref(0);

  const onChangeTabIndex = (index) => {
    activeIndex.value = index;
  };

  onChangeTabIndex(activeIndex.value);

  return { activeIndex, onChangeTabIndex };
}
