<template>
  <div
    class="hint"
    :class="{
      'not-padding': notPadding,
    }"
  >
    <div
      :style="{
        top: hintOptions.top ? hintOptions.top : '-35px',
        left: hintOptions.left ? hintOptions.left : '20px',
        width: width ? width : '185px',
      }"
      class="hint__body"
    >
      {{ title }}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    hintOptions: {
      type: Object,
      default() {
        return { top: '', left: '' };
      },
    },
    width: {
      type: String,
      default: '',
    },
    notPadding: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss">
@import '@/assets/styles/colors';
@import '@/assets/styles/base';

.hint {
  position: absolute;
  z-index: 20;

  &__body {
    position: absolute;
    top: -35px;
    z-index: 100;
    font-size: 12px;
    left: 20px;
    padding: 8px 5px;
    text-align: left;
    word-break: break-word;
    font-family: Gotham_Pro_Regular;
    border-radius: 2px;
    color: $color-white;
    background: #333232;
    overflow: auto;
  }
}
</style>
