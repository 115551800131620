export default class Connector {
  constructor() {}

  connectLabelUserId() {
    if (!this.serverInfo) {
      return '';
    }
    return `${this.serverInfo.providerId.toUpperCase()}@${this.serverInfo.provider.toUpperCase()}`;
  }

  static staticMethod() {
    return 'Static';
  }
}
