<template>
  <div class="info-block-vert-panel">
    <div class="col-wrap">
      <div class="info-block-vert-panel__title">{{ title }}</div>
      <div :class="{ isGreen, isRed }" class="info-block-vert-panel__value">
        {{ value }}
      </div>
    </div>
    <div
      v-if="description"
      class="info-block-vert-panel__description"
      v-html="description"
    />
  </div>
</template>
<script>
export default {
  name: 'InfoBlockVertPanel',
  props: {
    title: {
      required: true,
    },
    value: {
      required: true,
    },
    description: {
      type: String,
      default: '',
    },
    isGreen: {
      type: Boolean,
      default: false,
    },
    isRed: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss" scoped>
.info-block-vert-panel {
  display: flex;
  justify-content: space-between;
  // flex-direction: column;
  align-items: center;
  min-height: 69px;
  background: rgb(220, 218, 218);
  padding: 0 20px;
  box-sizing: border-box;
  width: 100%;

  .col-wrap {
    display: flex;
    flex-direction: column;
  }

  &__description {
    font-size: 11px;
    line-height: 14px;
    color: #444;
  }

  &__title {
    // font-size: 11px;
    font-family: Gotham_Pro_Regular;
    line-height: 14px;
    font-size: 0.9375rem;
    color: #888;
  }

  &__value {
    margin-top: 3px;
    font-size: 30px;
    font-family: Cantarell_Bold;
    line-height: 30px;
    text-transform: uppercase;

    &.isGreen {
      color: #509f96;
    }

    &.isRed {
      color: #de5e56;
    }
  }
}

body.dark {
  .info-block-vert-panel {
    background: #262c3c;

    &__description {
      color: #fff;
    }
  }
}
</style>
