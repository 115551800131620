import { useStore } from 'vuex';
import { computed } from 'vue';
import { demoPositions, demoFavourites } from '@/config/demoPositions';

export default function useDemopositions() {
  const store = useStore();

  const activeConnect = computed(
    () => store.getters['connectors/activeConnect']
  );

  const positions = computed(() => store.getters['positions/getPositions']);

  const initialDemoPositions = () => {
    if (!activeConnect.value.provider && !positions.value.length) {
      store.commit('settings/SET_UISETTING', {
        k: 'marketFavourites',
        v: demoFavourites,
      });
      store.dispatch(
        'positions/setAllPositions',
        demoPositions.map((positionInfo) => {
          return {
            ...positionInfo[1],
            market: positionInfo[0],
          };
        })
      );
    }
  };

  return { initialDemoPositions };
}
