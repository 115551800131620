<template>
  <div class="position-update">
    <div class="position-update__controls">
      <SimpleInput
        :value="priceInput"
        :title="$t('order.card.titles.price')"
        type="text"
        :min-value="0"
        :error="priceInputError"
        directive="number"
        @onChange="onChange"
        class="position-update__input entry-price-input"
      />
    </div>
    <div class="position-update__description">
      {{ $t('order.card.hints.entryPrice') }}
    </div>
  </div>
</template>
<script>
import SimpleInput from '@/components/ui/SimpleInput';
import { ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';

export default {
  name: 'PositionUpdateProposed',
  components: { SimpleInput },
  props: {
    position: {
      type: Object,
      required: true,
    },
  },
  emits: ['changePosition', 'error'],
  setup(props, { emit }) {
    const priceInput = ref('');
    const { t } = useI18n();

    if (props.position.entry_price) {
      // eslint-disable-next-line vue/no-setup-props-destructure
      priceInput.value = props.position.entry_price;
    }

    const onChange = (value) => {
      priceInput.value = value;

      emit('error', priceInputError.value);

      !priceInputError.value && emit('changePosition', priceInput.value);
    };

    const priceInputError = computed(() => {
      if (!priceInput.value) {
        return t('order.new.toasts.amountIsRequired');
      }

      if (priceInput.value === '0') {
        return t('order.new.mustBe', {
          value: `> 0`,
        });
      }

      return false;
    });

    return {
      priceInput,
      onChange,
      priceInputError,
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/colors';
@import '@/assets/styles/base';

.position-update {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  min-height: 180px;

  &__input {
    width: 100%;
  }

  &__description {
    margin-top: 30px;
    font-family: Gotham_Pro_Regular;
  }
}
</style>
