/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'fiorin': {
    width: 72,
    height: 83,
    viewBox: '0 0 72 83',
    data: '<path pid="0" d="M43.396 21.886a14.1 14.1 0 0118.79-10.495l1.66.62a3.523 3.523 0 004.626-2.029 3.525 3.525 0 00-2.15-4.57l-1.657-.624C52.42.198 38.985 7.7 36.475 20.532l-3.031 15.5H15.729a3.525 3.525 0 000 7.051h16.333l-3.455 17.673a14.102 14.102 0 01-18.79 10.495l-1.664-.62a3.524 3.524 0 10-2.475 6.599l1.66.624c12.244 4.59 25.676-2.912 28.186-15.744l3.723-19.027H56.27a3.525 3.525 0 100-7.05H40.629l2.767-14.148z" _fill="#000"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M61.133 14.2a11.102 11.102 0 00-14.793 8.262l-2.068 10.57h11.999a6.525 6.525 0 110 13.051H41.717l-3.249 16.603C35.602 77.338 20.265 85.904 6.286 80.663h-.002l-1.628-.613a6.524 6.524 0 114.58-12.218l1.635.61a11.102 11.102 0 0014.792-8.262l2.756-14.097h-12.69a6.525 6.525 0 010-13.05h15.245l2.558-13.077C36.398 5.303 51.739-3.262 65.718 1.979l.004.001 1.624.612a6.525 6.525 0 11-4.583 12.217l-1.63-.61zm2.714-2.189a3.523 3.523 0 004.625-2.029 3.525 3.525 0 00-2.15-4.57l-1.657-.624C52.42.198 38.985 7.7 36.475 20.532l-3.031 15.5H15.729a3.525 3.525 0 000 7.051h16.333l-3.455 17.673a14.102 14.102 0 01-18.79 10.495l-1.664-.62a3.524 3.524 0 10-2.475 6.599l1.66.624c12.244 4.59 25.676-2.912 28.186-15.744l3.723-19.027H56.27a3.525 3.525 0 100-7.05H40.629l2.767-14.148a14.1 14.1 0 0118.79-10.494l1.66.62z" _fill="#000"/>'
  }
})
