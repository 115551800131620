<template>
  <div
    :class="{
      'input--has-icon': icon,
      'input--has-error': error,
      'input--disabled': disabled,
      'input--centered': centered,
      'input--value-mode': valueMode,
      'new-version': newVersion,
    }"
    class="input"
  >
    <input
      ref="input"
      :value="value"
      :placeholder="usedPlaceholder"
      :type="inputType"
      :disabled="disabled"
      autocorrect="off"
      autocomplete="off"
      :inputmode="inputMode"
      spellcheck="false"
      :maxlength="maxChars"
      :min="minValue"
      :max="maxValue"
      :step="step"
      :readonly="readonly"
      :class="{ hasContent: !!value, sm: size }"
      class="input__input"
      @input="handleInput"
      @focus="handleFocus"
      @focusout="handleFocusOut"
      @blur="handleBlur"
      @paste="handlePaste"
      @keydown="handleKeyDown"
      @keyup.enter="$refs.input.blur"
    />

    <!-- floating title -->
    <span
      v-if="!valueMode && !tipText && !error"
      :class="{ 'input_has-content': !!value || value == '0', isWindows }"
      class="input__title"
    >
      {{ titleText }}
    </span>

    <Icon
      v-if="icon"
      :name="icon"
      color="white"
      class="input__icon"
      width="24"
      height="24"
      @click="handleIconClick"
    />

    <div
      v-if="newVersion && optionalBtn && !hideSecondary"
      :class="{ hasSecondary: optionalBtnSecondary, optionalRightPos }"
      class="optional-btn"
      @click="$emit('optionalBack')"
    >
      {{ optionalBtn }}
    </div>
    <div
      v-if="newVersion && optionalBtnSecondary"
      :class="{ optionalRightPos }"
      class="optional-btn secondary"
      @click="$emit('optionalSecondary')"
    >
      {{ optionalBtnSecondary }}
    </div>
    <!-- clear (cross) icon -->
    <Icon
      v-if="
        (value || value == '0') &&
        !icon &&
        !valueMode &&
        !disabled &&
        !newVersion
      "
      name="cross"
      class="delete__icon"
      width="12"
      height="12"
      @click="handleClear"
    />

    <!-- tip message -->
    <div
      v-if="tipText && !error"
      :class="{ 'input__tip--clickable': noteClickable }"
      class="input__tip"
      v-html="tipText"
      @click="noteClickable && $emit('note-clicked')"
    />

    <!-- error message -->
    <div v-if="error" class="input__error">
      <div>{{ error }}</div>
    </div>
    <template v-if="withArrows">
      <div class="arrow-left" @click="$emit('onArrowLeft')">
        <Icon name="newarrow" width="8" height="13" />
      </div>
      <div class="arrow-right" @click="$emit('onArrowRight')">
        <Icon name="newarrow" width="8" height="13" />
      </div>
    </template>
  </div>
</template>

<script>
import Icon from '@/components/ui/Icon';
import '@/assets/icons/cross';
import '@/assets/icons/newarrow';
import { DIRECTIVE_TYPES, PLUS_BTN } from '@/helpers/enums';
import { ref } from 'vue';
import { scope } from '@/breakpoints';
import { useStore } from 'vuex';
// import { useUAParser } from '@/compositions/useUAParser';

export default {
  components: { Icon },
  props: {
    type: {
      type: String,
      default: 'text',
    },
    value: {
      type: [String, Number],
      default: '',
    },
    placeholder: {
      type: [String, Number],
      required: false,
      default: '',
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    pattern: {
      type: Object,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    maxChars: {
      type: Number,
      default: 99,
    },
    icon: {
      type: String,
      required: false,
      default: '',
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    tip: {
      type: String,
      default: '',
    },
    password: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: '',
    },
    valueMode: {
      type: Boolean,
      default: false,
    },
    centered: {
      type: Boolean,
      default: false,
    },
    noteClickable: {
      type: Boolean,
      default: false,
    },
    directive: {
      type: String,
      default: '',
    },
    minValue: {
      type: Number,
      default: undefined,
    },
    maxValue: {
      type: Number,
      default: undefined,
    },
    step: {
      type: String,
      default: '1',
    },
    updateValue: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: '',
    },
    stopScrolling: {
      type: Boolean,
      default: false,
    },
    optionalBtn: {
      type: String,
      default: '',
    },
    optionalBtnSecondary: {
      type: String,
      default: '',
    },
    withArrows: {
      type: Boolean,
      default: false,
    },
    newVersion: {
      type: Boolean,
      default: false,
    },
    hideSecondary: {
      type: Boolean,
      default: false,
    },
    optionalRightPos: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    // const UAParser = useUAParser();
    const store = useStore();
    const topPosition = ref(0);
    const isWindows = ref(navigator.appVersion.indexOf('Win') != -1);

    const setScrollStatus = (action) => {
      const classNode = 'scrolling';
      document.querySelector('.layout-mobile').classList[action](classNode);
      document.querySelector('body').classList[action](classNode);
      document.querySelector('html').classList[action](classNode);
    };

    const handleFocus = () => {
      emit('focus');
      // глоабльно на моб убирать бэк и оступ
      if (
        (scope.isSmall || scope.isMedium || scope.noMatch) &&
        props.stopScrolling
      ) {
        const positionInfo = document
          .querySelector('.positions-list__title')
          .getBoundingClientRect();
        topPosition.value = positionInfo?.top;

        if (topPosition.value <= 0 && topPosition.value > -21) {
          setScrollStatus('add');
        }
      }
      setTimeout(() => {
        store.dispatch('localUiSettings/setShowBackControls', false);
      }, 10);
    };

    const handleFocusOut = () => {
      emit('focusOut');
      // глоабльно на моб возвращать бэк и оступ
      if (
        (scope.isSmall || scope.isMedium || scope.noMatch) &&
        props.stopScrolling
      ) {
        if (topPosition.value <= 0 && topPosition.value > -21) {
          setScrollStatus('remove');
        }
      }
      setTimeout(() => {
        store.dispatch('localUiSettings/setShowBackControls', true);
      }, 10);
    };

    return { handleFocus, handleFocusOut, isWindows };
  },
  watch: {
    updateValue(updateValue) {
      if (updateValue || updateValue === '') {
        if (updateValue === ' ') {
          this.$emit('onChange', '');
          this.$emit('onUpdateValue');
        } else {
          this.$emit('onChange', updateValue);
          this.$emit('onUpdateValue');
        }
      }
    },
  },
  computed: {
    inputType() {
      if (this.type === 'password') {
        return this.type;
      }
      if ((this.type === 'number' && this.password) || this.type === 'tel') {
        return 'tel';
      }
      if (this.type === 'number') {
        return this.type;
      }
      return 'text';
    },
    inputMode() {
      if (this.directive === DIRECTIVE_TYPES.number) {
        return 'decimal';
      } else if (this.directive === DIRECTIVE_TYPES.tel) {
        return 'tel';
      }

      return 'text';
    },
    usedPlaceholder() {
      if (this.valueMode) {
        return this.placeholder;
      }
      return this.title ? '' : this.placeholder;
    },
    isNumber() {
      return this.type === 'number';
    },
    titleText() {
      return this.title;
    },
    tipText() {
      return this.tip;
    },
  },
  mounted() {
    if (this.autofocus) {
      this.$nextTick(() => {
        this.$refs.input.focus();
      });
    }
  },
  beforeUnmount() {
    this.$refs.input && this.$refs.input.blur();
  },
  methods: {
    handleInput(e) {
      if (this.pattern && !this.pattern.exec(e.target.value)) {
        e.target.value = e.target.value.slice(0, -1);

        return;
      }

      let value = e.target.value;

      // { target: { value } }
      if (this.maxChars && value.length > this.maxChars) {
        value = value.substr(0, this.maxChars);
      }
      if (this.directive === DIRECTIVE_TYPES.number) {
        value = value.replace(/\s+/g, '').replace(/[БбЮю]/, '.');
        if (value.toString().indexOf(',') > -1) {
          value = value.replace(',', '.');
        }
        value = value.replace(' ', '');
        if (
          isNaN(+value) &&
          !(
            value === '-' &&
            (this.minValue === undefined ||
              this.minValue === null ||
              this.minValue < 0)
          )
        ) {
          this.$emit('invalid-input');
          value = '';
          e.target.value = '';
        } else {
          this.$emit('valid-input');
        }
      }
      // const newValue = this.isNumber ? (parseFloat(value) || null) : value
      if (value < this.minValue) {
        value = this.minValue;
      } else if (value > this.maxValue) {
        setTimeout(() => {
          value = this.maxValue + '';
          this.$refs.input.value = value;
          this.$emit('onChange', value);
          return;
        }, 300);
      }
      this.$emit('onChange', value);
    },
    // prevent pasting non-numbers if this is a number input
    handlePaste(event) {
      if (!this.isNumber) {
        return;
      }
      event.preventDefault();
      const text = event.clipboardData.getData('text');
      const number = this.value + (parseFloat(text) || null);
      this.$emit('onChange', number);
    },
    handleKeyDown(event) {
      if (this.directive === DIRECTIVE_TYPES.email) {
        if (PLUS_BTN.includes(event.keyCode)) {
          event.preventDefault();
        }
      }
    },
    handleBlur() {
      if (!this.$refs.input) {
        return;
      }
      if (
        this.directive === 'number' &&
        this.minValue !== undefined &&
        this.minValue !== null &&
        this.$refs.input.value < this.minValue
      ) {
        this.$emit('onChange', this.minValue);
      }
      if (
        this.directive === 'number' &&
        this.maxValue !== undefined &&
        this.maxValue !== null &&
        this.$refs.input.value > this.maxValue
      ) {
        this.$emit('onChange', this.maxValue);
        this.$refs.input.value = this.maxValue;
        return;
      }
      this.$emit('blur', this.$refs.input.value);
    },
    handleIconClick() {
      this.$emit('icon-click');
    },
    handleClear() {
      this.$emit('onChange', this.isNumber ? null : '');
      this.$refs.input.focus();
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/styles/colors';

.input__tip--clickable {
  cursor: pointer;
}
.input {
  font-family: Gotham_Pro_Regular;
  position: relative;
  // @apply pt-3 pb-4;

  .arrow-left {
    position: absolute;
    box-sizing: border-box;
    top: 5px;
    right: 54px;
    padding: 10px;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .arrow-right {
    position: absolute;
    box-sizing: border-box;
    padding: 10px;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 5px;
    right: -5px;
    cursor: pointer;

    svg {
      transform: rotate(180deg);
    }
  }

  &--centered {
    .input__input {
      text-align: center;
      // @apply pr-0;
    }
    .input__error {
      width: 100%;
      text-align: center;
    }
    .input__tip {
      width: 100%;
      text-align: center;

      &--clickable {
        cursor: pointer;
      }
    }
    .input__title {
      width: 100%;
      text-align: center;
    }
  }
}

.input__icon {
  position: absolute;
  top: 16px;
  right: 0;
  cursor: pointer;
}

.delete__icon {
  // @apply text-grey-darker;
  position: absolute;
  top: 5px;
  right: 0;
  cursor: pointer;
}

.optional-btn {
  text-transform: uppercase;
  font-size: 15px;
  font-family: 'Gotham_Pro_Bold';
  position: absolute;
  cursor: pointer;
  right: 10px;
  top: 6px;

  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.hasSecondary {
    right: 59px;
    bottom: 4px;
  }

  &.secondary {
    right: 6px;
    bottom: 4px;
  }

  &.optionalRightPos {
    right: 15px;
  }
}

.input__tip {
  font-size: 14px;
  // @apply text-text-primary;
}
.input--value-mode {
  padding: 0 !important;

  .input__input {
    padding-right: 0;
    outline: none;
    border-bottom: none;
    // @apply text-xl;

    &:disabled {
      opacity: 1;
    }
  }
}

.input {
  position: relative;
  // overflow: -moz-hidden-unscrollable;
  // @apply pt-3 pb-4;
}

.input__icon {
  position: absolute;
  top: 16px;
  right: 0;
  cursor: pointer;
}

.delete__icon {
  // @apply text-grey-darker;
  position: absolute;
  top: 5px;
  right: 0;
  cursor: pointer;
}

.input__input {
  font-family: Gotham_Pro_Regular;
  font-size: 1.5rem;
  width: 100%;
  border-radius: 0;
  background-color: transparent;
  border: none;
  color: $color-black;
  border-bottom: 1px solid rgb(250, 250, 250);
  box-shadow: none !important;
  text-overflow: ellipsis;
  transition: border-color ease-in-out 0.15s;
  padding: 1px 0 0;
  height: 24px;

  &.sm {
    font-size: 15px;
    padding-right: 20px;
  }

  &.hasContent {
    padding-bottom: 2px;
  }

  &:disabled {
    opacity: 0.3;
    user-select: none;
    pointer-events: none;
  }
  &:focus {
    outline: 0 !important;
  }
  .title {
    color: #a0a6ad;
    letter-spacing: 0.3px;
  }
}

.input--has-error .input__input {
  @apply border-text-error;
}
.input__title {
  font-size: 14px;
  position: absolute;
  top: 5px;
  left: 0;
  transition: all 0.3s;
  pointer-events: none;
  text-transform: uppercase;
  user-select: none;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.input__title.input_has-content {
  font-family: Gotham_Pro_Bold;
}

.has-value .input__title,
.input__title.input_has-content,
.input__input:focus + .input__title {
  font-size: 0.65rem;
  // line-height: 10px;
  top: -14px;
  opacity: 0.8;
}

.input__error,
.input__tip {
  font-size: 0.75rem;
  margin-top: 9px;
  position: absolute;
  top: 100%;
  left: 0;
}

.input__tip {
}

.light {
  input.input__input {
    border-bottom: 1px solid rgba(206, 210, 214, 1);

    &:focus {
      border-bottom: 1px solid #000;
    }
  }
}

.new-version {
  .input__error,
  .input__tip {
    top: 1px;
    left: 15px;
    font-size: 0.65rem;
  }

  .input__tip {
    top: -3px;
  }

  .input__error {
    text-transform: uppercase;
  }

  .input__title.input_has-content {
    font-family: Gotham_Pro_Regular;
  }
  .input__input {
    border: 1px solid rgba(250, 250, 250, 0.25);
    border-radius: 6px;
    height: 60px;
    padding: 0 15px;
    padding-top: 20px;

    &:focus {
      border: 1px solid #fff;
    }

    &.hasContent {
      padding-top: 16px;
      border: 1px solid rgba(250, 250, 250, 0.25);

      &:focus {
        border: 1px solid #fff;
      }
    }
  }

  .has-value .input__title,
  .input__title.input_has-content,
  .input__input:focus + .input__title {
    font-size: 0.65rem;
    top: 10px;
    // line-height: normal;
  }

  .input__title {
    font-size: 15px;
    top: 24px;
    left: 15px;

    &.isWindows {
      top: 21px;

      &.input_has-content {
        top: 10px;
      }
    }
  }
}

.new-version {
  .input__input {
    font-family: 'Cantarell_Regular';
  }
}

.light {
  .new-version {
    input.input__input {
      border: 1px solid rgba(206, 210, 214, 1);

      &:focus {
        border: 1px solid #000;
      }
    }
  }
}
</style>
