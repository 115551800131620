import axios from 'axios';
import config from '@/config';

const state = {
  clientId: '',
  userInfo: {},
};

const getters = {
  clientId: (state) => state.clientId,
  userInfo: (state) => state.userInfo,
};

const types = {
  SET_CLIENT_ID: 'SET_CLIENT_ID',
  SET_USER_INFO: 'SET_USER_INFO',
};

const mutations = {
  [types.SET_CLIENT_ID](state, value) {
    state.clientId = value;
  },
  [types.SET_USER_INFO](state, data) {
    state.userInfo = data;
  },
};

const actions = {
  async getClientId({ commit }) {
    try {
      let result = await axios.get(`${config.apiURL}wallets/dotwallet/config`);
      commit(types.SET_CLIENT_ID, result.data.clientId);
    } catch (e) {
      console.error('Error in DW getClientId', e);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
