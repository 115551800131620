import { reactive } from 'vue-demi';
import { useBreakpoints } from '@vueuse/core';

const breakpoints = useBreakpoints({
  smx: 481,
  sm: 576,
  md: 768,
  lg: 1024,
  xl: 1240,
});
const isExtraSmall = breakpoints.smaller('smx');
const noMatch = breakpoints.smaller('sm');
const isSmall = breakpoints.between('sm', 'md');
const isMedium = breakpoints.between('md', 'lg');
const isLarge = breakpoints.between('lg', 'xl');
const isXlarge = breakpoints.greater('xl');
export const scope = reactive({
  isExtraSmall,
  noMatch,
  isSmall,
  isMedium,
  isLarge,
  isXlarge,
});
