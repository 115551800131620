/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'high': {
    width: 32.122,
    height: 22.149,
    viewBox: '0 0 32.122 22.149',
    data: '<path pid="0" _fill="#79C610" d="M23.351 0l-3.725 3.725 6.187-.002-8.936 8.935-5.793-5.792L0 22.149l11.084-10.11 5.793 5.793L28.399 6.31l-.001 6.187 3.724-3.725V0z"/>'
  }
})
