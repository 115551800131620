const state = {
  tokenData: {},
  balancePostfix: '',
  isApp: false,
};

const getters = {
  tokenData: (state) => state.tokenData,
  balancePostfix: (state) => state.balancePostfix,
  isApp: (state) => state.isApp,
};

const types = {
  SET_TOKEN_DATA: 'SET_TOKEN_DATA',
  SET_BALANCE_POSTFIX: 'SET_BALANCE_POSTFIX',
  SET_IS_APP: 'SET_IS_APP',
};
export const relayxMutations = types;

const mutations = {
  [types.SET_TOKEN_DATA](state, value) {
    state.tokenData = value;
  },
  [types.SET_BALANCE_POSTFIX](state, value) {
    state.balancePostfix = value;
  },
  [types.SET_IS_APP](state, value) {
    state.isApp = value;
  },
};

const actions = {};

export const cachedPaths = ['tokenData', 'isApp'];
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
