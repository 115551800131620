const types = {
  SET_ACTIVE_CONNECT: 'SET_ACTIVE_CONNECT',
  SET_USER_NAME: 'SET_USER_NAME',
  ADD_NEW_CONNECT: 'ADD_NEW_CONNECT',
  UPDATE_CONNECTS: 'UPDATE_CONNECTS',
  UPDATE_LOADING_STATUS: 'UPDATE_LOADING_STATUS',
  UPDATE_CONNECTION_STATUS: 'UPDATE_CONNECTION_STATUS',
  SET_AUTH_LOADER: 'SET_AUTH_LOADER',
  SET_RECONNECT_FLAG: 'SET_RECONNECT_FLAG',
};

let serverInfo;

const getInitialState = () => ({
  activeConnect: {},
  connects: [],
  walletLoading: true,
  connectionStatus: true,
  userName: '',
  authLoader: false,
  isReconnect: false,
});

const getters = {
  isReconnect: (state) => state.isReconnect,
  authLoader: (state) => state.authLoader,
  activeConnect: (state) => state.activeConnect,
  connects: (state) => state.connects,
  connectStatus: (state) => state.connectionStatus,
  getWalletLoadingStatus: (state) => state.walletLoading,
  userName: (state) => state.userName,
};

const mutations = {
  [types.SET_RECONNECT_FLAG](state, value) {
    state.isReconnect = value;
  },
  [types.SET_AUTH_LOADER](state, value) {
    state.authLoader = value;
  },
  [types.SET_ACTIVE_CONNECT](state, value) {
    if (value.serverInfo) {
      serverInfo = value.serverInfo;
    }
    value.serverInfo =
      serverInfo || value.serverInfo || state.activeConnect.serverInfo; // preserve serverInfo if set
    state.activeConnect = value;
  },
  [types.SET_USER_NAME](state, value) {
    state.userName = value;
  },
  [types.ADD_NEW_CONNECT](state, value) {
    state.connects.push(value);
  },
  [types.UPDATE_CONNECTS](state, value) {
    state.connects = value;
  },
  [types.UPDATE_LOADING_STATUS](state, value) {
    state.walletLoading = value;
  },
  [types.UPDATE_CONNECTION_STATUS](state, value) {
    state.connectionStatus = value;
  },
};

const actions = {
  setReconnectFlag({ commit }, value) {
    commit(types.SET_RECONNECT_FLAG, value);
  },
  setAuthLoader({ commit }, value) {
    commit(types.SET_AUTH_LOADER, value);
  },
  disconnect({ commit, dispatch }) {
    commit(types.SET_ACTIVE_CONNECT, {});
    dispatch('user/setTruedexAuth', null, { root: true });
  },
  setUserName({ commit }, name) {
    commit(types.SET_USER_NAME, name);
  },
  async setActiveConnect({ state, commit }, wallet) {
    if (wallet) {
      commit(
        types.UPDATE_CONNECTS,
        state.connects.filter(
          (conn) => conn.provider && conn.provider !== wallet.provider
        )
      );
      (await wallet.fetchBalance) && wallet.fetchBalance();
      (await wallet.fetchProfile) && wallet.fetchProfile();
    }
    commit(types.SET_ACTIVE_CONNECT, wallet);
    commit(types.UPDATE_LOADING_STATUS, false);

    const findAccount = state.connects.find(
      (acc) => acc.provider === wallet.provider
    );

    if (!findAccount) {
      commit(types.ADD_NEW_CONNECT, wallet);
    }
  },
  setActiveConnectInfo({ getters, dispatch }, info) {
    const activeConnect = getters.activeConnect;
    activeConnect.serverInfo = info;
    dispatch('setActiveConnect', activeConnect);
  },
  addNewConnect({ commit }, value) {
    commit(types.ADD_NEW_CONNECT, value);
  },
};

export default {
  namespaced: true,
  state: getInitialState(),
  mutations,
  actions,
  getters,
};
