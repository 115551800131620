<template>
  <Modal :stop-mobile-max-height="true" @close="$emit(EVENTS.close)">
    <Card
      :title="$t('popups.handCashNotify.title').toUpperCase()"
      class="card"
      :body-class="'card-full'"
    >
      <div class="handcash-notify">
        <p v-html="$t('popups.handCashNotify.text')"></p>
      </div>
    </Card>
    <div class="btns">
      <a
        href="https://faq.dxs.app/getting-started/wallet-and-dxs-troubleshooting#why-doesnt-my-wallet-balance-match-dxs"
        rel="noopener noreferrer"
        target="_blank"
        class="handcash-notify-btn"
      >
        <Button
          :text="$t('popups.buttons.learnMore')"
          type="secondary"
          class="btn"
        />
      </a>
      <Button
        :text="$t('popups.buttons.continue')"
        type="secondary"
        class="handcash-notify-btn"
        @click="$emit('close')"
      />
    </div>
  </Modal>
</template>
<script>
import Modal from '@/modules/modals/Modal';
import Card from '@/components/ui/Card';
import Button from '@/components/ui/Button';

const EVENTS = {
  close: 'close',
};

export default {
  name: 'HandcashNotifyModal',
  components: { Modal, Card, Button },
  emits: [EVENTS.close],
  setup() {
    return {
      EVENTS,
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/colors';
@import '@/assets/styles/base';

.handcash-notify {
  height: auto;
  overflow-y: auto;
  margin-bottom: 45px;
  padding-right: $px20;
  padding-left: $px20;
  padding-bottom: 10px;
  box-sizing: border-box;
  font-family: Gotham_Pro_Regular;
  line-height: 20px;

  p {
    &.underline {
      text-decoration-line: underline;
    }
  }

  div {
    margin-top: 25px;

    h4 {
      font-family: Gotham_Pro_Bold !important;
    }
  }

  span {
    text-decoration-line: underline;
    cursor: pointer;
  }
}

.btns {
  width: 100%;
  margin-top: 30px;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  a {
    text-decoration: none;
  }
}

.handcash-notify-btn {
  flex: 1 1 50%;
  .btn {
    width: 100%;
  }
}
</style>
