import * as SignalR from '@microsoft/signalr';
import EventEmitter from 'events';
import config from '@/config';
import store from '../store';
import { getSleepInfo } from '@/helpers/sleep';
import { v4 as uuidv4 } from 'uuid';

const RECONNECT_TIMEOUT = 3000;

const origin = config.apiUrl.replace('/api/', '/ws/');

const defaultOptions = {
  log: false,
};

// taken from https://github.com/latelierco/vue-signalr/
class SocketConnection extends EventEmitter {
  constructor(connection) {
    super();

    this.connection = connection;
    this.listened = [];
    this.toSend = [];
    this.socket = null;
    this.offline = false;
  }

  dispatchCloseAndReload() {
    window.document.dispatchEvent(
      new CustomEvent('closeLogin', { bubbles: true })
    );
    window.location.reload();
  }

  async _initialize(tokenStr) {
    if (!localStorage.getItem('dxsUUID')) {
      localStorage.setItem('dxsUUID', uuidv4());
    }

    const connectionString =
      this.connection + `?browserId=${localStorage.getItem('dxsUUID')}`;
    const userOptions = {};
    const activeConnect = store.getters['connectors/activeConnect'];
    const provider = activeConnect.provider;
    const accessToken = activeConnect.accessToken;

    console.log('_initialize:', {
      tokenStr,
      provide: activeConnect.provider,
      token: activeConnect.accessToken,
    });

    if (tokenStr && activeConnect.provider === 'HandCash') {
      // when redirect fix
      window.location.reload();
      return;
    }

    if (tokenStr || (activeConnect.provider && activeConnect.accessToken)) {
      userOptions.accessTokenFactory = () =>
        tokenStr || `${activeConnect.provider}:${activeConnect.accessToken}`;
    }

    setTimeout(() => {
      if (!provider || !store.getters['connectors/activeConnect']?.provider) {
        return;
      }

      if (activeConnect.provider === 'Fiorin') {
        if (provider !== store.getters['connectors/activeConnect']?.provider) {
          this.dispatchCloseAndReload();
        }

        return;
      }

      if (
        accessToken !== store.getters['connectors/activeConnect']?.accessToken
      ) {
        this.dispatchCloseAndReload();
      }
    }, RECONNECT_TIMEOUT);

    try {
      const socket = new SignalR.HubConnectionBuilder()
        .withUrl(connectionString, userOptions)
        .build();

      socket.connection.onclose = async () => {
        console.log('Socket connection has closed');
        console.log(`Will try again in ${RECONNECT_TIMEOUT}ms`);

        this.socket = null;

        setTimeout(() => {
          this._initialize(tokenStr);
          this.emit('reconnect');
        }, RECONNECT_TIMEOUT);
      };

      const startSocket = async () => {
        try {
          await socket.start();
        } catch (err) {
          if (err.statusCode === 401) {
            this.unauthorized = true;
            this.emit('unauthorized');
            return;
          }

          return new Promise((res, rej) => {
            setTimeout(() => {
              startSocket().then(res).catch(rej);
            }, RECONNECT_TIMEOUT);
          });
        }
      };

      await startSocket();

      this.socket = socket;
      this.emit('init');
    } catch (error) {
      if (this.options?.log) {
        console.log('Unknown error while socket connection');
        console.log(`Will reconnect in ${RECONNECT_TIMEOUT}ms`);
      }

      setTimeout(async () => {
        if (document[getSleepInfo().hidden]) {
          return;
        }

        this._initialize(tokenStr);
      }, RECONNECT_TIMEOUT);
    }
  }

  async disconnect() {
    this.socket = null;
  }

  async start(options = {}, tokenStr) {
    this.options = Object.assign(defaultOptions, options);

    await this._initialize(tokenStr);
  }

  async authenticate(accessToken, options = {}) {
    this.connection = `${this.connection}?authorization=${accessToken}`;

    /* eslint-disable no-underscore-dangle */
    await this.start(options);
  }

  listen(method) {
    if (this.offline) {
      return;
    }

    if (this.listened.some((v) => v === method)) {
      return;
    }

    this.listened.push(method);

    this.on('init', () => {
      this.socket.on(method, (data) => {
        if (this.options?.log) {
          console.log({ type: 'receive', method, data });
        }
        this.emit(method, data);
      });
    });
  }

  send(methodName, ...args) {
    if (this.options?.log) {
      console.log({ type: 'send', methodName, args });
    }
    if (this.offline) {
      return;
    }

    if (this.socket) {
      this.socket.send(methodName, ...args);
      return;
    }

    this.once('init', () => this.socket.send(methodName, ...args));
  }

  async invoke(methodName, ...args) {
    if (this.unauthorized) {
      throw new Error('Unable to invoke method because unauthorized');
    }

    if (this.options?.log) {
      console.log({ type: 'invoke', methodName, args });
    }

    if (this.offline) {
      return false;
    }

    if (this.socket) {
      return this.socket.invoke(methodName, ...args);
    }

    return new Promise((resolve) =>
      this.once('init', () => resolve(this.socket.invoke(methodName, ...args)))
    );
  }
}

export function getErrorDetails(err) {
  try {
    // parse SignalR hardcoded string
    var msgJson = (
      err.message.match(/HubException: (.*)/) || [err.message]
    ).pop();
    return JSON.parse(msgJson);
  } catch (e) {
    return { id: null, message: err.message };
  }
}

export const connMarkets = new SocketConnection(origin + 'markets');
export const connApp = new SocketConnection(origin + 'app');
