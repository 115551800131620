/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'star': {
    width: 16,
    height: 15.219,
    viewBox: '0 0 16 15.219',
    data: '<path pid="0" d="M8 2.819l1.396 2.829.279.566.625.09 3.121.453-2.258 2.2-.451.44.106.621.533 3.113-2.793-1.47L8 11.369l-.559.293-2.795 1.47.534-3.112.106-.622-.451-.44-2.257-2.2 3.12-.453.625-.091.279-.565L8 2.819M8 .107l-2.473 5.01L0 5.919l3.998 3.897-.945 5.509L8 12.725l4.944 2.601-.943-5.509L16 5.919l-5.528-.802L8 .107z"/>'
  }
})
