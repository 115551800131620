<template>
  <div class="position-update">
    <!-- PROFIT -->
    <div class="position-update__controls">
      <SimpleInput
        :value="profitAmount"
        :key="tpUpdateKey"
        :title="`${$t('order.card.titles.takeProfit')} ${profitPlaceholder}`"
        type="text"
        :min-value="0"
        directive="number"
        stop-scrolling
        @onChange="onTakeProfitSetValue"
        @input="onTakeProfitInput"
        class="position-update__input takeprofit-input"
      />
      <div class="separator" />
      <div
        :class="{ 'box-active': profitIndexMode === 0 }"
        class="position-update__control-btn border box-hover"
        @click="setModeProfit(0)"
      >
        %
      </div>
      <div
        :class="{ 'box-active': profitIndexMode === 1 }"
        class="position-update__control-btn border box-hover"
        @click="setModeProfit(1)"
      >
        {{ position.base_currency === 'USD' ? '$' : 'Ƀ' }}
      </div>
      <div
        :class="{ 'box-active': profitIndexMode === 2 }"
        class="position-update__control-btn border box-hover"
        @click="setModeProfit(2)"
      >
        =
      </div>
    </div>
    <div class="position-update__info">
      <div class="position-update__values">
        <div class="label text-secondary">{{ typePositionProfitLabel }}</div>
        <div class="value text-main">
          {{
            tpValue1 === 'N/A'
              ? tpValue1
              : profitIndexMode !== 2
              ? toCurrencyFormat(tpValue1)
              : toCurrencyFormat(tpValue1)
          }}
        </div>
      </div>
      <div class="position-update__values">
        <div class="label text-secondary">{{ profitAmountLabel }}</div>
        <div class="value text-main">
          {{ toCurrencyFormat(tpValue2) }}
          {{ profitAmountPlaceholder }}
        </div>
      </div>
    </div>
    <!-- -->
    <div class="position-update__controls">
      <SimpleInput
        :value="lossAmount"
        :title="`${$t('order.card.titles.stopLoss')} ${lossPlaceholder}`"
        type="text"
        directive="number"
        stop-scrolling
        @onChange="onStopLossSetValue"
        @input="onStopLossInput"
        class="position-update__input stoploss-input"
      />
      <div class="separator" />
      <div
        :class="{ 'box-active': lossIndexMode === 0 }"
        class="position-update__control-btn border box-hover"
        @click="setModeLoss(0)"
      >
        %
      </div>
      <div
        :class="{ 'box-active': lossIndexMode === 1 }"
        class="position-update__control-btn border box-hover"
        @click="setModeLoss(1)"
      >
        {{ position.base_currency === 'USD' ? '$' : 'Ƀ' }}
      </div>
      <div
        :class="{ 'box-active': lossIndexMode === 2 }"
        class="position-update__control-btn border box-hover"
        @click="setModeLoss(2)"
      >
        =
      </div>
    </div>
    <div class="position-update__info">
      <div class="position-update__values">
        <div class="label text-secondary">{{ typePositionLossLabel }}</div>
        <div class="value text-main">
          {{
            slValue1 === 'N/A' ? slValue1 : toCurrencyFormat(Math.abs(slValue1))
          }}
        </div>
      </div>
      <div class="position-update__values">
        <div class="label text-secondary">{{ lossAmountLabel }}</div>
        <div class="value text-main">
          {{ toCurrencyFormat(Math.abs(slValue2)) }}
          {{ lossAmountPlaceholder }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useStore } from 'vuex';
import SimpleInput from '@/components/ui/SimpleInput';
import { ref, computed } from 'vue';
import { toCurrencyFormat, toNumber } from '@/helpers/utils';
import { useI18n } from 'vue-i18n';
import notify from '@/plugins/notify';
import { PositionSides } from '@/config/constants';
import bsvPrice from '@/compositions/bsvPrice';
import { userProviders } from '@/modules/user/api';

export default {
  name: 'PositionUpdate',
  components: { SimpleInput },
  props: {
    position: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();

    const store = useStore();
    const profitAmount = ref('');
    const takeProfit = ref(null);
    const tpUpdateKey = ref(false);

    const lossAmount = ref('');
    const stopLoss = ref(null);
    const tpValue1 = ref('0');
    const tpValue2 = ref('0');
    const slValue1 = ref('0');
    const slValue2 = ref('0');

    const profitIndexMode = ref(2);
    const lossIndexMode = ref(2);

    const profitIndexModeInitial = computed(
      () => store.getters['localUiSettings/defaultProfitBtnIndex']
    );
    const lossIndexModeInitial = computed(
      () => store.getters['localUiSettings/defaultLossBtnIndex']
    );
    const currentMarket = computed(
      () => store.getters['markets/marketsById'][props.position.market_id]
    );

    const activeConnect = computed(
      () => store.getters['connectors/activeConnect']
    );

    const getMarginCoefficient = () => {
      if (activeConnect.value.provider === userProviders.fiorin) {
        return 1;
      }

      return bsvPrice();
    };

    // TP

    const calculateTakeProfitAmount = () => {
      // return props.position.margin * (takeProfit.value / 100);
      return (
        props.position.amount *
        (takeProfit.value / 100 / props.position.leverage)
      );
    };

    const calculateTakeProfitPrice = () => {
      const tp = takeProfit.value || 0;
      const position = props.position;

      return position.side === PositionSides.BUY
        ? position.entry_price * (1 + tp / 100 / position.leverage)
        : position.entry_price * (1 - tp / 100 / position.leverage);
    };

    const updateTakeProfit = () => {
      const precision = currentMarket.value.precision;
      switch (profitIndexMode.value) {
        case 0: {
          tpValue1.value = calculateTakeProfitPrice().toFixed(precision);
          tpValue2.value = calculateTakeProfitAmount().toFixed(precision);
          break;
        }
        case 1: {
          tpValue1.value = calculateTakeProfitPrice().toFixed(precision);
          tpValue2.value =
            takeProfit.value && +takeProfit.value.toFixed(precision);
          break;
        }
        case 2: {
          tpValue1.value =
            takeProfit.value && +takeProfit.value.toFixed(precision);
          tpValue2.value = calculateTakeProfitAmount().toFixed(precision);
          break;
        }
      }
      if (
        (!profitAmount.value || !profitAmount.value.length) &&
        !takeProfit.value
      ) {
        tpValue1.value = 'N/A';
      }
    };

    const onTakeProfitSetValue = (val) => {
      profitAmount.value = val;
      onTakeProfitInput();
    };

    const onTakeProfitInput = (isDefault) => {
      switch (profitIndexMode.value) {
        case 0: {
          takeProfit.value = +profitAmount.value;
          break;
        }
        case 1: {
          takeProfit.value =
            (100 * profitAmount.value) /
            (props.position.margin * getMarginCoefficient());
          break;
        }
        case 2: {
          if (!profitAmount.value) {
            takeProfit.value = null;
            updateTakeProfit();

            break;
          }

          const position = props.position;
          if (isDefault === 'default' && props.position.take_profit) {
            takeProfit.value = props.position.take_profit;
          } else {
            takeProfit.value =
              position.side === PositionSides.BUY
                ? position.leverage *
                  (profitAmount.value / position.entry_price - 1) *
                  100
                : position.leverage *
                  (1 - profitAmount.value / position.entry_price) *
                  100;
          }
          break;
        }
      }

      if (
        takeProfit.value > 100 * props.position.leverage &&
        props.position.side === PositionSides.SELL
      ) {
        takeProfit.value = +(100 * props.position.leverage).toFixed(2);

        switch (profitIndexMode.value) {
          case 0: {
            profitAmount.value = toCurrencyFormat(takeProfit.value);
            break;
          }
          case 1: {
            profitAmount.value = toCurrencyFormat(
              +(
                (takeProfit.value *
                  (props.position.margin * getMarginCoefficient())) /
                100
              ).toFixed(2)
            );
            break;
          }
          case 2: {
            profitAmount.value = 0;
            break;
          }
        }

        tpUpdateKey.value = !tpUpdateKey.value;

        notify({
          text: t('order.card.tpsl.toasts.notNegative'),
          type: 'info',
        });
      }

      updateTakeProfit();

      emit('changePosition', {
        position_id: props.position.id,
        take_profit: !profitAmount.value.length ? null : takeProfit.value,
        stop_loss: !lossAmount.value.length ? null : stopLoss.value,
        update: true,
        market: props.position.market,
      });
      // for check errors
      emit('changePositionInfo', {
        profitAmount: profitAmount.value,
        lossAmount: lossAmount.value,
        takeProfit: takeProfit.value,
        stopLoss: stopLoss.value,
        tpValue1: tpValue1.value,
        tpValue2: tpValue2.value,
        slValue1: slValue1.value,
        slValue2: slValue2.value,
        profitIndexMode: profitIndexMode.value,
        lossIndexMode: lossIndexMode.value,
        calculateStopLossPrice,
        calculateTakeProfitPrice,
      });
    };

    // ----

    // SL
    const calculateStopLossAmount = () => {
      // return props.position.margin * (stopLoss.value / 100);
      return (
        props.position.amount * (stopLoss.value / 100 / props.position.leverage)
      );
    };

    const calculateStopLossPrice = () => {
      const sl = stopLoss.value;
      const position = props.position;

      return position.side === PositionSides.BUY
        ? position.entry_price * (1 - sl / 100 / position.leverage)
        : position.entry_price * (1 + sl / 100 / position.leverage);
    };

    const updateStopLoss = () => {
      const precision = currentMarket.value.precision;

      switch (lossIndexMode.value) {
        case 0: {
          slValue1.value = calculateStopLossPrice().toFixed(precision);
          slValue2.value = calculateStopLossAmount().toFixed(precision);
          break;
        }
        case 1: {
          slValue1.value = calculateStopLossPrice().toFixed(precision);
          slValue2.value = stopLoss.value && +stopLoss.value.toFixed(precision);
          break;
        }
        case 2: {
          slValue1.value = stopLoss.value && +stopLoss.value.toFixed(precision);
          slValue2.value = calculateStopLossAmount().toFixed(precision);
          break;
        }
      }
      if ((!lossAmount.value || !lossAmount.value.length) && !stopLoss.value) {
        slValue1.value = 'N/A';
      }
    };

    const onStopLossSetValue = (val) => {
      lossAmount.value = val;
      onStopLossInput();
    };

    const onStopLossInput = (isDefault) => {
      switch (lossIndexMode.value) {
        case 0: {
          stopLoss.value = +lossAmount.value;
          break;
        }
        case 1: {
          stopLoss.value =
            (100 * lossAmount.value) /
            (props.position.margin * getMarginCoefficient());
          break;
        }
        case 2: {
          const position = props.position;

          if (isDefault === 'default' && props.position.stop_loss !== null) {
            stopLoss.value = props.position.stop_loss;
          } else {
            stopLoss.value =
              position.side === PositionSides.BUY
                ? position.leverage *
                  (1 - lossAmount.value / position.entry_price) *
                  100
                : position.leverage *
                  (lossAmount.value / position.entry_price - 1) *
                  100;
          }
          break;
        }
      }
      updateStopLoss();

      emit('changePosition', {
        position_id: props.position.id,
        take_profit: !profitAmount.value.length ? null : takeProfit.value,
        stop_loss: !lossAmount.value.length ? null : stopLoss.value,
        update: true,
        market: props.position.market,
      });
      // for check errors
      emit('changePositionInfo', {
        profitAmount: profitAmount.value,
        lossAmount: lossAmount.value,
        takeProfit: takeProfit.value,
        stopLoss: stopLoss.value,
        tpValue1: tpValue1.value,
        tpValue2: tpValue2.value,
        slValue1: slValue1.value,
        slValue2: slValue2.value,
        profitIndexMode: profitIndexMode.value,
        lossIndexMode: lossIndexMode.value,
        calculateStopLossPrice,
        calculateTakeProfitPrice,
      });
    };

    // -----
    // initial
    if (props.position.take_profit_price) {
      profitAmount.value = toCurrencyFormat(props.position.take_profit_price);
      updateTakeProfit();
      onTakeProfitInput();
    }

    if (props.position.stop_loss_price) {
      lossAmount.value = toCurrencyFormat(props.position.stop_loss_price);
      updateStopLoss();
      onStopLossInput();
    }

    setTimeout(() => {
      const TP = toCurrencyFormat(props.position.take_profit_price, 'nospace');
      const SL = toCurrencyFormat(props.position.stop_loss_price, 'nospace');

      profitAmount.value = [0, null].includes(props.position.take_profit_price)
        ? ''
        : TP;
      lossAmount.value = SL === '0' ? '' : SL;

      if (profitAmount.value) {
        onTakeProfitInput('default');
      }

      if (lossAmount.value) {
        onStopLossInput('default');
      }

      profitIndexMode.value = profitIndexModeInitial.value;
      lossIndexMode.value = lossIndexModeInitial.value;

      setModeProfit(profitIndexMode.value);
      setModeLoss(lossIndexMode.value);
    }, 50);

    const profitPlaceholder = computed(() => {
      if (profitIndexMode.value === 0) {
        return '(%)';
      }
      if (profitIndexMode.value === 1) {
        return '(USD)';
      }
      return `(${t('order.card.titles.price')})`;
    });
    const profitAmountPlaceholder = computed(() => {
      if (profitIndexMode.value === 1) {
        return '%';
      }
      return props.position?.base_currency;
    });
    const lossPlaceholder = computed(() => {
      if (lossIndexMode.value === 0) {
        return '(%)';
      }
      if (lossIndexMode.value === 1) {
        return '(USD)';
      }
      return `(${t('order.card.titles.price')})`;
    });
    const lossAmountPlaceholder = computed(() => {
      if (lossIndexMode.value === 1) {
        return '%';
      }
      return props.position?.base_currency;
    });

    const typePositionProfitLabel = computed(() => {
      if ([0, 1].includes(profitIndexMode.value)) {
        return t('order.card.tpsl.priceLabelStop0');
      }

      return t('order.card.tpsl.priceLabelTake2');
    });
    const typePositionLossLabel = computed(() => {
      if ([0, 1].includes(lossIndexMode.value)) {
        return t('order.card.tpsl.priceLabelStop1');
      }

      const entryPrice = props.position.entry_price;

      if (props.position.side === PositionSides.BUY) {
        if (toNumber(lossAmount.value) > entryPrice) {
          return t('order.card.tpsl.priceLabelTake2');
        }

        return t('order.card.tpsl.priceLabelStop2');
      } else {
        if (toNumber(lossAmount.value) < entryPrice) {
          return t('order.card.tpsl.priceLabelTake2');
        }

        return t('order.card.tpsl.priceLabelStop2');
      }
    });

    const profitAmountLabel = computed(() => {
      if ([0, 2].includes(profitIndexMode.value)) {
        return t('order.card.tpsl.profitLabelTake0');
      }

      return t('order.card.tpsl.profitLabelTake1');
    });
    const lossAmountLabel = computed(() => {
      let lossLabel = t('order.card.tpsl.priceLabelStop2');

      switch (lossIndexMode.value) {
        case 0:
          if (toNumber(lossAmount.value) < 0) {
            lossLabel = t('order.card.tpsl.profitLabelTake0');
          } else {
            lossLabel = t('order.card.tpsl.lossLabelStop0');
          }
          break;
        case 1:
          if (toNumber(lossAmount.value) < 0) {
            lossLabel = t('order.card.tpsl.profitLabelTake1');
          } else {
            lossLabel = t('order.card.tpsl.lossLabelStop1');
          }
          break;
        case 2:
          if (props.position.side === PositionSides.BUY) {
            if (toNumber(lossAmount.value) > props.position.entry_price) {
              lossLabel = t('order.card.tpsl.profitLabelTake2');
            } else {
              lossLabel = t('order.card.tpsl.lossLabelStop2');
            }
          } else {
            if (toNumber(lossAmount.value) < props.position.entry_price) {
              lossLabel = t('order.card.tpsl.profitLabelTake2');
            } else {
              lossLabel = t('order.card.tpsl.lossLabelStop2');
            }
          }
          break;
      }

      return lossLabel;
    });

    const setModeProfit = (index) => {
      profitIndexMode.value = index;
      store.commit('localUiSettings/SET_PROFIT_OPTION', index);

      switch (profitIndexMode.value) {
        case 0: {
          profitAmount.value = takeProfit.value
            ? toCurrencyFormat(takeProfit.value)
            : '';
          break;
        }

        case 1: {
          profitAmount.value = takeProfit.value
            ? toCurrencyFormat(calculateTakeProfitAmount(), 'nospace')
            : '';
          break;
        }

        case 2: {
          profitAmount.value = takeProfit.value
            ? toCurrencyFormat(calculateTakeProfitPrice(), 'nospace')
            : '';
          break;
        }
      }

      updateTakeProfit();
    };

    const setModeLoss = (index) => {
      lossIndexMode.value = index;
      store.commit('localUiSettings/SET_LOSS_OPTION', index);

      switch (lossIndexMode.value) {
        case 0: {
          lossAmount.value =
            stopLoss.value !== null ? toCurrencyFormat(stopLoss.value) : '';
          break;
        }

        case 1: {
          lossAmount.value =
            stopLoss.value !== null
              ? toCurrencyFormat(calculateStopLossAmount(), 'nospace')
              : '';
          break;
        }

        case 2: {
          lossAmount.value =
            stopLoss.value !== null
              ? toCurrencyFormat(calculateStopLossPrice(), 'nospace')
              : '';
          break;
        }
      }

      updateStopLoss();
    };

    return {
      profitPlaceholder,
      profitAmountPlaceholder,
      profitIndexMode,
      lossIndexMode,
      toCurrencyFormat,
      profitAmount,
      tpUpdateKey,
      lossAmount,

      lossPlaceholder,
      lossAmountPlaceholder,

      typePositionProfitLabel,
      typePositionLossLabel,

      profitAmountLabel,
      lossAmountLabel,

      setModeProfit,
      setModeLoss,

      // tp
      onTakeProfitInput,
      onTakeProfitSetValue,
      tpValue1,
      tpValue2,

      // sl
      onStopLossInput,
      onStopLossSetValue,
      slValue1,
      slValue2,
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/colors';
@import '@/assets/styles/base';

.position-update {
  display: flex;
  flex-direction: column;
  padding-top: 20px;

  .separator {
    margin: 0 5px;
  }

  &__controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__input {
    width: 180px;
  }

  &__control-btn {
    font-family: Gotham_Pro_Regular;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 46px;
    margin-right: 5px;
    height: 40px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    border-width: 1px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__values {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    font-family: Gotham_Pro_Regular;
  }

  &__info {
    display: flex;
    flex-direction: column;
    margin: 20px 0 40px 0;

    &:last-child {
      margin-bottom: 0;
    }

    .value {
      font-family: Cantarell_Regular;
    }
  }

  .split-btn {
    position: absolute;
    bottom: -10px;
    left: 0;
  }
}
</style>
