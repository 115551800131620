<template>
  <Modal @close="$emit('close')" stop-mobile-max-height>
    <div class="kys-status">
      <Card :title="$t('account.titles.KYC')" class="card kys-status__inner">
        <div class="kys-status__content">
          {{ status }}
        </div>
      </Card>
      <div class="kys-status__btns">
        <Button
          v-if="kycStatus !== 'Accepted'"
          type="secondary"
          width="full"
          :text="$t('account.titles.chat')"
          @click="openChat"
        />
      </div>
    </div>
  </Modal>
</template>
<script>
import Modal from '@/modules/modals/Modal';
import Card from '@/components/ui/Card';
import Button from '@/components/ui/Button';
import { useStore } from 'vuex';
import { defineComponent, computed } from 'vue';

export default defineComponent({
  name: 'KYCStatus',
  components: { Button, Modal, Card },
  setup(_, { emit }) {
    const store = useStore();

    const settings = computed(() => store.getters['settings/settings']);

    const kycStatus = computed(() => settings.value.kycStatus);

    const status = computed(() => {
      switch (kycStatus.value) {
        case 'NotStarted':
          return 'Not Started';
        case 'InProgress':
          return 'In Progress';
        case 'Accepted':
          return 'Verified';
        case 'Refused':
          return 'Attention required';
        default:
          return '';
      }
    });

    const openChat = () => {
      store.dispatch('burger/support');
      emit('close');
    };

    return {
      settings,
      kycStatus,
      status,
      openChat,
    };
  },
});
</script>

<style lang="scss">
.kys-status {
  &__inner {
    min-height: 300px !important;
    padding-bottom: 50px;
  }
  &__content {
    position: relative;
    height: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    font-size: 1.5rem;
    text-align: center;
    font-family: Gotham_Pro_Regular;
  }
  &__btns {
    position: absolute;
    display: flex;
    flex-direction: row;
    bottom: 0;
    width: 100%;
  }
}
</style>
