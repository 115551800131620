<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :viewBox="viewBox ? viewBox : `0 0 ${iconData.width} ${iconData.height}`"
    :aria-labelledby="name"
    role="presentation"
  >
    <!-- <title v-if="title" :id="name" lang="en">{{ title }}</title> -->
    <!-- <title v-else :id="name" lang="en">{{ name }} icon</title> -->
    <g :fill="fillColor" :stroke="strokeColor" v-html="iconData.data" />
  </svg>
</template>

<script>
import icons from 'vue-svgicon';

export default {
  props: {
    name: {
      type: String,
      default: 'box',
    },
    title: {
      type: String,
      default: 'box',
    },
    width: {
      type: [Number, String],
      default: 18,
    },
    height: {
      type: [Number, String],
      default: 18,
    },
    viewBox: {
      type: String,
      default: '',
    },
    strokeColor: {
      type: String,
      default: 'none',
    },
    fillColor: {
      type: String,
      default: 'currentColor',
    },
  },
  setup(props) {
    const iconData = icons.icons[props.name];
    return { iconData };
  },
};
</script>
