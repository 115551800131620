/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'wallet-connect': {
    width: 48,
    height: 48,
    viewBox: '0 0 48 48',
    data: '<path pid="0" d="M9.746 15.285c7.664-7.502 20.092-7.502 27.756 0l.923.902a.945.945 0 010 1.358l-3.156 3.089a.499.499 0 01-.694 0l-1.27-1.243c-5.346-5.232-14.015-5.232-19.363 0l-1.359 1.33a.499.499 0 01-.694 0l-3.156-3.088a.946.946 0 010-1.358l1.013-.99zM44.03 21.67l2.807 2.749a.947.947 0 010 1.358L34.175 38.172a.996.996 0 01-1.389 0L23.8 29.375a.249.249 0 00-.348 0l-8.987 8.797a.996.996 0 01-1.389 0L.412 25.778a.946.946 0 010-1.358l2.809-2.75a.996.996 0 011.387 0l8.987 8.797a.249.249 0 00.347 0l8.987-8.796a.996.996 0 011.389 0l8.988 8.796a.249.249 0 00.348 0l8.986-8.796a.996.996 0 011.389 0z" _fill="#404040"/>'
  }
})
