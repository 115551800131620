/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'buy_simple': {
    width: 6,
    height: 5,
    viewBox: '0 0 6 5',
    data: '<path pid="0" d="M.362 3.759L2.42.777c.319-.463.84-.463 1.16 0l2.057 2.982c.32.462.12.84-.441.84H.803c-.562 0-.76-.379-.44-.84z" _fill="#3F7D76"/>'
  }
})
