<template>
  <div class="e-not">404</div>
</template>

<script>
export default {
  name: 'NotFound',
  mounted() {
    this.$router.push('/');
  },
};
</script>

<style lang="sass" scoped>
.e-not
  display: flex
  justify-content: center
  align-items: center
  width: 100%
  height: 100vh
</style>
