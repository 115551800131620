/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'arrow_down': {
    width: 30.828,
    height: 18.242,
    viewBox: '0 0 30.828 18.242',
    data: '<path pid="0" _fill="#FFF" d="M15.414 18.242L0 2.828 2.828 0l12.586 12.586L28 0l2.828 2.828z"/>'
  }
})
