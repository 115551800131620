<template>
  <Modal :stop-mobile-max-height="true" @close="$emit(EVENTS.close)">
    <Card :title="$t('updateMarkets.title').toUpperCase()" class="card">
      <div class="update-markets">
        <p>{{ $t('updateMarkets.text') }}</p>
      </div>
    </Card>
    <Button
      :text="$t('update')"
      type="secondary"
      class="markets-btn"
      @click="updateMarkets"
    />
  </Modal>
</template>
<script>
import Modal from '@/modules/modals/Modal';
import Card from '@/components/ui/Card';
import Button from '@/components/ui/Button';
import notify from '@/plugins/notify';
import { useInitialize } from '@/compositions/useInitialize';

const EVENTS = {
  close: 'close',
};

export default {
  components: { Modal, Card, Button },
  emits: [EVENTS.close],
  setup(_, { emit }) {
    const { initMarkets } = useInitialize();

    const updateMarkets = async () => {
      await initMarkets();
      notify({
        text: 'Markets updated',
        type: 'info',
      });
      emit(EVENTS.close);
    };

    return {
      updateMarkets,
      EVENTS,
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/colors';
@import '@/assets/styles/base';

.update-markets {
  height: auto;
  overflow-y: auto;
  margin-bottom: 45px;
  padding-right: 15px;
  box-sizing: border-box;
  font-family: Gotham_Pro_Regular;
  line-height: 20px;

  p {
    &.underline {
      text-decoration-line: underline;
    }
  }

  div {
    margin-top: 25px;

    h4 {
      font-family: Gotham_Pro_Bold !important;
    }
  }

  span {
    text-decoration-line: underline;
    cursor: pointer;
  }
}

.markets-btn {
  margin-top: 30px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
</style>
