/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'ios_share': {
    width: 20,
    height: 20,
    viewBox: '0 5 60 90',
    data: '<path pid="0" d="M29.959 7.604a2.835 2.835 0 00-.836.138 2.835 2.835 0 00-.52.229 2.835 2.835 0 00-.238.15 2.835 2.835 0 00-.222.176 2.835 2.835 0 00-.147.135l-.058.06-9.942 9.94a2.835 2.835 0 104.008 4.01l5.162-5.163v38.159a2.835 2.835 0 105.668 0V17.279l5.162 5.162a2.835 2.835 0 004.008-4.01l-10-10a2.835 2.835 0 00-.21-.189 2.835 2.835 0 00-.728-.431 2.835 2.835 0 00-.002 0 2.835 2.835 0 00-.265-.094 2.835 2.835 0 00-.002 0 2.835 2.835 0 00-.276-.067 2.835 2.835 0 00-.562-.046zM5 27.602a2.835 2.835 0 00-2.834 2.835v50A2.835 2.835 0 005 83.272h50a2.835 2.835 0 002.834-2.834v-50A2.835 2.835 0 0055 27.602H40a2.835 2.835 0 000 5.67h12.166v44.33H7.834v-44.33H20a2.835 2.835 0 000-5.67H5z" _fill="#77d"/>'
  }
})
