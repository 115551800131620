/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'metamask': {
    width: 48,
    height: 48,
    viewBox: '0 0 48 48',
    data: `
    <g fill="none">
    <path pid="0" d="M23.771 9.761h-5.799L5.006 4.293l-2.164 6.915 2.902 13.097s-2.584 8.177-2.62 8.885c-.035.708 2.833 8.637 2.833 8.637 2.448-.337 9.054-1.962 9.054-1.962 1.786 1.112 5.207 3.948 5.207 3.948h3.553" stroke="currentColor" stroke-width="4.5" stroke-linecap="round" stroke-linejoin="round"/><path pid="1" d="M23.477 9.761h5.799l12.966-5.468 2.164 6.915-2.902 13.097s2.584 8.177 2.62 8.885c.035.708-2.833 8.637-2.833 8.637-2.448-.337-9.054-1.962-9.054-1.962-1.786 1.112-5.208 3.948-5.208 3.948h-3.552" stroke="currentColor" stroke-width="4.5" stroke-linecap="round" stroke-linejoin="round"/><path pid="2" fill-rule="evenodd" clip-rule="evenodd" d="M14.763 26.948l2.736-1.306c.559-.245.89.007.986.164.146.274.476.917.801 1.692.1.238.188.439.264.611.295.672.403.918.273 1.289-.122.343-.636.27-.93.227l-.07-.01c-.42-.122-.954-.276-1.5-.432-1.119-.32-2.28-.653-2.56-.757-.521-.191-.725-.34-.725-.638 0-.419.275-.594.725-.84zm17.722 0l-2.736-1.306c-.558-.245-.89.007-.986.164-.146.274-.476.917-.801 1.692-.1.238-.188.439-.264.611-.295.672-.404.918-.273 1.289.122.343.636.27.93.227l.07-.01c.42-.122.954-.276 1.5-.432 1.119-.32 2.28-.653 2.56-.757.521-.191.725-.34.725-.638 0-.419-.275-.594-.725-.84z" fill="currentColor"/><path pid="3" d="M20.73 36.614l.771-2.517h4.246l.771 2.517H20.73z" fill="currentColor"/>
    </g>
    `
  }
})
