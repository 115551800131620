import { computed } from 'vue';
import { PositionTypes } from '@/modules/positions/helpers';
import { useI18n } from 'vue-i18n';

export const usePositionsTable = (isMobile, filterType) => {
  const { t } = useI18n();

  const positionsTableGrid = computed(() => {
    if (!isMobile) {
      if (['open', 'pending'].includes(filterType)) {
        return {
          tableHeader: '1.3fr 0.88fr 1.16fr 1.03fr 1fr 4rem',
          groupHeader: '1.5rem 1.22fr 0.88fr 1.16fr 1.03fr 1fr 5.1rem 2rem',
          position: '2.6rem 1.2fr 0.88fr 1.16fr 1.03fr 1fr 5rem 1.7rem',
        };
      }
      return {
        tableHeader: '1.3fr 0.88fr 1.16fr 1.03fr 1fr 4rem',
        groupHeader: '1.5rem 1.22fr 0.88fr 1.16fr 1.03fr 1fr 5.1rem 2rem',
        position: '2.6rem 1.2fr 0.88fr 1.16fr 1.03fr 1fr 5rem 1.7rem',
      };
    } else {
      return {
        tableHeader: '1.16fr 1fr 1fr',
        groupHeader: '2rem 1fr 1fr 1fr 3.1rem',
        position: '2.3rem 1.1fr 1fr 1.1fr 2rem',
      };
    }
  });

  const positionsColumns = computed(() => {
    if (!isMobile) {
      if (filterType === PositionTypes.open) {
        return [
          {},
          {
            title: t('tables.headers.positions.market'),
            grid: '1.2',
            prop: '',
            align: 'left',
          },
          {
            title: `${t('tables.headers.positions.amount')} $`,
            grid: '0.88',
            prop: '',
            align: 'left',
          },
          {
            title: t('tables.headers.positions.entryCurr'),
            grid: '1.16',
            prop: '',
            align: 'left',
          },
          {
            title: t('tables.headers.positions.target'),
            grid: '1.03',
            prop: '',
            align: 'left',
          },
          {
            title: t('tables.headers.positions.stop'),
            grid: '1',
            prop: '',
            align: 'left',
          },
          {
            title: `Gross P/L $`,
            grid: '4rem',
            prop: '',
            align: 'left',
          },
          {},
        ];
      } else if (filterType === PositionTypes.pending) {
        return [
          {},
          {
            title: t('tables.headers.positions.market'),
            grid: '1.2',
            prop: '',
            align: 'left',
          },
          {
            title: `${t('tables.headers.positions.amount')} $`,
            grid: '0.88',
            prop: '',
            align: 'left',
          },
          {
            title: t('tables.headers.positions.entryCurr'),
            grid: '1.16',
            prop: '',
            align: 'left',
          },
          {
            title: t('tables.headers.positions.target'),
            grid: '1.03',
            prop: '',
            align: 'left',
          },
          {
            title: t('tables.headers.positions.stop'),
            grid: '1',
            prop: '',
            align: 'left',
          },
          {
            title: t('tables.headers.positions.date'),
            grid: '4rem',
            prop: '',
            align: 'left',
          },
          {},
        ];
      }
      return [
        {},
        {
          title: t('tables.headers.positions.market'),
          grid: '1.45',
          prop: '',
          align: 'left',
        },
        {
          title: `${t('tables.headers.positions.amount')} $`,
          grid: '1.26',
          prop: '',
          align: 'left',
        },
        {
          title: t('tables.headers.positions.entry'),
          grid: '1.5',
          prop: '',
          align: 'left',
        },
        {
          title: t('tables.headers.positions.close'),
          grid: '1.5',
          prop: '',
          align: 'left',
        },
        {
          title: t('tables.headers.positions.date'),
          grid: '2.3rem',
          prop: '',
          align: 'left',
        },
        {
          title: `${t('tables.headers.positions.pl')} $`,
          grid: '1.5',
          prop: '',
          align: 'left',
        },
      ];
    } else {
      if (filterType === PositionTypes.open) {
        return [
          {
            title: t('tables.headers.positions.market'),
            grid: '1.2',
            prop: '',
            align: 'left',
          },
          {},
          {
            title: `${t('tables.headers.positions.amount')} $`,
            prop: '',
            align: 'left',
          },
          {
            title: `Gross P/L $`,
            prop: '',
            align: 'left',
          },
          {},
        ];
      } else if (filterType === PositionTypes.pending) {
        return [
          {
            title: t('tables.headers.positions.market'),
            grid: '1.2',
            prop: '',
            align: 'left',
          },
          {},
          {
            title: `${t('tables.headers.positions.amount')} $`,
            prop: '',
            align: 'left',
          },
          {
            title: t('tables.headers.positions.entryCurr'),
            prop: '',
            align: 'left',
          },
          {},
        ];
      }
      return [
        {},
        {
          title: t('tables.headers.positions.market'),
          size: 20,
          grid: '1.45',
          prop: '',
          align: 'left',
        },
        {
          title: `${t('tables.headers.positions.amount')} $`,
          prop: '',
          align: 'left',
        },
        {
          title: `${t('tables.headers.positions.pl')} $`,
          prop: '',
          align: 'left',
        },
      ];
    }
  });

  return {
    positionsColumns,
    positionsTableGrid,
  };
};
