import {
  getUserBalance as dwGetUserBalance,
  refreshToken as dwRefreshToken,
} from './dotWallet';
import store from '@/store';
import { connApp } from '@/helpers/signalR';

// @ts-ignore
import HandcashConnector from '@/wallets/Handcash';
import DotwalletConnector from '@/wallets/Dotwallet';
import MoneybuttonConnector from '@/wallets/Moneybutton';
import RelayXConnector from '@/wallets/RelayX';
import FiorinConnector from '@/wallets/Fiorin';

export const userProviders = {
  demo: '',
  moneyButton: 'MoneyButton',
  relayX: 'RelayX',
  dotWallet: 'DotWallet',
  handCash: 'HandCash',
  fiorin: 'Fiorin',
};

export const refreshTokenUpfrontMs = 5 * 60000;
const userProvidersList = Object.values(userProviders);

export async function startAuth(provider, params) {
  switchAuth(provider);
  switch (provider) {
    case userProviders.moneyButton:
      return new MoneybuttonConnector({}).connect();

    case userProviders.relayX:
      return store.dispatch(
        'connectors/setActiveConnect',
        await new RelayXConnector({}).connect()
      );

    case userProviders.dotWallet:
      return new DotwalletConnector({}).connect();

    case userProviders.fiorin:
      return store.dispatch(
        'connectors/setActiveConnect',
        await new FiorinConnector({}).connect(params)
      );
    case userProviders.handCash: {
      return new HandcashConnector({}).connect();
    }

    default:
      return;
  }
}

export function switchAuth(provider) {
  if (
    userProvidersList.includes(provider) &&
    provider !== store.getters['user/provider']
  ) {
    store.commit('user/SET_PROVIDER', provider);
    store.commit('user/SET_SATOSHI_BALANCE', 0);
  }
}

export async function refreshToken(provider) {
  switch (provider) {
    case userProviders.dotWallet:
      await dwRefreshToken();
      break;
  }
}

export async function getUserBalance() {
  const provider = store.getters['user/provider'];
  switch (provider) {
    case userProviders.moneyButton:
      // mbGetUserBalance();
      break;
    case userProviders.relayX:
      // rxGetUserBalance();
      break;
    case userProviders.dotWallet:
      dwGetUserBalance();
      break;
    case userProviders.handCash:
      // hcGetUserBalance()
      break;
    default:
      break;
  }
}

export function useLogout() {
  return {
    logout: () => {
      store.dispatch('connectors/disconnect');
      console.log(8);
      window.location.reload();
    },
  };
}

export async function updateConfig() {
  let config = await connApp.invoke('GetAddresses', 'BSV');
  console.log('config', config);
  store.commit('user/SET_WS_CONFIG', config);
}
