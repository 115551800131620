/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'createwallet': {
    width: 38,
    height: 38,
    viewBox: '0 0 38 38',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M18.82 20.462c1.03 0 7.532-.47 7.532-8.038 0-4.44-3.372-8.038-7.532-8.038s-7.531 3.599-7.531 8.038c0 7.569 6.5 8.038 7.531 8.038zm11.277 6.61c1.877 1.527 3.38 3.552 4.334 6.119a13.347 13.347 0 01-2.962 2.567c-.695-2.695-2.034-4.583-3.645-5.895-2.44-1.988-5.734-2.835-9.005-2.835-3.27 0-6.565.848-9.005 2.835-1.611 1.311-2.95 3.2-3.645 5.895a13.346 13.346 0 01-2.962-2.569c.955-2.566 2.457-4.59 4.334-6.118 3.27-2.662 7.457-3.643 11.278-3.643 3.82 0 8.009.98 11.278 3.643z" _fill="#242A3A"/>'
  }
})
