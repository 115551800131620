<template>
  <Modal :stop-mobile-max-height="true" @close="$emit('close')">
    <div class="market-open-close-wrap">
      <Card
        :title="$t('popups.marketInactive.marketClosed').toUpperCase()"
        class="card"
      >
        <div class="market-open-close">
          <div class="market-open-close__info">
            <div class="label">{{ label }}</div>
            <div class="value">
              {{ value.includes('-') || value.includes('0m') ? '~1m' : value }}
            </div>
          </div>
        </div>
      </Card>
      <div class="btns">
        <Button
          v-if="!hideAgainBtn"
          :text="$t('tradingSession.titles.shutDown')"
          type="secondary"
          class="btn"
          @click="$emit('dontShowAgainMarket')"
        />
      </div>
    </div>
  </Modal>
</template>
<script>
import Modal from '@/modules/modals/Modal';
import Card from '@/components/ui/Card';
import Button from '@/components/ui/Button';
import { onMounted } from 'vue';
import { useInitialize } from '@/compositions/useInitialize';

export default {
  components: { Modal, Card, Button },
  emits: ['dontShowAgainMarket', 'close'],
  props: {
    hideAgainBtn: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: 'Market opens in',
    },
    value: {
      type: String,
      default: '41m',
    },
  },
  setup(props, { emit }) {
    const { initMarkets } = useInitialize();
    onMounted(async () => {
      await initMarkets();
      if (props.value.includes('-')) {
        emit('close');
      }
    });
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/colors';
@import '@/assets/styles/base';

.market-open-close-wrap {
  .card {
    min-height: 220px;
  }

  .market-open-close {
    &__info {
      // margin-top: 20px;
      height: 170px;
      padding-bottom: 40px;
      flex-direction: column;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: Gotham_Pro_Regular;
      font-size: 0.9375rem;
      color: $color-gray;
      line-height: $default-lineheight;

      .value {
        color: $color-gray;
        font-family: Gotham_Pro_Bold;
        font-size: 36px;
      }

      &.mt-20 {
        margin-top: 20px;
      }
    }
  }

  .btns {
    width: 100%;
    margin-top: 30px;
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .btn {
    width: 49%;
    margin: 0 1px;

    &.hideAgainBtn {
      width: 100%;
    }
  }
}
</style>
