import { ref } from 'vue';

export default function useToggleCard() {
  const openCard = ref(true);

  const toggleCard = () => {
    openCard.value = !openCard.value;
  };

  return { openCard, toggleCard };
}
