/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'markets_off': {
    width: 17,
    height: 17,
    viewBox: '0 0 17 17',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M6 0a6 6 0 00-6 6v5c0 .133.004.264.013.395l1.326-1.513.428-.489.489.428.988.865 2.449-2.947.437-.527.503.464 2.25 2.074 5.07-5.855.446-.514.493.47 1.163 1.105.67-.756A6.003 6.003 0 0011 0H6zm10.998 5.852l-.417.47-.447.505-.488-.465-1.156-1.1-5.064 5.846-.44.507-.492-.454-2.239-2.065-2.437 2.934-.426.513-.502-.44-1.002-.876L.34 12.994A6.003 6.003 0 006 17h5a6 6 0 006-6V6c0-.05 0-.099-.002-.148z" _fill="#fff"/>'
  }
})
