<template>
  <Modal :stop-mobile-max-height="true" @close="$emit('close')">
    <div class="tour-wrap">
      <Card
        :title="$t('popups.tour.title')"
        :body-class="'card-full'"
        class="card"
      >
        <div class="tour__info">
          {{ $t('popups.tour.text') }}
        </div>
        <div class="tour__btns">
          <Button
            :text="$t('popups.buttons.confirm')"
            type="secondary"
            class="btn"
            @click="showTour"
          />
          <Button
            :text="$t('popups.buttons.close')"
            type="secondary"
            class="btn"
            @click="closeModal"
          />
        </div>
      </Card>
    </div>
  </Modal>
</template>
<script>
import Modal from '@/modules/modals/Modal';
import Card from '@/components/ui/Card';
import Button from '@/components/ui/Button';
import tourMixin from '@/mixins/tour.mixin';

export default {
  components: { Modal, Card, Button },
  emits: ['close'],
  mixins: [tourMixin],
  setup(_, { emit }) {
    const closeModal = () => {
      emit('close');
    };

    return {
      closeModal,
    };
  },
  methods: {
    async showTour() {
      await this.$router.push('/markets');
      this.$tours[this.$data.$tour_desktop]?.start();
      this.$tours[this.$data.$tour_laptop]?.start();
      this.$tours[this.$data.$tour_mobile]?.start();
      this.closeModal();
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/colors';
@import '@/assets/styles/base';

.tour-wrap {
  .tour {
    padding: 15px;
    box-sizing: border-box;
    overflow: auto;
    height: 260px;

    &__btns {
      width: 100%;
      display: flex;
      left: 0;
      bottom: 0;
      flex-direction: row;
    }

    &__info {
      padding: 15px;
      font-family: Gotham_Pro_Regular;
      font-size: 16px;
      line-height: 20px;
    }
  }

  .btn {
    width: 100%;
  }
}
</style>
