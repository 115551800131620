/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'dotwallet': {
    width: 1024,
    height: 1024,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M231.609 333.7c56.078 0 100.653-46.013 100.653-100.653 0-56.078-46.013-100.653-100.653-100.653-56.078 0-100.653 46.013-100.653 100.653S175.53 333.7 231.609 333.7z"/><path pid="1" d="M865.724 601.15c8.628-30.196 12.941-63.268 12.941-96.34 0-207.058-166.797-373.854-373.855-373.854h-5.751c-56.078 0-100.653 46.013-100.653 100.653s46.013 100.653 100.653 100.653h4.314c84.836 0 153.855 61.83 168.234 142.353 1.438 11.503 2.876 23.006 2.876 34.51 0 7.189-1.438 12.94-1.438 20.13-12.941 83.398-86.274 145.228-172.548 145.228H333.7V499.059c0-56.078-46.013-100.653-100.653-100.653-56.078 0-100.653 46.013-100.653 100.653v277.515c0 56.078 46.013 100.653 100.653 100.653h273.201c171.11 1.438 317.777-116.47 359.476-276.077z"/>'
  }
})
