<template>
  <div class="markets-group">
    <TableRow
      :dataset="dataset"
      :stop-behavior="true"
      class="positions-list-item-wrapper"
      @click="() => (opened = !opened)"
    >
      <template v-slot:0>
        <div class="item market">{{ groupName }}</div>
      </template>
      <template v-slot:3>
        <Icon
          :class="{ opened }"
          name="arrow_down"
          width="12"
          height="12"
          class="arrow-icon"
        />
      </template>
    </TableRow>
    <div v-if="opened">
      <slot name="markets" />
    </div>
  </div>
</template>
<script>
import '@/assets/icons/arrow_down';
import { ref } from 'vue';
import Icon from '@/components/ui/Icon';
import TableRow from '@/components/ui/TableRow';

export default {
  name: 'MarketListGroup',
  components: { TableRow, Icon },
  props: {
    dataset: {
      type: Array,
      required: true,
    },
    groupName: {
      type: String,
      required: true,
    },
    groupList: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const opened = ref(true);
    return {
      opened,
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/colors';
@import '@/assets/styles/base';

.positions-list-item-wrapper {
  position: relative;
  height: 24px;
  display: flex;
  align-items: center;
  margin-bottom: 1px;
  background: #dce1e6;
  text-transform: uppercase;

  .table-row__item {
    max-height: 24px;
  }

  .arrow-icon {
    opacity: 0;
    position: absolute;
    right: 15px;
    transition: all 0.2s ease-in-out;
    transform: rotate(-90deg);

    &.opened {
      transform: rotate(0);
    }
  }
}

.market {
  font-family: Gotham_Pro_Bold;
  font-size: 0.875rem;
  padding-top: 3px;

  &.cantarell {
    font-family: Cantarell_Bold;
  }
}
</style>
