/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'ico_cross_burg': {
    width: 14.645,
    height: 14.645,
    viewBox: '0 0 14.645 14.645',
    data: '<path pid="0" _fill="#FFF" d="M14.645 1.632L13.013 0 7.322 5.69 1.632 0 0 1.632l5.69 5.69L0 13.013l1.632 1.632 5.69-5.691 5.691 5.691 1.632-1.632-5.691-5.691z"/>'
  }
})
