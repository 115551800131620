/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'burgerref': {
    width: 18,
    height: 17,
    viewBox: '0 0 18 17',
    data: '<path pid="0" d="M5.54 16.5v-2H0v2h5.54zm5.863-9H0v2.063h11.403V7.5zm5.827-7H0v2.063h17.23V.5z" _fill="#fff"/>'
  }
})
