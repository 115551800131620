import isEqual from 'lodash/isEqual';
import { createSets } from './sets';

export const SetType = {
  linked: 'linked',
  independent: 'independent',
};

export const listenPositions = (
  cb,
  getPositionById,
  maxAccumulateDelay = 500
) => {
  let buffer = [];
  let timer = null;
  const addPosition = (positionToAdd) => {
    const hasSamePosition = buffer.some((position) =>
      isEqual(position, positionToAdd)
    );

    if (!hasSamePosition) {
      buffer.push(positionToAdd);
    }
  };
  const getPositions = () => [...buffer];
  const clearBuffer = () => {
    buffer = [];
  };

  return (data) => {
    addPosition(data);
    clearInterval(timer);

    timer = setTimeout(() => {
      const positions = getPositions();
      const sets = createSets(positions, getPositionById);

      cb(sets);
      clearBuffer();
    }, maxAccumulateDelay);
  };
};
