import router from '@/router';
import { connApp } from '@/helpers/signalR';
import Radio from '@/components/app/Radio';
import DotwalletConnector from '@/wallets/Dotwallet';
import { PositionTypes } from '@/modules/positions/helpers';
import { v4 as uuidv4 } from 'uuid';
// import { userProviders } from '@/modules/user/api';
import { updateAndSetUserSettings } from '@/modules/settings/api';
// const FIORIN_HOST = process.env.VUE_APP_FIORIN_HOST;

let firstInitAuth = false;
let logined = false;

async function onConnect(store) {
  async function auth() {
    const activeConnect = store.getters['connectors/activeConnect'];

    try {
      if (activeConnect.provider === 'MoneyButton' && activeConnect.authorize) {
        await activeConnect.authorize();
        store.dispatch('connectors/setActiveConnect', activeConnect);
      }

      if (activeConnect.provider === 'DotWallet') {
        if (activeConnect.authorizeCode) {
          await activeConnect.authorizeCode(activeConnect.code);
          store.dispatch('connectors/setActiveConnect', activeConnect);
        } else {
          const dotwalletWallet = new DotwalletConnector(activeConnect);
          store.dispatch('connectors/setActiveConnect', dotwalletWallet);
        }
      }

      localStorage.setItem('dxsLogined', true);
      if (
        activeConnect &&
        activeConnect.provider &&
        activeConnect.accessToken
      ) {
        if (!localStorage.getItem('dxsUUID')) {
          localStorage.setItem('dxsUUID', uuidv4());
        }

        if (!firstInitAuth) {
          await store.dispatch('connectors/setAuthLoader', true);
          firstInitAuth = true;
        }

        // const result = await connApp.invoke(
        //   'Authenticate',
        //   activeConnect.provider,
        //   activeConnect.accessToken,
        //   localStorage.getItem('dxsUUID')
        // );
        let settings;

        settings = await connApp.invoke('GetUser').catch(async () => {
          await connApp.disconnect();
          await connApp.start(
            {},
            `${activeConnect.provider}:${activeConnect.accessToken}`
          );
        });

        if (activeConnect.provider !== 'Fiorin') {
          const el = document.querySelector('.static-splash');
          if (el) {
            el.style.display = 'none';
            document.body.style.overflow = '';
          }
        }

        window.document.dispatchEvent(
          new CustomEvent('closeLogin', { bubbles: true })
        );

        logined = true;
        // console.log('socket auth result', result);
        setTimeout(() => {
          document.body.style.overflow = '';
        }, 1500);

        const authInfo = {
          provider: settings?.auth?.provider,
          providerId: settings?.auth?.providerId,
          userName: settings?.auth?.userName,
          userId: settings?.user?.id,
        };

        if (authInfo.userId) {
          // localStorage.removeItem('cacheAmountUsd');
          setTimeout(async () => {
            await store.dispatch('connectors/setActiveConnectInfo', authInfo);
            await store.dispatch('user/setTruedexAuth', authInfo);
            await store.dispatch('connectors/setActiveConnect', {});

            activeConnect.serverInfo = authInfo;
            await store.dispatch('connectors/setActiveConnect', activeConnect);

            await store.dispatch('session/updateInfo');
            // await store.dispatch('volumeLimits/fetchLoyaltyPoolBalance');
            const stats = await connApp.invoke('GetTradingStats');
            store.commit('session/SET_USER_TRADE_INFO', stats);

            if (settings?.user) {
              await store.dispatch('settings/setUserSettings', settings.user);
            }

            if (activeConnect.provider === 'Fiorin') {
              store.dispatch('burger/fiorinMode', true, { root: true });
            } else {
              store.dispatch('burger/fiorinMode', false, { root: true });
            }

            connApp
              .invoke('FilterUserPositions', {
                state: 'Open,Proposed',
                take: 200,
              })
              .then(async (positions) => {
                const stats = await connApp.invoke('GetTradingStats');
                store.commit('session/SET_USER_TRADE_INFO', stats);
                store.dispatch('positions/setAllPositions', positions);
                document.querySelector('.landing-dxs').classList.remove('show');
                customRedirect();

                // load history later
                setTimeout(() => {
                  connApp
                    .invoke('FilterUserPositions', {
                      state: 'History',
                      take: 200,
                    })
                    .then(async (positions) => {
                      const openPositions =
                        store.getters['positions/getPositionsByType'][
                          PositionTypes.open
                        ];
                      const pendingPositions =
                        store.getters['positions/getPositionsByType'][
                          PositionTypes.pending
                        ];

                      store.dispatch('positions/setAllPositions', [
                        ...openPositions,
                        ...pendingPositions,
                        ...positions,
                      ]);
                      const historyStats = await connApp.invoke(
                        'GetClosedPositionsStats'
                      );

                      store.commit(
                        'session/SET_USER_TRADE_INFO_HISTORY',
                        historyStats
                      );
                    });
                  store.dispatch('localUiSettings/setFirstAppLoaded', true);
                }, 2500);
              });

            if (activeConnect.provider === 'Fiorin') {
              let hasLogs =
                store.getters['settings/uiSettings'].balancesLog || [];
              if (hasLogs.length > 100) {
                hasLogs = [];
              }
              const logs = hasLogs.concat(activeConnect.balancesLog);
              logs.push(settings.bountyProgramAvailable);
              await updateAndSetUserSettings({
                uiSettings: {
                  balancesLog: logs,
                },
              });
            }
          }, 1000);
        }
      } else {
        store.commit('connectors/UPDATE_LOADING_STATUS', false);
      }
    } catch (e) {
      store.dispatch('connectors/setAuthLoader', false);
      store.dispatch('connectors/setUserName', '', { root: true });

      if (activeConnect.provider === 'Fiorin' && activeConnect.accessToken) {
        // window.location.reload();
      } else {
        store.dispatch('burger/fiorinMode', false, { root: true });
        store.dispatch('connectors/disconnect');
        store.dispatch('user/logout');
        store.dispatch('connectors/setActiveConnect', {});
        customRedirect();

        window.location.reload();
      }
    }
  }

  const customRedirect = async () => {
    const href = router.currentRoute.value?.href || '/';

    if (['index?code', 'authToken'].some((part) => href.includes(part))) {
      clearInterval(checkTimer);
      await router.push('/');
    } else {
      clearInterval(checkTimer);
      await router.push(href);
    }
  };

  await auth();
  Radio.$emit('signal-onconnect');
}

let checkTimer = null;

export default function onConnectPlugin(store) {
  connApp.on('init', async () => {
    store.commit('graph/SET_RELOAD_FLAG', true);
    setTimeout(() => store.commit('graph/SET_RELOAD_FLAG', false), 500);

    if (logined) {
      const activeConnect = store.getters['connectors/activeConnect'];
      console.log('after sleep');

      await connApp.invoke('GetUser').catch(async () => {
        await connApp.disconnect();
        await connApp.start(
          {},
          `${activeConnect.provider}:${activeConnect.accessToken}`
        );
      });

      store.commit('graph/SET_RELOAD_FLAG', true);
      setTimeout(() => store.commit('graph/SET_RELOAD_FLAG', false), 200);

      setTimeout(() => {
        document.body.style.overflow = '';
      }, 1500);

      setTimeout(() => {
        connApp
          .invoke('FilterUserPositions', {
            state: 'Open,Proposed',
            take: 200,
          })
          .then((positions) => {
            store.dispatch('positions/setAllPositions', positions);

            // load history later
            setTimeout(() => {
              connApp
                .invoke('FilterUserPositions', {
                  state: 'History',
                  take: 200,
                })
                .then(async (positions) => {
                  const openPositions =
                    store.getters['positions/getPositionsByType'][
                      PositionTypes.open
                    ];
                  const pendingPositions =
                    store.getters['positions/getPositionsByType'][
                      PositionTypes.pending
                    ];

                  store.dispatch('positions/setAllPositions', [
                    ...openPositions,
                    ...pendingPositions,
                    ...positions,
                  ]);

                  const historyStats = await connApp.invoke(
                    'GetClosedPositionsStats'
                  );

                  store.commit(
                    'session/SET_USER_TRADE_INFO_HISTORY',
                    historyStats
                  );
                });
            }, 1000);
          });
      }, 4000);
    }
    checkTimer = setInterval(() => {
      if (
        !logined &&
        (store.getters['connectors/activeConnect'].provider === 'Fiorin'
          ? frames.fiorin
          : true) &&
        store.getters['connectors/activeConnect'].accessToken
      ) {
        setTimeout(() => {
          onConnect(store);
        }, 700);
        clearInterval(checkTimer);
      } else {
        if (!store.getters['connectors/activeConnect'].accessToken) {
          setTimeout(() => {
            onConnect(store);
          }, 1500);
        }
      }
    }, 1500);
  });
}
