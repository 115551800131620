/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'arrowleft': {
    width: 9,
    height: 14,
    viewBox: '0 0 9 14',
    data: '<path pid="0" d="M6.559 0l1.51 1.509-5.05 5.05 5.05 5.049-1.51 1.509L0 6.559 6.559 0z" _fill="#fff"/>'
  }
})
