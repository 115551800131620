/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'low': {
    width: 32.122,
    height: 22.149,
    viewBox: '0 0 32.122 22.149',
    data: '<path pid="0" _fill="#FF3030" d="M28.398 9.653l.002 6.187L16.877 4.317l-5.793 5.793L0 0l11.084 15.284 5.793-5.793 8.936 8.936-6.187-.002 3.725 3.724h8.771v-8.772z"/>'
  }
})
