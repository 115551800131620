<template>
  <div>
    <div v-if="isBounty">
      <BountyButton
        :loading="bountyTxLoading"
        :margin-btn-value="
          toUSDMarginFormat(+formData.amountBtc / +formData.leverage, 2)
        "
        :fiorin-mode="fiorinMode"
        :margin="marginBsv"
        @onClick="openBountyPosition"
      />
    </div>
    <div v-else>
      <div v-if="activeConnect.provider === userProviders.fiorin">
        <!-- FIORIN -->
        <HandCash
          :loading="handcashTxLoading"
          :margin-btn-value="
            toUSDMarginFormat(+formData.amountBtc / +formData.leverage, 2)
          "
          :margin="marginBsv"
          is-usd
          @onClick="openFiorinPosition"
        />
      </div>
      <div v-if="activeConnect.provider === userProviders.handCash">
        <HandCash
          :loading="handcashTxLoading"
          :margin-btn-value="marginValueCustom"
          :margin="marginValueCustom"
          @onClick="openHandCashPosition"
        />
      </div>
      <div
        v-if="activeConnect.provider === userProviders.dotWallet"
        style="width: 210px"
      >
        <DotwalletButton
          :address="to"
          :amount="+marginBsv"
          :opreturn="transactionKey.toHex()"
          product-name="Position"
          @payment="onPayment"
          @error="$emit('error')"
        />
      </div>

      <div v-if="activeConnect.provider === userProviders.moneyButton">
        <MoneyButton
          v-if="msg.indexOf(' ') === -1"
          :outputs="[
            {
              to: to,
              amount: +marginBsv,
              currency: defaultCurrency,
            },
            {
              script: transactionKey.toAsm(),
              amount: '0',
              currency: defaultCurrency,
            },
          ]"
          @payment="onPayment"
          @error="onError"
          @load="() => {}"
        />
      </div>
      <div v-if="activeConnect.provider === userProviders.relayX">
        <RelayX
          v-if="msg.indexOf(' ') === -1"
          label="OPEN"
          :outputs="[
            {
              to: to,
              amount: +marginBsv,
              currency: defaultCurrency,
            },
            {
              script: transactionKey.toAsm(),
              amount: '0',
              currency: defaultCurrency,
            },
          ]"
          @payment="onPayment"
          @error="onError"
          @load="() => {}"
        />
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-ignore */
import { mapGetters } from 'vuex';
import { defineComponent } from 'vue';
import { userProviders } from '@/modules/user/api';
import { connApp, getErrorDetails } from '@/helpers/signalR';
import HandCash from '@/components/HandCashButton';
import BountyButton from '@/components/BountyButton';
import MoneyButton from '@/components/MoneyButton/MoneyButton';
import DotwalletButton from '@/components/DotwalletButton/DotwalletButton';
import { proposeBountyPosition } from '@/modules/bounty/api';
import RelayX from '@/components/RelayXButton/RelayXButton';
import { fiorinStables } from '@/config/constants';

import {
  paymentUID,
  toCurrencyFormat,
  toUSDMarginFormat,
  toSentenceCase,
  trim,
} from '@/helpers/utils';
import envConfig from '@/config';
import notify from '@/plugins/notify';
import { PositionSides } from '@/config/constants';
import bsvPrice from '@/compositions/bsvPrice';
import useUserActions from '@/compositions/useUserActions';
import { NullDataBuilder } from 'dxs-stas-sdk/dist/script/build/null-data-builder';
const FIORIN_HOST = process.env.VUE_APP_FIORIN_HOST;

export default defineComponent({
  setup(_, { emit }) {
    const userComposition = useUserActions();

    const onSuccess = () => {
      if (
        userComposition.isHandCashProfile.value &&
        !userComposition.isHasEverOpenedPosition.value
      ) {
        userComposition.notifyHandCashBalance();
      }
    };

    return { emit, onSuccess, toUSDMarginFormat };
  },
  emits: ['error'],
  components: {
    HandCash,
    MoneyButton,
    RelayX,
    BountyButton,
    DotwalletButton,
  },
  props: {
    formData: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      default: PositionSides.BUY,
    },
    to: {
      type: String,
      default: '',
    },
    amount: {
      type: [String, Number],
      default: 0.01,
    },
    currency: {
      type: String,
      default: 'USD',
    },
    marginValueCustom: {
      required: true,
    },
    isBounty: Boolean,
  },
  data() {
    return {
      userProviders,
      handcashTxLoading: false,
      bountyTxLoading: false,
      defaultCurrency: 'BSV',
      marketId: null,
    };
  },
  computed: {
    ...mapGetters({
      activeMarketId: 'markets/activeMarketId',
      activeMarket: 'markets/activeMarket',
      marketsList: 'markets/markets',
      provider: 'user/provider',
      paymail: 'user/loginPaymail',
      authInfo: 'user/authInfo',
      getUserDetails: 'handCash/getUserDetails',
      floatList: 'floatList/getFloatList',
      activeConnect: 'connectors/activeConnect',
      accountBalance: 'user/getAccountBalance',
      fiorinLastTx: 'fiorin/fiorinLastTx',
      fiorinMode: 'burger/fiorinModeFlag',
    }),
    marginBsvValue() {
      const margin =
        +this.formData.amountBtc.replace(/ /g, '') /
        bsvPrice() /
        `${this.formData.leverage}`.replace(/ /g, '');
      return margin;
    },
    marginFiorinValue() {
      const margin =
        +this.formData.amountBtc.replace(/ /g, '') /
        `${this.formData.leverage}`.replace(/ /g, '');
      return toUSDMarginFormat(margin, 2);
    },
    marginFromTokenId() {
      const findMarket =
        fiorinStables.includes(
          this.activeConnect?.tokenIdCurrency?.toLowerCase()
        ) &&
        this.marketsList.find(
          (item) =>
            item.name.toLowerCase() ===
            `${this.activeConnect?.tokenIdCurrency?.toLowerCase()}/usd`
        );
      const priceMarket = findMarket?.usdPrice || 1;
      console.log('REFILL LOG market token id find price', priceMarket);
      return this.marginFiorinValue / priceMarket;
    },
    marginBsv() {
      return toCurrencyFormat(this.marginBsvValue);
    },
    msg() {
      let msg =
        paymentUID() +
        ',m' +
        this.marketId +
        ',t' +
        (this.type === PositionSides.BUY ? 'b' : 's') +
        ',a' +
        (this.formData.amountBtc && this.formData.amountBtc.replace(/ /g, ''));

      if (this.formData.entryPrice) {
        msg += ',e' + this.formData.entryPrice.replace(/ /g, '');
      }
      if (this.formData.stopLoss && +this.formData.stopLoss >= 0) {
        msg += ',s' + this.formData.stopLoss.replace(/ /g, '');
      }
      if (this.formData.takeProfit) {
        msg += ',p' + this.formData.takeProfit.replace(/ /g, '');
      }
      return msg;
    },
    transactionKey() {
      return new NullDataBuilder([
        Buffer.from(envConfig.txPrefix),
        Buffer.from(this.msg),
        Buffer.from('text'),
        Buffer.from('B'),
      ]);
    },
  },
  created() {
    this.marketId = this.activeMarketId;
  },
  watch: {
    fiorinLastTx(fiorinLastTx) {
      if (fiorinLastTx) {
        this.onPayment({ txid: fiorinLastTx, isFiorin: true });
        this.$store.dispatch('fiorin/setFiorinLastTx', '');
        frames.fiorin.postMessage({ event: 'GET_BALANCE' }, FIORIN_HOST);
        this.handcashTxLoading = false;
      }
    },
  },
  methods: {
    async openBountyPosition() {
      if (this.bountyTxLoading) {
        return;
      }
      if (!this.activeConnect.paymailAddress && !this.paymail) {
        notify({ text: 'Paymail not found', type: 'info' });
        return;
      }

      this.bountyTxLoading = true;
      const msg = `${this.msg},d${
        this.activeConnect.paymailAddress || this.paymail
      }`;
      const txMsg = new NullDataBuilder([
        Buffer.from(envConfig.txPrefix),
        Buffer.from(msg),
        Buffer.from('text'),
        Buffer.from('B'),
      ]).toHex();

      const result = await proposeBountyPosition(
        txMsg,
        this.marginFiorinValue
      ).catch((err) => {
        notify({ text: getErrorDetails(err).message, type: 'info' });
      });

      notify({
        text: this.$t('order.new.toasts.opening'),
        type: 'info',
        group: 'opened',
      });

      if (this.fiorinMode) {
        this.bountyTxLoading = false;
        this.onSuccess();
        this.emit('payment');
        return;
      }
      if (result.txId) {
        await this.onPayment({
          txid: result.txId,
          msg,
          isBounty: true,
        });
        this.bountyTxLoading = false;
      }
      if (result.error) {
        this.bountyTxLoading = false;
        notify({
          text: result.error.message || 'Bounty wallet: unknown pay error',
          type: 'info',
        });
      }
    },
    async openFiorinPosition() {
      if (!this.msg) {
        notify({
          text: 'Message error. Try again later.',
          type: 'info',
        });
        return;
      }
      const msg = `${envConfig.txPrefix} ${this.msg} text B`;

      if (this.handcashTxLoading) {
        return;
      }

      this.handcashTxLoading = true;

      console.log(
        'REFILL LOG',
        this.activeConnect.tokenIdCurrency,
        this.activeConnect.tokenId,
        this.marginFiorinValue,
        this.marginFromTokenId
      );
      frames.fiorin.postMessage(
        {
          event: 'REFILL',
          payload: {
            tokenId: this.activeConnect?.tokenId || '',
            amount: this.marginFromTokenId + '',
            message: msg,
            type: 'ACCOUNT',
          },
          // payload: { amount: +this.marginBsv, message: msg, type: 'ACCOUNT' },
        },
        FIORIN_HOST
      );
    },
    async openHandCashPosition() {
      if (!this.msg) {
        notify({
          text: 'Message error. Try again later.',
          type: 'info',
        });
        return;
      }
      if (this.handcashTxLoading) {
        return;
      }
      this.handcashTxLoading = true;

      const msg = `${envConfig.txPrefix} ${this.msg} text B`
        .split(' ')
        .map((item) => Buffer.from(item).toString('hex'));

      const form = this.formData;

      console.log('margin final save', +this.marginValueCustom);
      console.log('margin final actual', +this.marginBsvValue);

      const result = await this.activeConnect.sendHandCashTx({
        msg,
        to: this.to,
        amount: +this.marginValueCustom,
        description: trim(
          `${toSentenceCase(this.type)} $${form.amountBtc} of ${
            this.activeMarket.name
          }`,
          25
        ), // HC limit - 25 characters
      });

      if (result.transactionId) {
        this.onPayment({
          txId: result.transactionId,
        });
        this.handcashTxLoading = false;
      }

      if (result.error) {
        this.handcashTxLoading = false;
        notify({
          text: result.error.message || 'Wallet error',
          type: 'info',
        });
      }
    },
    async onPayment(data) {
      let error;

      await connApp
        .invoke('ProposeUserPosition', {
          bsvPrice: bsvPrice(),
          tokenId: this.activeConnect.tokenId || '',
          TransactionId: data.txid || data.txId,
          TransactionMessage: data.msg || this.msg,
          TransactionAmount: data.isFiorin
            ? this.marginFromTokenId
            : this.marginValueCustom, // this.marginBsvValue,
          IsBounty: data.isBounty || false,
        })
        .catch((e) => {
          error = e;
          // notify({
          //   text: `Check pay parameters. ${e}`,
          //   type: 'info',
          // });
          this.emit('error');
        });
      if (!error) {
        notify({
          text: this.$t('order.new.toasts.opening'),
          type: 'info',
          group: 'opened',
        });

        this.onSuccess();
        this.emit('payment');
      }
    },
    onError() {
      this.emit('error');
    },
  },
});
</script>
