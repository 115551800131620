import { isIOSDxsApp } from '@/helpers/detects';
import { computed } from 'vue';
import { useStore } from 'vuex';

export const Theme = {
  dark: 'dark',
  light: 'light',
};

export default function useTheme() {
  const store = useStore();
  const storeTheme = computed(() => {
    const settings = store.getters['settings/uiSetting'];

    return isIOSDxsApp() ? Theme.dark : settings('theme');
  });
  const localStorageTheme = isIOSDxsApp()
    ? Theme.dark
    : localStorage.getItem('tdxp.uiSettings.theme') || Theme.light;

  return { storeTheme, localStorageTheme };
}
