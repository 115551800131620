import MBRedirect from '@/components/app/MBRedirect.vue';
import config from '@/config';
import store from '@/store';
import { transformTokenDataClientStorage } from '@/modules/user/api/moneyButton';
import { userProviders } from '@/modules/user/api';

export const MBRoutes = [
  {
    path: '/auth/mb/redirect',
    name: 'MB Redirect',
    beforeEnter: function (to, from, next) {
      import('@moneybutton/api-client').then(({ MoneyButtonClient }) => {
        const client = new MoneyButtonClient(config.mbOauthId);

        client.handleAuthorizationResponse();

        store.commit('user/UPDATE_AUTH_DATA', {
          data: transformTokenDataClientStorage(),
          provider: userProviders.moneyButton,
        });

        next('/');
      });
    },
    component: MBRedirect,
  },
];
