import { scope } from '@/breakpoints';
import { computed } from 'vue';

export const useScope = () => {
  const isNotDesktop = computed(() => {
    return scope.noMatch || scope.isSmall || scope.isMedium;
  });

  return { isNotDesktop };
};
