/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'account_off': {
    width: 17,
    height: 17,
    viewBox: '0 0 17 17',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M6 0a6 6 0 00-6 6v5c0 1.646.662 3.136 1.735 4.22.308-1.644 1.071-2.862 2.11-3.707C5.188 10.418 6.916 10.01 8.5 10.01c1.583 0 3.31.407 4.656 1.502 1.038.845 1.801 2.063 2.109 3.707A5.981 5.981 0 0017 11V6a6 6 0 00-6-6H6zm8.081 16.15c-.155-1.755-.848-2.898-1.746-3.63-1.046-.85-2.45-1.21-3.835-1.21-1.385 0-2.79.36-3.835 1.21-.898.732-1.59 1.875-1.746 3.63.9.54 1.954.85 3.081.85h5c1.127 0 2.18-.31 3.081-.85zM6.743 5.345c0-1.1.826-1.918 1.757-1.918.931 0 1.757.818 1.757 1.918 0 .981-.4 1.413-.767 1.636a2.113 2.113 0 01-.99.283c-.106 0-.573-.03-.99-.283-.367-.223-.767-.655-.767-1.636zM8.5 2.128c-1.727 0-3.057 1.481-3.057 3.218 0 1.438.64 2.29 1.393 2.748.704.426 1.441.471 1.664.471.223 0 .96-.045 1.664-.471.754-.458 1.393-1.31 1.393-2.748 0-1.737-1.33-3.218-3.057-3.218z" _fill="#fff"/>'
  }
})
