<template>
  <Modal :stop-mobile-max-height="true" @close="$emit('close')">
    <Card
      :small-paddings="true"
      :title="$t('popups.liquidityFundraiser.mechanics').toUpperCase()"
      class="card"
      :body-class="'card-full'"
    >
      <div class="mechanics">
        <div class="head-title mt-15">
          {{ $t('popups.liquidityFundraiser.mechanism') }}
        </div>
        <p>{{ $t('popups.liquidityFundraiser.tradingPositions') }}</p>
        <!-- i -->
        <img :src="imgMechanics" class="img" />
        <div class="head-title mt-15">
          {{ $t('popups.liquidityFundraiser.role') }}
        </div>
        <p>{{ $t('popups.liquidityFundraiser.mech0') }}</p>
        <div class="head-title mt-15">
          {{ $t('popups.liquidityFundraiser.risk') }}
        </div>
        <p>
          {{ $t('popups.liquidityFundraiser.mech1') }}
          <span @click="$emit('showRefundModal')"
            >{{ $t('popups.liquidityFundraiser.mech2') }}
          </span>
          {{ $t('popups.liquidityFundraiser.mech3') }}
        </p>
        <p>{{ $t('popups.liquidityFundraiser.mech4') }}:</p>
        <p>{{ $t('popups.liquidityFundraiser.mech5') }}</p>
        <p>{{ $t('popups.liquidityFundraiser.mech6') }}</p>
        <p>{{ $t('popups.liquidityFundraiser.mech7') }}</p>
        <p>{{ $t('popups.liquidityFundraiser.mech8') }}</p>

        <template v-if="showLinks">
          <div class="head-title mt-15">
            {{ $t('popups.liquidityFundraiser.links') }}
          </div>
          <UsefulLinks
            :hide-explorer="true"
            :hide-mechanics="true"
            @showRefundModal="$emit('showRefundModal')"
            @showAllocationModal="$emit('showAllocationModal')"
          />
        </template>
      </div>
    </Card>
    <Button
      :text="$t('popups.buttons.close')"
      type="secondary"
      class="btn"
      @click="$emit('close')"
    />
  </Modal>
</template>
<script>
import Modal from '@/modules/modals/Modal';
import Card from '@/components/ui/Card';
import Button from '@/components/ui/Button';
import UsefulLinks from './UsefulLinks.vue';
import mechanicsIcon from '@/assets/mechanics.png';
import { ref } from 'vue';

export default {
  components: { Modal, Card, Button, UsefulLinks },
  emits: ['close', 'showRefundModal', 'showAllocationModal'],
  props: {
    noLinks: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const showLinks = !props.noLinks;
    const imgMechanics = ref(mechanicsIcon);
    return {
      imgMechanics,
      showLinks,
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/colors';
@import '@/assets/styles/base';

.mechanics {
  height: calc(100vh - 160px);
  margin-bottom: 60px;
  padding: 0 30px;
  font-family: Gotham_Pro_Regular;
  line-height: 20px;
  color: #fff;
  overflow-y: auto;
  background-color: rgb(18, 23, 34);

  @media screen and (max-width: 1024px) {
    max-height: calc(100% - 60px);
  }

  .img {
    width: 100%;
    height: auto;
  }

  p {
    color: #fff;

    span {
      text-decoration-line: underline;
      color: #fff;
    }
  }

  .head-title {
    margin-bottom: 1rem;
    font-family: Gotham_Pro_Bold;
    margin-top: 40px;
    margin-bottom: 5px;
  }

  p {
    &.underline {
      text-decoration-line: underline;
    }
  }

  div {
    margin-top: 25px;
  }

  span {
    text-decoration-line: underline;
    cursor: pointer;
  }
}

.btn {
  margin-top: 30px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
</style>
