/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'gonext': {
    width: 9,
    height: 14,
    viewBox: '0 0 9 14',
    data: '<path pid="0" d="M1.775 13.559l-1.51-1.51L5.315 7 .265 1.952 1.775.44 8.335 7l-6.56 6.559z" _fill="#fff"/>'
  }
})
