/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'markets_on': {
    width: 17,
    height: 17,
    viewBox: '0 0 17 17',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M6 0a6 6 0 00-6 6v5.03l1.15-1.313.593-.676.677.592.796.696L5.5 7.579l.605-.73.697.643 2.059 1.9 4.902-5.66.618-.713.683.65.975.928.535-.605.116.103A6.003 6.003 0 0011 0H6zm11 6.227l-.232.261-.618.698-.676-.643-.967-.92-4.892 5.649-.608.702-.683-.63L6.28 9.458 4.01 12.19l-.59.71-.695-.607-.813-.713L.44 13.26A6.002 6.002 0 006 17h5a6 6 0 006-6V6.227z" _fill="#fff"/>'
  }
})
