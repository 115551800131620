import { queryPositions } from '@/modules/positions/api';

import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { PositionTypes } from '@/modules/positions/helpers';

export const usePositionsScroll = () => {
  const store = useStore();
  const loadFinished = ref(false);

  const openPositions = computed(
    () => store.getters['positions/getPositionsByType'][PositionTypes.open]
  );
  // const pendingPositions = computed(
  //   () => store.getters['positions/getPositionsByType'][PositionTypes.pending]
  // );

  const loadMorePositions = async () => {
    if (loadFinished.value) {
      return;
    }
    const skip = openPositions.value?.length; // + pendingPositions.value?.length;

    const positions = await queryPositions({
      state: 'Open,Proposed', // 'Open,Proposed',
      take: 200,
      skip,
    });

    const positionsHistory = await queryPositions({
      state: 'History',
      take: 200,
    });

    if (positions.length) {
      store.dispatch('positions/setAllPositions', [
        ...openPositions.value,
        // ...pendingPositions.value,
        ...positions,
        ...positionsHistory,
      ]);
    } else {
      loadFinished.value = true;
    }

    console.log(
      'load more positions',
      positions,
      openPositions.value.length // + pendingPositions.value.length
    );
  };

  return {
    loadMorePositions,
  };
};
