<template>
  <Modal :stop-mobile-max-height="true" @close="close" corner>
    <Card :title="'INSTALL WEBAPP'" class="card">
      <div class="pwa-modal">
        <div class="paragraph">
          <div class="install-title">
            Install this webapp on your iPhone: tap<br />
            <!-- <div class="separator" /> -->
            and then Add to homescreen.
          </div>
          <Icon
            name="ios_share"
            view-box="0 5 60 90"
            width="24"
            height="24"
            color="#888"
            class="icon"
          />
        </div>
        <video class="video-wrap" loop autoplay muted playsinline>
          <source src="@/assets/video/pwa.mp4" type="video/mp4" />
          <!-- <source src="@/assets/video/pwa.webm" type="video/webm">-->
          <p>
            Sorry, there's a problem playing this video. Please try using a
            different browser.
          </p>
        </video>
      </div>
    </Card>
    <Button
      v-if="false"
      :text="$t('standalone.btn')"
      type="secondary"
      class="pwa-btn"
      @click="close"
    />
  </Modal>
</template>
<script>
import Modal from '@/modules/modals/Modal';
import Card from '@/components/ui/Card';
import Button from '@/components/ui/Button';
import Icon from '@/components/ui/Icon';

import '@/assets/icons/ios_share';

const EVENTS = {
  close: 'close',
};

export default {
  components: { Modal, Card, Button, Icon },
  emits: [EVENTS.close],
  setup(_, { emit }) {
    const close = () => {
      localStorage.setItem('showPWAModal', true);
      emit(EVENTS.close);
    };

    return {
      close,
      EVENTS,
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/colors';
@import '@/assets/styles/base';

.pwa-modal {
  height: 500px;
  overflow-y: auto;
  // margin-bottom: 45px;
  // padding-right: 15px;
  box-sizing: border-box;
  font-family: Gotham_Pro_Regular;
  line-height: 20px;
  overflow: hidden;

  .video-wrap {
    width: 100%;
    // height: 100%;
    overflow: hidden;
    position: absolute;
    left: 0;
    bottom: 2px;
    // width: 100%;
    // height: calc(100% - 20px);
  }

  .paragraph {
    position: relative;

    .separator {
      display: inline-block;
      margin-right: -2px;
    }

    div {
      display: inline-block;
    }

    .icon {
      position: absolute;
      left: 315px;
      bottom: 19px;
    }

    &.underline {
      text-decoration-line: underline;
    }
  }

  div {
    margin-top: 10px;

    h4 {
      font-family: Gotham_Pro_Bold !important;
    }
  }

  span {
    text-decoration-line: underline;
    cursor: pointer;
  }
}

.pwa-btn {
  margin-top: 30px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
</style>
