<template>
  <div
    :class="{
      buy: formType === PositionSides.BUY,
      sell: formType === PositionSides.SELL,
      closedMarket,
    }"
    class="positon-order-btns"
  >
    <div
      class="positon-order-btns__btn buy"
      @click="changeFormType(PositionSides.BUY)"
    >
      <div class="positon-order-btns__btn-title">
        {{ $t('order.sides.buy') }} {{ marketTicker(activeMarket.name) }}
      </div>
      <div v-if="closedMarket" class="positon-order-btns__btn-sub-title">
        {{ $t('popups.marketInactive.marketClosed') }}
      </div>
      <div v-else class="positon-order-btns__btn-sub-title">
        {{ formType === PositionSides.BUY ? 'HIDE' : priceBuy }}
        <Icon
          v-if="formType !== PositionSides.BUY"
          name="high"
          width="12"
          height="12"
          class="side-icon"
        />
      </div>
    </div>
    <div
      class="positon-order-btns__btn sell"
      @click="changeFormType(PositionSides.SELL)"
    >
      <div class="positon-order-btns__btn-title">
        {{ $t('order.sides.sell') }} {{ marketTicker(activeMarket.name) }}
      </div>
      <div v-if="closedMarket" class="positon-order-btns__btn-sub-title">
        {{ $t('popups.marketInactive.marketClosed') }}
      </div>
      <div v-else class="positon-order-btns__btn-sub-title">
        {{ formType === PositionSides.SELL ? 'HIDE' : priceSell }}
        <Icon
          v-if="formType !== PositionSides.SELL"
          name="low"
          width="12"
          height="12"
          class="side-icon"
        />
      </div>
    </div>
  </div>
</template>
<script>
import '@/assets/icons/high';
import '@/assets/icons/low';

import Icon from '@/components/ui/Icon';
import useMarketCoin from '@/compositions/useMarketCoin';
import { PositionSides } from '@/config/constants';
import { marketTicker } from '@/config/wrapMarkets';
import { useStore } from 'vuex';

export default {
  name: 'formPositionBuySell',
  components: { Icon },
  props: {
    formType: { required: true },
    activeMarket: { required: true },
    priceBuy: { required: true },
    priceSell: { required: true },
    closedMarket: { required: true },
  },
  setup(props, { emit }) {
    const store = useStore();
    const { marketHold, isMarketActive } = useMarketCoin();

    const toggleForm = () => {
      store.dispatch('positions/toggleFormPosition', true);
    };

    const changeFormType = (type) => {
      if (
        marketHold(props.activeMarket) &&
        isMarketActive(props.activeMarket)
      ) {
        emit('onConnectionLost');
        return;
      }

      // inactive
      if (props.closedMarket) {
        emit('onInactiveBuySell');
        return;
      }

      if (type === props.formType) {
        toggleForm();
        return;
      }

      if (props.formType === PositionSides.BUY) {
        emit('showForm', PositionSides.SELL);
        return;
      }
      if (props.formType === PositionSides.SELL) {
        emit('showForm', PositionSides.BUY);
        return;
      }

      emit('showForm', type);
    };

    return {
      changeFormType,
      PositionSides,
      marketTicker,
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/base';
@import '@/assets/styles/colors';

.positon-order-btns {
  display: flex;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;

  &__btn {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease-in-out;
    color: $color-white;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }

  &__btn-title {
    font-family: Gotham_Pro_Bold;
    margin-bottom: 0.3125rem;
    font-size: 0.9375rem;
    font-weight: 800;
    text-align: center;
    white-space: nowrap;
  }

  &__btn-sub-title {
    display: flex;
    align-items: center;
    font-family: Cantarell_Regular;
    font-size: 0.6875rem;

    .side-icon {
      margin-left: 3px;
    }

    span {
      margin-right: 3px;
    }
  }
}

.positon-order-btns {
  &.closedMarket {
    &__btn-sub-title {
      font-family: Cantarell_Medium;
    }

    .positon-order-btns__btn.sell {
      color: $color-white;
    }
    .positon-order-btns__btn.buy {
      color: $color-white;
    }
  }
}

.body--light {
  .positon-order-btns {
    &.closedMarket {
      &__btn-sub-title {
        font-family: Cantarell_Medium;
      }

      .positon-order-btns__btn.sell {
        // background: $color-gray-lightness;
        color: $color-gray;
      }
      .positon-order-btns__btn.buy {
        // background: $color-gray-lightness;
        color: $color-gray;
      }
    }
  }
}
</style>
