/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'search': {
    width: 14.109,
    height: 14.11,
    viewBox: '0 0 14.109 14.11',
    data: '<path pid="0" d="M14.109 13.191l-3.228-3.23A6.065 6.065 0 0012.24 6.12a6.077 6.077 0 00-1.794-4.327A6.076 6.076 0 006.12 0a6.085 6.085 0 00-4.329 1.793 6.126 6.126 0 00.003 8.653 6.078 6.078 0 004.326 1.793 6.072 6.072 0 003.843-1.359l3.227 3.229.919-.918zM2.713 9.526a4.825 4.825 0 01-.003-6.814A4.794 4.794 0 016.12 1.3c1.286 0 2.497.501 3.408 1.413.911.91 1.413 2.121 1.413 3.407s-.502 2.497-1.412 3.407c-.912.911-2.123 1.412-3.409 1.412s-2.496-.501-3.407-1.413z"/>'
  }
})
