<template>
  <Modal stop-mobile-max-height @close="onClose">
    <Card title="YOU RECEIVED BOUNTY MONEY" class="card">
      <div class="bounty-modal">
        <p>
          {{
            $t('bountyModal.received', { amount: bountyBalanceWithLeverage })
          }}
        </p>
      </div>
    </Card>
    <div class="btns">
      <Button
        :text="$t('bountyModal.understand')"
        type="secondary"
        class="btn"
        @click="onClose"
      />
    </div>
  </Modal>
</template>
<script>
import Modal from '@/modules/modals/Modal';
import Card from '@/components/ui/Card';
import Button from '@/components/ui/Button';
import '@/assets/icons/check';
import { useStore } from 'vuex';
import { computed, onMounted, ref } from 'vue';
import { setModalFlag } from '@/helpers/storage';
import { useModals } from '@/modules/modals/api';
const EVENTS = {
  close: 'close',
};

export default {
  components: { Modal, Card, Button },
  emits: [EVENTS.close],
  setup(_, { emit }) {
    const store = useStore();
    const startLoading = ref(false);

    const { modalsByName } = useModals();

    const bountyBalanceWithLeverage = computed(() =>
      ((store.getters['bounty/balance'] || 0) * 10).toFixed(1)
    );

    const authInfo = computed(() => store.getters['user/truedexAuth']);

    const onClose = async () => {
      await store.dispatch('bounty/updateBountyTasks');
      await store.dispatch('bounty/updateBalance');

      setModalFlag(modalsByName.bountyReceived, true);

      emit(EVENTS.close);
    };

    onMounted(async () => {
      if (authInfo?.value?.userId) {
        await store.dispatch('bounty/updateBountyTasks');
        store.dispatch('bounty/updateBalance');
      }
    });

    return {
      onClose,
      startLoading,
      bountyBalanceWithLeverage,
      EVENTS,
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/colors';
@import '@/assets/styles/base';

.bounty-modal {
  height: auto;
  overflow-y: auto;
  margin-bottom: 45px;
  padding-right: 15px;
  box-sizing: border-box;
  font-family: Gotham_Pro_Regular;
  line-height: 20px;

  div {
    margin-top: 25px;

    h4 {
      font-family: Gotham_Pro_Bold !important;
    }
  }

  &__issue {
    display: flex;
    align-items: center;
    margin-top: 10px;

    &.last {
      margin-bottom: 20px;
    }

    .check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      border-radius: 4px;
      margin-top: 0;
      border: 1px solid #ccc;
    }

    .label-description {
      margin-top: 1px;
      margin-left: 10px;
    }
  }

  p {
    &.underline {
      text-decoration-line: underline;
    }
  }

  span {
    text-decoration-line: underline;
    cursor: pointer;
  }
}

.btns {
  width: 100%;
  margin-top: 30px;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  a {
    text-decoration: none;
  }
}

.bounty-btn {
  margin-top: 30px;
  position: absolute;
  text-transform: initial;
  bottom: 0;
  left: 0;
  width: 100%;
}
</style>
