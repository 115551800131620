<template>
  <TableRow
    v-if="item.list.length && showPositionGroup"
    :dataset="dataset"
    :stop-behavior="true"
    class="positions-list-item-wrapper"
    :class="{
      history: filterType === PositionTypes.history,
    }"
    :style="{
      display: grid ? 'grid' : '',
      'grid-template-columns': grid,
    }"
  >
    <template v-slot:1>
      <div class="item market wide">
        {{
          cutAndSetComma(
            `${marketLabel(item.data.market)} ${item.data.side.toUpperCase()}`,
            opened
          )
        }}
      </div>
    </template>
    <template v-slot:2>
      <div v-if="showStats || !opened" class="item market cantarell">
        {{ groupTotalAmount }}
      </div>
    </template>
    <template v-slot:3>
      <div v-if="!isMobile">
        <div
          v-if="(showStats || !opened) && filterType !== PositionTypes.history"
          class="item market cantarell"
        >
          {{ entrySummary }}
        </div>
      </div>
      <div v-else class="center-icon">
        <div
          v-if="
            groupTotalPL !== '?' &&
            (showStats || !opened) &&
            filterType !== PositionTypes.pending
          "
          :class="{ down: groupTotalPLValue < 0 }"
          class="item market cantarell up"
        >
          {{ groupTotalPL.replaceAll(' ', '') > 0 ? '+' : ''
          }}{{ shortBigNumber(groupTotalPL) }}
        </div>
        <icon
          v-if="filterType === PositionTypes.history"
          :class="{ opened }"
          name="arrow_down"
          width="12"
          height="12"
          class="arrow-icon"
        />
      </div>
    </template>
    <template v-slot:4>
      <div v-if="!isMobile">
        <div
          v-if="
            (showStats || !opened) &&
            ![PositionTypes.open, PositionTypes.pending].includes(filterType) &&
            filterType !== PositionTypes.history
          "
          class="item market cantarell"
        >
          {{ closeSummary }}
        </div>
      </div>
      <div v-else-if="filterType !== PositionTypes.history" class="center-icon">
        <icon
          :class="{ opened }"
          name="arrow_down"
          width="12"
          height="12"
          class="arrow-icon"
        />
      </div>
    </template>
    <template v-slot:6>
      <div
        v-if="
          (showStats || !opened) &&
          ![PositionTypes.open, PositionTypes.pending].includes(filterType)
        "
        :class="{ down: groupTotalPLValue < 0 }"
        class="item market cantarell up"
      >
        {{ groupTotalPL.replaceAll(' ', '') > 0 ? '+' : ''
        }}{{ shortBigNumber(groupTotalPL) }}
      </div>
      <div
        v-if="
          groupTotalPL !== '?' &&
          (showStats || !opened) &&
          filterType === PositionTypes.open
        "
        :class="{ down: groupTotalPLValue < 0 }"
        class="item market cantarell up"
      >
        {{ groupTotalPL.replaceAll(' ', '') > 0 ? '+' : ''
        }}{{ shortBigNumber(groupTotalPL) }}
      </div>
      <icon
        :class="{ opened }"
        name="arrow_down"
        width="12"
        height="12"
        class="arrow-icon"
      />
    </template>
  </TableRow>
</template>
<script>
import '@/assets/icons/arrow_down';
import '@/assets/icons/star';
import TableRow from '@/components/ui/TableRow';
import { getFormatedAmount, toCurrencyFormat } from '@/helpers/utils';
import { computed } from 'vue';
import { marketLabel } from '@/config/wrapMarkets';
import { scope } from '@/breakpoints';
import { PositionTypes } from '../helpers';
import { floatList } from '@/helpers/floatList';
import store from '@/store';
import { shortBigNumber } from '@/helpers/utils';

export default {
  name: 'PositionsListItemWrapper',
  components: { TableRow },
  props: {
    filterType: {
      required: true,
    },
    dataset: {
      type: Object,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    opened: {
      type: Boolean,
      default: false,
    },
    grid: {
      type: String,
      default: '',
    },
    isMobile: {
      type: Boolean,
      required: false,
      default: false,
    },
    totalGroupAmount: {
      type: [Number, String],
      required: true,
    },
    userTradeInfo: {
      required: true,
    },
    userTradeInfoHistory: {
      required: true,
    },
    isDemo: {
      required: true,
    },
  },
  setup(props) {
    const showPositionGroup = computed(
      () => store.getters['localUiSettings/showPositionGroup']
    );

    const showStats = computed(() => props.item.list.length > 1);

    const amountTreeTotal = computed(() => {
      const amounts = {
        amountBuy:
          (props.userTradeInfo?.ms?.[props.item.data.marketId]?.b?.o?.a || 0) +
          (props.userTradeInfo?.ms?.[props.item.data.marketId]?.b?.p?.a || 0),
        amountSell:
          (props.userTradeInfo?.ms?.[props.item.data.marketId]?.s?.o?.a || 0) +
          (props.userTradeInfo?.ms?.[props.item.data.marketId]?.s?.p?.a || 0),
      };
      return amounts;
    });

    // eslint-disable-next-line vue/no-setup-props-destructure
    const groupTotalAmount = computed(() => {
      if (props.isDemo) {
        return getFormatedAmount(props.item.data.amount, true);
      }

      if (props.filterType === 'history') {
        if (props.item.data.side === 'buy') {
          const group = props.userTradeInfoHistory.find(
            (info) =>
              info.marketId === props.item.data.marketId && info.side === 'buy'
          );
          return getFormatedAmount(group?.amount || 0, true);
        }

        const group = props.userTradeInfoHistory.find(
          (info) =>
            info.marketId === props.item.data.marketId && info.side === 'sell'
        );
        return getFormatedAmount(group?.amount || 0, true);
      }

      if (props.item.data.side === 'buy') {
        return getFormatedAmount(amountTreeTotal.value.amountBuy, true);
      }

      return getFormatedAmount(amountTreeTotal.value.amountSell, true);
    });

    const groupTotalPLValue = computed(() => {
      if (props.isDemo) {
        return props.item.data.pl;
      }

      if (props.filterType === 'history') {
        if (props.item.data.side === 'buy') {
          const group = props.userTradeInfoHistory.find(
            (info) =>
              info.marketId === props.item.data.marketId && info.side === 'buy'
          );
          return group?.netBalance || 0;
        }

        const group = props.userTradeInfoHistory.find(
          (info) =>
            info.marketId === props.item.data.marketId && info.side === 'sell'
        );
        return group?.netBalance || 0;
      }

      // open/pending
      if (props.item.data.side === 'buy') {
        if (!props.userTradeInfo?.ms?.[props.item.data.marketId]?.b?.o?.pl) {
          return '?';
        }

        return props.userTradeInfo?.ms?.[props.item.data.marketId]?.b?.o?.pl;
      }

      if (!props.userTradeInfo?.ms?.[props.item.data.marketId]?.s?.o?.pl) {
        return '?';
      }
      return props.userTradeInfo?.ms?.[props.item.data.marketId]?.s?.o?.pl;
    });

    const groupTotalPL = computed(() => {
      if (props.isDemo) {
        return getFormatedAmount(props.item.data.pl, true);
      }

      if (props.filterType === 'history') {
        if (props.item.data.side === 'buy') {
          const group = props.userTradeInfoHistory.find(
            (info) =>
              info.marketId === props.item.data.marketId && info.side === 'buy'
          );
          return getFormatedAmount(group?.netBalance || 0, true);
        }

        const group = props.userTradeInfoHistory.find(
          (info) =>
            info.marketId === props.item.data.marketId && info.side === 'sell'
        );
        return getFormatedAmount(group?.netBalance || 0, true);
      }

      // open/pending
      if (props.item.data.side === 'buy') {
        // console.log(
        //   `${props.item.data.market}, ${props.item.data.side}, ${props.item.data.type}`,
        //   props.userTradeInfo?.ms?.[props.item.data.marketId]?.b?.o?.pl
        // );

        if (!props.userTradeInfo?.ms?.[props.item.data.marketId]?.b?.o?.pl) {
          return '?';
        }

        return getFormatedAmount(
          props.userTradeInfo?.ms?.[props.item.data.marketId]?.b?.o?.pl,
          true
        );
      }

      // console.log(
      //   `${props.item.data.market}, ${props.item.data.side}, ${props.item.data.type}`,
      //   props.userTradeInfo?.ms?.[props.item.data.marketId]?.s?.o?.pl
      // );
      if (!props.userTradeInfo?.ms?.[props.item.data.marketId]?.s?.o?.pl) {
        return '?';
      }
      return getFormatedAmount(
        props.userTradeInfo?.ms?.[props.item.data.marketId]?.s?.o?.pl,
        true
      );
    });

    const entrySummary = computed(() => {
      return toCurrencyFormat(
        props.item.data.entrySummary,
        null,
        floatList[props.item.data.market]
      );
      // return getFormatedAmount(data.entrySummary, true)
    });

    const closeSummary = computed(() =>
      getFormatedAmount(props.item.data.closedSummary, true)
    );

    const cutAndSetComma = (val, opened) => {
      if (!showStats.value && opened) {
        return val;
      }
      if (scope.isSmall || scope.isMedium || scope.noMatch) {
        return val.length > 13 ? `${val.slice(0, 13)}...` : val;
      }
      return val.length > 15 ? `${val.slice(0, 15)}...` : val;
    };

    return {
      showStats,
      groupTotalAmount,
      groupTotalPL,
      groupTotalPLValue,
      entrySummary,
      closeSummary,
      cutAndSetComma,
      PositionTypes,
      marketLabel,
      showPositionGroup,
      shortBigNumber,
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/colors';
@import '@/assets/styles/base';

.positions-list-item-wrapper {
  position: relative;
  height: 23px;
  padding-top: 1px;
  border-bottom: 1px solid #121722;
  display: flex;
  align-items: center;
  padding-left: 20px; // 11px;
  padding-right: 0;

  .arrow-icon {
    opacity: 0;
    position: absolute;
    right: 15px;
    transition: all 0.2s ease-in-out;
    transform: rotate(-90deg);

    &.opened {
      transform: rotate(0);
    }
  }
}

.center-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.market {
  font-family: Gotham_Pro_Bold;
  font-size: 0.875rem;

  &.wide {
    position: relative;
    left: -1.5rem;
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 10px;
    min-width: 50%;

    @media screen and (max-width: 1024px) {
      left: -2rem;
      transform: translateY(1px);
    }
  }

  &.cantarell {
    font-family: Cantarell_Bold;
    transform: translateY(-1px);
  }
}

body.twilight {
  .positions-list-item-wrapper {
    border-bottom: 1px solid #5a67a755;
  }
}

body.light {
  .positions-list-item-wrapper {
    border-bottom: 1px solid #fff;
  }
}
</style>
