import { computed } from 'vue';
import { useStore } from 'vuex';
import { userProviders } from '@/modules/user/api';

export const useFiorinBalanceNull = () => {
  const store = useStore();

  const accountBalance = computed(
    () => store.getters['user/getAccountBalance']
  );

  const activeConnect = computed(
    () => store.getters['connectors/activeConnect']
  );

  const firstAppLoaded = computed(
    () => store.getters['localUiSettings/firstAppLoaded']
  );

  const isFiorinNullableBalance = computed(() => {
    return (
      firstAppLoaded.value &&
      activeConnect.value.accessToken &&
      activeConnect.value.provider === userProviders.fiorin &&
      accountBalance.value.available == 0
    );
  });

  return { isFiorinNullableBalance };
};
