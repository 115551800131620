<template>
  <Modal @close="$emit('close')">
    <div class="weave-wrap">
      <Card :title="$t('popups.weave.title')" class="card">
        <div class="weave">
          <ItemLabelValue :label="$t('popups.weave.send')" />
          <div class="label-value-item">
            <div
              class="label-value-item__label text-secondary bold"
              @click="changeSourceCurrency"
            >
              {{ sourceCurrencyName }}
            </div>
            <div class="label-value-item__value bold weave__input">
              <SimpleInput
                :directive="'number'"
                :maxValue="tabsItems[2].amount"
                :minValue="tabsItems[0].amount"
                :value="sourceCurrencyAmount"
                @onChange="onSourceCurrencyAmountChange"
              />
            </div>
          </div>

          <div class="weave__tabs">
            <div
              v-for="(tab, ndx) in tabsItems"
              :key="ndx"
              :class="{
                border: true,
                'box-active': ndx === activeIndex,
                borderLeft: ndx === 1,
                borderRight: ndx === 1,
              }"
              class="weave__tabs__item box-hover text-secondary"
              @click="changeTab(ndx)"
            >
              {{ $t(tab.title) }}
            </div>
          </div>

          <hr />

          <ItemLabelValue :label="$t('popups.weave.iwant')" />
          <div class="label-value-item">
            <div class="label-value-item__label text-secondary bold">BSV</div>
            <div class="label-value-item__value bold weave__input">
              <SimpleInput :value="bsvAmount" :disabled="true" />
            </div>
          </div>
          <ItemLabelValue :label="infoLabel">
            <template v-slot:label-icon>
              <icon name="what" width="18" height="18" />
            </template>
          </ItemLabelValue>
        </div>
      </Card>
      <Button
        :text="$t('popups.weave.button')"
        type="secondary"
        class="btn"
        @click="$emit('close')"
      />
    </div>
  </Modal>
</template>
<script>
import '@/assets/icons/what';
import Modal from '@/modules/modals/Modal';
import Card from '@/components/ui/Card';
import Button from '@/components/ui/Button';
import useChangeFilter from '@/compositions/useChangeFilter';
import ItemLabelValue from '@/components/ui/ItemLabelValue';
import SimpleInput from '@/components/ui/SimpleInput';
// import
import { computed, ref } from 'vue';
import { useStore } from 'vuex';

export default {
  components: {
    Modal,
    Card,
    Button,
    ItemLabelValue,
    SimpleInput,
  },
  setup() {
    const store = useStore();
    const { activeIndex, onChangeTabIndex } = useChangeFilter();
    const initialPair = store.getters['markets/marketsByName']['BSV/BTC'];
    const sourceCurrencyName = ref('BTC');
    const sourceCurrencyAmount = ref(0.02558822);
    onChangeTabIndex(2);
    console.log(sourceCurrencyAmount);

    const tabsItems = [
      { title: 'popups.weave.tabs.min', amount: 0.00014082 },
      { title: 'popups.weave.tabs.half', amount: 0.01287173 },
      { title: 'popups.weave.tabs.max', amount: 0.02558822 },
    ];

    const initialPairKoef = computed(() => {
      return initialPair.usdPrice;
    });

    const bsvAmount = computed(() => {
      return (sourceCurrencyAmount.value / initialPairKoef.value).toFixed(8);
    });

    const infoLabel = computed(() => {
      return `1 ${sourceCurrencyName.value} = ${(
        1 / initialPairKoef.value
      ).toFixed(8)} BSV`;
    });

    const onSourceCurrencyAmountChange = (amount) => {
      sourceCurrencyAmount.value = amount;
      console.log(amount);
      const index = tabsItems.map((t) => t.amount).indexOf(+amount);
      if (index == -1) {
        onChangeTabIndex(-1);
      } else {
        onChangeTabIndex(index);
      }
    };

    // TODO: get theese values from backend
    const availableCurrencies = ['BTC', 'ETH'];

    const changeSourceCurrency = () => {
      console.log('change source currency');
    };

    const changeTab = (ndx) => {
      sourceCurrencyAmount.value = tabsItems[ndx].amount;
      onChangeTabIndex(ndx);
    };

    return {
      sourceCurrencyName,
      sourceCurrencyAmount,
      availableCurrencies,
      changeSourceCurrency,
      onSourceCurrencyAmountChange,
      activeIndex,
      changeTab,
      bsvAmount,
      infoLabel,
      tabsItems,
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/colors';
@import '@/assets/styles/base';

hr {
  border-bottom: 1px solid #ccc;
}

.weave-wrap {
  position: relative;
  .card {
    min-height: 350px;
  }

  .weave {
    &__info {
      font-family: Gotham_Pro_Regular;
      font-size: 0.9375rem;
      color: $color-gray;
      line-height: $default-lineheight;

      &.mt-20 {
        margin-top: 20px;
      }
    }

    &__tabs {
      display: flex;
      width: 100%;
      margin-bottom: 16px;
      border: 1px solid #ccc;

      &__item {
        flex: 1;
        height: 40px;
        line-height: 40px;
        font-family: Gotham_Pro_Medium;
        text-align: center;
        cursor: pointer;

        &.borderLeft {
          border-left: 1px solid #ccc;
        }
        &.borderRight {
          border-right: 1px solid #ccc;
        }
      }
    }

    &__input {
      max-width: 200px;
    }

    .label-value-item {
      width: 100%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-height: 18px;
      margin-bottom: 1rem;

      &__label {
        font-size: 1.25rem;
        text-align: left;
        font-family: Gotham_Pro_Regular;

        &.bold {
          font-family: Gotham_Pro_Regular;
          font-weight: 600;
        }
      }

      &__value {
        font-size: 0.9375rem;
        text-align: right;
        margin-top: -2px;
        font-family: Cantarell_Regular;

        &.bold {
          font-family: Cantarell_Bold;
        }
      }
    }

    .label-value-item.withHint {
      .label-value-item__label {
        text-decoration-line: underline;
        cursor: pointer;
      }
    }
  }

  .btn {
    margin-top: 30px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
}
</style>
