import positionModule from './store/module';
import mount from './store/mount';

export default function module({ store, options }) {
  if (!store) {
    return;
  }
  store.registerModule('positions', positionModule);
  mount(store);

  return options;
}
