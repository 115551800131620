<template>
  <div
    :class="{ autoWidth, openCard, smallPaddings, stopWebPaddings }"
    class="card box"
  >
    <div
      v-if="!link"
      :class="[titleClass, { stopWebPaddings }]"
      class="card__title"
    >
      <span class="decoration--underlined" @click="onTitleClick">
        {{ parseTitle(title) }}
      </span>
      <slot name="title" />
      <Icon
        v-if="isToggle"
        :class="{ openCard }"
        name="arrow_down"
        width="12"
        height="12"
        class="arrow-icon"
        @click="isToggle && toggleCard()"
      />
    </div>
    <div v-else class="card__title withLink">
      <a
        v-if="redirectLink"
        :href="link"
        rel="noopener noreferrer"
        target="_blank"
        >{{ parseTitle(title) }}</a
      >
      <span
        v-else
        @click.prevent="$emit('showLinks')"
        class="decoration--underlined"
        >{{ parseTitle(title) }}</span
      >
      <slot name="title" />
    </div>
    <div
      v-if="description"
      class="card__description"
      :class="{ ml20: descriptionLeft }"
    >
      {{ description }}
    </div>
    <div class="card__body" :class="[bodyClass, { stopWebPaddings }]">
      <slot />
    </div>
  </div>
</template>
<script>
import Icon from '@/components/ui/Icon.vue';
import useToggleCard from '@/compositions/useToggleCard';
import '@/assets/icons/arrow_down';

export default {
  name: 'Card',
  components: { Icon },
  props: {
    title: {
      type: String,
    },
    description: {
      type: String,
      default: '',
    },
    emitClickEvent: {
      type: String,
      default: '',
    },
    redirectLink: {
      type: Boolean,
      default: false,
    },
    isToggle: {
      type: Boolean,
      default: false,
    },
    link: {
      type: String,
      default: '',
    },
    autoWidth: {
      type: Boolean,
      default: false,
    },
    titleClass: {
      type: String,
      default: '',
    },
    bodyClass: {
      type: String,
      default: '',
    },
    smallPaddings: {
      type: Boolean,
      default: false,
    },
    replaceStrUpper: {
      type: String,
      default: '',
    },
    noFirstLetterParseTitle: {
      default: false,
      type: Boolean,
    },
    capitalize: {
      default: false,
      type: Boolean,
    },
    descriptionLeft: {
      default: false,
      type: Boolean,
    },
    stopWebPaddings: {
      default: false,
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const { openCard, toggleCard } = useToggleCard();

    const onTitleClick = () => {
      if (props.emitClickEvent) {
        emit(props.emitClickEvent);
      }
      if (props.isToggle) {
        toggleCard();
      }
    };

    const parseTitle = (title) => {
      if (!title) {
        return title;
      }
      return `${
        props.noFirstLetterParseTitle
          ? title[0].toLowerCase()
          : title[0].toUpperCase()
      }${title.toLowerCase().slice(1)}`.replace(
        props.replaceStrUpper.toLowerCase(),
        props.replaceStrUpper.toUpperCase()
      );
    };

    return { openCard, toggleCard, onTitleClick, parseTitle };
  },
};
</script>
<style lang="scss">
@import '@/assets/styles/colors';
@import '@/assets/styles/base';

.card {
  // border-radius: $base-radius;
  width: 375px;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  min-height: 0;
  height: 60px;
  overflow: hidden;
  transition: all 0.3s ease-in-out;

  @media screen and (min-width: 1024px) {
    padding: 0 40px;
    width: 420px;

    &.stopWebPaddings {
      width: 375px;
      padding: 0 20px;
    }
  }

  &.smallPaddings {
    padding: 0 3px;
  }

  &__body {
    padding-bottom: $px15;

    &.card-full {
      width: calc(100% + 40px);
      margin-left: -20px;
      padding-bottom: 0;

      @media screen and (min-width: 1024px) {
        width: calc(100% + 80px);
        margin-left: -40px;

        &.stopWebPaddings {
          width: calc(100% + 40px);
          margin-left: -20px;
        }
      }
    }

    @media screen and (max-width: 1024px) {
      height: 100%;
      width: 100%;

      &:has(.positions-card__body) {
        max-height: calc(100% - 90px);
      }

      max-height: calc(100% - 58px);
    }
  }

  &__description {
    font-size: 15px;
    font-family: Gotham_Pro_Regular;
    margin-top: -5px;
    margin-bottom: 20px;

    &.ml20 {
      margin-left: 20px;
    }
  }

  &.openCard {
    min-height: auto;
    height: auto;
    @media screen and (max-width: 1024px) {
      height: 100%;
      width: 100%;
    }
  }

  .arrow-icon {
    margin-left: 10px;
    margin-top: 5px;
    transition: all 0.3s ease-in-out;
    transform: rotate(-90deg);

    &.openCard {
      transform: rotate(0);
    }
  }

  &.autoWidth {
    width: auto;
  }

  &__title {
    display: flex;
    align-items: center;
    font-family: Gotham_Pro_Medium;
    font-size: 22px; // 1.1rem; // 1
    padding: 18px 0;
    position: relative;
    // min-height: 64px;
    text-transform: capitalize;

    // &.capitalize {
    //   text-transform: capitalize;
    // }

    @media screen and (min-width: 1024px) {
      padding: 30px 0 18px;

      &.stopWebPaddings {
        padding: 18px 0;
      }
    }

    &-right {
      position: absolute;
      right: 0;
      top: $px15;
    }

    &--menu {
      position: absolute;
      right: 20px;
      top: 22px;
      z-index: 1;
    }

    &--p15 {
      padding: 18px 20px;
    }

    &.underline {
      text-decoration-line: underline;
      text-decoration-thickness: 1px;
    }

    &.withLink {
      span {
        text-decoration-line: underline;
        text-decoration-thickness: 1px;
        cursor: pointer;
      }
    }
  }
}

.card.smallPaddings {
  .card__title {
    padding: 12px 10px;
  }
}
</style>
