<!-- from https://github.com/libitx/vue-money-button /-->
<template>
  <div class="handcash-btn handcash-btn--moneybutton" @click="swipeIMB">
    <template v-if="loading">
      <Loader class="handcash-loading" />
    </template>
    <template v-else>
      {{ label || `SEND ${+outputs[0].amount} BSV` }}
    </template>
  </div>
</template>

<script>
/* eslint-disable vue/require-default-prop */
import config from './config';
import envConfig from '@/config';
import Loader from '@/components/ui/Loader';
export default {
  components: {
    Loader,
  },
  props: {
    to: [String, Number],
    amount: [String, Number],
    editable: { type: Boolean, default: undefined },
    currency: { type: String, default: 'USD' },
    label: { type: String },
    successMessage: String,
    opReturn: String,
    outputs: { type: Array, default: () => [] },
    cryptoOperations: { type: Array, default: () => [] },
    clientIdentifier: String,
    buttonId: String,
    buttonData: String,
    type: { type: String, default: 'buy' },
    devMode: { type: Boolean, default: undefined },
    disabled: { type: Boolean, default: undefined },
    loaderColor: { type: String, default: '#fff' },
    loaderLeft: { type: Number, default: 50 },
  },
  data() {
    return {
      loading: true,
      imb: null,
      size: {
        width: '200px',
        height: '50px',
      },
    };
  },
  computed: {
    params() {
      return {
        to: this.to,
        amount: this.amount,
        editable: this.editable,
        currency:
          this.outputs.length || this.cryptoOperations.length
            ? undefined
            : this.currency,
        label: this.label,
        successMessage: this.successMessage,
        opReturn: this.opReturn,
        outputs: this.outputs.length ? this.outputs : undefined,
        cryptoOperations: this.cryptoOperations,
        clientIdentifier: envConfig.mbClientIdBtn,
        buttonId: this.buttonId,
        buttonData: this.buttonData,
        type: this.type,
        devMode: this.devMode,
        disabled: this.disabled,
        onLoad: (...args) => {
          setTimeout(() => {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.loading = false;
          }, 1000);
          this.$emit('load', ...args);
        },
        onPayment: (...args) => {
          this.$emit('payment', ...args);
        },
        onError: (...args) => {
          this.handleError(...args);
        },
        onCryptoOperations: (...args) => {
          this.$emit('cryptoOperations', ...args);
        },
      };
    },
  },
  watch: {
    params: {
      deep: true,
      handler() {
        this.refreshMoneyButton();
      },
    },
  },
  mounted() {
    this.refreshMoneyButton();
    window.addEventListener('message', this.handleMessage, false);
  },
  beforeUnmount() {
    window.removeEventListener('message', this.handleMessage, false);
  },
  methods: {
    swipeIMB() {
      !this.loading &&
        this.imb
          .swipe(this.params)
          .then(() => {
            this.loading = false;
          })
          .catch((e) => {
            this.loading = false;
            this.handleError(e);
          });
      this.loading = true;
    },
    handleError(error) {
      if (error.message === 'invalid token') {
        localStorage.setItem('tdxp.moneybutton.permission_token', '');
        this.refreshMoneyButton().then(() => this.swipeIMB());
      } else if (error.message !== 'Not enough authorized amount.') {
        this.$emit('error', error);
      }
    },
    refreshMoneyButton() {
      const savePermissionToken = (token) => {
        localStorage.setItem('permissionToken', token);
      };

      const getPermissionForCurrentUser = () => {
        return localStorage.getItem('permissionToken');
      };

      return import('../../vendors/mb').then(({ IMB }) => {
        this.loading = false;
        this.imb = new IMB({
          clientIdentifier: envConfig.mbClientIdBtn,
          permission: getPermissionForCurrentUser(),
          onNewPermissionGranted: (token) => savePermissionToken(token),
        });
      });
    },
    handleMessage(e) {
      if (e.origin === config.iframeOrigin) {
        if (e.data.v1.topic === 'ready') {
          if (this.$refs.button) {
            this.size = {
              width: this.$refs.button.offsetWidth + 'px',
              height: this.$refs.button.offsetHeight + 'px',
            };
          }
        }
      }
    },
  },
};
</script>

<style>
.v-money-button-outer {
  position: relative;
  display: inline-block;
  margin: 0;
}

.loader-container {
  position: absolute;
  width: 100px;
  top: 16px;
  left: 50px;
  right: 0;
}
</style>
