import { TelegramAppData } from './TelegramAppData';

export class TelegramMiniApp {
  constructor(params) {
    this.data = new TelegramAppData(params.get('tgWebAppData'));
    this.version = params.get('tgWebAppVersion');
    this.platform = params.get('tgWebAppPlatform');
    this.theme = params.get('tgWebAppThemeParams');
  }

  static createIfInContext(hash) {
    const params = new URLSearchParams(hash.slice(1));

    return params.get('tgWebAppVersion')
      ? new TelegramMiniApp(params)
      : undefined;
  }
}
