import { TelegramUser } from './TelegramUser';

export class TelegramAppData {
  constructor(data) {
    const params = new URLSearchParams(data);

    this.user = new TelegramUser(params.get('user'));
    this.chat_instance = params.get('chat_instance');
    this.chat_type = params.get('chat_type');
    this.auth_date = params.get('auth_date');
    this.hash = params.get('hash');
  }
}
