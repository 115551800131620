import { computed } from 'vue';
import { PositionTypes } from '@/modules/positions/helpers';

export const usePosition = (position = {}) => {
  const isProposed = computed(() => {
    return position.state === PositionTypes.proposed;
  });

  const isOpened = computed(() => {
    return position.state === PositionTypes.opened;
  });

  const isCanceled = computed(() => {
    return position.state === PositionTypes.canceled;
  });

  const isHistory = computed(() => {
    return position.state === PositionTypes.history;
  });

  const isClosed = computed(() => {
    return position.state === PositionTypes.closed;
  });

  const getPositionState = (position) => {
    const state = {
      [PositionTypes.opened]: 'opened',
      [PositionTypes.proposed]: 'pending',
      [PositionTypes.canceled]: 'canceled',
      [PositionTypes.closed]: 'closed',
      [PositionTypes.history]: 'closed',
    }[position.state];

    if (!state) {
      throw new Error(`Unable to determine position state=${position.state}`);
    }

    return state;
  };

  return {
    isProposed,
    isOpened,
    isCanceled,
    isHistory,
    isClosed,
    getPositionState,
  };
};
