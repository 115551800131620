/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'newarrow': {
    width: 9,
    height: 14,
    viewBox: '0 0 9 14',
    data: '<path pid="0" d="M6.889.268l1.51 1.509-5.05 5.05 5.05 5.049-1.51 1.509-6.56-6.558L6.89.267z" _fill="#fff"/>'
  }
})
