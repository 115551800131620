import clone from 'lodash/clone';
import fromPairs from 'lodash/fromPairs';
import get from 'lodash/get';
import { reactive } from 'vue';
import { ANIMATION_DELAY_MS } from '@/config/constants';

const PositionValuesChangeState = {
  none: 'none',
  increase: 'increase',
  decrease: 'decrease',
};

const getStateOfComparison = (oldValue, newValue) => {
  if (newValue > oldValue) {
    return PositionValuesChangeState.increase;
  }

  if (newValue < oldValue) {
    return PositionValuesChangeState.decrease;
  }

  return PositionValuesChangeState.none;
};

const useAnimationState = ({ fields = [], buildKey = ({ id }) => id }) => {
  const animationsState = reactive({});
  let animationResetTimer;

  const createState = () => {
    const initState = {
      state: PositionValuesChangeState.none,
      prev: 0,
    };
    const pairs = fields.map((field) => [field, clone(initState)]);

    return fromPairs(pairs);
  };

  const recalculateState = (item) => {
    const dataSet = animationsState[buildKey(item)];

    fields.forEach((field) => {
      const oldValue = dataSet[field].prev;
      const newValue = item[field];

      dataSet[field].state = getStateOfComparison(oldValue, newValue);
      dataSet[field].prev = newValue;
    });
  };

  const process = (items) => {
    items.forEach((item) => {
      const key = buildKey(item);

      if (!animationsState[key]) {
        animationsState[key] = createState();
      }

      recalculateState(item);
    });

    setResetTimer();
  };

  const setResetTimer = () => {
    clearInterval(animationResetTimer);

    animationResetTimer = setTimeout(() => {
      const sets = Object.values(animationsState);

      sets.forEach((dataSet) => {
        fields.forEach((field) => {
          dataSet[field].state = PositionValuesChangeState.none;
        });
      });
    }, ANIMATION_DELAY_MS);
  };

  const getState = (key, field) =>
    get(
      animationsState,
      `${key}.${field}.state`,
      PositionValuesChangeState.none
    );

  const hasIncreased = (key, field) =>
    getState(key, field) === PositionValuesChangeState.increase;

  const hasDecreased = (key, field) =>
    getState(key, field) === PositionValuesChangeState.decrease;

  return {
    process,
    hasIncreased,
    hasDecreased,
  };
};

export default useAnimationState;
