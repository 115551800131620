<template>
  <Modal :stop-mobile-max-height="true" to-top @close="$emit(EVENTS.close)">
    <Card
      :title="$t('popups.liquidityFundraiser.liquidityProvided').toUpperCase()"
      class="card"
      :body-class="'card-full'"
    >
      <div class="liquidity-provided">
        <p>{{ $t('popups.liquidityFundraiser.rounds') }}</p>
        <liquidityTable
          :user-funded-filter="true"
          @getLiquidityTransactions="onGetLiquidityTransactions"
          @getUserShare="onGetUserShare"
        />
        <ItemLabelValue
          :label="$t('popups.liquidityFundraiser.totalLiquidity')"
          :value="totalLiquidity"
          class="mt-25"
        />
        <ItemLabelValue
          :label="$t('popups.liquidityFundraiser.yourLiquidity')"
          :value="yourLiquidity"
          is-html-value
        />
        <ItemLabelValue
          :label="$t('popups.liquidityFundraiser.yourShare')"
          :value="userShare"
          is-html-value
        />
        <!-- <ItemLabelValue
          :label="$t('popups.liquidityFundraiser.overTime')"
          :value="`${userReturn} / +${userProfitPercent}%`"
        /> -->
        <div
          class="align-right"
          v-html="
            `+ ${yourLiquidity.replace('BSV', '')} ${$t(
              'popups.liquidityFundraiser.initial'
            )}`
          "
        />
        <div class="head-title mt-15">
          {{ $t('popups.liquidityFundraiser.txLinks') }}
        </div>
        <div v-for="link in liquidityTransactions" :key="link" class="pb-1">
          <a
            v-if="link.txId"
            class="decoration--underlined underline tx-item"
            target="_blank"
            rel="noopener noreferrer"
            :href="`https://whatsonchain.com/tx/${link.txId}`"
          >
            {{ $t('popups.liquidityFundraiser.txLink') }}
          </a>
          <div v-if="link.liquidityFeePositionId" class="tx-item">
            {{
              `Liquidity fee of ${link.amount} for position #${link.liquidityFeePositionId}`
            }}
          </div>
        </div>
        <div class="head-title mt-15">
          {{ $t('popups.liquidityFundraiser.links') }}
        </div>
        <UserfulLinks
          :hide-explorer="true"
          @showRefundModal="$emit(EVENTS.showRefundModal)"
          @showAllocationModal="$emit(EVENTS.showAllocationModal)"
          @showMechanics="$emit(EVENTS.showMechanics)"
        />
      </div>
    </Card>
  </Modal>
</template>
<script>
import ItemLabelValue from '@/components/ui/ItemLabelValue';
import Modal from '@/modules/modals/Modal';
import Card from '@/components/ui/Card';
import UserfulLinks from './UsefulLinks';
import liquidityTable from './LiquidityTable';
import { ref } from 'vue';

const EVENTS = {
  close: 'close',
  showRefundModal: 'showRefundModal',
  showMechanics: 'showMechanics',
  showAllocationModal: 'showAllocationModal',
};

export default {
  components: {
    Modal,
    Card,
    ItemLabelValue,
    liquidityTable,
    UserfulLinks,
  },
  props: {
    totalLiquidity: { required: true },
    yourLiquidity: { required: true },
    userProfitPercent: { required: true },
    userReturn: { required: true },
  },
  emits: [
    EVENTS.close,
    EVENTS.showRefundModal,
    EVENTS.showMechanics,
    EVENTS.showAllocationModal,
  ],
  setup() {
    const userShare = ref(0);
    const liquidityTransactions = ref([]);

    const onGetUserShare = (val) => {
      userShare.value = val;
    };

    const onGetLiquidityTransactions = (val) => {
      liquidityTransactions.value = val;
    };

    return {
      userShare,
      liquidityTransactions,
      onGetUserShare,
      onGetLiquidityTransactions,
      EVENTS,
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/colors';
@import '@/assets/styles/base';

.liquidity-provided {
  height: calc(100vh - 230px);
  overflow-y: auto;
  margin-bottom: 1px;
  padding-right: $px20;
  padding-left: $px20;
  box-sizing: border-box;
  font-family: Gotham_Pro_Regular;
  line-height: 20px;

  @media screen and (max-width: 1024px) {
    max-height: 100%;
    height: calc(100% - 55px);
  }

  @media screen and (min-width: 1024px) {
    padding-right: 40px;
    padding-left: 40px;
  }

  .tx-item {
    display: inline-block;
    margin-bottom: 10px;

    &:first-child {
      margin-top: 5px;
    }
  }

  .align-right {
    font-size: 12px;
    text-align: right;
    margin-top: -15px;
  }

  .mt-25 {
    margin-top: 25px;
  }

  .head-title {
    margin-bottom: 1rem;
    font-family: Gotham_Pro_Bold;
    margin-top: 40px;
    margin-bottom: 5px;
  }

  p {
    &.underline,
    a {
      text-decoration-line: underline;
      text-decoration-thickness: 1px;
    }
  }

  span {
    text-decoration-line: underline;
    cursor: pointer;
  }
}

.btn {
  margin-top: 30px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
</style>
