import { APP_ROUTE_NAMES, MOBILE_ROUTES_WHITE_LIST } from '@/helpers/enums';

export const localConstPrefix = 'tdxp.';

/**
 * Строит ключ для storage
 * @param {string} paramName - Имя ключа
 * @returns {string}
 */
const getStorageKey = (paramName) => {
  return `${localConstPrefix}${paramName}`;
};

/**
 * Возвращает сохраненные данные по ключу
 * @param {string} paramName - Имя ключа
 * @returns {*}
 */
export const getLocalItem = (paramName) => {
  try {
    return JSON.parse(localStorage.getItem(getStorageKey(paramName)));
  } catch (e) {
    throw new Error(
      `Unable to parse JSON from param=${getStorageKey(paramName)}`
    );
  }
};

/**
 * Устанавливает параметры
 * @param {string} paramName - Имя ключа в котором сохранять данные
 * @param {any} obj - Данные для сохранения
 */
export const setLocalItem = (paramName, obj) => {
  localStorage.setItem(getStorageKey(paramName), JSON.stringify(obj));
};

/**
 * Удаляет параметр
 * @param {string} paramName - Имя ключа для удаления данных
 */
export const deleteLocalItem = (paramName) => {
  localStorage.removeItem(getStorageKey(paramName));
};

/**
 * Сохраняет позицию прокрутки в storage
 */
export const saveMobileScrollPositions = (routeName) => {
  if (routeName === APP_ROUTE_NAMES.chart) {
    deleteLocalItem(`mobileScrollPositions.${routeName}`);
    return;
  }
  const container = document.querySelector('html');
  setLocalItem(`mobileScrollPositions.${routeName}`, {
    top: container.scrollTop || 0,
    left: container.scrollLeft || 0,
  });
};

export const clearSavedPositions = () => {
  MOBILE_ROUTES_WHITE_LIST.forEach((routeName) => {
    deleteLocalItem(`mobileScrollPositions.${routeName}`);
  });
};

/**
 * Сохраняет урл последней страницы
 */
export const saveMobileLastPage = (fullPath) => {
  setLocalItem('savedMobileLastPage', fullPath);
};

/**
 * Формирует ключ модалки для localStorage
 * @param {string} modalName
 * @returns {string} modal's LS key
 */
const getModalLSKey = (modalName) => getStorageKey(`modal.${modalName}`);

/**
 * Сохраняет флаг 'don't show again' для модалок
 * @param {string} modalName - Name from modals API (modalsByName[<modalName>])
 * @param {boolean} value - Modal flag value
 * @returns {void}
 */
export const setModalFlag = (modalName, value) => {
  setLocalItem(getModalLSKey(modalName), value);
};

/**
 * Проверяет флаг 'don't show again'
 * @param {string} modalName - Name from modals API (modalsByName[<modalName>])
 * @returns {string} LS flag value for passed modalName
 */
export const getModalFlag = (modalName) => {
  return getLocalItem(getModalLSKey(modalName));
};
