import axios from 'axios';
import { createI18n } from 'vue-i18n';

const releaseDate = process.env.VUE_APP_RELEASE_DATE;

const lsSettings = {
  get locale() {
    return localStorage.getItem('tdxp.uiSettings.locale');
  },
};

async function loadLocaleMessages() {
  console.log(releaseDate);
  const locale = lsSettings.locale || 'en';
  const messages = {};
  try {
    const response = await axios.get(
      `/lang/${locale}.json?release=${releaseDate}`
    );
    messages[locale] = response.data;
  } catch (e) {
    messages[locale] = {};
  }
  return messages;
}

export default async function i18nFactory() {
  const messages = await loadLocaleMessages();
  return createI18n({
    locale: lsSettings.locale || 'en',
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
    messages,
  });
}
