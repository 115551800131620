export const initLandingListeners = (
  store,
  showModal,
  hideModal,
  modalsByName,
  router
) => {
  window.document.addEventListener('termsConditions', () => {
    store.dispatch('burger/info');
  });
  window.document.addEventListener('privacy', () => {
    store.dispatch('burger/togglePrivacy');
  });
  window.document.addEventListener('cookie', () => {
    store.dispatch('burger/toggleCookiesPolicy');
  });
  window.document.addEventListener('persons', () => {
    store.dispatch('burger/toggleProhibitedPersons');
  });
  window.document.addEventListener('antimoney', () => {
    store.dispatch('burger/toggleKYCPolicy');
  });
  window.document.addEventListener('risk', () => {
    store.dispatch('burger/toggleRiskStatement');
  });
  window.document.addEventListener('review', () => {
    store.dispatch('burger/toggleWhatUsersSay');
  });
  window.document.addEventListener('chat', () => {
    store.dispatch('burger/support');
  });
  window.document.addEventListener('login', () => {
    showModal(modalsByName.login);
  });
  window.document.addEventListener('closeLogin', () => {
    hideModal(modalsByName.login);
  });
  window.document.addEventListener('openMobilePositions', () => {
    if (window.document.body.clientWidth <= 480) {
      router.push('positions');
    }
  });
};
