import settingsStore, { cachedPaths, storePlugin } from './store';
export default function module({ store, options }) {
  if (!store) {
    return;
  }
  store.registerModule('settings', settingsStore);
  options.cachedPaths = options.cachedPaths.concat(
    [...cachedPaths].map((item) => 'settings.' + item)
  );
  storePlugin(store);
  return options;
}
