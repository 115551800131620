<template>
  <Card :title="$t('header.menuItems.logOut')">
    Confirm logout
    <div class="bottom-btns">
      <button
        class="my-button"
        @click="
          logout();
          emit('close');
        "
      >
        {{ t('account.titles.confirm') }}
      </button>
    </div>
  </Card>
</template>
<script>
import { defineComponent } from 'vue';
import Card from '@/components/ui/Card.vue';
import { useI18n } from 'vue-i18n';
import { useLogout } from '@/modules/user/api';

export default defineComponent({
  name: 'Logout',
  emits: ['close'],
  components: {
    Card,
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const { logout } = useLogout();
    return { t, logout, emit };
  },
});
</script>
<style lang="scss">
.bottom-btns {
  display: flex;
  flex-flow: row wrap;
}
.my-button {
  min-height: 44px;
  width: 100%;
  padding: 2px;
  text-transform: uppercase;
  cursor: pointer;
}
</style>
