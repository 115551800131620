import sessionInfoModule, { storePlugin } from './store';

export default function module({ store, options }) {
  if (!store) {
    return;
  }
  store.registerModule('session', sessionInfoModule);
  storePlugin(store);
  return options;
}
