import { useStore } from 'vuex';
import { computed } from 'vue';
import { connApp, getErrorDetails } from '@/helpers/signalR';
import notify from '@/plugins/notify';
import { useI18n } from 'vue-i18n';
import { useModals } from '@/modules/modals/api';

export default function useClosePosition() {
  const store = useStore();
  const { showModal, modalsByName } = useModals();
  const { t } = useI18n();

  const marketsById = computed(() => store.getters['markets/marketsById']);

  const currentPosition = computed(
    () => store.getters['positions/getCurrentPosition']
  );

  const closePositionJustNow = async () => {
    store
      .dispatch('positions/onPositionConfirm')
      .catch(() => {
        notify({
          text: t('order.history.waitAndTryAgain'),
          type: 'warning',
        });
      })
      .finally(async () => {
        let error = '';
        const result = await connApp
          .invoke('GetLiquidityFeeProvided')
          .catch((err) => {
            error = getErrorDetails(err);
          });
        if (!error) {
          console.log('resss', result);

          if (+result >= 10) {
            if (!localStorage.getItem('showEarnedLiquidity')) {
              localStorage.setItem('showEarnedLiquidity', true);
              showModal(modalsByName.earnedLiquidity);
            }
          }
        } else {
          notify({
            text: error.message,
            type: 'warning',
          });
        }
        const activeMarket = marketsById.value[currentPosition.value.market_id];
        await store.dispatch(
          'volumeLimits/fetchGroupActiveAmount',
          activeMarket.id
        );
      });
  };

  return { closePositionJustNow };
}
