export class TelegramUser {
  constructor(user) {
    const params = JSON.parse(user ?? '{}');

    this.id = params.id;
    this.username = params.last_name;
    this.firstName = params.first_name;
    this.lastName = params.last_name;
    this.isPremium = params.is_premium;
    this.allowsWriteToPm = params.allows_write_to_pm;
    this.languageCode = params.language_code;
  }
}
