/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'pen': {
    width: 9.49,
    height: 9.489,
    viewBox: '0 0 9.49 9.489',
    data: '<path pid="0" d="M0 7.751v1.738h1.739l4.866-4.866-1.739-1.74L0 7.751zM7.751 0L6.012 1.738l1.74 1.74L9.49 1.74 7.751 0z"/>'
  }
})
