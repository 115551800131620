/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'account_on': {
    width: 17,
    height: 17,
    viewBox: '0 0 17 17',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M0 6a6 6 0 016-6h5a6 6 0 016 6v5c0 1.456-.519 2.79-1.381 3.83a6.45 6.45 0 00-1.968-2.757c-1.497-1.219-3.411-1.665-5.151-1.665-1.74 0-3.654.446-5.151 1.665A6.45 6.45 0 001.38 14.83 5.976 5.976 0 010 11V6zm12.515 7.47c.717.583 1.316 1.429 1.624 2.644A5.972 5.972 0 0111 17H6a5.972 5.972 0 01-3.14-.886c.309-1.215.908-2.06 1.625-2.645 1.083-.882 2.55-1.261 4.015-1.261 1.465 0 2.932.38 4.015 1.261zM8.5 9.153c.466 0 3.403-.212 3.403-3.632 0-2.006-1.523-3.633-3.403-3.633-1.88 0-3.403 1.627-3.403 3.633 0 3.42 2.937 3.632 3.403 3.632z" _fill="#fff"/>'
  }
})
