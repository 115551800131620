/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'masterCard': {
    width: 32,
    height: 21,
    viewBox: '0 0 32 21',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M0 1.592C0 .885.573.312 1.28.312h29.44c.707 0 1.28.573 1.28 1.28v17.536a1.28 1.28 0 01-1.28 1.28H1.28A1.28 1.28 0 010 19.128V1.592zM15.553 16.16l-.169-.136a7.507 7.507 0 01-2.581-5.663c0-2.17.946-4.236 2.592-5.659.049-.05.1-.098.155-.143a6.933 6.933 0 00-3.856-1.159 6.962 6.962 0 10.004 13.922 6.915 6.915 0 003.854-1.162zm.898-11.601a6.925 6.925 0 013.855-1.159 6.962 6.962 0 11-.004 13.922 6.907 6.907 0 01-3.854-1.162l.168-.136a7.517 7.517 0 002.582-5.663 7.482 7.482 0 00-2.592-5.659 1.938 1.938 0 00-.155-.143zM14.04 7.32A6.94 6.94 0 0116 4.892a6.944 6.944 0 012.66 5.469A6.94 6.94 0 0116 15.833a6.941 6.941 0 01-2.659-5.472c0-1.054.238-2.094.699-3.041z" _fill="#767C83"/>'
  }
})
