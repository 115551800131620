<template>
  <Modal :stop-mobile-max-height="true" @close="$emit(EVENTS.close)">
    <Card
      :title="$t('popups.AML-KYC.title').toUpperCase()"
      class="card"
      :body-class="'card-full'"
    >
      <div class="kyc-policy">
        <p>{{ $t('popups.AML-KYC.item0') }}</p>
        <i18n-t keypath="popups.AML-KYC.item0-1" tag="p">
          <template v-slot:terms>
            <span @click="openTerms" class="underline">
              {{ $t('footer.terms') }}
            </span>
          </template>
        </i18n-t>
        <p>{{ $t('popups.AML-KYC.item1-title') }}</p>
        <p>{{ $t('popups.AML-KYC.item1-1') }}</p>
        <p>{{ $t('popups.AML-KYC.item1-2') }}</p>
        <p>{{ $t('popups.AML-KYC.item1-3') }}</p>
        <p>{{ $t('popups.AML-KYC.item1-3-1') }}</p>
        <p>{{ $t('popups.AML-KYC.item1-3-2') }}</p>
        <p>{{ $t('popups.AML-KYC.item1-4') }}</p>
        <p>{{ $t('popups.AML-KYC.item1-5') }}</p>
        <p>{{ $t('popups.AML-KYC.item1-6') }}</p>
        <p>{{ $t('popups.AML-KYC.item2-title') }}</p>
        <p>{{ $t('popups.AML-KYC.item2-1') }}</p>
        <p>{{ $t('popups.AML-KYC.item2-2') }}</p>
        <p>{{ $t('popups.AML-KYC.item2-3') }}</p>
        <p>{{ $t('popups.AML-KYC.item2-4') }}</p>
        <p>{{ $t('popups.AML-KYC.item2-5') }}</p>
        <p>{{ $t('popups.AML-KYC.item2-6') }}</p>
        <p>{{ $t('popups.AML-KYC.item2-7') }}</p>
        <p>{{ $t('popups.AML-KYC.item3-title') }}</p>
        <p>{{ $t('popups.AML-KYC.item3-1') }}</p>
        <p>{{ $t('popups.AML-KYC.item4-title') }}</p>
        <p>{{ $t('popups.AML-KYC.item4-1') }}</p>
        <p>{{ $t('popups.AML-KYC.item4-2-content-1') }}</p>
        <ul>
          <li>{{ $t('popups.AML-KYC.item4-2-list-1') }}</li>
          <li>{{ $t('popups.AML-KYC.item4-2-list-2') }}</li>
          <li>{{ $t('popups.AML-KYC.item4-2-list-3') }}</li>
        </ul>
        <p>{{ $t('popups.AML-KYC.item4-2-content-2') }}</p>
        <ul>
          <li>{{ $t('popups.AML-KYC.item4-2-list-4') }}</li>
          <li>{{ $t('popups.AML-KYC.item4-2-list-5') }}</li>
          <li>{{ $t('popups.AML-KYC.item4-2-list-6') }}</li>
        </ul>
        <p>{{ $t('popups.AML-KYC.item4-2-content-3') }}</p>
        <p>{{ $t('popups.AML-KYC.item4-3') }}</p>
        <ul>
          <li>{{ $t('popups.AML-KYC.item4-3-list-1') }}</li>
          <li>{{ $t('popups.AML-KYC.item4-3-list-2') }}</li>
          <li>{{ $t('popups.AML-KYC.item4-3-list-3') }}</li>
          <li>{{ $t('popups.AML-KYC.item4-3-list-4') }}</li>
          <li>{{ $t('popups.AML-KYC.item4-3-list-5') }}</li>
        </ul>
        <p>{{ $t('popups.AML-KYC.item4-4') }}</p>
        <p>{{ $t('popups.AML-KYC.item4-5') }}</p>
        <p>{{ $t('popups.AML-KYC.item4-6') }}</p>
        <ul>
          <li>{{ $t('popups.AML-KYC.item4-6-list-1') }}</li>
          <li>{{ $t('popups.AML-KYC.item4-6-list-2') }}</li>
        </ul>
        <p>{{ $t('popups.AML-KYC.item4-7') }}</p>
        <p>{{ $t('popups.AML-KYC.item4-8') }}</p>
        <p>{{ $t('popups.AML-KYC.item4-9') }}</p>
        <p>{{ $t('popups.AML-KYC.item4-10') }}</p>
        <p>{{ $t('popups.AML-KYC.item4-11') }}</p>
        <p>{{ $t('popups.AML-KYC.item4-12') }}</p>
        <p>{{ $t('popups.AML-KYC.item4-13') }}</p>
        <p>{{ $t('popups.AML-KYC.item4-14') }}</p>
        <p>{{ $t('popups.AML-KYC.item4-15') }}</p>
        <ul>
          <li>{{ $t('popups.AML-KYC.item4-15-list-1') }}</li>
          <li>{{ $t('popups.AML-KYC.item4-15-list-2') }}</li>
        </ul>
        <p>{{ $t('popups.AML-KYC.item4-16') }}</p>
        <p>{{ $t('popups.AML-KYC.item4-17') }}</p>
        <p>{{ $t('popups.AML-KYC.item4-18') }}</p>
        <p>{{ $t('popups.AML-KYC.item4-19') }}</p>
      </div>
    </Card>
    <Button
      :text="$t('popups.buttons.continue')"
      type="secondary"
      class="kyc-btn"
      @click="$emit('close')"
    />
  </Modal>
</template>
<script>
import Modal from '@/modules/modals/Modal';
import Card from '@/components/ui/Card';
import Button from '@/components/ui/Button';
import { useModals } from '@/modules/modals/api';

const EVENTS = {
  close: 'close',
};

export default {
  components: { Modal, Card, Button },
  emits: [EVENTS.close],
  setup() {
    const { showModal, modalsByName } = useModals();

    const openTerms = () => {
      window.open(
        'https://faq.dxs.app/policies/terms-and-conditions',
        '_blank'
      );
    };

    return {
      openTerms,
      EVENTS,
      showModal,
      modalsByName,
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/colors';
@import '@/assets/styles/base';

.kyc-policy {
  height: 400px;
  overflow-y: auto;
  margin-bottom: 45px;
  padding-right: $px20;
  padding-left: $px20;
  box-sizing: border-box;
  font-family: Gotham_Pro_Regular;
  line-height: 20px;

  p {
    &.underline {
      text-decoration-line: underline;
    }
  }

  div {
    margin-top: 25px;

    h4 {
      font-family: Gotham_Pro_Bold !important;
    }
  }

  span {
    text-decoration-line: underline;
    cursor: pointer;
  }
}

.kyc-btn {
  margin-top: 30px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
</style>
