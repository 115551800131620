<template>
  <Modal :stop-mobile-max-height="true" to-top @close="$emit(EVENTS.close)">
    <Card
      :title="$t('popups.crowdfunding.modalTitle').toUpperCase()"
      class="card"
      :body-class="'card-full'"
    >
      <div
        :class="{ relay: activeConnect.provider === userProviders.relayX }"
        class="confirm-payment"
      >
        <p v-html="$t('popups.liquidityFundraiser.confirmProvide0')" />
        <p>{{ $t('popups.liquidityFundraiser.confirmProvide') }}</p>
        <ItemLabelValue
          :label="$t('popups.liquidityFundraiser.liqAmount1')"
          :value="`${amountBsv} ${
            fiorinToken
              ? fiorinToken.name
              : activeConnect.provider === userProviders.fiorin
              ? fiorinStables.includes(
                  activeConnect?.tokenIdCurrency?.toLowerCase()
                )
                ? activeConnect?.tokenIdCurrency?.toUpperCase()
                : 'USD'
              : 'BSV'
          }`"
          class="mt-15"
        />
        <ItemLabelValue
          :label="$t('popups.liquidityFundraiser.overTime')"
          :value="returnValue"
          class="mt-15"
        />
        <p>{{ $t('popups.liquidityFundraiser.subject') }}</p>
        <liquidityTable :rounds-items="roundsItems" />
        <template v-if="activeConnect.provider === userProviders.fiorin">
          <p>{{ $t('popups.liquidityFundraiser.limitPerUser.usd') }}</p>
        </template>
        <template v-else>
          <p>{{ $t('popups.liquidityFundraiser.limitPerUser') }}</p>
          <p>{{ $t('popups.liquidityFundraiser.limitPerUser1') }}</p>
        </template>
      </div>
      <HandCash
        v-if="activeConnect.provider === userProviders.handCash"
        :loading="handcashTxLoading"
        :margin-btn-value="amountBsv"
        :margin="amountBsv"
        @onClick="openHandCashPosition"
        :class="{
          standalone:
            wd.navigator.standalone ||
            isRunningStandaloneAndroid() ||
            isTelegram,
        }"
      />
      <HandCash
        v-if="activeConnect.provider === userProviders.fiorin"
        :loading="handcashTxLoading"
        :margin-btn-value="amountBsv"
        :margin="amountBsv"
        :fiorin-currency="fiorinToken ? fiorinToken.name : fiorinCurrency"
        is-usd
        @onClick="openFiorinPosition"
        :class="{
          standalone:
            wd.navigator.standalone ||
            isRunningStandaloneAndroid() ||
            isTelegram,
        }"
      />
      <div
        v-if="activeConnect.provider === userProviders.dotWallet"
        style="width: 210px; margin: 0 0 20px 10px"
      >
        <DotwalletButton
          :address="config.liquidity_pool_account"
          :amount="amountBsv"
          :opreturn="transactionKey.toHex()"
          product-name="Liquidity"
          @error="onError"
          @payment="onPayment"
        />
      </div>
      <MoneyButton
        v-if="activeConnect.provider === userProviders.moneyButton"
        :outputs="[
          {
            to: config.liquidity_pool_account,
            amount: amountBsv,
            currency: 'BSV',
          },
          {
            script: transactionKey.toAsm(),
            amount: '0',
            currency: 'BSV',
          },
        ]"
        @payment="onPayment"
        @error="onError"
      />
      <RelayX
        v-if="activeConnect.provider === userProviders.relayX"
        label="PAY"
        provide-mode
        :outputs="[
          {
            to: config.liquidity_pool_account,
            amount: amountBsv,
            currency: 'BSV',
          },
          {
            script: transactionKey.toAsm(),
            amount: '0',
            currency: 'BSV',
          },
        ]"
        @payment="onPayment"
        @error="onError"
      />
    </Card>
  </Modal>
</template>
<script>
import Modal from '@/modules/modals/Modal';
import Card from '@/components/ui/Card';
import ItemLabelValue from '@/components/ui/ItemLabelValue';
import liquidityTable from './LiquidityTable';
import HandCash from '@/components/HandCashButton';
import { paymentUID } from '@/helpers/utils';
import envConfig from '@/config';
import notify from '@/plugins/notify';
import { ref, computed, watch } from 'vue';
import { useStore } from 'vuex';
import { userProviders } from '@/modules/user/api';
import MoneyButton from '@/components/MoneyButton/MoneyButton';
import DotwalletButton from '@/components/DotwalletButton/DotwalletButton';
import RelayX from '@/components/RelayXButton/RelayXButton';
import { NullDataBuilder } from 'dxs-stas-sdk/dist/script/build/null-data-builder';
import { fiorinStables } from '@/config/constants';
import { isRunningStandaloneAndroid } from '@/helpers/detects';

const FIORIN_HOST = process.env.VUE_APP_FIORIN_HOST;

const EVENTS = {
  close: 'close',
  successPayment: 'successPayment',
};

export default {
  components: {
    HandCash,
    MoneyButton,
    DotwalletButton,
    RelayX,
    Modal,
    Card,
    ItemLabelValue,
    liquidityTable,
  },
  emits: [EVENTS.close, EVENTS.successPayment],
  props: {
    fiorinToken: { required: true },
    amountBsv: { required: true },
    returnValue: { required: true },
    roundsItems: { required: true },
    fiorinCurrency: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const handcashTxLoading = ref(false);

    const isTelegram = computed(() =>
      Boolean(store.getters['telegram/instance'])
    );

    const msg1 = computed(() => {
      let msg = paymentUID();
      return msg;
    });

    const onPayment = () => {
      emit(EVENTS.successPayment);
    };

    const onError = (data) => {
      notify({
        text: data.error || 'Moneybutton: unknown pay error',
        type: 'info',
      });
    };

    const transactionKey = computed(() => {
      return new NullDataBuilder([
        Buffer.from(envConfig.txPrefix),
        Buffer.from(msg1.value),
        Buffer.from('text'),
        Buffer.from('L'),
      ]);
    });

    const activeConnect = computed(
      () => store.getters['connectors/activeConnect']
    );
    const config = computed(() => store.getters['user/wsConfig']);
    const fiorinLastTx = computed(() => store.getters['fiorin/fiorinLastTx']);

    watch(
      () => fiorinLastTx.value,
      async () => {
        if (fiorinLastTx.value) {
          emit(EVENTS.successPayment);
          frames.fiorin.postMessage({ event: 'GET_BALANCE' }, FIORIN_HOST);
          handcashTxLoading.value = false;

          // await connApp.invoke('ProposeLiquidityFunding', {
          //   TransactionId: fiorinLastTx.value,
          //   Amount: props.amountBsv,
          //   Currency: 'USD',
          // });
          store.dispatch('fiorin/setFiorinLastTx', '');
        }
      }
    );

    const openFiorinPosition = async () => {
      if (handcashTxLoading.value) {
        return;
      }

      handcashTxLoading.value = true;
      const msg = `${envConfig.txPrefix} ${msg1.value} text L`;

      frames.fiorin.postMessage(
        {
          event: 'REFILL',
          payload: {
            tokenId: props.fiorinToken
              ? props.fiorinToken.tokenId
              : activeConnect.value.tokenId || '',
            amount: +props.amountBsv,
            message: msg,
            type: 'POOL',
          },
        },
        FIORIN_HOST
      );
    };

    const openHandCashPosition = async () => {
      handcashTxLoading.value = true;

      const msg = `${envConfig.txPrefix} ${msg1.value} text L`
        .split(' ')
        .map((item) => Buffer.from(item).toString('hex'));
      const result = await activeConnect.value.sendHandCashTx({
        msg,
        to: config.value.liquidity_pool_account,
        amount: +props.amountBsv,
        description: ``,
      });

      if (result && result.transactionId) {
        emit(EVENTS.successPayment);
        handcashTxLoading.value = false;
      }

      if (result && result.error) {
        handcashTxLoading.value = false;
        notify({
          text: result.error.message || 'Handcash: unknown pay error',
          type: 'info',
        });
      }
    };

    return {
      userProviders,
      handcashTxLoading,
      openHandCashPosition,
      openFiorinPosition,
      onPayment,
      onError,
      config,
      transactionKey,
      activeConnect,
      EVENTS,
      fiorinStables,
      wd: window,
      isTelegram,
      isRunningStandaloneAndroid,
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/colors';
@import '@/assets/styles/base';

.confirm-payment {
  font-family: Gotham_Pro_Regular;
  line-height: 18px;
  height: calc(100% - 105px);
  padding-left: $px20;
  padding-right: $px20;
  margin-bottom: 50px;
  overflow-y: auto;

  @media screen and (min-width: 1024px) {
    max-height: calc(100vh - 280px) !important;
    padding-left: 40px;
    padding-right: 40px;
  }

  + ::v-deep {
    .handcash-btn {
      @media screen and (max-width: 1024px) {
        bottom: 50px;

        &.standalone {
          bottom: 82px;
        }
      }
    }
  }

  &.relay {
    margin-bottom: 25px;
  }

  div {
    margin-top: 15px;
  }

  span {
    text-decoration-line: underline;
    cursor: pointer;
  }
}
</style>
