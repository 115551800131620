import axios from 'axios';
import config from '@/config';
import notify from '@/plugins/notify';
import router from '@/router';

const getInitialState = () => ({});

const getters = {};

const mutations = {};

const actions = {
  confirmUserEmail(_, { userId, code }) {
    axios
      .post(`${config.apiUrl}User/email/confirm/code`, { userId, code })
      .then(
        (res) => {
          if (res) {
            notify({ text: 'Email verified', type: 'info' });
            router.push('/');
          }
        },
        (err) => {
          notify({ text: JSON.stringify(err), type: 'info' });
        }
      );
  },
};

export default {
  namespaced: true,
  state: getInitialState(),
  mutations,
  actions,
  getters,
};
