<template>
  <Modal
    @close="
      () => {
        $emit(EVENTS.close);
        $tours['mobile-tour']?.isRunning && $tours['mobile-tour'].finish();
      }
    "
    :stop-mobile-max-height="true"
    modalClass="_align-bottom"
  >
    <Card
      :title="$t('blocks.titles.tradingSession')"
      :long-auto="true"
      class="card"
      :body-class="'card-full'"
    >
      <SessionInfo hide-toggle />
    </Card>
  </Modal>
</template>

<script>
import Modal from '@/modules/modals/Modal';
import Card from '@/components/ui/Card';
import SessionInfo from '@/modules/session/SessionInfo.vue';

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SessionInfoModal',
  components: {
    Modal,
    Card,
    SessionInfo,
  },
  props: {
    hideToggle: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const EVENTS = {
      close: 'close',
    };

    return {
      EVENTS,
    };
  },
});
</script>

<style scoped></style>
