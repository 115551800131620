<template>
  <Modal
    @close="$emit('close')"
    stop-mobile-max-height
    :body-style="{
      'max-height': '80%',
      'overflow-x': 'hidden',
      'overflow-y': 'auto',
    }"
    modal-class="icon--black"
  >
    <div class="kys-verification-wrap">
      <Card
        :title="$t('popups.verification.title')"
        class="card kys-verification-wrap__inner"
        :style="{
          height: modalHeight,
        }"
      >
        <div id="sumsub-websdk-container"></div>
      </Card>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/modules/modals/Modal';
import Card from '@/components/ui/Card';

import { onMounted, defineComponent, ref } from 'vue';
import snsWebSdk from '@sumsub/websdk';
import { connApp } from '@/helpers/signalR';

export default defineComponent({
  components: {
    Modal,
    Card,
  },
  setup() {
    const getNewAccessToken = () => {
      return connApp.invoke('CreateSumSubAccessToken');
    };

    const launchWebSdk = (accessToken) => {
      let snsWebSdkInstance = snsWebSdk
        .init(accessToken, () => getNewAccessToken())
        .withConf({
          lang: 'en',
        })
        .on('onError', (error) => {
          console.log('onError', error);
        })
        .onMessage((type, payload) => {
          if (type === 'idCheck.onResize') {
            modalHeight.value = `${payload.height + 50}px`;
          }
        })
        .build();

      console.log(snsWebSdkInstance);

      snsWebSdkInstance.launch('#sumsub-websdk-container');
    };

    const modalHeight = ref('460px');

    onMounted(async () => {
      const result = await getNewAccessToken();

      launchWebSdk(result.token);
    });

    return {
      modalHeight,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/styles/colors';
@import '@/assets/styles/base';

::v-deep {
  .modal__body {
    max-height: 80% !important;
  }
}

.kys-verification-wrap {
  &__inner {
    height: 460px;
    padding: 0;
    background-color: white !important;
  }

  ::v-deep {
    .card__body {
      position: relative;
    }

    .card {
      // overflow-y: auto;

      &__title,
      &__body {
        background-color: white;
      }

      &__title {
        padding: 14px 15px;

        span {
          color: black;
        }
      }
    }
  }
}
</style>
