<template>
  <Modal :stop-mobile-max-height="true" @close="close">
    <Card :title="$t('standalone.title').toUpperCase()" class="card">
      <div class="standalone-wrap">
        <p>{{ $t('standalone.description1') }}</p>
        <p>{{ $t('standalone.description2') }}</p>
      </div>
    </Card>
    <Button
      :text="$t('standalone.btn')"
      type="secondary"
      class="standalone-btn"
      @click="close"
    />
  </Modal>
</template>
<script>
import Modal from '@/modules/modals/Modal';
import Card from '@/components/ui/Card';
import Button from '@/components/ui/Button';

const EVENTS = {
  close: 'close',
};

export default {
  components: { Modal, Card, Button },
  emits: [EVENTS.close],
  setup(_, { emit }) {
    const close = () => {
      emit(EVENTS.close);
    };

    return {
      close,
      EVENTS,
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/colors';
@import '@/assets/styles/base';

.standalone-wrap {
  height: auto;
  overflow-y: auto;
  margin-bottom: 45px;
  padding-right: 15px;
  box-sizing: border-box;
  font-family: Gotham_Pro_Regular;
  line-height: 20px;

  p {
    &.underline {
      text-decoration-line: underline;
    }
  }

  div {
    margin-top: 25px;

    h4 {
      font-family: Gotham_Pro_Bold !important;
    }
  }

  span {
    text-decoration-line: underline;
    cursor: pointer;
  }
}

.standalone-btn {
  margin-top: 30px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
</style>
