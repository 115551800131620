<template>
  <div class="info-block-vert">
    <div
      :class="{ underline }"
      class="info-block-vert__title decoration--underlined text-secondary"
    >
      <span
        @mouseover="showHint = true"
        @touchend="showHint = true"
        @mouseleave="showHint = false"
        v-click-away="onAwayClick"
      >
        {{ title }}
      </span>
    </div>
    <div
      :class="{ isRed, isGreen, large }"
      class="info-block-vert__value"
      v-html="value"
    />
    <Hint
      v-if="hintTitle && showHint"
      ref="hintElement"
      :title="hintTitle"
      :hint-options="hintOptions"
    />
  </div>
</template>
<script>
import Hint from '@/components/ui/Hint';
import { ref } from 'vue';

export default {
  name: 'InfoBlockVert',
  components: {
    Hint,
  },
  props: {
    title: {
      required: true,
    },
    value: {
      required: true,
    },
    isRed: {
      type: Boolean,
      default: false,
    },
    isGreen: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
    underline: {
      type: Boolean,
      default: false,
    },
    hintTitle: {
      type: String,
      default: '',
    },
    hintOptions: {
      type: Object,
    },
  },
  setup() {
    const showHint = ref(false);

    const onAwayClick = () => {
      showHint.value = false;
    };

    return {
      showHint,
      onAwayClick,
    };
  },
};
</script>
<style lang="scss" scoped>
.info-block-vert {
  display: flex;
  flex-direction: column;

  &__title {
    font-size: 0.9375rem;
    line-height: 14px;

    span {
      font-family: Gotham_Pro_Regular;
      color: #888;
      user-select: none;
    }

    &.underline {
      text-decoration-line: underline;
      text-decoration-thickness: 1px;
      cursor: pointer;
    }

    // .label-value-item__label:not(.underline) {
    //   text-decoration-line: underline;
    //   text-decoration-thickness: 1px;
    //   cursor: pointer;
    // }
  }

  &__value {
    margin-top: 3px;
    font-size: 15px;
    font-family: Cantarell_Bold;
    line-height: 20px;
    text-transform: uppercase;

    &.large {
      font-size: 30px;
      line-height: 33px;
    }

    &.isRed {
      color: #de5e56;
    }

    &.isGreen {
      color: #509f96;
    }
  }
}

body.dark {
  .info-block-vert {
    &__title {
      span {
        color: #888;
      }
    }
  }
}
</style>
