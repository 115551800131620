<template>
  <Modal :stop-mobile-max-height="true" @close="$emit(EVENTS.close)">
    <div class="features-modal">
      <Card
        :title="$t('popups.crowdfunding.title')"
        class="card"
        :body-class="'card-full'"
      >
        <div class="card-content">
          <crowdfunding />
        </div>
      </Card>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/modules/modals/Modal';
import Card from '@/components/ui/Card';
import Crowdfunding from '@/modules/features/Crowdfunding/Crowdfunding';

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'FeaturesModal',
  components: {
    Modal,
    Card,
    Crowdfunding,
  },
  setup() {
    const EVENTS = {
      close: 'close',
    };

    return { EVENTS };
  },
});
</script>

<style scoped lang="scss">
.features-modal {
  .card-content {
    max-height: calc(100vh - 280px);
    overflow-y: auto;
    padding-right: 20px;
    padding-left: 20px;
    box-sizing: border-box;

    .mt-15 {
      margin-top: 25px;
    }
  }
}
</style>
