<template>
  <div class="features-confirm-content">
    <div class="modal-card">
      <div class="modal-card-content modal-card-scroller relative">
        <p>
          {{ confirmText }}
        </p>
        <div v-if="userId" class="modal-card-pay-button">
          <div v-if="activeConnect.provider === userProviders.handCash">
            <HandCash
              :loading="handcashTxLoading"
              :margin="amount"
              @onClick="openHandCashPosition"
            />
          </div>
          <div v-if="activeConnect.provider === userProviders.fiorin">
            <HandCash
              :loading="handcashTxLoading"
              :margin="amount"
              is-usd
              @onClick="openFiorinPosition"
            />
          </div>
          <div
            v-if="activeConnect.provider === userProviders.dotWallet"
            style="width: 210px; margin: 0 0 20px 10px"
          >
            <DotwalletButton
              :address="to"
              :amount="+amount"
              :opreturn="transactionKey.toHex()"
              product-name="Feature"
              @payment="onPayment"
              @error="onError"
            />
          </div>
          <div v-if="activeConnect.provider === userProviders.moneyButton">
            <MoneyButton
              v-if="msg.indexOf(' ') === -1"
              :outputs="[
                {
                  to: to,
                  amount: amount,
                  currency: defaultCurrency,
                },
                {
                  script: transactionKey.toAsm(),
                  amount: '0',
                  currency: defaultCurrency,
                },
              ]"
              @payment="onPayment"
              @error="onError"
              @load="onLoad"
            />
          </div>
          <div
            v-if="activeConnect.provider === userProviders.relayX"
            class="relay-btn"
          >
            <RelayX
              v-if="msg.indexOf(' ') === -1"
              label="OPEN"
              :outputs="[
                {
                  to: to,
                  amount: amount,
                  currency: defaultCurrency,
                },
                {
                  script: transactionKey.toAsm(),
                  amount: '0',
                  currency: defaultCurrency,
                },
              ]"
              @payment="onPayment"
              @error="onError"
              @load="onLoad"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { userProviders } from '@/modules/user/api';
import { paymentUID } from '@/helpers/utils';
import { computed, reactive, watch } from 'vue';
import { toRefs } from '@vueuse/core';
import { useI18n } from 'vue-i18n';
import notify from '@/plugins/notify';
import envConfig from '@/config';

import RelayX from '@/components/RelayXButton/RelayXButton';
import MoneyButton from '@/components/MoneyButton';
import HandCash from '@/components/HandCashButton';
import DotwalletButton from '@/components/DotwalletButton/DotwalletButton';
import { NullDataBuilder } from 'dxs-stas-sdk/dist/script/build/null-data-builder';

export default {
  name: 'PayButton',
  components: {
    MoneyButton,
    HandCash,
    RelayX,
    DotwalletButton,
  },
  props: {
    to: {
      type: String,
      default: '',
    },
    amount: {
      type: [String, Number],
      default: 0.01,
    },
    currency: {
      type: String,
      default: 'USD',
    },
    title: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const { t } = useI18n();

    const localState = reactive({
      handcashTxLoading: false,
      defaultCurrency: 'BSV',
    });

    const provider = computed(() => {
      return store.getters['user/provider'];
    });

    const userId = computed(() => {
      return store.getters['user/userId'];
    });

    const msg = computed(() => {
      return paymentUID();
    });

    const transactionKey = computed(() => {
      return new NullDataBuilder([
        Buffer.from(envConfig.txPrefix),
        Buffer.from(msg.value),
        Buffer.from('text'),
        Buffer.from('B'),
      ]);
    });

    const getUserBalance = computed(() => {
      return store.getters['user/getUserBalance'];
    });

    const activeConnect = computed(() => {
      return store.getters['connectors/activeConnect'];
    });

    const fiorinLastTx = computed(() => store.getters['fiorin/fiorinLastTx']);

    watch(
      () => fiorinLastTx.value,
      () => {
        if (fiorinLastTx.value) {
          store.dispatch('fiorin/setFiorinLastTx', '');
          frames.fiorin.postMessage(
            { event: 'GET_BALANCE' },
            process.env.VUE_APP_FIORIN_HOST
          );
          localState.handcashTxLoading = false;
          emit('payment');
        }
      }
    );

    const fiorinMode = computed(() => store.getters['burger/fiorinModeFlag']);

    const confirmText = computed(() => {
      return t('popups.crowdfunding.modalConfirmText', {
        symbol: fiorinMode.value ? 'USD' : 'BSV',
      })
        .replace('__feature_name__', props.title)
        .replace('__feature_amount__', props.amount);
    });

    const onPayment = async () => {
      emit('payment');
    };

    const openFiorinPosition = () => {
      if (localState.handcashTxLoading) {
        return;
      }

      localState.handcashTxLoading = true;

      const message = `19HxigV4QyBv3tHpQVcUEQyq1pzZVdoAut ${msg.value} text B`;

      frames.fiorin.postMessage(
        {
          event: 'REFILL',
          payload: {
            amount: +props.amount,
            message: message,
            type: 'FUND',
          },
        },
        process.env.VUE_APP_FIORIN_HOST
      );
    };

    const openHandCashPosition = async () => {
      localState.handcashTxLoading = true;

      const message = `19HxigV4QyBv3tHpQVcUEQyq1pzZVdoAut ${msg.value} text B`
        .split(' ')
        .map((item) => Buffer.from(item).toString('hex'));
      const result = await activeConnect.value.sendHandCashTx({
        msg: message,
        to: props.to,
        amount: +props.amount,
        description: `Crowdfunding`,
      });

      if (result && result.transactionId) {
        await onPayment({
          txid: result.transactionId,
        });
        localState.handcashTxLoading = false;
      }

      if (result && result.error) {
        localState.handcashTxLoading = false;
        notify({
          text: result.error.message || 'Handcash: unknown pay error',
          type: 'error',
        });
      }
    };

    const onLoad = () => {
      // Moneybutton is loaded
    };

    const onError = () => {
      emit('error');
    };

    return {
      ...toRefs(localState),
      userProviders,
      provider,
      userId,
      confirmText,
      openHandCashPosition,
      openFiorinPosition,
      onLoad,
      onPayment,
      onError,
      msg,
      transactionKey,
      getUserBalance,
      activeConnect,
    };
  },
};
</script>
<style lang="scss">
.features-confirm-content {
  .modal-card .modal-card-pay-button {
    position: absolute;
    left: 0;
    bottom: 0;
    min-height: 50px;
    width: 100%;

    .relay-btn {
      margin-left: 10px;
    }
  }

  .modal-card-content.modal-card-scroller {
    min-height: 135px;
  }

  .modal-card-pay-button {
    position: relative;
  }

  .modal-card-title {
    font-family: Gotham_Pro_Medium;
  }

  p {
    font-family: Gotham_Pro_Regular;
    line-height: 1.25;
  }

  .modal-card-scroller {
    overflow: hidden;
  }
}
</style>
