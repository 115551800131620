export const DIRECTIVE_TYPES = {
  email: 'email',
  number: 'number',
  tel: 'tel',
};

export const PLUS_BTN = [187, 43];

export const MARKETS_TYPES = [
  '_star',
  '_fire',
  'crypto',
  'commodities',
  'stocks',
  'indices',
  'forex',
];

export const DEFAULT_MARKET = 'crypto';

export const APP_ROUTE_NAMES = {
  dashboard: 'Dashboard',
  default: 'Default',
  markets: 'Markets',
  positions: 'Positions',
  account: 'Account',
  chart: 'Chart',
  login: 'Login',
  signup: 'Signup',
  confirmation: 'confirmation',
  mbIndex: 'indexMb',
  accountDeletion: 'accountDeletion',
  notFound: 'NotFound',
};

export const TABS_ROUTE_NAMES = {
  markets: APP_ROUTE_NAMES.markets,
  positions: APP_ROUTE_NAMES.positions,
  account: APP_ROUTE_NAMES.account,
  default: APP_ROUTE_NAMES.default,
};

export const MOBILE_ROUTES_WHITE_LIST = [
  APP_ROUTE_NAMES.markets,
  APP_ROUTE_NAMES.positions,
  APP_ROUTE_NAMES.account,
  APP_ROUTE_NAMES.chart,
];

export const SWAP_URL = 'https://swaps.app/checkout/dxs';

export const STATS_URL = '/stats';

export const FAVOURITES_LIMIT_TO_SHOW = 4;

export const SETTLEMENT_INTERVAL = 60000;

export const MODAL_DEFAULT_Z_INDEX = 901;

export const CONNECTION_LOST_TIMEOUT = 15000;

export const POSITION_OPEN_MIN_VAL = 0.01;

export const DOCUMENT_STATES = {
  visible: 'visible',
  hidden: 'hidden',
};
