/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'trust-wallet': {
    width: 48,
    height: 48,
    viewBox: '0 0 48 48',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M41.646 5.362c.643 0 1.25.264 1.698.712.448.459.7 1.078.689 1.71-.115 6.839-.379 12.071-.872 16.214-.482 4.143-1.205 7.218-2.295 9.708-.735 1.664-1.641 3.041-2.709 4.211-1.434 1.55-3.075 2.674-4.865 3.742-.765.457-1.561.91-2.396 1.383-1.78 1.012-3.739 2.124-5.958 3.608-.803.54-1.848.54-2.65 0-2.254-1.502-4.238-2.628-6.035-3.647-.4-.227-.79-.448-1.172-.667-2.1-1.217-3.994-2.376-5.646-4.051-1.102-1.102-2.054-2.445-2.812-4.04-1.032-2.134-1.732-4.716-2.249-8.055-.688-4.464-1.033-10.305-1.159-18.407-.011-.63.23-1.25.677-1.71a2.433 2.433 0 011.71-.711h.987c3.04.012 9.754-.287 15.56-4.808a2.394 2.394 0 012.927 0c5.806 4.521 12.52 4.82 15.572 4.808h.998zM36.31 32.18c.746-1.538 1.366-3.66 1.836-6.713.562-3.65.907-8.607 1.067-15.354-3.58-.104-9.73-.792-15.595-4.74-5.863 3.936-12.014 4.625-15.583 4.74.126 5.577.378 9.915.78 13.323.46 3.878 1.113 6.506 1.928 8.354.54 1.227 1.136 2.111 1.848 2.891.952 1.045 2.157 1.905 3.798 2.892.68.409 1.431.833 2.248 1.296 1.456.824 3.122 1.767 4.981 2.95 1.826-1.164 3.468-2.097 4.906-2.915.434-.247.85-.483 1.245-.711 2.02-1.16 3.512-2.123 4.625-3.248.746-.768 1.354-1.606 1.916-2.765z" _fill="#404040"/>'
  }
})
