<template>
  <div
    :class="{
      max,
      active,
      long,
      reverseColor,
      isOpacity,
    }"
    class="tab-button"
    @click="$emit('onClick')"
  >
    {{ title }}
  </div>
</template>
<script>
export default {
  props: {
    max: {
      type: Boolean,
      default: false,
    },
    long: {
      type: Boolean,
      default: false,
    },
    reverseColor: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    isOpacity: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss">
.tab-button {
  background-color: #2a2b3f;
  border-radius: 4px;
  border: 1px solid transparent;
  color: #fff;
  min-width: 80px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 44px;
  box-sizing: border-box;
  font-size: 14px;
  font-family: Gotham_Pro_Medium;
  transition: all 0.5s ease;

  &.isOpacity {
    opacity: 0.5;

    @media (max-width: 520px) {
      opacity: 1;
    }
  }

  &.reverseColor {
    background-color: #fff;
    color: #000;

    &:hover {
      background-color: #2a2b3f;
      color: #fff;
    }
  }

  @media (min-width: 520px) {
    &:hover {
      cursor: pointer;
      background-color: #fff;
      color: #353535;
    }
  }

  &.active {
    border: 1px solid #fff;
    background: rgb(20, 23, 33);
  }

  &.max {
    width: 100%;
    text-transform: uppercase;
  }

  &.long {
    min-width: 180px;
  }
}

body.light {
  .tab-button {
    background-color: rgb(235, 235, 235);
    color: rgb(148, 148, 148);

    @media (min-width: 520px) {
      &:hover {
        background-color: #000;
        color: #fff;
      }
    }

    &.active {
      border: 1px solid #000;
      background: #fff;
      color: #000;

      &:hover {
        border: 1px solid #000;
        background: #fff;
        color: #000;
      }
    }
  }
}

body.twilight {
  .tab-button {
    background-color: #485490;
    color: #fff;

    @media (min-width: 520px) {
      &:hover {
        background-color: #5a67a7;
        color: #fff;
      }
    }

    &.active {
      border: 1px solid #fff;
      background: transparent;
    }
  }
}
</style>
