<template>
  <Modal stop-mobile-max-height to-top @close="onClose">
    <Card
      :title="$t('closePositionsModal.title')"
      :description="
        $t('closePositionsModal.description', { val: userTradeInfo.c })
      "
      class="card"
    >
      <div class="confirm-remove">
        <InfoBlockVertPanel
          title="Est. Net P/L, USD"
          :is-green="userTradeInfo.pl_b >= 0"
          :is-red="userTradeInfo.pl_b < 0"
          :value="`${userTradeInfo.pl_b >= 0 ? '+ ' : ''}${usdBsvFormatAmount(
            userTradeInfo.pl_b,
            '',
            true,
            true
          )}`"
          class="confirm-remove__stats-total"
        />
        <InfoBlockVert
          title="Amount, USD"
          :value="usdBsvFormatAmount(userTradeInfo.a, '', true, true)"
          class="confirm-remove__stats-block"
        />
      </div>
    </Card>
    <div
      :class="{
        standalone:
          wd.navigator.standalone || isRunningStandaloneAndroid() || isTelegram,
      }"
      class="btns"
    >
      <Button text="Close 100%" type="secondary" @click="closePositions" />
    </div>
  </Modal>
</template>

<script>
import Modal from '@/modules/modals/Modal';
import Card from '@/components/ui/Card';
import Button from '@/components/ui/Button';
import { computed } from 'vue';
import { connApp } from '@/helpers/signalR';
import notify from '@/plugins/notify';
import { usdBsvFormatAmount } from '@/helpers/utils';
// import { useI18n } from 'vue-i18n';
import { isRunningStandaloneAndroid } from '@/helpers/detects';
import { useStore } from 'vuex';
import InfoBlockVert from '@/components/ui/InfoBlockVert';
import InfoBlockVertPanel from '@/components/ui/InfoBlockVertPanel';

const EVENTS = {
  close: 'close',
};

export default {
  components: { Modal, Card, Button, InfoBlockVert, InfoBlockVertPanel },
  emits: [EVENTS.close],
  setup(_, { emit }) {
    // const { t } = useI18n();
    const store = useStore();

    const onClose = () => {
      emit(EVENTS.close);
    };

    const closePositions = async () => {
      onClose();
      notify({
        text: 'Closing positions...',
        type: 'info',
      });
      store.dispatch('localUiSettings/setRemoveAllPositionsStatus', true);
      try {
        await connApp.invoke('ClosePositions', {});
      } catch (err) {
        setTimeout(() => {
          store.dispatch('localUiSettings/setRemoveAllPositionsStatus', false);
        }, 5000);
        notify({
          text: 'Wait and try again',
          type: 'info',
        });
      }
    };

    const userTradeInfo = computed(
      () => store.getters['session/userTradeInfo']
    );

    const isTelegram = computed(() =>
      Boolean(store.getters['telegram/instance'])
    );

    return {
      wd: window,
      isTelegram,
      userTradeInfo,
      usdBsvFormatAmount,
      onClose,
      isRunningStandaloneAndroid,
      closePositions,
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/colors';
@import '@/assets/styles/base';

.confirm-remove {
  height: auto;
  height: 300px;
  overflow: hidden;
  max-height: 300px;
  width: calc(100% + 40px);
  margin-left: -20px;
  overflow-y: auto;
  margin-bottom: 45px;
  padding: 20px 20px 0;
  box-sizing: border-box;
  font-family: Gotham_Pro_Regular;
  line-height: 20px;

  @media screen and (min-width: 1024px) {
    max-height: calc(100vh - 200px) !important;
    width: calc(100% + 80px);
    margin-left: -40px;
    padding: 20px 40px 0;
  }

  &__stats-total {
    margin-left: -20px;
    margin-bottom: 25px;
    width: calc(100% + 40px);
    padding: 15px 20px;

    @media screen and (min-width: 1024px) {
      margin-left: -40px;
      width: calc(100% + 80px);
      padding: 15px 40px;
    }
  }

  &__stats-block {
    margin-top: 24px;
  }
}

.btns {
  width: 100%;
  margin-top: 30px;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 1024px) {
    bottom: 50px;

    &.standalone {
      bottom: 82px;
    }
  }

  a {
    text-decoration: none;
  }
}
</style>
