<template>
  <Modal
    @close="$emit(EVENTS.close)"
    :stop-mobile-max-height="true"
    modalClass="_align-bottom"
  >
    <Card
      :title="$t('blocks.titles.marketData')"
      :long-auto="true"
      class="card"
      :body-class="'card-full'"
    >
      <MarketInfo hide-toggle />
    </Card>
  </Modal>
</template>

<script>
import Modal from '@/modules/modals/Modal';
import Card from '@/components/ui/Card';
import MarketInfo from '@/modules/marketInfo/MarketInfo.vue';

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'MarketInfoModal',
  components: {
    Modal,
    Card,
    MarketInfo,
  },
  props: {
    hideToggle: Boolean,
  },
  setup() {
    const EVENTS = {
      close: 'close-modal',
    };

    return {
      EVENTS,
    };
  },
});
</script>
