import HandcashConnector from '@/wallets/Handcash';
import MoneybuttonConnector from '@/wallets/Moneybutton';
import DotwalletConnector from '@/wallets/Dotwallet';

import { useScope } from '@/compositions/useScope';
import { saveMobileScrollPositions, setLocalItem } from '@/helpers/storage';
import { getAuthParamsFromRoute } from '@/helpers/utils';

export default ({ store, router }) => {
  const scopeComposition = useScope();

  router.beforeEach(async (to, from, next) => {
    // auth checks
    const { handCashAuthToken, mbAuthCode, isNotAuthPage, dwAuthCode } =
      getAuthParamsFromRoute(to);

    if (mbAuthCode) {
      const moneybuttonWallet = new MoneybuttonConnector({
        code: mbAuthCode,
      });
      store.dispatch('connectors/setActiveConnect', moneybuttonWallet);
    }

    if (dwAuthCode) {
      const dotwalletWallet = new DotwalletConnector({ code: dwAuthCode });
      store.dispatch('connectors/setActiveConnect', dotwalletWallet);
    }

    // INITIALIZE WHEN TOKEN IN URL
    if (
      handCashAuthToken !== 'undefined' &&
      handCashAuthToken &&
      handCashAuthToken.length % 2 === 0
    ) {
      const handCashWallet = new HandcashConnector({
        accessToken: handCashAuthToken,
      });
      store.dispatch('connectors/setActiveConnect', handCashWallet);
    }

    if (scopeComposition.isNotDesktop && isNotAuthPage) {
      if (!from.name) {
        ['Account', 'Markets', 'Positions'].forEach((routeName) => {
          setLocalItem(`mobileScrollPositions.${routeName}`, {
            top: 0,
            left: 0,
          });
        });
      }
      saveMobileScrollPositions(from.name);
    }
    next();
  });
};
