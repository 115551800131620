<template>
  <ItemLabelValue
    :label="$t('order.card.titles.side')"
    :is-green="position.side === PositionSides.BUY"
    :is-red="position.side === PositionSides.SELL"
    :value="position.side.toUpperCase()"
  />
  <ItemLabelValue :label="$t('order.card.titles.entryPrice')">
    <Icon
      name="pen"
      width="14"
      height="14"
      class="icon-opacity"
      @click="$emit('setEntryPrice')"
    />
    {{
      position.side === PositionSides.BUY
        ? PositionSideTypes.ask
        : PositionSideTypes.bid
    }}
    {{ calcEntryPrice(position) }}
  </ItemLabelValue>

  <ItemLabelValue :label="$t('order.card.titles.currentPrice')">
    {{
      position.side === PositionSides.BUY
        ? PositionSideTypes.ask
        : PositionSideTypes.bid
    }}
    {{ calcClosePrice(position) }}
  </ItemLabelValue>

  <ItemLabelValue
    :label="$t('order.card.titles.amount')"
    :value="`${
      position.base_currency === 'USD'
        ? getFormatedAmount(position.amount, true)
        : toCurrencyFormat(position.amount)
    } ${position.base_currency === 'USD' ? 'USD' : 'BSV'}`"
  />
  <ItemLabelValue
    :label="
      $t('order.card.titles.margin') +
      `${position.is_bounty ? ' (bounty)' : ''}`
    "
    :value="`${
      fiorinMode && !fiorinStables.includes(position?.currency?.toLowerCase())
        ? toUSDMarginFormat(position.margin, 2)
        : toCurrencyFormat(position.margin)
    } ${
      !activeConnect?.provider
        ? 'USD'
        : fiorinMode
        ? position.is_bounty
          ? 'USD'
          : position?.currency
        : 'BSV'
    }`"
    :hint-options="{ top: '-40px', left: '70px' }"
    :hint-title="$t('order.card.hints.margin')"
  />
  <ItemLabelValue
    :label="$t('order.card.titles.leverage')"
    :value="`${(+toCurrencyFormat(position.leverage))
      .toFixed(1)
      .replace('.0', '')}×`"
  />

  <ItemLabelValue :label="$t('order.card.titles.takeProfit')">
    <Icon
      name="pen"
      width="14"
      height="14"
      class="icon-opacity"
      @click="$emit('setTakeProfit')"
    />
    <div v-if="position.take_profit_price" class="tpsl-label">
      {{
        `${
          position.side === PositionSides.BUY
            ? PositionSideTypes.bid
            : PositionSideTypes.ask
        } ${toCurrencyFormat(position.take_profit_price)}`
      }}
    </div>
  </ItemLabelValue>

  <ItemLabelValue :label="$t('order.card.titles.stopLoss')">
    <Icon
      name="pen"
      width="14"
      height="14"
      class="icon-opacity"
      @click="$emit('setStopLoss')"
    />
    <div v-if="position.stop_loss_price" class="tpsl-label">
      {{
        `${
          position.side === PositionSides.BUY
            ? PositionSideTypes.bid
            : PositionSideTypes.ask
        } ${toCurrencyFormat(position.stop_loss_price)}`
      }}
    </div>
  </ItemLabelValue>

  <ItemLabelValue
    :label="$t('order.card.titles.liquidationPrice')"
    :value="`${
      position.side === PositionSides.BUY
        ? PositionSideTypes.bid
        : PositionSideTypes.ask
    } ${toCurrencyFormat(position.liquidation_price)}`"
    :hint-options="{ top: '-40px', left: '140px' }"
    :hint-title="$t('order.card.hints.liquidationPrice')"
  />
  <ItemLabelValue
    :label="$t('order.card.titles.created')"
    :value="showDateFormat(position.proposed)"
  />
  <ItemLabelValue
    v-if="position.rebate"
    :label="$t('order.card.titles.liquidityRebate')"
    :value="`-${toCurrencyFormat(position.rebate)}`"
    :hint-options="{ top: '-100px', left: '135px' }"
    :hint-title="$t('order.card.hints.liquidityRebate')"
  />
</template>
<script>
import ItemLabelValue from '@/components/ui/ItemLabelValue.vue';
import {
  toCurrencyFormat,
  toUSDMarginFormat,
  getFormatedAmount,
} from '@/helpers/utils';
import usePositionCalcs from '@/compositions/usePositionCalcs';
import Icon from '@/components/ui/Icon';
import '@/assets/icons/pen';
import DateTime from 'luxon/src/datetime.js';
import { PositionSides } from '@/config/constants';
import { PositionSideTypes } from '@/modules/positions/helpers';
import { computed } from 'vue';
import { useStore } from 'vuex';
import { fiorinStables } from '@/config/constants';

export default {
  name: 'PositionDetailsProposed',
  components: { ItemLabelValue, Icon },
  props: {
    position: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const store = useStore();
    const { calcClosePrice, calcEntryPrice } = usePositionCalcs();

    const fiorinMode = computed(() => store.getters['burger/fiorinModeFlag']);

    const activeConnect = computed(
      () => store.getters['connectors/activeConnect']
    );

    const showDateFormat = (date) =>
      DateTime.fromISO(date).toUTC().toFormat('HH:mm, dd.MM.yy');

    return {
      toCurrencyFormat,
      toUSDMarginFormat,
      calcClosePrice,
      calcEntryPrice,
      showDateFormat,
      getFormatedAmount,
      PositionSides,
      PositionSideTypes,
      fiorinMode,
      activeConnect,
      fiorinStables,
    };
  },
};
</script>
<style lang="scss">
.icon-opacity {
  transition: all 0.2s ease-in-out;
  opacity: 0.3;

  &:hover {
    cursor: pointer;
    opacity: 0.5;
  }
}
</style>
