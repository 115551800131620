import isEqual from 'lodash/isEqual';
import keys from 'lodash/keys';
import pick from 'lodash/pick';
import uniqBy from 'lodash/uniqBy';
import sortBy from 'lodash/sortBy';
import { PositionTypes } from '../helpers';

export const isPositionsLinked = (collectedPositions) => {
  const uniqPositions = uniqBy(collectedPositions, 'id');
  const sortedPositions = sortBy(uniqPositions, 'id');

  if (sortedPositions.length !== 2) {
    return false;
  }

  const [splitedFromPosition, newPosition] = sortedPositions;

  return newPosition.parent_position_id === splitedFromPosition.id;
};

export const isPositionDuplicate = (position, getPositionById) => {
  const existingPosition = getPositionById(position.id);
  const plainObjectFromStore = pick(existingPosition, keys(position));

  // TODO: We have to reset this field scince it redefining in store
  // TODO: refactor calcStats function not to overwrite this field
  plainObjectFromStore.closed_price = null;

  return isEqual(position, plainObjectFromStore);
};

export const isPositionUpdated = (position, getPositionById) =>
  Boolean(getPositionById(position.id));

export const isPositionOpened = (position, getPositionById) =>
  position.state === PositionTypes.opened && !getPositionById(position.id);

export const isPositionProposed = (position, getPositionById) =>
  position.state === PositionTypes.proposed && !getPositionById(position.id);

export const isPositionClosed = (position) =>
  position.state === PositionTypes.closed;

export const isPositionCanceled = (position) =>
  position.state === PositionTypes.canceled;
