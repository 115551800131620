import { PositionTypes } from '../helpers';
import { SetType } from '.';
import {
  isPositionOpened,
  isPositionClosed,
  isPositionUpdated,
  isPositionsLinked,
  isPositionProposed,
  isPositionCanceled,
  isPositionDuplicate,
} from './predicates';

const createSet = (position, state) => ({
  position,
  state,
});

const createState = (position, getPositionById) => {
  const predicatePairs = [
    [isPositionDuplicate, PositionTypes.duplicate],
    [isPositionCanceled, PositionTypes.canceled],
    [isPositionClosed, PositionTypes.closed],
    [isPositionProposed, PositionTypes.proposed],
    [isPositionOpened, PositionTypes.opened],
    [isPositionUpdated, PositionTypes.updated],
  ];

  const [, state] =
    predicatePairs.find(([predicate]) =>
      predicate(position, getPositionById)
    ) || [];

  if (!state) {
    throw new Error(
      `Unable to determine state for position: ${JSON.stringify(position)}`
    );
  }

  return createSet(position, state);
};

export const createSets = (positions, getPositionById) => ({
  type: isPositionsLinked(positions) ? SetType.linked : SetType.independent,
  set: positions.map((position) => createState(position, getPositionById)),
});
