/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'backforward': {
    width: 12,
    height: 11,
    viewBox: '0 0 12 11',
    data: '<path pid="0" d="M9.355 4.636l1.65-1.65a.317.317 0 000-.448L9.358.892M.903 2.764h9.323M2.645 10.308l-1.65-1.65a.317.317 0 010-.449l1.646-1.645M11.097 8.436H1.774" _stroke="#fff" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
