<template>
  <div :class="{ provideMode }" class="v-relayx-outer">
    <div ref="button" class="v-money-button-inner" />
  </div>
</template>
<script>
import notify from '@/plugins/notify';

export default {
  name: 'RelayXButton',
  props: {
    provideMode: { type: Boolean, default: false },
    to: { type: String, default: null },
    amount: { type: Number, default: null },
    currency: { type: String, default: null },
    opReturn: { type: [Array, String], default: null },
    outputs: { type: Array, default: null },
    editable: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    devMode: { type: Boolean, default: false },
    loaderColor: { type: String, default: '#fff' },
    loaderLeft: { type: Number, default: 50 },
  },
  computed: {
    params() {
      return {
        to: this.to,
        amount: this.amount,
        currency: this.currency,
        opReturn: this.opReturn,
        outputs: this.outputs,
        onPayment: (...args) => {
          this.$emit('payment', ...args);
        },
        onError: (err) => {
          notify({
            text: err,
            type: 'info',
          });
        },
        onLoad: (...args) => {
          this.$emit('load', ...args);
        },
        editable: this.editable,
        disabled: this.disabled,
        devMode: this.devMode,
      };
    },
  },
  mounted() {
    this.refreshRelayX();
  },
  methods: {
    refreshRelayX() {
      import('../../vendors/relay').then((relayone) => {
        relayone.render(this.$refs.button, this.params);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.v-relayx-outer {
  position: relative;
  display: inline-block;
  margin: 0;

  &.provideMode {
    margin: 8px;
  }
}
</style>
