/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'what': {
    width: 15.25,
    height: 15.25,
    viewBox: '0 0 15.25 15.25',
    data: '<path pid="0" d="M7.625.076a7.549 7.549 0 10.002 15.098A7.549 7.549 0 007.625.076zm.042 12.941c-.573 0-1.037-.46-1.037-1.028s.464-1.029 1.037-1.029c.572 0 1.036.461 1.036 1.029s-.464 1.028-1.036 1.028zm2.928-6.503c-.212.361-.664.853-1.358 1.475-.359.322-.583.58-.669.776-.086.195-.126.546-.118 1.05H6.907a39.87 39.87 0 01-.006-.437c0-.539.082-.983.247-1.332.167-.348.497-.738.993-1.174.496-.435.792-.72.89-.855.149-.212.224-.447.224-.703 0-.357-.133-.662-.396-.917-.265-.255-.621-.381-1.068-.381-.433 0-.793.132-1.083.397-.291.266-.49.67-.6 1.214l-1.56-.209a2.878 2.878 0 01.923-1.983c.571-.544 1.32-.815 2.248-.815.975 0 1.751.274 2.328.826.577.549.865 1.19.865 1.92 0 .405-.105.787-.317 1.148z"/>'
  }
})
