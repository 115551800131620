import { connApp } from '@/helpers/signalR';
import { useModals } from '@/modules/modals/api';
import { setModalFlag } from '@/helpers/storage';

const { showModal, modalsByName } = useModals();

const state = {
  info: {},
  logs: [],
  grandTotalLoader: false,
  userTradeInfoHistory: [],
  userTradeInfo: {
    a: 0, // positions balance
    c: 0, // count amount
    pl: 0, // pl total
    r: 0, // risk
    b: {
      '-1': {
        a: 0, // amount total of market
        pl: 0, // pl of market
      },
    }, // buy structure
    s: {
      '-1': {
        a: 0, // amount total of market
        pl: 0, // pl of market
      },
    }, // sell structure
  },
};

const getters = {
  info: (state) => state.info,
  sessionLogs: (state) => state.logs,
  grandTotalLoader: (state) => state.grandTotalLoader,
  userTradeInfo: (state) => state.userTradeInfo,
  userTradeInfoHistory: (state) => state.userTradeInfoHistory,
};

const types = {
  SET_INFO: 'SET_INFO',
  TOGGLE_GRAND_TOTAL_LOADER: 'TOGGLE_GRAND_TOTAL_LOADER',
  SET_USER_TRADE_INFO: 'SET_USER_TRADE_INFO',
  SET_USER_TRADE_INFO_HISTORY: 'SET_USER_TRADE_INFO_HISTORY',
};

const mutations = {
  [types.SET_USER_TRADE_INFO](state, value) {
    state.userTradeInfo = value;
    // console.log('state.userTradeInfo', state.userTradeInfo);
  },
  [types.SET_USER_TRADE_INFO_HISTORY](state, value) {
    state.userTradeInfoHistory = value;
    // console.log('state.userTradeInfo', state.userTradeInfo);
  },
  [types.TOGGLE_GRAND_TOTAL_LOADER](state, value) {
    state.grandTotalLoader = value;
  },
  [types.SET_INFO](state, { value, fiorinMode = false }) {
    if (state.logs.length > 30) {
      state.logs = [];
      console.log(fiorinMode);
    }
    state.logs.push({ time: new Date(), value });

    state.info.maxProfit = value.max_profit;
    state.info.insurancePool = value?.liquidityPoolDetails?.totalUsdBalance; // value.liquidity_pool_balance;
    state.info.userLoosed = value.user_loosed;
    state.info.sessionPL = value.profit - value.loosed;
    state.info.spa = 0.005 * value?.liquidityPoolDetails?.totalUsdBalance; // value.liquidity_pool_balance;
    state.info.sessionStart = value.session_start;
    state.info.sessionEnd = value.session_end;
    state.info.rebateRatio = value.rebate_ratio;

    // override props, only when received them
    console.log('SESSION INFO', value);
    if (Object.keys(value).includes('user_profit')) {
      state.info.userProfit =
        value.user_profit_base + value.unsettled_user_balance;
      state.info.unsettledBalance = value.unsettled_user_balance;
      state.info.myPendingPL = value.user_profit_base - value.user_loosed;

      if (value.unsettled_user_balance > 0) {
        showModal(modalsByName.subCentProfit, true, { usdOnly: true });

        setModalFlag(modalsByName.subCentProfit, true);
      }
    }
  },
};

const actions = {
  toggleGrandTotalLoader({ commit }) {
    commit(types.TOGGLE_GRAND_TOTAL_LOADER, true);

    setTimeout(() => {
      commit(types.TOGGLE_GRAND_TOTAL_LOADER, false);
    }, 2500);
  },
  async updateInfo({ dispatch, commit, rootGetters }, val) {
    const fiorinMode = rootGetters['burger/fiorinModeFlag'];
    const sessionMode = val || fiorinMode ? 'USD' : 'BSV';
    const result = await connApp.invoke('GetUserBalance', sessionMode);

    dispatch(
      'positions/updateIncomingBalance',
      {
        positions: (result && result.incoming_margin) || 0,
      },
      { root: true }
    );
    console.log('session info hand', result);
    commit(types.SET_INFO, { value: result, fiorinMode });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export const storePlugin = (store) => {
  connApp.listen('OnSessionInfoChanged');

  connApp.on('OnSessionInfoChanged', (data) => {
    if (!data.is_active) {
      store.dispatch('session/updateInfo');
    }

    console.log('session info', data);
    const fiorinMode = store.rootGetters['burger/fiorinModeFlag'];
    store.commit('session/' + types.SET_INFO, { value: data, fiorinMode });
    store.dispatch('positions/updateIncomingBalance', {
      positions: (data && data.incoming_margin) || 0,
    });
  });

  connApp.listen('OnActivityPointsBalanceUpdated');
  connApp.on('OnActivityPointsBalanceUpdated', async (data) => {
    console.log('BOUNTY POINTS OnActivityPointsBalanceUpdated', data);
    store.dispatch('localUiSettings/setActivityPointsBalance', data);
    const bountyTasks = await connApp.invoke('ListBountyTasks');
    console.log('BOUNTY POINTS UPDATE TASKS', bountyTasks);
    store.dispatch('bounty/setNewBountyBalance', bountyTasks);
  });

  connApp.listen('OnUserStatsUpdated');
  connApp.on('OnUserStatsUpdated', (data) => {
    // console.log(data);
    store.commit('session/' + types.SET_USER_TRADE_INFO, data);

    // flag loading
    const loading = store.getters['positions/sleepLoading'];

    if (loading) {
      store.commit('connectors/UPDATE_LOADING_STATUS', false);
      store.commit('positions/UPDATE_LOADING_STATUS', false);
      store.commit('positions/UPDATE_LOADING_SLEEP_STATUS', false);
      store.commit('markets/UPDATE_LOADING_STATUS', false);
      console.log('UNSLEEP');
    }
  });
};
