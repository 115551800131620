<template>
  <div :class="{ stopBehavior }" class="table-row box-hover">
    <div
      v-for="(row, index) in dataset"
      :key="index"
      :style="{ width: `${row.size}%` }"
      :class="{
        alignLeft: row.align === 'left',
        alignCenter: row.align === 'center',
      }"
      class="table-row__item"
      @click="selectRow(row)"
    >
      <slot :name="index" />
    </div>
  </div>
</template>
<script>
export default {
  name: 'TableRow',
  props: {
    dataset: {
      type: Array,
      required: true,
    },
    active: {
      type: Boolean,
      default: false,
    },
    stopBehavior: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['selectRow'],
  setup(props, { emit }) {
    const selectRow = (row) => {
      emit('selectRow', row);
    };

    return { selectRow };
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/colors';
@import '@/assets/styles/base';

.table-row {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  padding: 0 20px;
  width: 100%;
  transition: background 0.3s ease;
  opacity: 0.9;

  &__content {
    display: flex;
  }

  &:hover {
    cursor: pointer;
  }

  &__item {
    display: flex;
    align-items: center;
    user-select: none;
    -webkit-user-select: none;
    text-align: left;
    min-height: 50px;

    &.alignLeft {
      text-align: left;
    }

    &.alignCenter {
      text-align: center;
    }
  }
}

.table-row.stopBehavior {
  .table-row__item {
    min-height: auto;
  }
}
</style>
