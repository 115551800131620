const types = {
  SET_INSTANCE: 'SET_INSTANCE',
};

const state = {
  instance: null,
};

const getters = {
  instance: (state) => state.instance,
};

const mutations = {
  [types.SET_INSTANCE](state, instance) {
    state.instance = instance;
  },
};

const actions = {
  setInstance({ commit }, instance) {
    commit(types.SET_INSTANCE, instance);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
