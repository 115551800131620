import { isIOSDxsApp } from '@/helpers/detects';

const banCountiresForIos = ['VG', 'HK', 'AT', 'LU', 'SG', 'AU', 'NZ', 'YE'];

export const banCountires = [
  'US',
  'CA',
  'SC',
  'CH',
  'DZ',
  'EC',
  'IR',
  'SY',
  'SD',
  'KP',
  'UM',
  'AS',
  'GB',
  'UK',
  'AI',
  'AG',
  'DM',
  'GD',
  'MS',
  'KN',
  'LC',
  'VC',
].concat(isIOSDxsApp() ? banCountiresForIos : []);

export const whiteListAfterBan = ['ID', 'IDN', 'RU', 'RUS'];
