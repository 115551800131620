<template>
  <Modal
    :stop-mobile-max-height="true"
    @close="$emit(EVENTS.close)"
    :modal-class="modalClass"
  >
    <div class="by-crypto-wrap">
      <iframe
        src="https://swaps.app/widget?partner=96cd3b90-23b6-4e34-a076-ad3105cf9af3"
        allow="camera"
        frameborder="0"
        class="landing-swaps-frame"
      />
    </div>
  </Modal>
</template>

<script>
import { defineComponent } from 'vue';

import Modal from '@/modules/modals/Modal';

export default defineComponent({
  name: 'SwapsWidget',
  props: {
    modalClass: {
      type: String,
      default: '',
    },
  },
  components: {
    Modal,
  },
  setup() {
    const EVENTS = {
      close: 'close',
    };

    return { EVENTS };
  },
});
</script>

<style lang="scss">
.by-crypto-wrap {
  display: flex;
  align-items: center;
  justify-content: center;

  .landing-swaps-frame {
    width: 404px;
    height: 680px;
    max-height: calc(100vh - 46px);
    margin: 0 auto;

    @media screen and (max-width: 410px) {
      width: 100%;
    }
  }
}
</style>
