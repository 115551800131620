import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import createPersistedState from 'vuex-persistedstate';
import VueTour from 'v3-tour';
import { cachedPaths } from '@/store';
import 'v3-tour/dist/vue-tour.css';
import { widget } from '../public/charting_library_v28/charting_library/charting_library.esm';
import Icon from '@/components/ui/Icon.vue';
import VueClickAway from 'vue3-click-away';

import registerModule from './registerModule';
import settings from '@/modules/settings';
import modals from '@/modules/modals';
import markets from '@/modules/markets';
import user from '@/modules/user';
import burger from '@/modules/burger';
import features from '@/modules/features';
import session from '@/modules/session';
import positions from '@/modules/positions';
import bounty from '@/modules/bounty';
import i18nFactory from '@/i18n';
import { connMarkets, connApp } from '@/helpers/signalR';

import '@/assets/styles/main.scss';

let options = {
  cachedPaths,
};
options = registerModule(modals, { store, options });
options = registerModule(burger, { store, options });
options = registerModule(user, { store, router, options });
options = registerModule(settings, { store, options });
options = registerModule(markets, { store, options });
options = registerModule(session, { store, options });
options = registerModule(positions, { store, options });
options = registerModule(features, { store, options });
options = registerModule(bounty, { store, options });
// multiple calls of createCachedPaths lead to bad behaviour, hence passing options in/out above
createPersistedState({ paths: options.cachedPaths })(store);

async function start() {
  const app = createApp(App);
  app.provide('tv_widget', widget);
  const i18n = await i18nFactory();
  app
    .use(store)
    .use(router)
    .use(i18n)
    .use(VueTour)
    .use(VueClickAway)
    .component('icon', Icon)
    .mount('#app');
}
start();

connMarkets.start();
connApp.start();
