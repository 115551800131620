<!-- from https://github.com/tran2/vue-swipe-button -->
<template>
  <div>
    <div
      ref="slider"
      class="slider"
      :class="sliderClass"
      :style="{ color: textColor, 'background-color': sliderBackgroundColor }"
    >
      <!--
        the overlay is the dark color stripe that will follow
        the button
      -->
      <div
        ref="overlay"
        class="overlay"
        :style="[overlayStyle, { 'background-color': overlayColor }]"
      />
      <div
        ref="slideButton"
        class="slide-button"
        :style="[{ 'background-color': buttonColor }, slideButtonStyle]"
        @mousedown="startSwipe"
        @mousemove="continueSwipe"
        @mouseup="endSwipe"
        @touchstart="startSwipe"
        @touchmove="continueSwipe"
        @touchend="endSwipe"
      >
        <div class="button-text">
          {{ buttonText }}
        </div>
      </div>
      <div class="slide-text">
        {{ instructionText }}
      </div>
    </div>
  </div>
</template>
<script>
const cssSliderWidth = 40;
const cssSliderLeft = 5;
export default {
  name: 'SwipeButton',
  props: {
    initialInstructionText: {
      type: String,
      default: 'Slide to confirm',
    },
    completedText: {
      type: String,
      default: 'Confirmed',
    },
    buttonText: {
      type: String,
      default: '>>>',
    },
    sliderBackgroundColor: {
      type: String,
      default: '#17255A',
    },
    overlayColor: {
      type: String,
      default: '#FFFFFF',
    },
    buttonColor: {
      type: String,
      default: '#D88373',
    },
    textColor: {
      type: String,
      default: '#F5E2C8',
    },
  },
  data() {
    return {
      cssSliderWidth,
      cssSliderLeft,
      initialMouseX: 0,
      currentMouseX: 0,
      startDrag: false,
      endPoint: 500,
      initialSliderWidth: cssSliderWidth / 2,
      initialSlideButtonPosition: 0,
      instructionTextIsInitial: true,
      overlayStyle: {
        width: `${cssSliderWidth}px`,
      },
      slideButtonStyle: {
        left: `${cssSliderLeft}px`,
      },
      sliderClass: '',
      completed: false,
    };
  },
  computed: {
    instructionText() {
      return this.instructionTextIsInitial
        ? this.initialInstructionText
        : this.completedText;
    },
  },
  mounted() {
    this.endPoint = this.getEndingPoint();
    document.addEventListener('mousemove', this.continueSwipe);
    document.addEventListener('mouseup', this.endSwipe);
  },
  unmounted() {
    document.removeEventListener('mousemove', this.continueSwipe);
    document.removeEventListener('mouseup', this.endSwipe);
  },
  methods: {
    startSwipe(event) {
      if (this.completed) {
        return;
      }
      // this will be used to calculate the offset to increase the width
      // of the slider
      this.initialMouseX = this.getMouseXPosFromEvent(event);
      // once our slider's x button position >= slider - button's width,
      // the action is confirmed
      this.endPoint = this.getEndingPoint();

      this.calculateSliderInitialWidth();
      this.calculateSlideButtonInitialPosition();

      this.updateSlideButton(0);
      this.updateSlider(0);
      this.startDrag = true;
      // for transition animation
      this.sliderClass = 'started';
    },
    getEndingPoint() {
      const clientRects = this.$refs.slider.getClientRects()[0];
      return clientRects && clientRects.right;
    },
    calculateSliderInitialWidth() {
      // const sliderLeftPos = this.$refs.slider.getClientRects()[0].x
      // this.initialSliderWidth = this.initialMouseX - sliderLeftPos
      // if (this.initialSliderWidth < this.cssSliderWidth) {
      //   this.initialSliderWidth = this.cssSliderWidth
      // }
      this.initialSliderWidth = this.cssSliderWidth;
    },
    calculateSlideButtonInitialPosition() {
      this.initialSlideButtonPosition = this.$refs.slider.getClientRects()[0].x;
    },
    continueSwipe(event) {
      if (!this.startDrag) {
        return;
      }
      this.currentMouseX = this.getMouseXPosFromEvent(event);
      const delta = this.currentMouseX - this.initialMouseX;
      this.updateSlider(delta);
      this.updateSlideButton(delta);

      if (this.sliderReachedEndPoint()) {
        this.endSwipe();
      }
    },
    endSwipe() {
      this.startDrag = false;
      if (this.sliderReachedEndPoint()) {
        this.sliderClass = 'completed';
        this.overlayStyle.width = `${
          this.$refs.slider.getClientRects()[0].width + this.cssSliderWidth / 2
        }px`;
        this.actionConfirmed();
      } else {
        this.sliderClass = '';
        this.overlayStyle.width = `${this.cssSliderWidth}px`;
        this.slideButtonStyle.left = `${this.cssSliderLeft}px`;
      }
    },
    getMouseXPosFromEvent(event) {
      return event.clientX || event.touches[0].pageX;
    },
    updateSlider(delta) {
      const sliderWidth = this.getSliderWidth();
      let newWidth = Math.max(
        this.cssSliderWidth,
        this.initialSliderWidth + delta
      ); // Math.max(this.initialSliderWidth + delta + 20, 40)
      // prevent overflow
      if (newWidth > sliderWidth) {
        newWidth = sliderWidth;
      }
      this.overlayStyle.width = `${newWidth}px`;
    },
    getSliderWidth() {
      return (
        this.$refs.slider.getClientRects() &&
        this.$refs.slider.getClientRects()[0].width
      );
    },
    updateSlideButton(delta) {
      if (delta < 0) {
        return;
      }
      this.slideButtonStyle.left = `${this.cssSliderLeft + delta}px`;
      // prevent overflow
      if (this.sliderReachedEndPoint()) {
        const buttonLeftPos =
          this.getSliderWidth() -
          this.getButtonWidth() -
          this.cssSliderWidth +
          this.cssSliderLeft;
        this.slideButtonStyle.left = `${buttonLeftPos}px`;
      }
    },
    getButtonWidth() {
      const slideButtonRect = this.$refs.slideButton.getClientRects()[0];
      return slideButtonRect && slideButtonRect.width;
    },
    sliderReachedEndPoint() {
      const slideButtonRect = this.$refs.slideButton.getClientRects()[0];
      return (
        slideButtonRect &&
        slideButtonRect.right >= this.endPoint - this.cssSliderLeft
      );
    },
    actionConfirmed() {
      // ensure the event is only fire once
      if (!this.completed) {
        this.completed = true;
        this.instructionTextIsInitial = false;
        this.$emit('actionConfirmed');
      }
      this.overlayStyle.width = '40px';
      this.slideButtonStyle.left = '5px';
    },
    reset() {
      this.completed = false;
      this.instructionTextIsInitial = true;
      this.sliderClass = '';
      this.updateSlider(0);
      this.updateSlideButton(0);
      this.slideButtonStyle.left = `${this.cssSliderLeft}px`;
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin nonselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
}

.slider {
  position: relative;
  height: 40px;
  @include flex-center;
  @include nonselect;
  font-family: 'Gotham_Pro_Regular';
  border-radius: 20px;
  border: 1px solid grey;
}

.overlay {
  /* this defines the return animation when user touchout */
  transition: 0.5s ease;
  position: absolute;
  left: 0px;
  border-radius: 20px;
  width: 100px;
  height: 40px;
  @include flex-center;
  @include nonselect;
}

.slide-text {
  @include nonselect;
}

.started {
  .overlay,
  .slide-button {
    transition: none;
  }
}

.slide-button {
  transition: 0.5s ease;
  height: 30px;
  width: 30px;
  position: absolute;
  left: 5px;
  padding-left: 10px;
  padding-right: 10px;
  @include flex-center;
  border-radius: 15px;
  border: 3px solid white;

  .carrot {
    width: 25px;
    height: 25px;
    margin-top: 2px;
    margin-left: 5px;
  }
}
</style>
