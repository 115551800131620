<template>
  <div :class="{ lefted }" class="selector">
    <div
      v-if="
        activeSecondaryMode || (descriptionInput ? !activeItem?.title : true)
      "
      class="selector__actions"
    >
      <div
        v-if="showCarousel"
        class="selector__item fixedArrowWidth back"
        :class="{ disabled: !(!!stateCarouselIndex && showCarousel) }"
        @click="backSlide"
      >
        <Icon name="gonext" width="8px" height="13px" />
      </div>
      <div ref="carouselEl" class="selector__items-wrap">
        <Loader v-if="!carouselSlides.length" class="selector__loader" />
        <template v-else>
          <div
            v-for="(item, ndx) in carouselSlides"
            :key="ndx"
            :class="{
              active: item.title === activeItem.title,
              fixedItemWidth,
              lastChild: !(
                showCarousel && stateCarouselIndex + showCarousel < items.length
              ),
            }"
            class="selector__item"
            @click="select(item)"
          >
            <div class="title">{{ getTitle(item.title)?.title }}</div>
            <div v-if="getTitle(item.title)?.description" class="description">
              {{ getTitle(item.title)?.description }}
            </div>
          </div>
        </template>
      </div>
      <div
        v-if="showCarousel"
        :class="{
          fixedItemWidth,
          disabled: !(
            showCarousel && stateCarouselIndex + showCarousel < items.length
          ),
        }"
        class="selector__item fixedArrowWidth"
        @click="nextSlide"
      >
        <Icon name="gonext" width="8px" height="13px" />
      </div>
    </div>
    <div v-else :class="{ disabled }" class="selector__input">
      <SimpleInput
        new-version
        :value="inputValue"
        type="text"
        :min-value="0"
        :max-value="maxValue"
        :disabled="disabled"
        directive="number"
        :update-value="updateValue"
        :optional-btn="optionalBtn"
        :hide-secondary="hideItems"
        :optional-btn-secondary="optionalBtnSecondary"
        @optionalBack="onOptionalBack"
        @optionalSecondary="onOptionalSecondary"
        :title="inputTitle"
        @focus="$emit('focus')"
        @focusOut="$emit('focusOut')"
        @onChange="onChangeValue"
        class="selectorLiquidityInput"
      />
    </div>
    <div
      v-if="!hideAllDescription && description"
      :class="{ hasValue: activeItem.title && !activeSecondaryMode }"
      class="selector__description"
      @click="onDescriptionClick"
    >
      {{
        activeSecondaryMode
          ? descriptionSecodary
          : !activeItem.title
          ? description
          : descriptionInput || description
      }}
    </div>
  </div>
</template>
<script>
import '@/assets/icons/gonext';
import SimpleInput from '@/components/ui/SimpleInput';
import Icon from '@/components/ui/Icon';
import Loader from '@/components/ui/Loader';

import { ref, computed, onMounted, nextTick } from 'vue';
import { onClickOutside } from '@vueuse/core';

export default {
  components: {
    Icon,
    SimpleInput,
    Loader,
  },
  props: {
    lefted: {
      type: Boolean,
      default: false,
    },
    items: {
      required: true,
    },
    secondaryItems: {
      type: Array,
      default: () => [],
    },
    description: {
      type: String,
      default: '',
    },
    updateValue: {
      type: String,
      default: '',
    },
    descriptionInput: {
      type: String,
      default: '',
    },
    descriptionSecodary: {
      type: String,
      default: '',
    },
    optionalBtnSecondary: {
      type: String,
      default: '',
    },
    optionalBtn: {
      type: String,
      default: '',
    },
    hideAllDescription: {
      type: Boolean,
      default: false,
    },
    showCarousel: {
      type: [Number, String],
      default: '',
    },
    fixedItemWidth: {
      type: Boolean,
      default: false,
    },
    fixedArrowWidth: {
      type: Boolean,
      default: false,
    },
    splitValue: {
      type: String,
      default: '',
    },
    inputTitleLabel: {
      type: String,
      default: '',
    },
    showInputFirst: {
      type: Boolean,
      default: false,
    },
    initialSliderValue: {
      type: [String, Number],
      default: '',
    },
    initialInputValue: {
      type: [String, Number],
      default: '',
    },
    notResetInputAfterSelect: {
      type: Boolean,
      default: false,
    },
    hideItems: {
      type: Boolean,
      default: false,
    },
    // input
    maxValue: {
      type: Number,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    stopOutside: {
      type: Boolean,
      default: false,
    },
    initialPercentAmount: {
      type: [String, Number],
      default: '',
    },
    resetActiveItem: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const activeItem = ref({});
    const inputValue = ref('');
    const stateCarouselIndex = ref(0);
    const activeSecondaryMode = ref(false);

    const onDescriptionClick = () => {
      if (activeItem.value.title && !activeSecondaryMode.value) {
        emit('descriptionClick');
      }
    };

    const carouselSlides = computed(() => {
      const items = activeSecondaryMode.value
        ? props.secondaryItems
        : props.items;
      if (!props.showCarousel) {
        return items;
      }

      return items.slice(
        stateCarouselIndex.value,
        stateCarouselIndex.value + +props.showCarousel
      );
    });

    const nextSlide = () => {
      if (
        !(
          props.showCarousel &&
          stateCarouselIndex.value + props.showCarousel < props.items.length
        )
      ) {
        return;
      }
      activeItem.value = {};
      stateCarouselIndex.value += +props.showCarousel;
      console.log(stateCarouselIndex.value);
    };

    const backSlide = () => {
      if (!(!!stateCarouselIndex.value && props.showCarousel)) {
        return;
      }
      activeItem.value = {};
      stateCarouselIndex.value -= +props.showCarousel;
      console.log(stateCarouselIndex.value);
    };

    const inputTitle = computed(() => {
      return (
        props.inputTitleLabel || `LIQUIDITY AMOUNT, ${selectorCurrency.value}`
      );
    });

    const selectorCurrency = computed(() => {
      return activeItem.value.title || '';
    });

    const onOptionalBack = () => {
      activeItem.value = {};
      if (!props.notResetInputAfterSelect) {
        onChangeValue('');
      }
    };

    const select = (item) => {
      if (activeSecondaryMode.value) {
        emit('selectSecondary', item);
        activeSecondaryMode.value = false;
        return;
      }
      activeItem.value = item;
      emit('select', item);
    };

    const carouselEl = ref(null);

    onClickOutside(carouselEl, () => {
      if (props.stopOutside) {
        return;
      }
      activeSecondaryMode.value = false;
      activeItem.value = {
        title: ' ',
      };
      emit('selectSecondary');
      emit('select');
    });

    const onChangeValue = (value) => {
      inputValue.value = value;
      emit('changeInput', value);
    };

    const getTitle = (item) => {
      if (props.splitValue) {
        const values = item.split(props.splitValue);
        return {
          title: values[0],
          description: values[1] || '',
        };
      }
      return {
        title: item,
      };
    };

    const onOptionalSecondary = () => {
      console.log(!props.updateValue, props.initialPercentAmount);
      if (
        (!props.updateValue || props.updateValue === ' ') &&
        props.initialPercentAmount
      ) {
        const findElem = props.secondaryItems.find(
          (item) => item.title == props.initialPercentAmount
        );
        if (findElem) {
          emit('selectSecondary', findElem);
          return;
        }
      }
      activeSecondaryMode.value = true;
    };

    onMounted(() => {
      if (props.resetActiveItem) {
        activeItem.value = {};
        return;
      }
      const findValue = props.items.find(
        (item) => item.title === props.initialSliderValue
      );

      if (findValue) {
        select(findValue);
        console.log('findValue', findValue);
      } else {
        select(props.items[props.items.length - 1] || {});
      }

      if (props.initialInputValue) {
        onChangeValue(props.initialInputValue);
      }

      nextTick(() => {
        const findElem = props.secondaryItems.find(
          (item) =>
            item.title ==
            (props.initialPercentAmount ||
              localStorage.getItem('setMaxPercentCache'))
        );
        if (findElem) {
          emit('selectSecondary', { value: findElem, fromMount: true });
        }
      });
    });

    return {
      carouselEl,
      inputValue,
      activeItem,
      inputTitle,
      selectorCurrency,
      stateCarouselIndex,
      carouselSlides,
      activeSecondaryMode,
      select,
      onChangeValue,
      onOptionalBack,
      nextSlide,
      backSlide,
      getTitle,
      onDescriptionClick,
      onOptionalSecondary,
    };
  },
};
</script>
<style lang="scss" scoped>
.selector {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &.lefted {
    width: calc(100% + 15px);
    margin-left: -6px;
  }

  &__loader {
    margin-top: -3px;
    width: calc(100% - 90px - 35px);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__description {
    font-size: 14px;
    margin-top: 18px;
    color: rgb(136, 139, 144);

    &.hasValue {
      cursor: pointer;
      color: #888;
    }
  }

  &__input {
    width: 100%;

    &.disabled {
      opacity: 0.3;
    }
  }

  &__actions {
    border: 1px solid #888b90;
    width: 100%;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    height: 60px;
  }

  &__items-wrap {
    display: flex;
    width: 100%;
  }

  &__item {
    width: 100%;
    height: 60px;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-right: 1px solid #a7acb3;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    text-align: center;
    margin-right: -1px;

    &.disabled {
      svg {
        opacity: 0.1;
      }
    }

    .description {
      font-size: 13px;
      margin-top: 5px;
      color: #888;
    }

    &.fixedItemWidth {
      min-width: 69px;
      width: 69px;
    }

    &.fixedArrowWidth {
      min-width: 45px;
      width: 45px;

      &.fixedItemWidth {
        border-left: 1px solid #a7acb3;
      }
    }

    &:last-child {
      &.lastChild {
        // border-right: none;
      }
    }

    &.back {
      svg {
        transform: rotate(180deg);
      }
    }

    &:hover {
      opacity: 0.8;
    }

    // &.active {
    //   background: #fff;
    //   color: #000;
    // }
  }
}

body.dark {
  .selector {
    &__actions {
      border: 1px solid #fff;
    }

    &__description {
      color: #888;

      &.hasValue {
        cursor: pointer;
        color: #fff;
      }
    }

    &__item {
      border-right: 1px solid rgba(255, 255, 255, 0.2);

      .description {
        font-size: 13px;
        margin-top: 5px;
        color: rgba(255, 255, 255, 0.25);
      }

      &.fixedArrowWidth {
        &.fixedItemWidth {
          border-left: 1px solid rgba(255, 255, 255, 0.2);
        }
      }
    }
  }
}
</style>
