const types = {
  SET_FIORIN_LAST_TX: 'SET_FIORIN_LAST_TX',
};

const state = {
  fiorinLastTx: '',
};

const getters = {
  fiorinLastTx: (state) => state.fiorinLastTx,
};

const mutations = {
  [types.SET_FIORIN_LAST_TX](state, data) {
    state.fiorinLastTx = data;
  },
};

const actions = {
  setFiorinLastTx({ commit }, value) {
    commit(types.SET_FIORIN_LAST_TX, value);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
