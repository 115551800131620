<template>
  <Modal :stop-mobile-max-height="true" @close="$emit('close')">
    <Card
      :title="$t('popups.confirmInvite.titles.welcome')"
      class="hello-modal"
    >
      <div
        class="hello-title-description"
        v-html="$t('popups.confirmInvite.titles.story')"
      />
      <div class="hello-btns">
        <Button class="button" type="secondary" width="full" @click="signup">
          {{ $t('popups.confirmInvite.titles.getInvitation') }}
        </Button>
        <Button class="button" type="secondary" width="full" @click="login">
          {{ $t('header.menuItems.logIn') }}
        </Button>
      </div>
    </Card>
  </Modal>
</template>
<script>
import { defineComponent, computed } from 'vue';
import Modal from '@/modules/modals/Modal';
import Card from '@/components/ui/Card';
import Button from '@/components/ui/Button';
import router from '@/router';
import { useStore } from 'vuex';
import { useModals } from '@/modules/modals/api';

export default defineComponent({
  name: 'HellpDXS',
  setup(props, { emit }) {
    const store = useStore();

    const activeConnect = computed(() => {
      return store.getters['connectors/activeConnect'];
    });

    if (+activeConnect.value.satoshiBalance) {
      emit('close');
    }

    const { showModal, modalsByName } = useModals();

    const login = () => {
      emit('close');
      showModal(modalsByName.login);
    };

    const signup = () => {
      emit('close');
      router.push('/DoYouKnowThat');
    };

    return { router, signup, login };
  },
  components: {
    Button,
    Card,
    Modal,
  },
});
</script>
<style lang="scss" scoped>
@import '@/assets/styles/base';
@import '@/assets/styles/colors';

.card.hello-modal {
  min-height: 381px;
  box-sizing: border-box;
}

.button {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 51px;
  font-family: Gotham_Pro_Medium;
  text-transform: uppercase;
  margin: 1px;

  box-sizing: border-box;
  font-size: 15px;
  transition: all 0.3s ease-in-out;

  &:hover {
    cursor: pointer;
    background: $color-white;
    opacity: 1;
    color: $color-black;
  }

  div {
    margin-left: 10px;
  }
}

.hello-title-description {
  margin: 15px 0 50px;
  font-family: Gotham_Pro_Regular;
  line-height: 1.25;
}

.hello-btns {
  width: 100%;
  display: flex;
  position: absolute;
  left: 0;
  bottom: 0;
  flex-direction: row;
  box-sizing: border-box;
}

body.body--light {
  .button {
    background: rgba(195, 192, 192, 0.88);
    color: rgb(51, 51, 51);
    transition: all 0.3s ease-in-out;

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }
}
</style>
