/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'handcash': {
    width: 385,
    height: 387,
    viewBox: '0 0 385 387',
    data: '<path pid="0" _fill="#000" d="M238.428 149.3l75.483-19.205 16.771 65.917-75.483 19.205z"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M325.008 194.534c.569-73.489-58.429-133.644-131.166-134.373l-.011-.046L179.292.45 148 8.143l-32.399 7.965 14.589 59.758C88.185 98.512 59.607 143.28 60.004 194.534c.567 73.115 59.048 131.737 131.445 132.302l14.571 59.666 31.852-7.84 31.807-7.829-14.579-59.709.019-.01c41.329-22.264 69.496-65.946 69.889-116.58zM192.506 0c106.868 0 193.33 87.86 192.5 195.001-.611 78.842-47.742 146.246-115.327 175.832l-31.807 7.829-31.852 7.84c-4.435.319-9.029.493-13.514.493C86.466 386.995.83 301.31.006 195.001-.612 115.275 47.106 46.224 115.6 16.108L148 8.143 179.292.45c4.366-.298 8.772-.45 13.214-.45z" _fill="#000"/><circle pid="2" cx="193.006" cy="195.001" r="67" _fill="#000"/>'
  }
})
