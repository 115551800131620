import Connector from './Connector';
import store from '@/store';
import { userProviders } from '@/modules/user/api';
import axios from 'axios';
import { verifyBitcoinSignedMessage } from 'dxs-stas-sdk/dist/bitcoin/private-key';

async function RelayXQueryPaymailPKI({ paymail, pubkey }) {
  try {
    const { data } = await axios.get(
      `https://relayx.io/bsvalias/verifypubkey/${paymail}/${pubkey}`
    );
    return data.match;
  } catch (e) {
    return false;
  }
}

export async function getAndCheckToken() {
  if (!window.relayone) {
    await import('../vendors/relay');
  }

  const token = await window.relayone.authBeta(true);
  const [payload, signature] = token.split('.');
  const data = JSON.parse(atob(payload));
  // check its token from our origin
  if (data.origin !== window.location.host) {
    throw new Error('token origin is messed');
  }

  const msg = Buffer.from(payload);
  const pk = Buffer.from(data.pubkey, 'hex');
  const sig = Buffer.from(signature, 'base64').slice(1);

  if (!verifyBitcoinSignedMessage(msg, pk, sig)) {
    throw new Error('signature not checked');
  }

  // check that pki matches paymail
  const match = await RelayXQueryPaymailPKI({
    paymail: data.paymail,
    pubkey: data.pubkey,
  });

  return { token, data, match };
}

export default class RelayXConnector extends Connector {
  constructor(opts) {
    super(opts);
    this.provider = 'RelayX';
    this.accessToken = opts.accessToken || '';
    this.satoshiBalance = 0; // 643178 / 100000000
  }

  async fetchBalance() {
    if (window.relayone) {
      const { satoshis } = await window.relayone.getBalance2();
      this.satoshiBalance = satoshis / 100000000;
      return;
    }
    return 0;
  }

  async fetchProfile() {
    this.profile = {};
  }

  async connect() {
    const { token, match } = await getAndCheckToken();
    store.commit('user/UPDATE_AUTH_DATA', {
      data: { accessToken: token },
      provider: userProviders.relayX,
    });
    localStorage.setItem('matchRelay', match || '');
    this.accessToken = token;
    await this.fetchBalance();
    // store.commit('relayx/SET_TOKEN_DATA', data);
    const result = await window.relayone.isLinked();
    if (result) {
      return this;
    } else {
      // radio.emit('toast') // this.$toast.error($t('order.new.toasts.unactiveRelay'))
    }
  }
}
