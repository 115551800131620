export const sortList = (list, field) => {
  if (!field) {
    return list;
  }

  const sortList = list.sort((rowA, rowB) => {
    let fn;
    if (typeof field.prop === 'string') {
      fn = (row) => row[field.prop];
    } else if (typeof field.prop === 'function') {
      fn = field.prop;
    } else {
      return 0;
    }
    if (fn(rowA) > fn(rowB)) {
      return field.reverse ? -1 : 1;
    }
    if (fn(rowA) < fn(rowB)) {
      return field.reverse ? 1 : -1;
    }
    return 0;
  });

  return sortList;
  // return field.reverse ? sortList.reverse() : sortList
};
