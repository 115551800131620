export const openLandingDXS = () => {
  if (window.document.body.clientWidth <= 77480) {
    window.document.dispatchEvent(
      new CustomEvent('refreshLanding', { bubbles: true })
    );
    document.querySelector('.landing-dxs-newwrap').classList.remove('hide');
    // showModal(modalsByName.tutorialHistory);
    return;
  }

  const dxsInfo = JSON.parse(localStorage.getItem('vuex'));
  if (!dxsInfo?.connectors?.activeConnect?.provider) {
    if (window.document.body.clientWidth > 1024) {
      document.querySelectorAll('.landing-buttons')[0].style.display = 'flex';
      document.querySelectorAll('.landing-buttons')[1].style.display = 'none';
    } else {
      document.querySelectorAll('.landing-buttons')[1].style.display = 'flex';
      document.querySelectorAll('.landing-buttons')[0].style.display = 'none';
    }

    document.querySelectorAll('.landing-description')[0].style.marginBottom =
      '0';
    document.querySelectorAll('.landing-description')[1].style.marginBottom =
      '0';
  } else {
    document.querySelectorAll('.landing-buttons')[0].style.display = 'none';
    document.querySelectorAll('.landing-buttons')[1].style.display = 'none';

    document.querySelectorAll('.landing-description')[0].style.marginBottom =
      '80px';
    document.querySelectorAll('.landing-description')[1].style.marginBottom =
      '80px';
  }
  document.querySelector('.landing-dxs').classList.add('show');
};
