import store from '@/store';

export default (params = {}) => {
  const defaultParams = {
    warning: {
      title: 'Error',
    },
    info: {
      title: 'Info',
    },
    success: {
      title: 'Success',
    },
  };

  const getParams = (params = {}) => {
    const type = params.type || 'warning';
    const title = params.title || defaultParams[type].title;
    const text = params.text || defaultParams[type].text;
    const duration = params.duration || 3000;
    const group = params.group;

    return {
      id: (+new Date()).toString(36),
      type,
      title,
      text,
      duration,
      group,
    };
  };

  store.dispatch('notifications/addNotification', getParams(params));
};
