<template>
  <div
    class="table-header"
    :style="{
      display: grid ? 'grid' : '',
      'grid-template-columns': grid,
    }"
  >
    <div
      v-for="(column, index) in columns"
      :key="index"
      :style="{ width: `${column.size}%` }"
      :class="{
        alignLeft: column.align === 'left',
        alignCenter: column.align === 'center',
        noSort,
        first: index === 0,
        isPositions,
        amountField: index === 1,
        shiftField: index >= 1,
        last: columns.length - 1 === index,
      }"
      class="table-header__column"
    >
      <div class="field" @click="sort(column, index)">
        <div>
          {{ column.title }}
          <span v-if="!noSort && sortBy && column.title === sortBy">
            <template v-if="dataFields[index].reverse"> ↑</template>
            <template v-else> ↓</template>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, ref } from 'vue';

export default {
  name: 'TableHeader',
  props: {
    columns: {
      type: Array,
      required: true,
    },
    reverse: {
      type: Boolean,
      default: false,
    },
    noSort: {
      type: Boolean,
      default: false,
    },
    grid: {
      type: String,
      default: '',
    },
    isPositions: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const fields = {};
    const sortBy = ref('');
    props.columns.forEach((field, index) => {
      fields[index] = { reverse: props.reverse, prop: field.prop };
    });

    const dataFields = reactive(fields);

    const sort = (item, index) => {
      if (props.noSort) {
        return;
      }

      if (props.columns[index].title === sortBy.value) {
        dataFields[index].reverse = !dataFields[index].reverse;
      }
      sortBy.value = props.columns[index].title;
      emit('sort', {
        prop: item.prop,
        reverse: dataFields[index].reverse,
        title: item.title,
      });
    };

    return { dataFields, sort, sortBy };
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/colors';
@import '@/assets/styles/base';

.table-header {
  // Блок не должен влиять на свое окружение, т. е. блоку не следует задавать внешнюю геометрию (в виде отступов, границ, влияющих на размеры) и позиционирование.
  padding: 35px 20px 20px 20px;
  line-height: 1;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  width: 100%;
  border-bottom-width: 1px;

  @media (max-width: 1024px) {
    padding: 0 20px 20px 20px;
  }

  &.markets-table__header {
    padding: 20px 25px 20px 12px;
  }

  &__column {
    user-select: none;
    -webkit-user-select: none;
    font-family: Gotham_Pro_Regular;
    font-size: 13px;
    text-align: center;

    &.last {
      min-width: 80px;
    }

    &.first {
      margin-left: 7px; // -16px;

      &.isPositions {
        margin-left: 12px;
      }
    }

    &.amountField {
      margin-left: 1px;
    }

    &.shiftField {
      @media (min-width: 1580px) {
        margin-left: -1px;
      }

      @media (min-width: 1780px) {
        margin-left: -2px;
      }
    }

    &.alignLeft {
      text-align: left;
    }

    &.alignCenter {
      text-align: center;
    }

    .field {
      display: inline-block;

      &:hover {
        cursor: pointer;
      }
    }

    .sort {
      transform: rotate(0);
    }

    .sort.sorted {
      transform: rotate(180deg);
    }

    .title {
      display: inline-block;
      margin-right: 6px;
    }
  }
}

.table-header {
  .table-header__column.noSort {
    .field:hover {
      cursor: initial;
    }
  }
}
</style>
