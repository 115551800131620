import { computed } from 'vue';
import BigNumber from 'bignumber.js';
import { PositionSides } from '@/config/constants';

export default function useCalcPl(side, market, amount, entry_price, leverage) {
  const entryPrice = computed(() => {
    if (!entry_price) {
      return side === PositionSides.BUY ? market.buy : market.sell;
    }
    return entry_price;
  });

  const closedPrice = computed(() => {
    return side === PositionSides.BUY ? market.sell : market.buy;
  });

  const pl = computed(() => {
    const value =
      side === PositionSides.BUY
        ? (amount * (closedPrice.value - entryPrice.value)) / entryPrice.value
        : (amount * (entryPrice.value - closedPrice.value)) / entryPrice.value;

    return BigNumber(value).toFixed();
  });

  const pl_percent = computed(() => {
    const percent =
      (side === PositionSides.BUY
        ? (closedPrice.value - entryPrice.value) / entryPrice.value
        : (entryPrice.value - closedPrice.value) / entryPrice.value) *
      leverage *
      100;

    return percent;
  });

  return { pl, pl_percent };
}
