import { createStore } from 'vuex';
import graph from './modules/graph';
import notifications from './modules/notifications';
import connectors from './modules/connectors';
import volumeLimits from './modules/volumeLimits';
import email from './modules/email';
import usa from './modules/usa';
import liquidity from './modules/liquidity';
import fiorin from './modules/fiorin';
import telegram from './modules/telegram';
import localUiSettings from './modules/localUiSettings';
import createListenMutationPlugin from './plugins/listenMutation';

export const cachedPaths = [
  'session.logs',
  'user.truedexAuth',
  'connectors.activeConnect',
  'connectors.connects',
  'connectors.userName',
  'markets.dontShowAgain',
  'markets.markets',
  'burger.toggleProTrading',
  'burger.fiorinMode',
  'bounty.realBalance',
  'graph.savedCharts',
  'localUiSettings.defaultProfitBtnIndex',
  'localUiSettings.defaultLossBtnIndex',
  'localUiSettings.showPositionGroup',
];

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    graph,
    notifications,
    connectors,
    volumeLimits,
    email,
    usa,
    liquidity,
    fiorin,
    telegram,
    localUiSettings,
  },
  plugins: [createListenMutationPlugin()],
});
