import i18n from '@/i18n';
import axios from 'axios';
import config from '@/config';

const API_SERVICE_URL = `${config.apiUrl}ip-info`;

const types = {
  SET_LOCATION: 'SET_LOCATION',
};

const state = {
  location: null,
};

const getters = {
  getLocation: (state) => state.location,
};

const mutations = {
  [types.SET_LOCATION](state, data) {
    state.location = data;
  },
};

const actions = {
  async fetchIPAddress({ commit }) {
    let res;

    try {
      res = await axios.get(API_SERVICE_URL);
    } catch (err) {
      i18n.locale = localStorage.getItem('tdxp.locale') || 'en';
      commit(types.SET_LOCATION, 'US');
    }

    if (res?.data) {
      let countryCode = 'en';
      if (['EN', 'CN', 'ES', 'JP'].includes(res.data.countryCode)) {
        countryCode = res.data.countryCode.toLowerCase();
      }

      i18n.locale = localStorage.getItem('tdxp.locale') || countryCode;
      commit(types.SET_LOCATION, res.data);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
