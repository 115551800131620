import Connector from './Connector';
import axios from 'axios';

export default class DotwalletConnector extends Connector {
  constructor(opts) {
    super(opts);
    this.provider = 'DotWallet';
    this.code = opts.code;
    this.clientId = opts.clientId;
    this.accessToken = opts.accessToken;
    this.refreshToken = opts.refreshToken;
    this.authInfo = opts.authInfo || {};
    this.userInfo = opts.userInfo || {};

    this.satoshiBalance = 0; // 643178 / 100000000
  }

  async fetchBalance() {
    try {
      const result = await axios.get(
        `${process.env.VUE_APP_API_URL}wallets/dotwallet/user/balance`,
        {
          headers: {
            Authorization: `Bearer ${this.authInfo.accessToken}`,
          },
        }
      );
      this.satoshiBalance = result.data.total;
    } catch (err) {
      console.error(err);
      this.fetchRefreshToken();
    }
  }

  async authorizeCode(authCode) {
    try {
      let result = await axios.post(
        `${process.env.VUE_APP_API_URL}wallets/dotwallet/token`,
        { code: authCode, redirectUri: process.env.VUE_APP_UI_URL }
      );
      this.authInfo = result.data;
      this.accessToken = this.authInfo.accessToken;
      this.fetchRefreshToken();
    } catch (e) {
      console.error(e);
    }
  }

  async fetchProfile() {
    try {
      const result = await axios.post(
        `https://api.ddpurse.com/v1/user/get_user_info`,
        {},
        {
          headers: {
            Authorization: `Bearer ${this.authInfo.accessToken}`,
          },
        }
      );
      this.userInfo = result.data.data;
    } catch (e) {
      console.error(e);
    }
  }

  async fetchRefreshToken() {
    try {
      const result = await axios.post(
        `${process.env.VUE_APP_API_URL}wallets/dotwallet/token/refresh`,
        { refreshToken: this.authInfo.refreshToken }
      );
      this.authInfo = result.data;
      this.accessToken = this.authInfo.accessToken;
    } catch (e) {
      console.error(e);
    }
  }

  async sendDotwalletTx() {}

  async getClientId() {
    try {
      const result = await axios.get(
        `${process.env.VUE_APP_API_URL}wallets/dotwallet/config`
      );

      if (result.data) {
        this.clientId = result.data.clientId;
      }
    } catch (e) {
      console.error(e);
    }
  }

  async connect() {
    if (!this.accessToken) {
      await this.getClientId();

      if (this.clientId) {
        window.location.href = `https://api.ddpurse.com/v1/oauth2/authorize?client_id=${this.clientId}&redirect_uri=${process.env.VUE_APP_UI_URL}&response_type=code&state=dotwalletcode&scope=user.info%20autopay.bsv`;
      }
    }
  }
}
