/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'buy': {
    width: 23.383,
    height: 23.382,
    viewBox: '0 0 23.383 23.382',
    data: '<path pid="0" _fill="#79C610" d="M4.019 23.324h15.345a3.971 3.971 0 003.96-3.96V4.019a3.971 3.971 0 00-3.96-3.96H4.019a3.972 3.972 0 00-3.96 3.96v15.345a3.972 3.972 0 003.96 3.96zm2.559-10.163l3.989-5.781c.618-.896 1.631-.896 2.249 0l3.989 5.781c.618.896.233 1.629-.856 1.629H7.433c-1.089.001-1.474-.733-.855-1.629z"/>'
  }
})
