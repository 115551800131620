export const demoPositions = [
  [
    'Apple',
    {
      id: 9,
      market_id: 20,
      state: 'opened',
      proposed: new Date(
        new Date().setDate(new Date().getDate() - 1)
      ).toISOString(),
      opened: new Date(
        new Date().setDate(new Date().getDate() - 1)
      ).toISOString(),
      side: 'sell',
      take_profit_price: 280,
      base_currency: 'USD',
      amount: 200,
      leverage: 1.0,
      entry_price: 230,
      decay_rate: 0.00018,
      decay: 0,
    },
  ],
  [
    'NVIDIA',
    {
      id: 10,
      market_id: 29,
      state: 'opened',
      proposed: new Date(
        new Date().setDate(new Date().getDate() - 1)
      ).toISOString(),
      opened: new Date(
        new Date().setDate(new Date().getDate() - 1)
      ).toISOString(),
      side: 'buy',
      take_profit_price: 320,
      stop_loss_price: 99,
      base_currency: 'USD',
      amount: 300,
      leverage: 1.0,
      entry_price: 135,
      decay_rate: 0.00018,
      decay: 0,
    },
  ],
  [
    'BSV/USD',
    {
      id: 1,
      market_id: 45,
      state: 'opened',
      proposed: new Date(
        new Date().setDate(new Date().getDate() - 1)
      ).toISOString(),
      opened: new Date(
        new Date().setDate(new Date().getDate() - 1)
      ).toISOString(),
      side: 'buy',
      base_currency: 'USD',
      amount: 110,
      leverage: 2.0,
      entry_price: 26,
      decay_rate: 0.00018,
      decay: 0,
    },
  ],
  [
    'BTC/USD',
    {
      id: 2,
      market_id: 9,
      state: 'opened',
      proposed: new Date(
        new Date().setDate(new Date().getDate() - 1)
      ).toISOString(),
      opened: new Date(
        new Date().setDate(new Date().getDate() - 1)
      ).toISOString(),
      side: 'buy',
      base_currency: 'USD',
      take_profit_price: 45000,
      amount: 650,
      leverage: 7.0,
      entry_price: 47500,
      decay_rate: 0.00018,
      decay: 0,
    },
  ],
  [
    'BTC/USD',
    {
      id: 8,
      market_id: 9,
      state: 'opened',
      proposed: new Date(
        new Date().setDate(new Date().getDate() - 1)
      ).toISOString(),
      opened: new Date(
        new Date().setDate(new Date().getDate() - 1)
      ).toISOString(),
      side: 'buy',
      base_currency: 'USD',
      stop_loss_price: 18300,
      amount: 700,
      leverage: 3.0,
      entry_price: 48600,
      decay_rate: 0.00018,
      decay: 0,
    },
  ],
  [
    'Silver',
    {
      id: 4,
      market_id: 5,
      state: 'opened',
      proposed: new Date(
        new Date().setDate(new Date().getDate() - 1)
      ).toISOString(),
      opened: new Date(
        new Date().setDate(new Date().getDate() - 1)
      ).toISOString(),
      side: 'sell',
      base_currency: 'USD',
      take_profit_price: 38,
      stop_loss_price: 22,
      amount: 105,
      leverage: 2.0,
      entry_price: 27,
      decay_rate: 0.00018,
      decay: 0,
    },
  ],
  [
    'EUR/USD',
    {
      id: 5,
      market_id: 105,
      state: 'opened',
      proposed: new Date(
        new Date().setDate(new Date().getDate() - 1)
      ).toISOString(),
      opened: new Date(
        new Date().setDate(new Date().getDate() - 1)
      ).toISOString(),
      side: 'buy',
      base_currency: 'USD',
      take_profit_price: 1.12,
      amount: 850,
      leverage: 2.0,
      entry_price: 1.12,
      decay_rate: 0.00018,
      decay: 0,
    },
  ],
  [
    'EUR/USD',
    {
      id: 6,
      market_id: 105,
      state: 'opened',
      proposed: new Date(
        new Date().setDate(new Date().getDate() - 1)
      ).toISOString(),
      opened: new Date(
        new Date().setDate(new Date().getDate() - 1)
      ).toISOString(),
      side: 'buy',
      base_currency: 'USD',
      amount: 220,
      leverage: 20,
      entry_price: 1.1,
      decay_rate: 0.00018,
      decay: 0,
    },
  ],
];

export const demoFavourites = [
  'Silver',
  'BSV/USD',
  'BTC/USD',
  'ETH/USD',
  'EUR/USD',
  'Nasdaq 100',
  'S&P 500',
  'Apple',
  'Tesla',
];
