<template>
  <Modal stop-mobile-max-height to-top @close="$emit(EVENTS.close)">
    <Card
      :title="$t('popups.liquidityFundraiser.confirm').toUpperCase()"
      class="card"
      :body-class="'card-full'"
    >
      <div class="confirm-refund">
        <template v-if="activeConnect.provider !== userProviders.fiorin">
          <p
            v-html="
              $t('popups.liquidityFundraiser.pleaseConfirm1', {
                txId: refundTx.transactionId,
                date: new Date(refundTx.transactionDate).toLocaleDateString(),
                info:
                  refundTx.amountAvailableStatus == 'PartiallyWithdrawable'
                    ? $t('popups.liquidityFundraiser.pleaseConfirm2')
                    : '',
              })
            "
          />
          <p>{{ $t('popups.liquidityFundraiser.pleaseConfirm3') }}</p>
        </template>
        <template v-else>
          <p
            v-html="
              $t('popups.liquidityFundraiser.pleaseConfirm.usd', {
                txId: refundTx.transactionId,
                amount: amountAvailable,
                date: new Date(refundTx.transactionDate).toLocaleDateString(),
              })
            "
          />
          <p>{{ $t('popups.liquidityFundraiser.pleaseConfirm3.usd') }}</p>
        </template>
        <ItemLabelValue
          :label="$t('popups.liquidityFundraiser.pleaseConfirm4')"
          :value="amountAvailable"
        />
        <p>
          {{ $t('popups.liquidityFundraiser.pleaseConfirm5') }}
          <a
            class="decoration--underlined underline"
            href="https://t.me/dxsapp"
            rel="noopener noreferrer"
            target="_blank"
          >
            {{ $t('popups.liquidityFundraiser.pleaseConfirm6') }}
          </a>
          &nbsp;<span>{{
            $t('popups.liquidityFundraiser.pleaseConfirm7')
          }}</span
          >&nbsp;<a
            class="underline cursor-pointer text-xs-sm"
            href="https://faq.dxs.app/policies/liquidity-provider-agreement"
            target="_blank"
            rel="noopener noreferrer"
          >
            {{ $t('popups.liquidityFundraiser.agreement') }}
          </a>
        </p>
      </div>
    </Card>
    <div
      :class="{
        standalone:
          wd.navigator.standalone || isRunningStandaloneAndroid() || isTelegram,
      }"
      class="btns"
    >
      <Button
        :text="$t('popups.buttons.cancel')"
        type="secondary"
        @click="$emit('close')"
      />
      <Button
        :loading="isRefundLoading"
        :text="$t('popups.buttons.confirm')"
        type="secondary"
        @click="confirmRefund"
      />
    </div>
  </Modal>
</template>
<script>
import Modal from '@/modules/modals/Modal';
import Card from '@/components/ui/Card';
import ItemLabelValue from '@/components/ui/ItemLabelValue';
import Button from '@/components/ui/Button';
import notify from '@/plugins/notify';
import { ref, computed } from 'vue';
import { userProviders } from '@/modules/user/api';
import { toCurrencyFormat } from '@/helpers/utils';
import { getErrorDetails, connApp } from '@/helpers/signalR';
import { useI18n } from 'vue-i18n';
import useBalance from '@/compositions/useBalance';
import { useStore } from 'vuex';
import { isRunningStandaloneAndroid } from '@/helpers/detects';

const EVENTS = {
  close: 'close',
  showAgreementModal: 'showAgreementModal',
  successConfirm: 'successConfirm',
};

export default {
  components: { Modal, Card, Button, ItemLabelValue },
  emits: [EVENTS.close, EVENTS.showAgreementModal, EVENTS.successConfirm],
  props: {
    refundTx: {
      required: true,
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const isRefundLoading = ref(false);
    const { fetchWalletBalance } = useBalance();
    const store = useStore();

    const activeConnect = computed(
      () => store.getters['connectors/activeConnect']
    );

    const amountAvailable = computed(() => {
      const amount = props.refundTx.amountAvailable;
      const isFiorin = activeConnect.value.provider === userProviders.fiorin;
      const prefix = isFiorin ? '$' : '';

      return prefix + +amount.toFixed(amount ? 5 : 2);
    });

    const confirmRefund = async () => {
      if (isRefundLoading.value) {
        return;
      }

      isRefundLoading.value = true;

      await connApp
        .invoke('QueueUserLiquidityRefundV2', props.refundTx.transactionId)
        .catch((err) => {
          notify({
            text: getErrorDetails(err).message,
            type: 'info',
          });
          isRefundLoading.value = false;

          return;
        });

      notify({
        text: t('order.new.toasts.refund'),
        type: 'info',
      });

      isRefundLoading.value = false;

      fetchWalletBalance();

      emit(EVENTS.successConfirm);
      emit(EVENTS.close);
    };

    const isTelegram = computed(() =>
      Boolean(store.getters['telegram/instance'])
    );

    return {
      toCurrencyFormat,
      confirmRefund,
      amountAvailable,
      isRefundLoading,
      EVENTS,
      activeConnect,
      userProviders,
      wd: window,
      isTelegram,
      isRunningStandaloneAndroid,
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/colors';
@import '@/assets/styles/base';

.confirm-refund {
  font-family: Gotham_Pro_Regular;
  line-height: 18px;
  max-height: calc(100vh - 112px);
  margin-bottom: 55px;
  padding-left: $px20;
  padding-right: $px20;
  overflow-y: auto;

  a {
    text-decoration-line: underline;
    text-decoration-thickness: 1px;
  }

  span.underline {
    cursor: pointer;
    text-decoration-line: underline;
    text-decoration-thickness: 1px;
  }

  div {
    margin-top: 15px;
  }
}

.btns {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;

  @media screen and (max-width: 1024px) {
    bottom: 50px;

    &.standalone {
      bottom: 82px;
    }
  }
}
</style>
