export default {
  wsPath: process.env.VUE_APP_WS_PATH,
  apiUrl: process.env.VUE_APP_API_URL,
  uiUrl:
    process.env.VUE_APP_UI_URL ||
    (process.env.VUE_APP_API_URL || '').replace('/api/', '/index'),
  mbRedirectUri: process.env.VUE_APP_UI_URL, //  + '/auth/mb/redirect',
  connectionStatusLostTime: parseInt(
    process.env.VUE_APP_CONNECTION_STATUS_LOST_TIME || '30000'
  ),
  isProduction: process.env.VUE_APP_IS_PRODUCTION === 'true',
  mbClientId: process.env.VUE_APP_MB_CLIENT_ID,
  hcClientId: process.env.VUE_APP_HC_CLIENT_ID,
  mbOauthId: process.env.VUE_APP_MB_OAUTH_ID,
  txPrefix: process.env.VUE_APP_TX_PREFIX,
  mbClientIdBtn: process.env.VUE_APP_MB_CLIENT_BTN,
  maxAmountCeiling: process.env.VUE_APP_MAX_AMOUNT_CEILING,
};
