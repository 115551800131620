/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'moneybutton': {
    width: 26,
    height: 17,
    viewBox: '0 0 26 17',
    data: '<path pid="0" d="M21.655.21h.127a3.654 3.654 0 013.654 3.655v8.738a3.718 3.718 0 01-7.436 0V3.865A3.654 3.654 0 0121.655.21zm.063 14.871a2.479 2.479 0 100-4.957 2.479 2.479 0 000 4.957zM12.979.21h.127a3.655 3.655 0 013.655 3.655v8.738a3.718 3.718 0 01-7.437 0V3.865A3.654 3.654 0 0112.98.21zM4.303.21h.127a3.654 3.654 0 013.655 3.655v8.738a3.718 3.718 0 01-7.437 0V3.865A3.654 3.654 0 014.303.21z" _fill="#fff"/>'
  }
})
