import {
  toCurrencyFormat,
  getFormatedAmount,
  shortBigNumber,
} from '@/helpers/utils';
import { floatList } from '@/helpers/floatList';
import DateTime from 'luxon/src/datetime.js';
import { PositionSides } from '@/config/constants';

/* const formatAmount = (amount) => {
  if (amount === null) {
    return 'N/A';
  }
  if (amount.length >= 10 && amount[0] === '0') {
    return parseFloat(amount).toString().slice(1);
  } else {
    return amount;
  }
}; */

export default function usePositionCalcs() {
  const calcAmount = (position) => {
    if (position.base_currency === 'USD') {
      return getFormatedAmount(position.amount, true);
    }

    const amount = parseFloat(toCurrencyFormat(position.amount, null, 4));
    return amount === 0 ? '0.0' : amount;
  };

  const calcEntryPrice = (position) => {
    const entryPrice = toCurrencyFormat(
      position.entry_price,
      null,
      floatList[position.market]
    ).toString();
    return entryPrice;
    // return formatAmount(entryPrice);
  };

  const calcCurrentPrice = (position, markets) => {
    let priceKey;
    if (position.state === 'proposed') {
      priceKey =
        position.side === PositionSides.BUY
          ? PositionSides.BUY
          : PositionSides.SELL;
    } else {
      priceKey =
        position.side === PositionSides.BUY
          ? PositionSides.SELL
          : PositionSides.BUY;
    }

    const market = markets.find((market) => {
      return market.id === position.market_id;
    });

    const currentPrice = toCurrencyFormat(
      (market && market[priceKey]) || 0,
      null,
      floatList[position.market]
    ).toString();
    return currentPrice;
    // return formatAmount(currentPrice);
  };

  const calcClosePrice = (position) => {
    const closePrice = toCurrencyFormat(
      position.closed_price,
      null,
      floatList[position.market]
    ).toString();
    return closePrice;
    // return formatAmount(closePrice);
  };

  const calcTakeProfitPrice = (position) => {
    if (floatList[position.market] && position.take_profit_price) {
      const takeProfitPrice = toCurrencyFormat(
        position.take_profit_price,
        null,
        floatList[position.market]
      ).toString();
      return takeProfitPrice;
      // return formatAmount(takeProfitPrice);
    }
    return toCurrencyFormat(position.take_profit_price);
  };

  const calcStopLossPrice = (position) => {
    if (floatList[position.market] && position.stop_loss_price) {
      const stopLossPrice = toCurrencyFormat(
        position.stop_loss_price,
        null,
        floatList[position.market]
      ).toString();
      return stopLossPrice;
      // return formatAmount(stopLossPrice);
    }
    return toCurrencyFormat(position.stop_loss_price);
  };

  const calcPlPercent = (position, parseBigNumber, key) => {
    const dynamicKey = key ? position[key] : position.pl_percent;
    const percentVal = dynamicKey ? dynamicKey.toFixed(2) : 0;
    const sign = percentVal > 0 ? '+' : percentVal < 0 ? '' : '';

    if (parseBigNumber) {
      return sign + shortBigNumber(percentVal);
    }
    return sign + percentVal;
  };

  const calcPL = (position, parseBigNumber, key, keyPercent) => {
    const dynamicKey = key ? position[key] : position.pl;
    const dynamicKeyPercent = keyPercent
      ? position[keyPercent]
      : position.pl_percent;

    const PL =
      position.base_currency === 'USD'
        ? getFormatedAmount(dynamicKey, true)
        : toCurrencyFormat(dynamicKey, null, 5);
    const sign = dynamicKeyPercent > 0 ? '+' : dynamicKeyPercent < 0 ? '-' : '';

    if (parseBigNumber) {
      return sign + shortBigNumber(PL.toString().replace('−', ''));
    }
    return sign + PL.toString().replace('−', '');
  };

  const calcPendingValueDiff = (pos, parseBigNumber) => {
    if (pos.type === 'pending') {
      const val = pos.entry_price - pos.closed_price;
      const price =
        pos.base_currency === 'USD'
          ? getFormatedAmount(val, true)
          : toCurrencyFormat(val, null, 5);

      if (parseBigNumber) {
        if (price.toString().replaceAll(' ', '') >= 0) {
          return `+${shortBigNumber(price.toString().replaceAll(' ', ''))}`;
        }
        return '-' + shortBigNumber(price.toString().replace('−', ''));
      }

      if (price.toString().replaceAll(' ', '') >= 0) {
        return `+${price}`;
      }
      return '-' + price.toString().replace('−', '');
    }
    return '';
  };

  const calcCreatedUp = (position) => {
    return DateTime.fromISO(position.proposed).toUTC().toFormat('dd.MMM.yy');
  };

  const calcCreatedDown = (position) => {
    return DateTime.fromISO(position.proposed).toUTC().toFormat('HH:mm');
  };

  const calcClosedUp = (position) => {
    return DateTime.fromISO(position.closed || position.proposed)
      .toUTC()
      .toFormat('dd.MMM.yy');
  };

  const calcClosedDown = (position) => {
    return DateTime.fromISO(position.closed || position.proposed)
      .toUTC()
      .toFormat('HH:mm');
  };

  return {
    calcAmount,
    calcEntryPrice,
    calcClosePrice,
    calcCurrentPrice,
    calcTakeProfitPrice,
    calcStopLossPrice,
    calcPlPercent,
    calcPL,
    calcCreatedUp,
    calcCreatedDown,
    calcClosedUp,
    calcClosedDown,
    calcPendingValueDiff,
  };
}
